import React, { Component } from 'react';
import { Container, Row, Col, Spinner, ListGroup } from 'react-bootstrap';
import { FaPlus } from "react-icons/fa";
import { API, graphqlOperation } from 'aws-amplify'
import { listActivities } from '../../graphql/queries';
import UserChallenges from '../widgets/UserChallenges'

class UserChallengesView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            isLoading: false
        }
    }

    async componentDidMount() {
        this.setState({
            isLoading: true
        });

        API.graphql(graphqlOperation(listActivities)).then((result) => {
            this.setState({ list: result.data.listActivities.items, isLoading: false });
        })        
    }

    selectUserChallenge(e, activity) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            activity: activity,
            mode: 'updateUserChallenge',
        });
    }

    createUserChallenge(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            mode: 'createUserChallenge'
        })
    }

    refreshChallenges(data) {
        this.setState({isLoading: true})
        API.graphql(graphqlOperation(listActivities)).then((result) => {
            this.setState({ list: result.data.listActivities.items, isLoading: false });
        }).catch(e => {
            console.debug('Error on refreshChallenges ',e)
            this.setState({isLoading: false})
        })
    }

    render() {
        const { list, activity, mode } = this.state;
        
        return <Container fluid>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h3>User Challenges</h3>
                        </Col>
                        <Col>
                            <FaPlus className="float-right" onClick={(e) => this.createUserChallenge(e)} />
                        </Col>
                    </Row>
                    <Row>
                    { this.state.isLoading ? 
                        <Spinner animation="border" />
                    : 
                        <Col className="scrollview">
                            <ListGroup variant="flush">
                            {
                                list.length > 0 ?
                                    list.map((item) =>
                                        <ListGroup.Item key={`list-userChallenges-${item.activityId}`} action onClick={(e) => this.selectUserChallenge(e, item)}>
                                            <div>
                                                { item.title }
                                            </div>
                                            <small>Coin value: { item.coinValue }</small>
                                        </ListGroup.Item>
                                    )
                                : 'No User Challenge found'
                            }
                            </ListGroup>
                        </Col>
                    }
                    </Row>
                </Col>

                <Col className="scrollview">
                    <UserChallenges
                        mode='create'
                        key={`create-userChallenge`}
                        onCreate={(data) => this.refreshChallenges(data)}
                        show={mode === 'createUserChallenge'}
                    />

                    <UserChallenges
                        mode='update'
                        key={`update-userChallenge-${activity ? activity.activityId : ''}`}
                        activity={activity}
                        onUpdate={(data) => this.refreshChallenges(data)}
                        show={mode === 'updateUserChallenge'}
                    />
                </Col>
            </Row>            
        </Container>
    }
}

export default UserChallengesView