import React, { Component } from 'react'
import { Form, Badge } from "react-bootstrap"

class CMSTag extends Component {

    constructor(props) { //eslint-disable-line
        super(props)
    }

    toggleTag(e, tag) {
        let tags = this.props.tags
        if (tags.includes(tag)) {
            tags = tags.filter((t) => t != tag)
        } else {
            tags.unshift(tag)
        }

        this.props.onChange(tags)
    }
    

    handleChange(e) {
        const tags = e.target.value.toLowerCase().split('\n')
        this.props.onChange(tags)
    }

    render() {
        return (
            <div>
                <Form.Label>{this.props.label? this.props.label: 'Tags'}</Form.Label>
                {this.props.predefinedTags ?
                    this.props.predefinedTags.map((tag) => <Badge pill variant={this.props.tags.includes(tag)?'primary':'secondary'} className="ml-1" style={{ cursor: 'pointer' }} onClick={(e)=>this.toggleTag(e, tag)}>{tag}</Badge>)
                : null}
                <Form.Control as="textarea" rows={3} disabled={this.props.disabled} readOnly={this.props.readOnly} value={this.props.tags.join('\n')} onChange={(e) => this.handleChange(e)} />
            </div>
        )
    }
}

export default CMSTag