import { API, graphqlOperation } from 'aws-amplify'
import { createMerchant, updateMerchant, deleteTags, createTags } from '../graphql/mutations'
import { tagById, getMerchant, listMerchantOutlets } from '../graphql/queries'
import ACL from '../utils/ACL'
import * as utils from '../utils'
import aws_exports from '../aws-exports'

import Model from './Model'
export default class MerchantModel {

    indices = ['subc_online', 'mainCat', 'ecommerce', 'merchant_name_lowercase']
    item = null

    constructor(item) {
        this.item = {
            merchant_id: item.merchant_id??'',
            merchant_name: item.merchant_name,
            merchant_name_lowercase: item.merchant_name_lowercase,
            display_merchant_name: item.display_merchant_name,
            mainCat: item.mainCat,
            google_type: item.google_type,
            valid: item.valid,
            acceptCC: item.acceptCC,
            merchantImageUrl: item.merchantImageUrl??'',
            merchantImageUrlV2: item.merchantImageUrlV2??'',
            defaultImageUrl: item.defaultImageUrl??'',
            pockCalMerchImage: item.pockCalMerchImage??''
        }
    }

    async setTags(originalTags, tags) {
        if (originalTags) {
            for (const tag of originalTags) {
                const result = await API.graphql(graphqlOperation(deleteTags, {
                    input:
                    {
                        id: this.item.merchant_id,
                        tagName: tag
                    }
                }))
            }
        }

        if (tags) {
            for (const tag of tags) {
                if (tag.trim() !== '') {
                    const result = await API.graphql(graphqlOperation(createTags, {
                        input: {
                            id: this.item.merchant_id, 
                            tagName: tag,
                            merchantId: this.item.merchant_id,
                            tagType: 'merchant'
                        }
                    }))
                    console.log(result) 
                }
            }
        }
    }

    async getTags() {
        const search = {
            id: this.item.merchant_id
        }
        let tags = []
        do {
            const result = await API.graphql(graphqlOperation(tagById, search))
            tags = tags.concat(result.data.tagById.items)
            search.nextToken = result.data.tagById.nextToken
        } while (search.nextToken)

        tags = tags.map(tag => tag.tagName)

        return tags
    }

    async getOutlets() {
        const result = await API.graphql(graphqlOperation(listMerchantOutlets, { merchant_id: this.item.merchant_id }))

        const outlets = result.data.listMerchantOutlets.items
        return outlets
    }

    async invalidate() {
        this.item.valid = 'N'
        return await this.update()
    }

    async validate() {
        this.item.valid = 'Y'
        return await this.update()
    }

    static async get(id) {
        const result = await API.graphql(graphqlOperation(getMerchant, { merchant_id: id }))
     
        return result.data.getMerchant
    }

    async create() {
        // remove indices with null values. ddb will throw error. cannot have null values in indices
        for (const index of this.indices) {
            if (index in this.item && (this.item[index] === null || this.item[index] === '' || this.item[index].toLowerCase() === 'null')) {
                delete this.item[index]
            }
        }

        const result = await API.graphql(graphqlOperation(createMerchant, { input: this.item }))
     
        this.item = result.data.createMerchant

        if (await ACL.isMerchant()) {
            console.log({
                username: (await ACL.getAttributes()).email,
                merchant_id: this.item.merchant_id
            })
            const response = await fetch(`${aws_exports.aws_cloud_logic_custom[0].endpoint}/access`, {
                method: 'POST',
                body: JSON.stringify({
                    username: (await ACL.getAttributes()).email,
                    merchant_id: this.item.merchant_id
                }),
            })
            const json = await response.json()
            console.log(json)
        }

        return this.item
    }

    async update(options) {

        // nullify indices with empty space values. ddb will throw error. cannot have empty values in indices
        for (const index of this.indices) {
            console.log(index, this.item[index])
            if (index in this.item && this.item[index] !== null && (this.item[index] === '' || this.item[index].toLowerCase() === 'null')) {
                this.item[index] = null
            }
        }

        const result = await API.graphql(graphqlOperation(updateMerchant, { input: this.item }))
        console.log(result)

        this.item = result.data.updateMerchant

        if (options && options.invalidateCloudFrontCache) {
            options.invalidateCloudFrontCache.forEach((key) => {
                utils.invalidateCloudFrontCache(this.item[key])
            })
        }

        // retrieve all outlets for merchant
        const outlets = await Model.listAll('MerchantOutlet', {
            merchant_id: this.item.merchant_id
        })
        this.item.outlets.items = outlets

        return this.item
    }
}
