import React, { Component } from 'react'
import { Form, Button, Toast } from "react-bootstrap"
import { API, graphqlOperation } from 'aws-amplify'
import { createAnnouncement, updateAnnouncement } from '../../graphql/mutations'
import { guidGenerator } from '../../utils/index'
class Announcement extends Component {

    constructor(props) {
        super(props)

        const announcement = props.announcement ? { ...props.announcement } : {};
        this.state = {
            item: {
                announcementId: announcement.announcementId??guidGenerator(),
                active: announcement.active??'false',
                message: announcement.message??'',
            },
            showToast: false
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        const announcement = {
            announcementId: this.state.item.announcementId,
            active: this.state.item.active,
            message: this.state.item.message,
        };

        if (this.props.mode === 'create') {
            this.handleCreate(announcement);
        } else if (this.props.mode === 'update') {
            this.handleUpdate(announcement);
        }
    }

    handleCreate(announcement) {
        API.graphql(graphqlOperation(createAnnouncement, { input: announcement })).then((result) => {
            console.log('create announcement: ', announcement);
            this.setState({ showToast: true });
            if (this.props.onCreate) {
                this.props.onCreate(result.data);
            }
        })
    }

    handleUpdate(announcement) {
        API.graphql(graphqlOperation(updateAnnouncement, { input: announcement })).then((result) => {
            console.log('update announcement: ', result);
            this.setState({ showToast: true });
            if (this.props.onUpdate) {
                this.props.onUpdate(result.data);
            }
        });
    }

    handleChange(e, fieldname) {
        const item = { ...this.state.item };
        const target = e.target;
        if (target.type === 'checkbox') {
            item[fieldname] = e.target.checked ? 'true' : 'false';
        } else {
            item[fieldname] = e.target.value;
        }
        this.setState({ item: item });
    }

    render() {
        return <div>
            {this.props.show ?
                <Form onSubmit={(e) => this.handleSubmit(e)}>

                    <h3>{this.props.title??this.props.mode==='create'?'Create Announcement':'Update Announcement'}</h3>
                    <Form.Group>
                        <Form.Label>Announcement Id</Form.Label>
                        <Form.Control type="text" disabled readOnly value={this.state.item.announcementId} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Announcement Message *</Form.Label>
                        <Form.Control required type="text" value={this.state.item.message} onChange={(e) => this.handleChange(e, 'message')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check label="Active" checked={this.state.item.active === 'true'} onChange={(e) => this.handleChange(e, 'active')} />
                    </Form.Group>

                    <Form.Group>
                        <Toast onClose={() => this.setState({ showToast: false })} show={this.state.showToast} delay={3000} autohide>
                            {this.props.mode==='create'?
                                <Toast.Body>Announcement Created.</Toast.Body>
                            : null}
                            {this.props.mode==='update'?
                                <Toast.Body>Announcement Updated.</Toast.Body>
                            : null}
                        </Toast>
                            {this.props.mode==='create'?
                                <Button type="submit">Create Announcement</Button>
                            : null}
                            {this.props.mode==='update'?
                                <Button type="submit">Update Announcement</Button>
                            : null}
                    </Form.Group>

                </Form>
                : null}
        </div>
            ;
    }
}

export default Announcement