import React, { Component } from 'react'
import { Container, Row, Col, Nav, Button, Modal } from "react-bootstrap"
import { FaPlus, FaCrown } from "react-icons/fa"
import 'bootstrap/dist/css/bootstrap.min.css'
import { API, graphqlOperation, Hub } from 'aws-amplify'
import moment from 'moment'
import { getSubPlan, getMerchant, dealByDealHandler, dealByAggregator, outletByPostalCode } from '../../graphql/queries'
import { publish } from '../widgets/CMSModal'
import ACL from '../../utils/ACL'
import { dealByMerchantId } from '../../graphql/queries'

import ListUsers from '../widgets/ListUsersV2'
import ListMerchants from '../widgets/ListMerchants'
import ListOutlets from '../widgets/ListOutlets'
import ListDeals from '../widgets/ListDeals'
import Merchant from '../widgets/Merchant'
import Outlet from '../widgets/Outlet'
import Deal from '../widgets/Deal'
import Extract from '../widgets/Extract'

import CMSLegend from '../widgets/CMSLegend'
import ApprovalDeal from '../views/ApprovalDealV2'
import AnnouncementView from './AnnouncementView'
import BannerView from './BannerView'
import CardView from './CardView'
import CampaignView from './CampaignView'
import CacheView from './CacheView'
import NotificationView from './NotificationView'
import {SubPlans, CMSSubPlan} from '../widgets/CMSSubPlan'
import SearchKeywordView from './SearchKeywordView';
import CouponsView from './CouponsView'
import CategoriesView from './CategoriesView';
import UserChallengesView from './UserChallengesView'
import PinnedDealsView from './PinnedDealsView'
import DailyTipsView from './DailyTipsView'
import DynamicContentView from './DynamicContentView'
import DynamicIconView from './DynamicIconView'
import DiscountCodeView from './DiscountCodeView'
import AdsView from './AdvertisementView'

class AdminView extends Component {

  tabs = [
    { key: 'home', text: 'Home' },
    { key: 'approval', text: 'Approval', component: <ApprovalDeal /> },
    { key: 'announcement', text: 'Announcement', component: <AnnouncementView /> },
    { key: 'banner', text: 'Banner', component: <BannerView /> },
    { key: 'card', text: 'Card', component: <CardView /> },
    { key: 'campaign', text: 'Campaign', component: <CampaignView /> },
    { key: 'user', text: 'User', component: <ListUsers /> },
    { key: 'cache', text: 'Cache', component: <CacheView /> },
    { key: 'extract', text: 'Extract', component: <Extract /> },
    { key: 'notification', text: 'Notification', component: <NotificationView /> },
    { key: 'searchKeyword', text: 'Trending Search', component: <SearchKeywordView /> },
    { key: 'categories', text: 'Categories', component: <CategoriesView /> },
    { key: 'coupons', text: 'Coupons', component: <CouponsView /> },
    { key: 'userChallenges', text: 'User Challenges', component: <UserChallengesView /> },
    { key: 'pinnedDeals', text: 'Pinned Deals', component: <PinnedDealsView /> },
    { key: 'dailytips', text: 'Daily Tips', component: <DailyTipsView /> },
    { key:  'dynamicContent', text:'Dynamic Content', component: <DynamicContentView /> },
    { key:  'dynamicIcon', text:'Dynamic Icon', component: <DynamicIconView /> },
    { key:  'discountCode', text:'Discount Code', component: <DiscountCodeView /> },
    { key:  'popupAds', text:'Popup Ads', component: <AdsView /> }
  ]

  views = this.tabs.reduce((a, b) => {
    a[b.key] = b.component
    return a
  }, {})

  constructor(props) {
    super(props)
    
    this.state = {
      mode: '',
      page: 'home',
      ...props,
      merchants: [],
      merchant: null
    }
  }

  async componentDidMount() {
    const attributes = await ACL.getAttributes()
    const isAdmin = await ACL.isAdmin()
    const plan = (await API.graphql(graphqlOperation(getSubPlan, { email: attributes.email }))).data.getSubPlan
    const subplan = plan?plan.plan:'free'
    const matchedSubPlan = SubPlans.filter(plan => plan.code === subplan)
    const subplanName = isAdmin?'Admin':matchedSubPlan.length>0?matchedSubPlan[0].heading:subplan

    this.setState({
        isLoading: true,
        subplan,
        subplanName
    })

    let merchants = []
    if (await ACL.isMerchant()) {
        const merchantIds = await ACL.getMerchantsFromGroups()

        for (const merchantId of merchantIds) {
            const result = await API.graphql(graphqlOperation(getMerchant, { merchant_id: merchantId }))
            merchants.push(result.data.getMerchant)
        }
    }
    
    if (await ACL.isHandler()) {
        const dealHandlers = await ACL.getHandlersFromGroups()
        console.log('dealHandlers', dealHandlers)
        for (const dealHandler of dealHandlers) {
            let merchantIds = new Set()
            let token = null
            do {
                const result = await API.graphql(graphqlOperation(dealByDealHandler, { deal_handler: dealHandler, nextToken: token }))
                console.log('result', result)
                const deals = result.data.dealByDealHandler.items
                for (const deal of deals) {
                    merchantIds.add(deal.merchant_id)
                }
                token = result.data.dealByDealHandler.nextToken
                console.log('token', token)
                console.log('deals', deals)
            } while (token)
            for (const merchantId of merchantIds) {
                const result = await API.graphql(graphqlOperation(getMerchant, { merchant_id: merchantId }))
                merchants.push(result.data.getMerchant)
            }   
        }
    }

    if (await ACL.isPostal()) {
        const postals = await ACL.getPostalsFromGroups()
        console.log('postals', postals)
        for (const postal of postals) {
            let merchantIds = new Set()
            let token = null
            do {
                const result = await API.graphql(graphqlOperation(outletByPostalCode, { postal_code: postal, nextToken: token }))
                console.log('result', result)
                const outlets = result.data.outletByPostalCode.items
                for (const outlet of outlets) {
                    merchantIds.add(outlet.merchant_id)
                }
                token = result.data.outletByPostalCode.nextToken
                console.log('token', token)
                console.log('outlets', outlets)
            } while (token)
            for (const merchantId of merchantIds) {
                const result = await API.graphql(graphqlOperation(getMerchant, { merchant_id: merchantId }))
                merchants.push(result.data.getMerchant)
            }   
        }
    }

    if (await ACL.isAggregator()) {
        const aggregators = await ACL.getAggregatorsFromGroups()
        console.log('aggregators', aggregators)
        for (const aggregator of aggregators) {
            let merchantIds = new Set()
            let token = null
            do {
                const result = await API.graphql(graphqlOperation(dealByAggregator, { aggregator: aggregator, nextToken: token }))
                console.log('result', result)
                const deals = result.data.dealByAggregator.items
                for (const deal of deals) {
                    merchantIds.add(deal.merchant_id)
                }
                token = result.data.dealByAggregator.nextToken
                console.log('token', token)
                console.log('deals', deals)
            } while (token)
            for (const merchantId of merchantIds) {
                const result = await API.graphql(graphqlOperation(getMerchant, { merchant_id: merchantId }))
                merchants.push(result.data.getMerchant)
            }   
        }
    }

    const groups = await ACL.getGroups()
    for(const merchant of merchants) {
        merchant.readOnly = !(await ACL.canWriteMerchant(merchant, groups))
    }

    this.setState({
      isAdmin: await ACL.isAdmin(),
      isMerchant: await ACL.isMerchant(),
      isHandler: await ACL.isHandler(),
      isPostal: await ACL.isPostal(),
      isLoading: false,
      merchants: merchants,
      merchant: merchants.length > 0 ? merchants[0] : null,
      merchantsUpdated: true
    })
  }

  onCreateMerchant(merchant) {
    console.log('create merchant', merchant)
    this.refs.listMerchants.addMerchant(merchant)
    this.setState({
      show: false
    })
  }

  onCreateOutlet(outlet) {
    console.log('create outlet', outlet)
    this.refs.listOutlets.addOutlet(outlet)
    this.setState({
      show: false
    })
  }

  onCreateDeal(deal) {
    this.refs.listDeals.addDeal(deal)
    this.setState({
      show: false
    })
  }

  selectMerchant(e, merchant) {
    this.setState({
      merchant: merchant,
    })
  }
  selectOutlet(e, outlet) {
    // refresh the list of deals
    if (this.refs.listDeals) this.refs.listDeals.listDeals()
    this.setState({
      outlet: outlet,
    })
  }
  selectDeal(e, deal) {
    this.setState({
      timestamp: moment().unix(),
      deal: deal,
    })
  }
  createMerchant() {
    this.setState({
      show: true,
      mode: 'createMerchant'
    })
  }
  
  createOutlet(merchant) {
    this.setState({
      show: true,
      mode: 'createOutlet'
    })
  }
  
  async createDeal(outlet) {
    if (outlet.valid==='N') {
      this.alert('Please enable the outlet before creating a deal')
      return 
    }

    let hitQuota = false

    // check subscription plans
    if (!this.state.isAdmin) {
      let numberOfDeals=0, numberOfBrandsWithDeals=0
      for (let i=0; i<this.state.merchants.length; i++) {
        const merchant = this.state.merchants[i]
        const result = await API.graphql(graphqlOperation(dealByMerchantId, { merchant_id: merchant.merchant_id }))
        const deals = result.data.dealByMerchantId.items
        const eligibleDeals = deals.filter(async (deal) => {
          return (deal.deal_handler==='exclusive_all' && !deal.aggregator && deal.valid.toLowerCase()==='y')
        })
        const numberOfMerchantDeals = eligibleDeals.length
        numberOfBrandsWithDeals += numberOfMerchantDeals>0?1:0
        numberOfDeals += numberOfMerchantDeals
    }
      switch (this.state.subplan) {
        case 'bronze':
        case 'silver':
        case 'gold':
        case 'waiver':
        case 'professional':
          hitQuota = false;
          break;
        case 'starter-1':
          hitQuota = numberOfBrandsWithDeals > 1;
          break;
        case 'starter-2':
          hitQuota = numberOfBrandsWithDeals > 2;
          break;
        case 'starter-3':
          hitQuota = numberOfBrandsWithDeals > 3;
          break;
        default:
          hitQuota = numberOfDeals > 1;
      }
    }

    if (hitQuota) {
      this.showSubPlans()
    } else {
      this.setState({
        show: true,
        mode: 'createDeal'
      })
    }
  }

  showSubPlans() {
    Hub.dispatch(
      'SubPlanChannel', 
      {
        event: 'open'
      }
    )
  }

  changePage(page) {
    this.setState({
      page: page
    })
  }

  handleClose() {
    this.setState({
      show: false
    })
  }

  alert(msg, callback) {
    this.setState({
      processing: false
    })
    publish({
      mode: 'alert',
      msg: msg,
      onClose: callback
    })
  }

  render() {

    return (
      <Container fluid>
        <Row>
          <Col>
            <span className="float-right">
              <FaCrown color="#2AD2C9" /> <span style={{textTransform: 'capitalize'}}>{this.state.subplanName}</span>
            </span>
          </Col>
        </Row>

        { this.state.isAdmin ? <Nav variant="tabs" defaultActiveKey="home">
            {this.tabs.map(tab => 
              <Nav.Link onClick={() => this.changePage(tab.key)} eventKey={tab.key}>{tab.text}</Nav.Link>
            )}
          </Nav>
        : null}

        { this.state.page === 'home' ?
          <Row>
            <Col>
              <Row>
                  <Col>
                      <h3>Merchants</h3>
                  </Col>
                  { (this.state.isAdmin || this.state.isMerchant) ? <Col>
                      <Button className="float-right" size="sm" onClick={(e) => this.createMerchant(e)}>
                        <FaPlus /> Add Merchant
                      </Button>
                    </Col>
                  : null}
              </Row>

              <ListMerchants 
                key={`list-merchants-${this.state.merchantsUpdated}`}
                ref={'listMerchants'} 
                isLoading={this.state.isLoading}
                editable={true} 
                merchants={this.state.merchants}
                onSelect={(e, merchant) => this.selectMerchant(e, merchant)} 
              />
            </Col>

            {this.state.merchant ?
              <Col style={{borderLeft: '1px solid'}}>
                  <Row>
                      <Col>
                          <h3>Outlets</h3>
                      </Col>
                      <Col>
                        <Button className="float-right" size="sm" onClick={(e) => this.createOutlet(e)}>
                          <FaPlus /> Add Outlet
                        </Button>
                      </Col>
                  </Row>

                  <ListOutlets
                      ref={'listOutlets'}
                      key={`list-outlets-${this.state.merchant.merchant_id}`}
                      merchantId={this.state.merchant.merchant_id}
                      onSelect={(e, outlet) => this.selectOutlet(e, outlet)}
                  />
              </Col>
            : null}

            {this.state.outlet ?
              <Col style={{borderLeft: '1px solid'}}>
                  <Row>
                      <Col>
                          <h3>Deals</h3>
                      </Col>
                      <Col>
                        <Button className="float-right" size="sm" onClick={(e) => this.createDeal(this.state.outlet)}>
                          <FaPlus /> Add Deal
                        </Button>
                      </Col>
                  </Row>
                  <ListDeals
                      ref={'listDeals'}
                      key={`list-outlet-deals-${this.state.outlet.merchant_unique_id}`}
                      merchantUniqueId={this.state.outlet.merchant_unique_id}
                      onSelect={(e, deal) => this.selectDeal(e, deal)}
                  />
                  <Row>
                    <Col>
                      {this.state.subplan === 'gold' ?
                        <div>
                          <p>You are entitled to list unlimited deals.</p>
                          <p><a href="https://cardspal.com/business/cardspal-for-business-contact-form/" target="_blank" rel="noreferrer">Wish to utilise your plan entitlements? Contact us now!</a></p>
                        </div>
                      : this.state.subplan === 'silver' ?
                        <div>
                          <p>You are entitled to list unlimited deals.</p>
                          <p><a href="#" onClick={() => this.showSubPlans()}>Upgrade to enjoy more perks!</a></p>
                          <p><a href="https://cardspal.com/business/cardspal-for-business-contact-form/" target="_blank" rel="noreferrer">Wish to utilise your plan entitlements? Contact us now!</a></p>
                        </div>
                      : (this.state.subplan === 'bronze') ?
                        <div>
                          <p>You are entitled to list unlimited deals.</p>
                          <p><a href="#" onClick={() => this.showSubPlans()}>Upgrade to enjoy more perks!</a></p>
                        </div>
                      : (this.state.subplan === 'waiver') ?
                        <div>
                          <p>You are entitled to list unlimited deals for all your brands.</p>
                          <p><a href="#" onClick={() => this.showSubPlans()}>Upgrade to enjoy more perks!</a></p>
                        </div>
                      : (this.state.subplan === 'starter-1') ?
                        <div>
                          <p>You are entitled to list unlimited deals for 1 brand.</p>
                          <p><a href="#" onClick={() => this.showSubPlans()}>Upgrade if you have more brands.</a></p>
                          <p><a href="https://cardspal.com/business/cardspal-for-business-contact-form/" target="_blank" rel="noreferrer">Wish to utilise your brand entitlements? Contact us now!</a></p>
                        </div>
                      : (this.state.subplan === 'starter-2') ?
                        <div>
                          <p>You are entitled to list unlimited deals for 2 brands.</p>
                          <p><a href="#" onClick={() => this.showSubPlans()}>Upgrade if you have more brands.</a></p>
                          <p><a href="https://cardspal.com/business/cardspal-for-business-contact-form/" target="_blank" rel="noreferrer">Wish to utilise your brand entitlements? Contact us now!</a></p>
                        </div>
                      : (this.state.subplan === 'starter-3') ?
                        <div>
                          <p>You are entitled to list unlimited deals for 3 brands.</p>
                          <p><a href="#" onClick={() => this.showSubPlans()}>Upgrade if you have more brands.</a></p>
                          <p><a href="https://cardspal.com/business/cardspal-for-business-contact-form/" target="_blank" rel="noreferrer">Wish to utilise your brand entitlements? Contact us now!</a></p>
                        </div>
                      : (this.state.subplan === 'professional') ?
                        <div>
                          <p>You are entitled to list unlimited deals for all your brands.</p>
                          <p><a href="https://cardspal.com/business/cardspal-for-business-contact-form/" target="_blank" rel="noreferrer">Wish to utilise your brand entitlements? Contact us now!</a></p>
                        </div>
                      :
                        <div>
                          <p>You are entitled to list 1 deal for 1 brand under the Free Trial plan.</p>
                          <p><a href="#" onClick={() => this.showSubPlans()}>Upgrade now to enjoy listing of unlimited deals and other perks!</a></p>
                        </div>
                      }
                    </Col>
                  </Row>
              </Col>
            : null}

            <Modal show={this.state.show} onHide={() => this.handleClose()} size="xl" backdrop='static'>
              <Modal.Header closeButton>
                <Modal.Title>
                  {this.state.mode==='createMerchant'?'Create Merchant':this.state.mode==='createOutlet'?'Create Outlet':this.state.mode==='createDeal'?'Create Deal':''}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Merchant
                  mode='create'
                  show={this.state.mode === 'createMerchant'}
                  onCreate={(merchant) => this.onCreateMerchant(merchant)}
                />

                <Outlet
                  mode='create'
                  merchant={this.state.merchant}
                  key={`create-outlet-${this.state.merchant ? this.state.merchant.merchant_id : ''}`}
                  onCreate={(outlet) => this.onCreateOutlet(outlet)}
                  show={this.state.mode === 'createOutlet'}
                />

                <Deal
                  mode='create'
                  outlet={this.state.outlet}
                  showApplyToOutlets={true}
                  key={`create-deal-${this.state.outlet ? this.state.outlet.merchant_unique_id : ''}`}
                  onCreate={(deal) => this.onCreateDeal(deal)}
                  show={this.state.mode === 'createDeal'}
                />
              </Modal.Body>
            </Modal>
            {/* </Col> */}
          </Row>
        :
          this.views[this.state.page]
        }

        <CMSSubPlan />

        <CMSLegend />
      </Container>
    )
  }
}

// export default App;
export default AdminView
