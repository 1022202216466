import React, { Component } from 'react'
import { Form, Button, Col, Toast } from "react-bootstrap"
import Select from 'react-select';
import { API, graphqlOperation } from 'aws-amplify'
import "react-datetime/css/react-datetime.css"
import { createCoupons, updateCoupons } from '../../graphql/mutations'
import ListMerchants from './ListMerchants'
import { guidGenerator } from '../../utils/index'
import { publish } from '../widgets/CMSModal'
import { COUPON_TYPES } from '../../utils/constants'

class Coupons extends Component {

    constructor(props) {
        super(props);

        const coupons = this.props.coupons ?? {}
        
        this.state = {
            item: {
                pk: coupons.pk ?? guidGenerator(),
                type: coupons.type ?? COUPON_TYPES[0].value,
                merchantId: coupons.merchantId ?? '',
                merchantName: coupons.merchantName ?? '',
                merchantImage: coupons.merchantImage ?? '',
                promoCode: coupons.promoCode ?? '',
                terms: coupons.terms ?? '',
                valid: coupons.valid ?? '',
                title: coupons.title ?? ''
            },
            showToast: false,
            selectedType: coupons.type ? COUPON_TYPES.find(o => o.value === coupons.type) : COUPON_TYPES[0]
        }
    }

    setCouponType(coupon) {
        this.state.item.type = coupon.value
        this.setState({
            item: this.state.item,
            selectedType: coupon
        })
    }

    onSelectMerchant(merchant) {
        this.state.item.merchantName = merchant.merchant_name
        this.state.item.merchantId = merchant.merchant_id
        this.state.item.merchantImage = merchant.merchantImageUrlV2 ?? merchant.defaultImageUrl
        this.setState({
            item: this.state.item
        })
    }

    handleChange(e, fieldname) {
        const { item } = this.state
        const target = e.target;
        if (target.type === 'checkbox') {
            item[fieldname] = e.target.checked ? '1' : '0';
        } else {
            item[fieldname] = e.target.value;
        }
        this.setState({ item: item });
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        const { pk, merchantName, merchantId, merchantImage, title, promoCode, terms, valid, type } = this.state.item
        const coupons = {
            pk: pk ?? '',
            type: type ?? '',
            merchantId: merchantId ?? '',
            merchantName: merchantName ?? '',
            merchantImage: merchantImage ?? '',
            promoCode: promoCode ?? '',
            terms: terms ?? '',
            valid: valid ?? '0',
            title: title ?? ''
        };

        if (!merchantName){
            this.alert('Please search for a merchant')
            return
        }
        if (!type) {
            this.alert('Please select the type')
            return
        }
        if (this.props.mode === 'create') {
            this.handleCreate(coupons);
        } else if (this.props.mode === 'update') {
            this.handleUpdate(coupons);
        }
    }

    alert(msg, callback) {
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    handleCreate(coupons) {
        API.graphql(graphqlOperation(createCoupons, { input: coupons })).then((result) => {
            this.setState({ showToast: true });
            if (this.props.refresh) {
                this.props.refresh(this.state.selectedType);
            }
        }).catch((error) => {
            console.debug('Error on handleCreate' , coupons)
            console.error(error)
        })
    }

    handleUpdate(coupons) {
        API.graphql(graphqlOperation(updateCoupons, { input: coupons })).then((result) => {
            this.setState({ showToast: true });
            if (this.props.refresh) {
                this.props.refresh(this.state.selectedType);
            }
        });
    }

    render() {
        const { item, selectedType } = this.state
        const { pk, merchantId, merchantImage, merchantName, title, promoCode, terms, valid } = item
        
        return <div>
            {this.props.show ?
            
            <Form onSubmit={(e) => this.handleSubmit(e)}>  
                <h3>{this.props.title??this.props.mode==='create'?'Create':'Update'} Coupons</h3>
                <Form.Group>
                        <Form.Check label="Valid" checked={valid==='1'} onChange={(e) => this.handleChange(e, 'valid')} />
                    </Form.Group>
                <Form.Group>
                    <Form.Label>Coupons Id</Form.Label>
                    <Form.Control name="notificationId" type="text" disabled value={pk ? pk : ''} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Type *</Form.Label>
                    <Select
                        closeMenuOnSelect={true}
                        placeholder="Choose the type of coupon"
                        options={COUPON_TYPES}
                        value={selectedType || COUPON_TYPES[0]}
                        onChange={(type) => this.setCouponType(type)}
                    />
                </Form.Group>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Merchant *</Form.Label>
                        <ListMerchants
                            merchantId={merchantId}
                            label= {merchantName || "Search Merchant"}
                            onSelect={(e, merchant) => this.onSelectMerchant(merchant)}
                            replace
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Group>
                    <Form.Label>Merchant Image *</Form.Label>
                    <Form.Control disabled required type="text" value={merchantImage ? merchantImage : ''}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Title *</Form.Label>
                    <Form.Control required type="text" value={title ? title : ''} onChange={(e) => this.handleChange(e, 'title')}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Promo Code *</Form.Label>
                    <Form.Control required type="text" value={promoCode ? promoCode : ''} onChange={(e) => this.handleChange(e, 'promoCode')}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Terms </Form.Label>
                    <Form.Control as="textarea" rows={5} value={terms ? terms : ''} onChange={(e) => this.handleChange(e, 'terms')}/>
                </Form.Group>
                <Form.Group>
                    <Toast onClose={() => this.setState({ showToast: false })} show={this.state.showToast} delay={3000} autohide>
                        {this.props.mode==='create'?
                            <Toast.Body>Coupons Created.</Toast.Body>
                        : null}
                        {this.props.mode==='update'?
                            <Toast.Body>Coupons Updated.</Toast.Body>
                        : null}
                    </Toast>

                    {this.props.mode==='create'?
                        <Button type="submit" style={{marginTop: 10}}>Create Coupons</Button>
                    : null}
                    {this.props.mode==='update'?
                        <Button type="submit" style={{marginTop: 10}}>Update Coupons</Button>
                    : null}
                </Form.Group>

            </Form>
            : null}
        </div>
    }
}

export default Coupons