/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://0w2qyirpy7.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "CloudFront",
            "endpoint": "https://l3vnqj9xqh.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "GAPI",
            "endpoint": "https://obimsgj8f9.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "access",
            "endpoint": "https://cgax5mg7tb.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "cmsSubPlan",
            "endpoint": "https://7955xa6hg8.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "invalidateCloudFrontCache",
            "endpoint": "https://i4x7iqclwa.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://ekexcy7u7nhstctvcfs5z2c3lu.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-southeast-1:19945e3f-961e-45e5-9091-2677b7abe1b4",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_4u4wx91eO",
    "aws_user_pools_web_client_id": "cvom1d7a86uf4l5vc1eqbnrnf",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "cardspal-assets-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
