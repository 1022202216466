import React, { Component } from 'react';
import { Container, Row, Col, Spinner, Button, ListGroup } from 'react-bootstrap';
import DiscountCode from '../widgets/DiscountCode'
import { FaPlus } from "react-icons/fa";
import { API, graphqlOperation } from 'aws-amplify'
import { listDiscountCampaigns } from '../../graphql/queries'

class DiscountCodeView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            mode:'',
            dynamic:'',
            list: [],
        }
    }

    async componentDidMount() {
        this.getDiscountCodes()
    }

    async getDiscountCodes() {
        API.graphql(graphqlOperation(listDiscountCampaigns, {})).then((result)=>{
            this.loadList(result.data.listDiscountCampaigns)
        })
    }

    loadList(result) {
        const res = result.items
        res.sort((a, b) => {
            let fieldA = a.updatedAt,
                fieldB = b.updatedAt;
        
            if (fieldA > fieldB) {
                return -1;
            }
            if (fieldA < fieldB) {
                return 1;
            }
            return 0;
        });
        this.setState({ list: res, isLoading: false });
    }

    selectDiscountCode = (e, data) => {
        e.preventDefault();
        e.stopPropagation();
        
        this.setState({
            discountCode: data,
            mode: 'update',
        });
    }

    createDiscountCode() {
        this.setState({
            discountCode: null,
            mode: "create"
        })
    }

    refreshDiscount = () => {
        this.setState({ isLoading: true });

        this.getDiscountCodes()
    }

    render() {
        const {list, mode, discountCode} = this.state
        
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <h3>Discount Code</h3>
                            </Col>
                            <Col>
                                <FaPlus className="float-right" onClick={(e) => this.createDiscountCode(e)} />
                            </Col>
                        </Row>
                        <Row>
                            { this.state.isLoading ? 
                                <Spinner animation="border" />
                            : 
                                <Col className="scrollview">
                                    <ListGroup variant="flush">
                                    {
                                        list.length > 0 ?
                                            list.map((item) =>
                                                <ListGroup.Item key={`list-discountCode-${item.campaignId}`} action onClick={(e) => this.selectDiscountCode(e, item)}>
                                                    <div>
                                                        { item.description }
                                                    </div>
                                                    <small>{ item.discountLevel } code [Valid: {item.valid==="1"?"true":"false"}]</small>
                                                    
                                                </ListGroup.Item>
                                            )
                                        : 'No discount code'
                                    }
                                    </ListGroup>
                                </Col>
                            }
                        </Row>
                    </Col>

                    <Col className="scrollview">
                        <DiscountCode
                            mode='create'
                            key={`create-discountCode`}
                            onCreate={(data) => this.refreshDiscount(data)}
                            show={mode === 'create'}
                            allList = {list}
                        />

                        <DiscountCode
                            mode='update'
                            key={`update-discountCode-${discountCode ? discountCode.campaignId : ''}`}
                            discountCode={discountCode}
                            onUpdate={(data) => this.refreshDiscount(data)}
                            show={mode === 'update'}
                            allList = {list}
                        />
                     </Col>
                </Row>
            </Container>
        )
    }
}

export default DiscountCodeView