import React, { Component } from 'react'
import { Form, Button, Col, Toast } from "react-bootstrap"
import "react-datetime/css/react-datetime.css"
import ListMerchants from './ListMerchants'
import SearchMerchantDeals from './SearchMerchantDeals'
import { publish } from '../widgets/CMSModal'
import DealModel from '../../models/DealModel'
import CMSTag from '../widgets/CMSTag'

class PinnedDeals extends Component {
    predefinedTags = [
        'homescreen_festivebanner',
        'homescreen_<dynamic-section>',
        'homescreen_merchantexclusive',
    ]

    constructor(props) {
        super(props);

        const pinned = this.props.data ?? {}
        this.state = {
            item: {},
            showToast: false
        }
        this.state.item = {...pinned}
        this.state.item.merchantId = pinned.merchant_id
        this.state.item.merchantName = pinned.merchant_name
        this.state.item.tags = pinned.TR_tags ?? []
        this.state.item.origTags = pinned.TR_tags ?? []
        this.state.item.isPinned = pinned.isPinned === '1'
        this.state.item.pinPageLocation = pinned.pinPageLocation ?? []
        this.state.item.pinOrder = pinned.pinOrder ?? '0'
        this.state.item.promoCaption = pinned.promotion_caption
    }

    onSelectMerchant(merchant) {
        this.state.item.merchantId = merchant.merchant_id
        this.state.item.merchantName = merchant.merchant_name
        this.setState({
            item: this.state.item
        })
    }

    onSelectDeal(deal) {
        this.state.item = {...deal}
        this.state.item.merchantId = deal.merchant_id
        this.state.item.merchantName = deal.merchant_name
        this.state.item.tags = deal.TR_tags ?? []
        this.state.item.origTags = deal.TR_tags ?? []
        this.state.item.isPinned = deal.isPinned === '1'
        this.state.item.pinPageLocation = deal.pinPageLocation ?? []
        this.state.item.pinOrder = deal.pinOrder ?? '0'
        this.state.item.valid = deal.valid === 'Y'
        this.state.promoCaption = deal.promotion_caption
        
        this.setState({
            item: this.state.item
        })
    }

    handleChange(e, fieldname) {
        const { item } = this.state
        const target = e.target;

        if (target && target.type === 'checkbox') {
            item[fieldname] = e.target.checked;
        } else {
            if (fieldname === 'tags' || fieldname === 'pinPageLocation') {
                item[fieldname] = e
            } else {
                item[fieldname] = e.target.value;
            }
        }
        this.setState({ item: item });
    }

    async handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        
        try{
            const { tags, valid, origTags, merchantId, pk, isPinned } = this.state.item
            const deals = {...this.state.item}
            delete deals['merchantId']
            delete deals['merchantName']
            delete deals['tags']
            delete deals['origTags']
            deals['valid'] = valid?'Y':'N'
            deals['isPinned'] = this.props.mode==='create'? '1': (isPinned ? '1' : '0')

            if (!merchantId){
                this.alert('Please search for a merchant')
                return
            }

            if (!pk) {
                this.alert('Please search for a merchant deal')
                return
            }

            const dealModel = new DealModel(deals)
            await dealModel.update()
            console.log({ origTags })
            console.log({ tags })
            await dealModel.setTags(origTags, tags)

            this.setState({ showToast: true });
            if (this.props.refresh) {
                this.props.refresh();
            }
        } catch (err) {
            this.alert('Error saving pinned deal ', JSON.stringify(err))
        }
    }

    alert(msg, callback) {
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    render() {
        const { item } = this.state
        const { pk, merchantId, merchantName, pinPageLocation, valid, pinOrder, tags, promoCaption, isPinned } = item
        
        return <div>
            {this.props.show ?
            
            <Form onSubmit={(e) => this.handleSubmit(e)}>  
                <h3>{this.props.title??this.props.mode==='create'?'Create':'Update'} Pinned Deal</h3>
                <Form.Group>
                    <Form.Check label="Valid" checked={valid} onChange={(e) => this.handleChange(e, 'valid')} />
                </Form.Group>
                <Form.Group>
                    <Form.Check label="Pinned" checked={isPinned} onChange={(e) => this.handleChange(e, 'isPinned')} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Deal ID</Form.Label>
                    <Form.Control name="pk" type="text" disabled value={pk ? pk : ''} />
                </Form.Group>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Merchant *</Form.Label>
                        <ListMerchants
                            merchantId={merchantId}
                            label= {merchantName || "Search Merchant"}
                            onSelect={(e, merchant) => this.onSelectMerchant(merchant)}
                            replace
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Deal promo title *</Form.Label>
                        <SearchMerchantDeals 
                            merchantId={merchantId}
                            dealId={pk}
                            onlyValid={true}
                            label= {promoCaption || "Search Merchant Deal"}
                            onSelect={(e, deal) => this.onSelectDeal(deal)}
                            replace
                        />
                        {/* <ListDeals
                            ref={'listDeals'}
                            key={`list-outlet-deals-${merchantUniqueId}`}
                            merchantUniqueId={merchantUniqueId}
                            onSelect={(e, deal) => this.onSelectDeal(deal)}
                        /> */}
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <CMSTag tags={tags} onChange={(e) => this.handleChange(e, 'tags')} />
                    </Form.Group>
                </Form.Row>
                <Form.Group>
                    <CMSTag label='Page Location' predefinedTags={this.predefinedTags} tags={pinPageLocation} onChange={(e) => this.handleChange(e, 'pinPageLocation')} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Order </Form.Label>
                    <Form.Control type="text" value={pinOrder ? pinOrder : '0'} onChange={(e) => this.handleChange(e, 'pinOrder')}/>
                </Form.Group>
                <Form.Group>
                    <Toast onClose={() => this.setState({ showToast: false })} show={this.state.showToast} delay={3000} autohide>
                        {this.props.mode==='create'?
                            <Toast.Body>Pinned Deals Created.</Toast.Body>
                        : null}
                        {this.props.mode==='update'?
                            <Toast.Body>Pinned Deals Updated.</Toast.Body>
                        : null}
                    </Toast>

                    {this.props.mode==='create'?
                        <Button type="submit" style={{marginTop: 10}}>Create Pinned Deals</Button>
                    : null}
                    {this.props.mode==='update'?
                        <Button type="submit" style={{marginTop: 10}}>Update Pinned Deals</Button>
                    : null}
                </Form.Group>

            </Form>
            : null}
        </div>
    }
}

export default PinnedDeals