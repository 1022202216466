import React, { Component } from 'react';
import { Container, Row, Col, Spinner, ListGroup } from 'react-bootstrap';
import { FaPlus } from "react-icons/fa";
import { API, graphqlOperation } from 'aws-amplify'
import { listCampaigns, getBannerByCampaign } from '../../graphql/queries';
import Campaign from '../widgets/Campaign'

class CampaignView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            banner: null,
            isLoading: false
        }
    }

    async componentDidMount() {
        this.setState({
            isLoading: true
        });

        API.graphql(graphqlOperation(listCampaigns)).then(async (result) => {
            const items = await result.data.listCampaigns.items.map((item) => {
                if (item.notificationSchedule) {
                    item.notificationSchedule = JSON.parse(item.notificationSchedule)
                }
                return item
            })

            items.sort((a, b) => {
                if (b.updatedAt === null || a.updatedAt > b.updatedAt) {
                    return -1
                } else if (a.updatedAt === null || a.updatedAt < b.updatedAt) {
                    return 1
                }
                return 0
            })

            this.setState({ list: items, isLoading: false });
        })        
    }

    selectCampaign(e, campaign) {
        e.preventDefault()
        e.stopPropagation()
        
        API.graphql(graphqlOperation(getBannerByCampaign,{campaignId: campaign.campaignId})).then((result) => {
            const items = result.data.getBannerByCampaign.items
            
            
            const banner = items.length > 0 ? items[0] : null
            this.setState({
                campaign: campaign,
                mode: 'updateCampaign',
                banner
            })
        })        
    }

    createCampaign(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            mode: 'createCampaign'
        })
    }

    refreshCampaign() {
        this.setState({ isLoading: true });
        API.graphql(graphqlOperation(listCampaigns)).then((result) => {
            this.setState({ list: result.data.listCampaigns.items, isLoading: false });
        })   
    }

    render() {
        const { list, banner, campaign, mode } = this.state;
        
        return <Container fluid>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h3>Campaign <a style={{"fontSize": "small"}} href="https://bit.ly/3sVNRhO" target="_blank" rel="noreferrer"> [User guide]</a></h3>
                            
                        </Col>
                        <Col>
                            <FaPlus className="float-right" onClick={(e) => this.createCampaign(e)} />
                        </Col>
                    </Row>
                    <Row>
                    { this.state.isLoading ? 
                        <Spinner animation="border" />
                    : 
                        <Col className="scrollview">
                            <ListGroup variant="flush">
                            {
                                list.length > 0 ?
                                    list.map((item) =>
                                        <ListGroup.Item key={`list-campaign-${item.campaignId}`} action onClick={(e) => this.selectCampaign(e, item)}>
                                            <div>
                                                { item.campaignName ? item.campaignName : item.campaignId }
                                            </div>
                                            <small>Valid: { item.valid==="1" ? 'true' : 'false' }</small>
                                        </ListGroup.Item>
                                    )
                                : 'No Campaign'
                            }
                            </ListGroup>
                        </Col>
                    }
                    </Row>
                </Col>

                <Col className="scrollview">
                    <Campaign
                        mode='create'
                        key={`create-campaign`}
                        show={mode === 'createCampaign'}
                        banner={banner}
                        refresh={() => this.refreshCampaign()}
                    />

                    <Campaign
                        mode='update'
                        key={`update-campaign-${campaign ? campaign.campaignId : ''}`}
                        campaign={campaign}
                        banner={banner}
                        refresh={() => this.refreshCampaign()}
                        show={mode === 'updateCampaign'}
                    />
                </Col>
            </Row>            
        </Container>
    }
}

export default CampaignView