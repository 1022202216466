import React, { Component } from 'react'
import { CSVReader } from 'react-papaparse'

export default class CMSUploadCodes extends Component {
    
    buttonRef = React.createRef()

    constructor(props) {
        super(props)

        this.state = {
            data: []
        }
    }

    handleOnDrop = (data) => {
        const massageData = data.map(row => row.data[0])
        if (this.props.onComplete) {
            this.props.onComplete(massageData.slice(1))
        }
    }

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
    }

    handleOnRemoveFile = (data) => {
        if (this.props.onComplete) {
            this.props.onComplete([])
        }
    }

    render() {
        return (
            <div>
                <CSVReader
                    config={{
                        header: false,
                        skipEmptyLines: true,
                        skipEmptyLines: 'greedy' //eslint-disable-line
                    }}
                    noClick={this.props.isEnabled}
                    ref={this.buttonRef}
                    onDrop={this.handleOnDrop}
                    onError={this.handleOnError}
                    addRemoveButton
                    removeButtonColor='#659cef'
                    onRemoveFile={this.handleOnRemoveFile}
                >
                    <span>Drop .csv file here or click to upload.</span>
                </CSVReader>

            </div>
        )
    }
}