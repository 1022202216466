import React, { Component } from 'react';
import { Container, Row, Col, Spinner, ListGroup, Button } from 'react-bootstrap';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment-timezone'
import { FaPlus } from "react-icons/fa";
import { API, graphqlOperation } from 'aws-amplify'
import DailyTips from '../widgets/DailyTips'
import { listDailyTips } from '../../graphql/queries'
import { TZ } from '../../utils/constants'
import * as utils from '../../utils/constants'

moment.tz.setDefault(TZ);

const getWeekDays = (weekStart) => {
    const days = [weekStart];
    for (let i = 1; i < 7; i += 1) {
      days.push(
        moment(weekStart)
          .add(i, 'days')
          .toDate()
      );
    }
    return days;
}

const getWeekRange = (date) =>{
    return {
      from: moment(date)
        .startOf('week')
        .toDate(),
      to: moment(date)
        .endOf('week')
        .toDate(),
    };
}

class DailyTipsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            mode: '',
            isLoading: false,
            hoverRange: undefined,
            selectedDays: [],
            calendarState: false
        }
    }

    createCoupons(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            mode: 'createDailyTips'
        })
    }

    selectTip(e, tip) {
        e.preventDefault()
        e.stopPropagation()
        this.setState({
            tip: tip,
            mode: 'updateDailyTips'
        })
    }

   
    getTipsByWeek(item) {
        if (!item) {
            this.setState({ list: [], isLoading: false})
            return
        }

        // TODO :: improve search with index instead of filters. will run into pagination problem. temporary raise limit to 1000
        const startDate = moment(item[0]).unix()
        const endDate = moment(item[6]).unix()
        const options = {
            filter: {
                displayDate: {
                    between: [startDate, endDate]
                }
            },
            limit: 1000
        }
        
        API.graphql(graphqlOperation(listDailyTips, options)).then((result) => {
            this.setState({ list: result.data.listDailyTips.items, isLoading: false});
        }).catch(e => {
            console.debug('Error on getTipsByWeek ',e)
            this.setState({isLoading: false})
        })
    }

    handleDayChange = async date => {
        const selectedDays = getWeekDays(getWeekRange(date).from)
        await this.getTipsByWeek(selectedDays)
        this.setState({
            selectedDays,
            calendarState: false
        });
    };

    handleDayEnter = date => {
        this.setState({
            hoverRange: getWeekRange(date),
        });
    };

    handleDayLeave = () => {
        this.setState({
        hoverRange: undefined,
        });
    };

    handleWeekClick = async (weekNumber, days, e) => {
        await this.getTipsByWeek(days)
        this.setState({
            selectedDays: days,
            calendarState: false
        });
    };

    displayCalendar = (state) => {
        this.setState({calendarState: !state})
    }

    render() {
        const { list, mode, tip, calendarState } = this.state
        const { hoverRange, selectedDays } = this.state;
        const daysAreSelected = selectedDays.length > 0;

        const modifiers = {
            hoverRange,
            selectedRange: daysAreSelected && {
                from: selectedDays[0],
                to: selectedDays[6],
            },
            hoverRangeStart: hoverRange && hoverRange.from,
            hoverRangeEnd: hoverRange && hoverRange.to,
            selectedRangeStart: daysAreSelected && selectedDays[0],
            selectedRangeEnd: daysAreSelected && selectedDays[6],
        };
        return <Container fluid>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h3>Daily Tips</h3>
                        </Col>
                        <Col>
                            <FaPlus className="float-right" onClick={(e) => this.createCoupons(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/* <div>Select a date to choose the week of the year</div> */}
                            <div>
                            <Button variant="secondary" size="sm" onClick={() => this.displayCalendar(calendarState)}>
                                Select a week to display
                            </Button>
                            </div>
                            {calendarState ? 
                                <DayPicker
                                selectedDays={selectedDays}
                                showWeekNumbers
                                showOutsideDays
                                modifiers={modifiers}
                                onDayClick={this.handleDayChange}
                                onDayMouseEnter={this.handleDayEnter}
                                onDayMouseLeave={this.handleDayLeave}
                                onWeekClick={this.handleWeekClick}
                                />
                            :null}
                            {selectedDays.length === 7 && (
                            <div>
                                {moment(selectedDays[0]).format('LL')} –{' '}
                                {moment(selectedDays[6]).format('LL')}
                                <div><small>({TZ})</small></div>
                            </div>
                            
                            )}
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row><Col><hr style={{height: 5}}></hr></Col></Row>
                    <Row>
                    { this.state.isLoading ? 
                        <Spinner animation="border" />
                    : 
                        <Col className="scrollview">
                            <ListGroup variant="flush">
                            {
                                list.length > 0 ?
                                    list.map((item) =>
                                        <ListGroup.Item key={`list-dailytip-${item.id}`} action onClick={(e) => this.selectTip(e, item)}>
                                            <div>
                                                { item.title? item.title: 'No Tip Title' }
                                            </div>
                                            <small>
                                                {item.day} [ Type: {item.displayType} ]
                                            </small>
                                        </ListGroup.Item>
                                    )
                                : 'No Daily Tips'
                            }
                            </ListGroup>
                        </Col>
                    }
                    </Row>
                </Col>

                <Col className="scrollview">
                    <DailyTips
                        mode='create'
                        key={`create-dailytips`}
                        show={mode === 'createDailyTips'}
                        refresh={() => this.getTipsByWeek(this.state.selectedDays)}
                    />

                    <DailyTips
                        mode='update'
                        key={`update-dailytips-${tip ? tip.id : ''}`}
                        data={tip}
                        show={mode === 'updateDailyTips'}
                        refresh={() => this.getTipsByWeek(this.state.selectedDays)}
                    />
                </Col>
            </Row>            
        </Container>
    }
}

export default DailyTipsView