import React, { Component } from 'react'
import { Form, Button, Spinner } from "react-bootstrap"
import { publish } from '../widgets/CMSModal'
import ACL from '../../utils/ACL'
import { guidGenerator } from '../../utils/index'
import CardParamModel from '../../models/CardParamModel'

class CardParam extends Component {

    constructor(props) {
        super(props)
        const userId = props.user ? props.user.sub : 'no user id'
        const cardParam = props.cardParam ? { ...props.cardParam } : {}
        if (props.type ==='cardParam-create') {
            cardParam.id = props.card.card_detail_id + '-' + guidGenerator()
            cardParam.cardId = props.card.card_detail_id
            cardParam.createdByUser = userId
        }
        this.state = {
            item: new CardParamModel(cardParam).toJson()
        }
    }

    async componentDidMount() {
        this.setState({
            isAdmin: await ACL.isAdmin(),
        })       
    }

    handleInputChange(e, fieldname) {
        const item = { ...this.state.item }
        const target = e.target
        if (target.type === 'checkbox') {
            item[fieldname] = e.target.checked ? 'TRUE' : 'FALSE'
        } else {
            item[fieldname] = e.target.value
        }

        this.setState({ item: item })
    }

    handleTextAreaChange(e, fieldname) {
        const item = { ...this.state.item }
        item[fieldname] = e.target.value.split('\n')
        this.setState({ item: item })
    }

    async handleSubmit(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            processing: true
        })

        try {
            if (this.props.mode === 'create') {
                await this.handleCreate()
            } else if (this.props.mode === 'update') {
                await this.handleUpdate()
            }
        } catch(error) {
            console.error(error)
            this.alert('Error processing Card')
        }
    }

    async handleCreate() {
        const items = { ...this.state.item }
        items.updatedByUser = this.props.user ? this.props.user.sub : ''
        const model = new CardParamModel(items)

        for (const [key, value] of Object.entries(model.item)) {
            if (!value) {
                delete model.item[key] // we remove empty/null fields from savings
            }
        }

        const createdObj = await model.create()

        this.alert('CardParam Created', () => {
            if (this.props.onCreate) {
                this.props.onCreate(createdObj)
            }
        })
    }

    async handleUpdate() {
        const items = { ...this.state.item }
        
        // change the createdByUser to the userId updating it
        items.updatedByUser = this.props.user ? this.props.user.sub : ''
        const model = new CardParamModel(items)
        
        for (const [key, value] of Object.entries(model.item)) {
            if (!value ) {
                model.item[key] = null // remove empty values
            }
        }

        const updatedObj = await model.update()

        this.alert('Card Param Updated', () => {
            if (this.props.onUpdate) {
                this.props.onUpdate(updatedObj)
            }
        })
    }

    alert(msg, callback) {
        this.setState({
            processing: false
        })
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    render() {
        const fields = Object.keys(this.state.item).map(key => {
            let enableField = false
            if (key === 'createdByUser' || key === 'createdByGroups' || key === 'updatedByUser'){
                enableField = true
            }
            return (<Form.Group key={key}>
                <Form.Label>{key}</Form.Label>
                {Array.isArray(this.state.item[key]) ? 
                    <Form.Control disabled={enableField} as="textarea" rows={3} value={this.state.item[key].join('\n')} onChange={(e) => this.handleTextAreaChange(e, key)} />
                :
                    <Form.Control disabled={enableField} type="text" value={this.state.item[key]} onChange={(e) => this.handleInputChange(e, key)} />
                }
            </Form.Group>)
        })
     
        return <div>
            {this.props.show ?
                <Form onSubmit={(e) => this.handleSubmit(e)}>

                    { this.state.isAdmin ?
                        <div>
                            {/* {params} */}
                            {fields}
                        </div>
                    : null}

                    <Form.Group>
                        <Button disabled={ this.state.processing || this.state.readOnly } type="submit">
                            {this.props.mode === 'create' ? 
                                <span>{this.props.btnText??'Create Card Param'}</span>
                            : this.props.mode === 'update' ?
                                <span>{this.props.btnText??'Update Card Param'}</span>
                            : null}
                            &nbsp;
                            {this.state.processing ?
                                <Spinner animation="border" size="sm" />
                            : null}
                        </Button>
                    </Form.Group>

                </Form>
                : null}
        </div>
            ;
    }
}

export default CardParam