import React, { Component } from 'react'
import { Image, Spinner, Form, ListGroup, InputGroup, Button, Modal } from "react-bootstrap"
import { FaSearch, FaStore, FaEye, FaEyeSlash, FaEdit } from "react-icons/fa"
import 'bootstrap/dist/css/bootstrap.min.css'
import { API, graphqlOperation } from 'aws-amplify'
import { debounce } from 'lodash'
import Deal from './Deal'
import { getDealMaster, searchDealMasters } from '../../graphql/queries'

class SearchMerchantDeals extends Component {
  apis = []
  cancelDebounce = false

  constructor(props) {
    super(props)
    
    this.state = {
      deals: this.props.deals??[],
      deal: (this.props.deal&&this.props.deals.length>0)?this.props.deals[0]:null,
      searchText: ''
    };
  }

  async componentDidMount() {

    if (this.props.dealId) {
      const result = await API.graphql(graphqlOperation(getDealMaster, {pk: this.props.dealId}))

      if (result.data.getDealMaster) {
        this.setState({
          searchText: result.data.getDealMaster.promotion_caption
        })
      }
    }
  }

  async clearSearch() {
    this.setState({
      searchText: '',
      deals: []
    })
  }

  debounceSearch = debounce((text) => {
    // must stop debounce function calling additional apis
    if (this.apis.length > 0) {
      this.cancelDebounce = true
      this.waitCancelDebounce()
    }

    this.search({
      text: text
    }).then(() => {
    })
  }, 1000)

  waitCancelDebounce() {
    setTimeout(() => {
      if (this.cancelDebounce) {
        this.waitCancelDebounce()
      }
    }, 1000)
  }

  async handleSearch(e) {
    const text = e.target.value
    if (text.trim() !== '') {
      if (text.length >= 2) {
        this.debounceSearch(text)
      }
    } else {
      this.clearSearch()
    }

    this.setState({
      searchText: text,
      selectedSearchDeal: null,
      deals: []
    })

    if (this.props.onChange) {
      // return original text value
      this.props.onChange(e, e.target.value)
    }
  }

  async search(params) {
    const filters = []
    
    if (this.props.merchantId) {
      filters.push({
        merchant_id: {
          eq: this.props.merchantId
        }
      })
    }

    if (this.props.onlyValid) {
      filters.push({
        valid: {
          eq: 'Y'
        }
      })
    }

    filters.push({
      promotion_caption: {
        matchPhrasePrefix: params.text
      }
    })

    const search = {
      filter: {
        and : filters
      }
    }

    this.setState({
      isLoading: true
    })

    let deals = []
    do {
      const api = API.graphql(graphqlOperation(searchDealMasters, search))
      this.apis.push(api)
      const result = await api
      const items = result.data.searchDealMasters.items
      // const groups = await ACL.getGroups()
      // for(const item of items) {
      //     item.readOnly = !(await ACL.canWriteMerchant(item, groups))
      // }
      deals = deals.concat(items)
      search.nextToken = result.data.searchDealMasters.nextToken

      this.setState({
        deals: deals,
      })  
    } while (search.nextToken && !this.cancelDebounce)

    if (this.cancelDebounce) {
      // cancel all existing api calls
      for (const api of this.apis) {
        API.cancel(api, 'cancelling api')
      }
      
      deals = []
      this.cancelDebounce = false
    }
    this.apis = []
    this.setState({
      deals: deals,
      isLoading: false
    })

    if (this.props.onSearch) {
      this.props.onSearch(deals)
    }
  }

  onSelect(e, item) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    if (this.props.replace) {
      this.setState({
        searchText: item.promotion_caption,
        selectedSearchDeal: item
      })
    }

    this.setState({
      deal: item
    })

    if (this.props.onSelect) {
        this.props.onSelect(e, item)
    }
  }

  updateDeal(e, deal) {
    e.preventDefault()
    e.stopPropagation()

    this.setState({
      show: true,
      mode: 'update',
      deal: deal
    })
  }

  viewDeal(e, deal) {
    e.preventDefault()
    e.stopPropagation()

    this.setState({
      show: true,
      mode: 'view',
      deal: deal
    })
  }

  onUpdate(merchant) {
    this.refreshMerchant(merchant)
    this.setState({
      show: false,
    })
  }

  // onCloseMerchant() {
  //   this.setState({
  //     show: false,
  //   })
  // }

  refreshMerchant(m) {
    const merchants = this.state.merchants
    for (const [index, merchant] of merchants.entries()) {
      if (m.merchant_id === merchant.merchant_id) {
        merchants[index] = m
      }
    }
    this.setState({
      merchants: merchants
    })
  }

  handleClose() {
    this.setState({
      show: false
    })
  }

  render() {
    const generateList = (item) => {

        return <ListGroup.Item key={`list-merchants-${item.pk}`} action active={this.state.deal?(item.pk==this.state.deal.pk):false} onClick={(e) => this.onSelect(e, item)}>
            {item.image_url?
              <Image src={`${item.image_url}?timestamp=${Date.now()}`} fluid rectangle="true" style={{height: '1em'}} />
            : <FaStore />}&nbsp;
            {item.promotion_caption}&nbsp;
            {item.valid && (item.valid.toUpperCase() === 'Y') ?
                <FaEye title="visible" color="green" />
            : <FaEyeSlash title="not visible" color="red" />}

            {this.props.editable && !item.readOnly ? 
              <Button className="float-right" size="sm" onClick={(e) => this.updateDeal(e, item)}>
                <FaEdit /> Edit
              </Button>
            :
              <Button className="float-right" size="sm" onClick={(e) => this.viewDeal(e, item)}>
                View
              </Button>
            }
        </ListGroup.Item>
    }

    return <div>
        { this.state.isLoading || this.props.isLoading ? 
          <Spinner animation="border" size="sm" />
        : (this.state.isAdmin || this.state.isBank || !this.props.deals ) ?
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FaSearch />
                </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control type="text" placeholder={this.props.label??'Deal title'} disabled={this.props.disabled} readOnly={this.props.readOnly} value={this.state.searchText} onChange={(e) => this.handleSearch(e)} />
          </InputGroup>
        : null}
        { !this.state.selectedSearchDeal ?
          <ListGroup className="scrollview" variant="flush" key={`list-deals`}>
          {
              this.state.deals.map(generateList)
          }
          </ListGroup>
        : null }

        <Modal show={this.state.show} onHide={() => this.handleClose()} size="xl" backdrop='static'>
          <Modal.Header closeButton>
                <Modal.Title>{(this.state.mode==='create')?'Create':(this.state.mode==='update'?'Update':'')} Deal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Deal
              show={this.state.show}
              mode='update'
              deal={this.state.deal}
              key={`update-deal-${this.state.deal ? this.state.deal.pk : ''}-${this.state.timestamp}`}
            />
          </Modal.Body>
        </Modal>

    </div>
  } 
}

export default SearchMerchantDeals
