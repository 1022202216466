import React, { Component } from 'react'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { Button, Table, Form, FormControl, InputGroup, Spinner } from 'react-bootstrap'
import { FaTimes, FaPlusCircle } from "react-icons/fa"
import moment from 'moment'
import Datetime from 'react-datetime'
import { dealByMerchantId, getCmsUserStripeAccount } from '../../graphql/queries'

import MerchantModel from '../../models/MerchantModel'
import {SubPlans} from './CMSSubPlan'
import { getSubPlan, listCMSUsers, getMerchant, stripeAccountByEmail } from '../../graphql/queries'
import { createSubPlan, updateCMSUser, updateSubPlan, updateMerchant } from '../../graphql/mutations'

const DATETIME_FORMAT = 'Do MMM YYYY, h:mm:ss a'
class ListUsers extends Component {
    
    constructor() {
        super()

        this.state = {
            users: [],
            addAccess: []
        }
    }

    async componentDidMount() {
        this.getUsers()
    }

    async getUsers() {

        this.setState({
            searching: true
        })

        let token = null
        let users = []

        do {
            const listCMSUsersResult = await API.graphql(graphqlOperation(listCMSUsers, {
                limit: 1000,
                nextToken: token
            }))
            console.log('listCMSUsersResult', listCMSUsersResult)
            const cmsUsers = listCMSUsersResult.data.listCMSUsers.items
            console.log('cmsUsers', cmsUsers)
            token = listCMSUsersResult.data.listCMSUsers.nextToken

            users = users.concat(cmsUsers)
            this.setState({
                users: users
            })    
        } while (token)

        // sort users by creation date
        users.sort((a, b) => {
            if (a.createdAt > b.createdAt) {
                return -1
            } else if (a.createdAt < b.createdAt) {
                return 1
            }
            return 0
        })

        for (const user of users) {
            console.log('user', user)
            await this.populateRelatedMerchantData(user)
        }

        this.setState({
            users: users,
            searching: false
        })
    }

    async populateRelatedMerchantData(user) {
        // get merchant accesses
        if (typeof user.roles === 'string') {
            user.roles = JSON.parse(user.roles)
        }

        if (user.roles && user.roles.merchants) {

            const merchants = user.roles.merchants

            // retrieve deals via merchant groups
            for (const merchant of merchants) {
                let deals = []
                let token = null
                
                if (merchant.merchantId) {
                    // get deals
                    do {
                        const dealResult = await API.graphql(graphqlOperation(dealByMerchantId, {
                            merchant_id: merchant.merchantId,
                            nextToken: token
                        }))
                        deals = deals.concat(dealResult.data.dealByMerchantId.items)
                        token = dealResult.data.dealByMerchantId.nextToken
                    } while (token)
                    merchant.deals = deals
                    merchant.validDeals = deals.filter((deal) => deal.valid && (deal.valid.toLowerCase() === 'y'))

                    // get merchant name
                    const merchantResult = await API.graphql(graphqlOperation(getMerchant, {
                        merchant_id: merchant.merchantId,
                    }))
                    merchant.merchantName = merchantResult.data.getMerchant?merchantResult.data.getMerchant.merchant_name:''

                    // get stripe
                    const stripeResult = (await API.graphql(graphqlOperation(stripeAccountByEmail, { 
                        email: user.email, 
                        filter: {
                            merchantId: {
                                eq: merchant.merchantId
                            }
                        }
                    }))).data.stripeAccountByEmail.items
                    console.log('stripeResult', stripeResult)
                    merchant.stripe = stripeResult[0]
                }
            }
        }
    }

    async updateVerification(e, user) {
        user.verified = e.target.value
        console.log('user', user)
        await API.graphql(graphqlOperation(updateCMSUser, {input: { email: user.email, verified: user.verified }}))
    }

    async updatePlan(e, user) {
        console.log('user', user)
        const subPlan = e.target.value
       
        user.plan = subPlan
        this.updateUserPlan(user)
    }

    async updateUserPlan(user) {
        console.log('user', user)
        const userPlan = await API.graphql(graphqlOperation(getSubPlan, { email: user.email}))
        console.log('userPlan', userPlan)
        
        const api = userPlan.data.getSubPlan?updateSubPlan:createSubPlan
        API.graphql(graphqlOperation(api, { input: { email: user.email, plan: user.plan, startDate: user.subPlanStartDate, endDate: user.subPlanEndDate } })).then((result) => {
            console.log(result)
        })

        // update for cms v2
        API.graphql(graphqlOperation(updateCMSUser, { input: { email: user.email, subPlan: user.plan, subPlanStartDate: user.subPlanStartDate, subPlanEndDate: user.subPlanEndDate } })).then((result) => {
            console.log(result)
        })
        // update cmsPlan for every brand/merchant
        const merchants = user.roles.merchants
        if (merchants) {
            merchants.forEach((merchant) => {
                API.graphql(graphqlOperation(updateMerchant, { input: { merchant_id: merchant.merchantId, cmsPlan: user.plan } })).then((result) => {
                    console.log(result)
                })
            })
        }
    }

    handleDateTime(m, fieldname, index) {
        const users = this.state.users
        const user = users[index]

        if (typeof m === 'string') {
            user[fieldname] = moment(m, 'YYYY-MM-DD HH:mm:ss').unix()
        } else {
            user[fieldname] = m.unix()
        }

        if (fieldname === 'subPlanStartDate') {
            console.log(user.subPlanStartDate)
            user.subPlanEndDate = moment.unix(user.subPlanStartDate).endOf('day').add(1, 'y').unix()
        }

        this.updateUserPlan(user)

        this.setState({
            users: users
        })
    }

    clearDateTime(fieldname, index) {
        const users = this.state.users
        const user = users[index]

        user[fieldname] = null

        this.updateUserPlan(user)
        this.setState({
            users: users
        })
    }

    handleAddAccessChange(e, index) {
        this.state.addAccess[index] = e.target.value
        this.setState({
            addAccess: this.state.addAccess
        })
    }


    async addMerchantAccess(user, index) {
        const newRole = {
            merchantId: this.state.addAccess[index],
            roles: 'admin'
        }
        
        if (!user.roles.merchants) {
            user.roles = {
                merchants: []
            }
        }
        user.roles.merchants.push(newRole)
        // get only relevant fields, and preserve other roles at the same time
        user.roles.merchants = user.roles.merchants.map((merchant) => {
            return {
                merchantId: merchant.merchantId,
                roles: merchant.roles
            }
        })
        console.log('user.roles', user.roles)
        API.graphql(graphqlOperation(updateCMSUser, {input: { email: user.email, roles: JSON.stringify(user.roles)}}))

        await this.populateRelatedMerchantData(user)

        const users = this.state.users
        users[index] = user
        this.state.addAccess[index] = ''
        this.setState({
            users: users,
            addAccess: this.state.addAccess
        })
    }

    render() {

        return <small>
        { this.state.searching ?
            <Spinner animation="border" role="status" size="sm">
            </Spinner>
        : null}
            <Table striped hover size="sm" className="text-center">
                <thead>
                    <tr>
                        <th>createDate</th>
                        <th>modifyDate</th>
                        <th>Corp Name</th>
                        <th>UEN no.</th>
                        <th>verified</th>
                        <th>email</th>
                        <th>firstname</th>
                        <th>lastname</th>
                        <th>mobile</th>
                        <th>marketing consent</th>
                        <th>plans</th>
                        <th>start</th>
                        <th>end</th>
                        <th>merchants</th>
                        <th>stripe status</th>
                        <th>deals</th>
                        <th>valid deals</th>
                    </tr>
                </thead>
                <tbody>
                    { this.state.users.map((item, index) => {

                        let merchants = []

                        if (item.roles && item.roles.merchants) {

                            merchants = item.roles.merchants.map((merchant, index) => {
                                return [
                                    <td>
                                        {merchant.merchantName}
                                    </td>,
                                    <td>
                                        {merchant.stripe && merchant.stripe.status}
                                    </td>,
                                    <td>
                                        {merchant.deals && merchant.deals.length}
                                    </td>,
                                    <td>
                                        {merchant.validDeals && merchant.validDeals.length}
                                    </td>
                                ]
                            })
                        }

                        let rows = [<tr key={`list-users-${index}`}>
                            <td>
                                {item.createdAt}
                            </td>
                            <td>
                                {item.updatedAt}
                            </td>
                            <td>
                                {item.corpName}
                            </td>
                            <td>
                                {item.uen}
                            </td>
                            <td>
                                <Form.Control as="select" size="sm" value={item.verified} custom onChange={(e) => this.updateVerification(e, item)}>
                                    <option key="verify-0" value="0">N</option>
                                    <option key="verify-1" value="1">Y</option>
                                </Form.Control>
                            </td>
                            <td>
                                {item.email}
                            </td>
                            <td>
                                {item.firstName}
                            </td>
                            <td>
                                {item.lastName}
                            </td>
                            <td>
                                {item.mobile}
                            </td>
                            <td>
                                {item.marketingConsent?(item.marketingConsent==='1'?'y':'n'):'-'}
                            </td>
                            <td md="3">
                                <Form.Control as="select" size="sm" custom onChange={(e) => this.updatePlan(e, item)}>
                                    <option key={`${item.username}-empty`}></option>
                                    { SubPlans.map(plan =>
                                        Array.isArray(plan.code)?
                                            plan.code.map((s,i) => <option key={`${item.username}-${s}`} selected={item.subPlan && s.toLowerCase()===item.subPlan.toLowerCase()}>{plan.ddlText[i]}</option>)
                                        :
                                            <option key={`${item.username}-${plan.code}`} selected={plan.code && item.subPlan && (plan.code.toLowerCase()===item.subPlan.toLowerCase())}>{plan.ddlText}</option>
                                    )}
                                </Form.Control>
                            </td>
                            <td md="3">
                                <Datetime size="sm" dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" closeOnSelect={true} initialViewDate={moment().startOf('day')} value={item.subPlanStartDate?moment.unix(item.subPlanStartDate):''} onChange={(m) => this.handleDateTime(m, 'subPlanStartDate', index)} />
                                <FaTimes onClick={() => this.clearDateTime('subPlanStartDate', index)} />
                            </td>
                            <td md="3">
                                <Datetime size="sm" dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" closeOnSelect={true} initialViewDate={moment().endOf('day')} value={item.subPlanEndDate?moment.unix(item.subPlanEndDate):''} onChange={(m) => this.handleDateTime(m, 'subPlanEndDate', index)} />
                                <FaTimes onClick={() => this.clearDateTime('subPlanEndDate', index)} />
                            </td>

                            <td>
                                <Form.Control placeholder="merchant id" size="sm" value={this.state.addAccess[index]} onChange={(e) => this.handleAddAccessChange(e, index)} />
                                <FaPlusCircle onClick={() => this.addMerchantAccess(item, index)}></FaPlusCircle>
                            </td>
                        </tr>]

                        merchants.forEach((merchant, mIndex) => {
                            rows.push(<tr key={`list-users-${index}-${mIndex}`}>
                                <td colSpan="13"></td>
                                {merchant}
                            </tr>)
                        })

                        return rows
                    })}
                </tbody>
            </Table>
        </small>
    }
}

export default ListUsers