import { API, Auth } from 'aws-amplify'
import Geohash from 'latlon-geohash'
import awsExports from '../aws-exports'

const guidGenerator = ()  => {
    var S4 = function() {
       return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}


// cannot import file outside from src so it has to come from the caller
const getEnv = () => {
    let env = 'dev'
    try{
        // parse endpts as amplify append "/dev" or "/prod" at the end of the name
        const REGEX = /.*\/(\w+)/
        env = awsExports.aws_cloud_logic_custom[0].endpoint.match(REGEX)[1]
    }catch (e){
        console.error('error on getEnv', e)
    }finally{
        return env
    }
}

const invalidateCloudFrontCache = async (path) => {

    const regex = /https:\/\/[^\/]*(\/.*)\./
    
    console.log('path', path)
    const cfPath = `${regex.exec(path)[1]}*`
    console.log('cfPath', cfPath)

    const invalidationResult = await API.post('CloudFront', '/invalidate', {
        body: {
            path: cfPath
        }
    })
    console.log('invalidationResult', invalidationResult)
}

const getAssetUrl = (path) => {
    return `https://assets.${getEnv()}.cardspal.com/public/${path}`
}

const updateGeohash = (item) => {
    try {
        item.geohashlong = Geohash.encode(item.latitude, item.longitude, 12)
        item.geohashshort = Geohash.encode(item.latitude, item.longitude, 7)
        item.geohashsix = Geohash.encode(item.latitude, item.longitude, 6)
    } catch (e) {
        // TODO :: show error on frontend
        console.error('geohash error', e)
    }
}

const invalidateRefreshToken = async (refreshToken, _clientId) => {
    const env = getEnv()
    const clientId = _clientId || awsExports.aws_user_pools_web_client_id
    const endPoint = `https://cardspal-${env}.auth.ap-southeast-1.amazoncognito.com/oauth2/revoke?token=${refreshToken}&client_id=${clientId}`
    const response = await fetch(`${endPoint}/oauth2/revoke`, {
        method: 'POST',
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
    })
    console.log('invalidateRefreshToken ', response)
}

const generatePromoCodes = async (total, prefix='CP') => {
    var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };

    const codes = []

    for (let i=0; i < total; i++){
        codes.push(prefix + S4() + S4())
    }
     return codes
}

const isValidHttpUrl = (string) => {
    const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
}

export {
    guidGenerator,
    getEnv,
    invalidateCloudFrontCache,
    getAssetUrl,
    updateGeohash,
    invalidateRefreshToken,
    generatePromoCodes,
    isValidHttpUrl
}