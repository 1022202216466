export const listMalls = /* GraphQL */ `
  query ListMalls(
    $mallId: ID
    $filter: ModelMallFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMalls(
      mallId: $mallId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        mallId
        address
        imageUrl
        name
        order
        searchField
        valid
        # createdAt
        # updatedAt
      }
      nextToken
    }
  }
`;

export const listTagss = /* GraphQL */ `
  query ListTagss(
    $id: ID
    $tagName: ModelStringKeyConditionInput
    $filter: ModelTagsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTagss(
      id: $id
      tagName: $tagName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        merchantId
        tagName
        tagType
        # createdAt
        # updatedAt
      }
      nextToken
    }
  }
`;
export const deleteTags = /* GraphQL */ `
  mutation DeleteTags(
    $input: DeleteTagsInput!
    $condition: ModelTagsConditionInput
  ) {
    deleteTags(input: $input, condition: $condition) {
      id
      merchantId
      tagName
      tagType
      # createdAt
      # updatedAt
    }
  }
`;

// export const listMerchantOutlets = /* GraphQL */ `
//   query ListMerchantOutlets(
//     $merchant_id: ID
//     $store_id: ModelIDKeyConditionInput
//     $postal_code: String
//     $filter: ModelMerchantOutletFilterInput
//     $limit: Int
//     $nextToken: String
//     $sortDirection: ModelSortDirection
//   ) {
//     listMerchantOutlets(
//       merchant_id: $merchant_id
//       store_id: $store_id
//       postal_code: $postal_code
//       filter: $filter
//       limit: $limit
//       nextToken: $nextToken
//       sortDirection: $sortDirection
//     ) {
//       items {
//         address
//         deals {
//           nextToken
//         }
//         deals_category
//         display_merchant_name
//         distance
//         general_location
//         geohashlong
//         geohashshort
//         geohashsix
//         google_id
//         google_placeid
//         google_type
//         image_path
//         image_src
//         latitude
//         longitude
//         mallId
//         merchant_id
//         merchant_name
//         merchant_name_lowercase
//         merchant_namelist
//         merchant_unique_id
//         online_company
//         outlet_name
//         phone
//         postal_code
//         postal_district
//         postal_district_name
//         postal_sector
//         store_id
//         subc_online
//         subcategory
//         valid
//         createdAt
//         updatedAt
//         createdByUser
//         createdByGroups
//       }
//       nextToken
//     }
//   }
// `;
export const getMerchant = /* GraphQL */ `
  query GetMerchant($merchant_id: ID!) {
    getMerchant(merchant_id: $merchant_id) {
      mainCat
      outlets {
        items {
          address
          mainCat
          display_merchant_name
          distance
          general_location
          geohashlong
          geohashshort
          geohashsix
          google_id
          google_placeid
          google_type
          image_path
          image_src
          latitude
          longitude
          mallId
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_namelist
          merchant_unique_id
          online_company
          outlet_name
          phone
          postal_code
          postal_district
          postal_district_name
          postal_sector
          store_id
          subc_online
          subcategory
          valid
          createdAt
          updatedAt
          createdByUser
          createdByGroups
        }
        nextToken
      }
      display_merchant_name
      google_type
      merchant_id
      merchant_name
      merchant_name_lowercase
      online_company
      valid
      createdAt
      updatedAt
      createdByUser
      createdByGroups
    }
  }
`;

export const listMerchants = /* GraphQL */ `
query listMerchants($nextToken: String, $limit: Int){
    listMerchants(nextToken: $nextToken, limit: $limit){
      nextToken
      items{
        merchant_id
        merchant_name
        merchant_name_lowercase
        display_merchant_name
        mainCat
        google_type
        online_company
        valid
        outlets {
          items {
            address
            mainCat
            display_merchant_name
            distance
            general_location
            geohashlong
            geohashshort
            google_id
            google_placeid
            google_type
            image_path
            image_src
            latitude
            longitude
            mallId
            merchant_id
            merchant_name
            merchant_namelist
            merchant_name_lowercase
            merchant_unique_id
            online_company
            outlet_name
            phone
            postal_code
            postal_district
            postal_district_name
            postal_sector
            store_id
            subc_online
            deals {
              items {
                address
                aggregator
                area
                bank
                brand
                mainCat
                cc_buddy_category
                ccbuddy_card_name
                country
                deal_handler
                display_merchant_name
                district
                district_name
                end
                end_timestamp
                festive
                general_location
                google_id
                google_placeid
                google_type
                image_path
                image_url
                is_online
                latitude
                longitude
                merchant_id
                merchant_name
                merchant_name_lowercase
                merchant_unique_id
                online_company
                phone
                pk
                postal_code
                promo_code
                promotion
                promotion_analytic
                promotion_caption
                rank
                rank_updated
                score
                sector
                start
                start_timestamp
                store_id
                subcategory
                terms
                valid
                website              
              }
            }
          }
        }
      }
    }
  }`

  export const searchMerchants = /* GraphQL */ `
  query SearchMerchants(
    $filter: SearchableMerchantFilterInput
    $sort: [SearchableMerchantSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMerchantAggregationInput]
  ) {
    searchMerchants(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        mainCat
        outlets {
          items {
            address
            mainCat
            display_merchant_name
            distance
            general_location
            geohashlong
            geohashshort
            google_id
            google_placeid
            google_type
            image_path
            image_src
            latitude
            longitude
            mallId
            merchant_id
            merchant_name
            merchant_namelist
            merchant_name_lowercase
            merchant_unique_id
            online_company
            outlet_name
            phone
            postal_code
            postal_district
            postal_district_name
            postal_sector
            store_id
            subc_online
            deals {
              items {
                address
                aggregator
                area
                bank
                brand
                mainCat
                cc_buddy_category
                ccbuddy_card_name
                country
                deal_handler
                display_merchant_name
                district
                district_name
                end
                end_timestamp
                festive
                general_location
                google_id
                google_placeid
                google_type
                image_path
                image_url
                is_online
                latitude
                longitude
                merchant_id
                merchant_name
                merchant_name_lowercase
                merchant_unique_id
                online_company
                phone
                pk
                postal_code
                promo_code
                promotion
                promotion_analytic
                promotion_caption
                rank
                rank_updated
                score
                sector
                start
                start_timestamp
                store_id
                subcategory
                terms
                valid
                website              
              }
            }
          }
          nextToken
        }
        display_merchant_name
        google_type
        merchant_id
        merchant_name
        merchant_name_lowercase
        online_company
        valid
        acceptCC
        merchantImageUrl
        merchantImageUrlV2
        defaultImageUrl
        pockCalMerchImage
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }`
// export const listDealMasters = /* GraphQL */ `
// query ListDealMasters(
//   $pk: ID
//   $merchant_id: ID
//   $merchant_unique_id: ID
//   $deal_handler: String
//   $aggregator: String
//   $filter: ModelDealMasterFilterInput
//   $limit: Int
//   $nextToken: String
//   $sortDirection: ModelSortDirection
// ) {
//   listDealMasters(
//     pk: $pk
//     merchant_id: $merchant_id
//     merchant_unique_id: $merchant_unique_id
//     deal_handler: $deal_handler
//     aggregator: $aggregator
//     filter: $filter
//     limit: $limit
//     nextToken: $nextToken
//     sortDirection: $sortDirection
//   ) {
//     items {
//       address
//       aggregator
//       area
//       bank
//       brand
//       category
//       cc_buddy_category
//       ccbuddy_card_name
//       country
//       deal_handler
//       display_merchant_name
//       district
//       district_name
//       end
//       end_timestamp
//       festive
//       general_location
//       google_id
//       google_placeid
//       google_type
//       image_path
//       image_url
//       is_online
//       latitude
//       longitude
//       merchant_id
//       merchant_name
//       merchant_name_lowercase
//       merchant_unique_id
//       online_company
//       phone
//       pk
//       postal_code
//       promo_code
//       promotion
//       promotion_analytic
//       promotion_caption
//       rank
//       rank_updated
//       score
//       sector
//       start
//       start_timestamp
//       store_id
//       subcategory
//       terms
//       usable_promocode
//       valid
//       website
//       # createdAt
//       # updatedAt
//       createdByUser
//       createdByGroups
//     }
//     nextToken
//   }
// }
// `;

export const getDealMaster = /* GraphQL */ `
  query GetDealMaster($pk: ID!) {
    getDealMaster(pk: $pk) {
      address
      aggregator
      area
      bank
      brand
      mainCat
      cc_buddy_category
      ccbuddy_card_name
      country
      deal_handler
      display_merchant_name
      district
      district_name
      end
      end_timestamp
      festive
      general_location
      geohashlong
      geohashshort
      google_id
      google_placeid
      google_type
      image_path
      image_url
      is_online
      isSponsored
      sponsoredRank
      latitude
      longitude
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_unique_id
      online_company
      phone
      pk
      postal_code
      promo_code
      promotion
      promotion_analytic
      promotion_caption
      rank
      rank_updated
      score
      sector
      start
      start_timestamp
      store_id
      subcategory
      terms
      usable_promocode
      valid
      website
      # createdAt
      # updatedAt
      createdByUser
      createdByGroups
    }
  }
`;

// export const createMerchant = `mutation createMerchant(
//     $merchant_name: String, 
//     $merchant_name_lowercase: String, 
//     $display_merchant_name: String, 
//     $category: String, 
//     $google_type: String, 
//     $online_company: String, 
//     $valid: String
// ){
//     createMerchant(input:{
//       merchant_name: $merchant_name
//       merchant_name_lowercase: $merchant_name_lowercase
//       display_merchant_name: $display_merchant_name
//       category: $category
//       google_type: $google_type
//       online_company: $online_company
//       valid: $valid
//     }){
//       merchant_id
//       merchant_name
//       merchant_name_lowercase
//       display_merchant_name
//       category
//       google_type
//       online_company
//       valid
//     }
//   }`

// export const createOutlet = `mutation createOutlet(
//     $merchant_id: ID!,
//     $address: String,
//     $deals_category: String,
//     $distance: String,
//     $general_location: String,
//     $geohashlong: String,
//     $geohashshort: String,
//     $google_id: String,
//     $google_placeid: String,
//     $image_path: String,
//     $image_src: String,
//     $latitude: String,
//     $longitude: String,
//     $merchant_namelist: String,
//     $outlet_name: String,
//     $phone: String,
//     $postal_code: String,
//     $postal_district: String,
//     $postal_district_name: String,
//     $postal_sector: String,
//     $subc_online: String
//   ){
//     createMerchantOutlet(input:{
//       merchant_id: $merchant_id
//       address: $address
//       deals_category: $deals_category,
//       distance: $distance,
//       general_location: $general_location,
//       geohashlong: $geohashlong,
//       geohashshort: $geohashshort,
//       google_id: $google_id,
//       google_placeid: $google_placeid,
//       image_path: $image_path
//       image_src: $image_src
//       latitude: $latitude
//       longitude: $longitude
//       merchant_namelist: $merchant_namelist
//       outlet_name: $outlet_name
//       phone: $phone
//       postal_code: $postal_code
//       postal_district: $postal_district
//       postal_district_name: $postal_district_name
//       postal_sector: $postal_sector
//       subc_online: $subc_online
//     }) {
//       address
//       deals_category
//       distance
//       general_location
//       geohashlong
//       geohashshort
//       google_id
//       google_placeid
//       image_path
//       image_src
//       latitude
//       longitude
//       merchant_namelist
//       merchant_unique_id
//       outlet_name
//       phone
//       postal_code
//       postal_district
//       postal_district_name
//       postal_sector
//       subc_online
//     }
//   }`

// export const createDeal = `mutation createDeal(
//     $address: String,
//     $area: String,
//     $bank: String,
//     $category: String,
//     $cc_buddy_category: String,
//     $ccbuddy_card_name: String,
//     $country: String,
//     $deal_handler: String,
//     $display_merchant_name: String,
//     $district: String,
//     $district_name: String,
//     $end: String,
//     $festive: [String],
//     $general_location: String,
//     $google_id: String,
//     $google_placeid: String,
//     $google_type: String,
//     $image_path: String,
//     $image_url: String,
//     $is_online: String,
//     $latitude: String,
//     $longitude: String,
//     $max_pax: String,
//     $merchant_id: ID!,
//     $merchant_name: String,
//     $merchant_name_lowercase: String,
//     $merchant_unique_id: ID!,
//     $min_pax: String,
//     $online_company: String,
//     $phone: String,
//     $postal_code: String,
//     $promo_code: String,
//     $promotion: String,
//     $promotion_analytic: String,
//     $promotion_caption: String,
//     $rank: String,
//     $rank_updated: String,
//     $score: String,
//     $sector: String,
//     $start: String,
//     $store_id: ID!,
//     $subcategory: String,
//     $terms: String,
//     $valid: String,
//     $website: String
// ){
// createDealMaster(input:{
//     address: $address
//     area: $area
//     bank: $bank
//     category: $category
//     cc_buddy_category: $cc_buddy_category
//     ccbuddy_card_name: $ccbuddy_card_name
//     country: $country
//     deal_handler: $deal_handler
//     display_merchant_name: $display_merchant_name
//     district: $district
//     district_name: $district_name
//     end: $end
//     festive: $festive
//     general_location: $general_location
//     google_id: $google_id
//     google_placeid: $google_placeid
//     google_type: $google_type
//     image_path: $image_path
//     image_url: $image_url
//     is_online: $is_online
//     latitude: $latitude
//     longitude: $longitude
//     max_pax: $max_pax
//     merchant_id: $merchant_id
//     merchant_name: $merchant_name
//     merchant_name_lowercase: $merchant_name_lowercase
//     merchant_unique_id: $merchant_unique_id
//     min_pax: $min_pax
//     online_company: $online_company
//     phone: $phone
//     postal_code: $postal_code
//     promo_code: $promo_code
//     promotion: $promotion
//     promotion_analytic: $promotion_analytic
//     promotion_caption: $promotion_caption
//     rank: $rank
//     rank_updated: $rank_updated
//     score: $score
//     sector: $sector
//     start: $start
//     store_id: $store_id
//     subcategory: $subcategory
//     terms: $terms
//     valid: $valid
//     website: $website
// }){
//     address
//     area
//     bank
//     category
//     cc_buddy_category
//     ccbuddy_card_name
//     country
//     deal_handler
//     display_merchant_name
//     district
//     district_name
//     end
//     festive
//     general_location
//     google_id
//     google_placeid
//     google_type
//     image_path
//     image_url
//     is_online
//     latitude
//     longitude
//     max_pax
//     merchant_id
//     merchant_name
//     merchant_name_lowercase
//     merchant_unique_id
//     min_pax
//     online_company
//     phone
//     pk
//     postal_code
//     promo_code
//     promotion
//     promotion_analytic
//     promotion_caption
//     rank
//     rank_updated
//     score
//     sector
//     start
//     store_id
//     subcategory
//     terms
//     valid
//     website
// }
// }`;

// export const updateMerchant = `mutation updateMerchant(
//     $merchant_id: ID!,
//     $merchant_name: String, 
//     $merchant_name_lowercase: String, 
//     $display_merchant_name: String, 
//     $category: String, 
//     $google_type: String, 
//     $online_company: String, 
//     $valid: String
//   ){
//     updateMerchant(input:{
//       merchant_id: $merchant_id
//       merchant_name: $merchant_name
//       merchant_name_lowercase: $merchant_name_lowercase
//       display_merchant_name: $display_merchant_name
//       category: $category
//       google_type: $google_type
//       online_company: $online_company
//       valid: $valid
//     }){
//       merchant_id
//       merchant_name
//       merchant_name_lowercase
//       display_merchant_name
//       category
//       google_type
//       online_company
//       valid
//       outlets {
//           items {
//               merchant_id
//               store_id
//           }
//       }
//     }
//   }`;

// export const updateOutlet = `mutation updateOutlet(
//     $merchant_id: ID!,
//     $store_id: ID!, 
//     $address: String,
//     $deals_category: String,
//     $distance: String,
//     $general_location: String,
//     $geohashlong: String,
//     $geohashshort: String,
//     $google_id: String,
//     $google_placeid: String,
//     $image_path: String,
//     $image_src: String,
//     $latitude: String,
//     $longitude: String,
//     $merchant_namelist: String,
//     $merchant_unique_id: ID!,
//     $outlet_name: String,
//     $phone: String,
//     $postal_code: String,
//     $postal_district: String,
//     $postal_district_name: String,
//     $postal_sector: String,
//     $subc_online: String
//   ){
//     updateMerchantOutlet(input:{
//       merchant_id: $merchant_id
//       store_id: $store_id
//       address: $address
//       deals_category: $deals_category,
//       distance: $distance,
//       general_location: $general_location,
//       geohashlong: $geohashlong,
//       geohashshort: $geohashshort,
//       google_id: $google_id,
//       google_placeid: $google_placeid,
//       image_path: $image_path
//       image_src: $image_src
//       latitude: $latitude
//       longitude: $longitude
//       merchant_namelist: $merchant_namelist
//       merchant_unique_id: $merchant_unique_id
//       outlet_name: $outlet_name
//       phone: $phone
//       postal_code: $postal_code
//       postal_district: $postal_district
//       postal_district_name: $postal_district_name
//       postal_sector: $postal_sector
//       subc_online: $subc_online
//     }) {
//       address
//       deals_category
//       distance
//       general_location
//       geohashlong
//       geohashshort
//       google_id
//       google_placeid
//       image_path
//       image_src
//       latitude
//       longitude
//       merchant_namelist
//       merchant_unique_id
//       outlet_name
//       phone
//       postal_code
//       postal_district
//       postal_district_name
//       postal_sector
//       subc_online
//     }
//   }`