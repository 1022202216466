import { API, graphqlOperation } from 'aws-amplify'
import { createDealMaster, updateDealMaster, deleteDealMaster, deleteDealApproval, deleteTags, createTags } from '../graphql/mutations'
import { tagById } from '../graphql/queries'
import * as utils from '../utils'

export default class DealModel {

    indices = ['geohashlong', 'geohashshort', 'rank_updated', 'deal_handler', 'cc_buddy_category', 'country', 'is_online', 'bank', 'aggregator', 'google_placeid', "isPinned"]
    item = null

    constructor(item) {
        this.item = {
            pk: item.pk??'',
            address: item.address,
            aggregator: item.aggregator,
            mainCat: item.mainCat,
            display_merchant_name: item.display_merchant_name,
            general_location: item.general_location,
            google_id: item.google_id,
            google_placeid: item.google_placeid,
            google_type: item.google_type,
            geohashlong: item.geohashlong,
            geohashshort: item.geohashshort,
            latitude: item.latitude,
            longitude: item.longitude,
            merchant_id: item.merchant_id,
            merchant_name: item.merchant_name,
            merchant_name_lowercase: item.merchant_name_lowercase,
            merchant_unique_id: item.merchant_unique_id,
            phone: item.phone,
            postal_code: item.postal_code,
            district: item.district,
            district_name: item.district_name,
            sector: item.sector,
            store_id: item.store_id,
            bank: item.bank,
            cc_buddy_category: item.cc_buddy_category,
            ccbuddy_card_name: item.ccbuddy_card_name,
            deal_handler: item.deal_handler,
            subcategory: item.subcategory,
            rank: item.rank,
            rank_updated: item.rank_updated,
            score: item.score,
            image_path: item.image_path,
            usable_promocode: item.usable_promocode,
            start: item.start,
            end: item.end,
            is_online: item.is_online,
            isSponsored: item.isSponsored,
            isPinned: item.isPinned,
            pinPageLocation: item.pinPageLocation,
            pinOrder: item.pinOrder,
            sponsoredRank: item.sponsoredRank,
            valid: item.valid,
            area: item.area,
            country: item.country,
            promotion: item.promotion,
            promo_code: item.promo_code,
            promotion_analytic: item.promotion_analytic,
            promotion_caption: item.promotion_caption,
            terms: item.terms,
            image_url: item.image_url,
            website: item.website,
            brand: item.brand,
            start_timestamp: item.start_timestamp,
            end_timestamp: item.end_timestamp,
            purchaseable: item.purchaseable,
            actualPrice: item.actualPrice,
            discount: item.discount,
            discountPrice: item.discountPrice,
            purchasable: item.purchasable,
            purchasePrice: item.purchasePrice,
            purchaseLimitPerCustomer: item.purchaseLimitPerCustomer,
            purchasableTexts: item.purchasableTexts ?? null,
            ticketType: item.ticketType ?? null,
            minQtyTx: item.minQtyTx ?? null,
        }
        
        if (item.mainCat) {
            this.item.mainCat = item.mainCat;
        }
    }

    async invalidate() {
        this.item.valid = 'N'
        return await this.update()
    }

    async validate() {
        this.item.valid = 'Y'
        return await this.update()
    }

    async updateMerchantInfo(merchant) {

        const delta = {
            merchant_id: merchant.merchant_id,
            display_merchant_name: merchant.display_merchant_name,
            google_type: merchant.google_type,
            merchant_name: merchant.merchant_name,
            merchant_name_lowercase: merchant.merchant_name_lowercase,
            mainCat: merchant.mainCat
        }
        this.item = { ...this.item, ...delta }
        return await this.update()
    }

    async updateOutletInfo(outlet) {

        const delta = {
            address: outlet.address,
            general_location: outlet.general_location,
            google_id: outlet.google_id,
            google_place_id: outlet.google_place_id,
            google_type: outlet.google_type,
            latitude: outlet.latitude,
            longitude: outlet.longitude,
            phone: outlet.phone,
            postal_code: outlet.postal_code,
            sector: outlet.postal_sector,
            district: outlet.postal_district,
            district_name: outlet.postal_district_name,
            subcategory: outlet.subcategory
        }
        if (outlet.mainCat) {
            delta.mainCat = outlet.mainCat;
        }
        this.item = { ...this.item, ...delta }
        return await this.update()
    }

    async setTags(originalTags, tags) {
        if (originalTags) {
            for (const tag of originalTags) {
                const result = await API.graphql(graphqlOperation(deleteTags, {
                    input:
                    {
                        id: this.item.pk,
                        tagName: tag
                    }
                }))
            }
        }

        if (tags) {
            for (const tag of tags) {
                if (tag.trim() !== '') {
                    const result = await API.graphql(graphqlOperation(createTags, {
                        input: {
                            id: this.item.pk, 
                            tagName: tag,
                            merchantId: this.item.merchant_id,
                            tagType: 'deal'
                        }
                    }))
                }
            }
        }
    }

    async getTags() {
        const search = {
            id: this.item.pk
        }
        let tags = []
        do {
            const result = await API.graphql(graphqlOperation(tagById, search))
            tags = tags.concat(result.data.tagById.items)
            search.nextToken = result.data.tagById.nextToken
        } while (search.nextToken)

        tags = tags.map(tag => tag.tagName)

        return tags
    }

    async create() {
        // remove indices with null values. ddb will throw error. cannot have null values in indices
        for (const index of this.indices) {
            if (index in this.item && (!this.item[index] || this.item[index] === null || this.item[index] === '' || this.item[index].toLowerCase() === 'null')) {
                delete this.item[index]
            }
        }
        
        const result = await API.graphql(graphqlOperation(createDealMaster, { input: this.item }))

        this.item = result.data.createDealMaster
        return this.item
    }

    async delete(tags) {
        {
            const result = await API.graphql(graphqlOperation(deleteDealApproval, { input: {pk: this.item.pk} }))
        }
    
        if (tags) {
            tags.forEach(async (tag) => {
                const result = await API.graphql(graphqlOperation(deleteTags, { input: {id: this.item.pk, tagName: tag} }))
            })
        }
        
        const result = await API.graphql(graphqlOperation(deleteDealMaster, { input: {pk: this.item.pk} }))

        this.item = result.data.deleteDealMaster
        return this.item
    }

    async update(options) {

        // nullify indices with empty space values. ddb will throw error. cannot have empty values in indices
        for (const index of this.indices) {
            if (index in this.item && this.item[index] !== null && (this.item[index] === '' || this.item[index].toLowerCase() === 'null')) {
                this.item[index] = null
            }
        }
        const result = await API.graphql(graphqlOperation(updateDealMaster, { input: this.item }))
        this.item = result.data.updateDealMaster

        if (options && options.invalidateCloudFrontCache) {
            options.invalidateCloudFrontCache.forEach((key) => {
                utils.invalidateCloudFrontCache(this.item[key])
            })
        }

        return this.item
    }
}
