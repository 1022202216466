import Model from './Model'

export default class CardParamModel extends Model {

    constructor(item) {
        super(item, 'CardParam', ['merchantId', 'bonus', 'cardId', 'mcc', 'valid'], ['0', '1'], 
            {
                id: null,
                cardId: null,
                discount: null,
                bonus: null,
                minSpend: null,
                maxSpend: null,
                cap: null,
                capFrequency: null,
                merchantId: null,
                merchant: null,
                merchantMainCat: null,
                merchantSubcat: null,
                mcc: null,
                mccMainCat: null,
                mccSubcat: null,
                valid: '1'
            }
        )
    }
}
