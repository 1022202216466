import React, { Component } from 'react';
import { Container, Row, Col, Spinner, ListGroup } from 'react-bootstrap';
import Select from 'react-select';
import { FaPlus } from "react-icons/fa";
import { API, graphqlOperation } from 'aws-amplify'
import Coupons from '../widgets/Coupons'
import { getCoupons, couponsByType } from '../../graphql/queries'
import { COUPON_TYPES } from '../../utils/constants'



class DailyTipsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            mode: '',
            isLoading: false,
            selectedType: COUPON_TYPES[0]
        }
    }

    async componentDidMount() {
        this.setState({
            isLoading: true
        });
        
        API.graphql(graphqlOperation(couponsByType, {type: this.state.selectedType.value})).then((result) => {
            this.setState({ list: result.data.couponsByType.items, isLoading: false });
        }).catch(e => {
            console.debug('Error on DidMount ',e)
            this.setState({isLoading: false})
        }
        )       
    }

    createCoupons(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            mode: 'createCoupons'
        })
    }

    selectCoupons(e, coupons) {
        e.preventDefault()
        e.stopPropagation()

        API.graphql(graphqlOperation(getCoupons,{pk: coupons.pk})).then((result) => {
            const coupons = result.data.getCoupons ?? null
            this.setState({
                coupons: coupons,
                mode: 'updateCoupons'
            })
        })  
    }

   
    getCouponsByType(item) {
        if (!item) {
            this.setState({ list: [], isLoading: false})
            return
        }

        API.graphql(graphqlOperation(couponsByType, {type: item.value})).then((result) => {
            this.setState({ list: result.data.couponsByType.items, isLoading: false, selectedType: item});
        }).catch(e => {
            console.debug('Error on getCouponsByType ',e)
            this.setState({isLoading: false})
        })
   }

    render() {
        const { list, mode, coupons, selectedType } = this.state
        
        return <Container fluid>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h3>Coupons</h3>
                        </Col>
                        <Col>
                            <FaPlus className="float-right" onClick={(e) => this.createCoupons(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Select
                                value={selectedType}
                                closeMenuOnSelect={true}
                                isSearchable={true}
                                placeholder="Choose Coupon Type"
                                options={COUPON_TYPES}
                                onChange={(id) => this.getCouponsByType(id)}
                            />
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row><Col><hr style={{height: 5}}></hr></Col></Row>
                    <Row>
                    { this.state.isLoading ? 
                        <Spinner animation="border" />
                    : 
                        <Col className="scrollview">
                            <ListGroup variant="flush">
                            {
                                list.length > 0 ?
                                    list.map((item) =>
                                        <ListGroup.Item key={`list-campaign-${item.pk}`} action onClick={(e) => this.selectCoupons(e, item)}>
                                            <div>
                                                { item.title }
                                            </div>
                                            <small>
                                                {item.merchantName} [ Valid: {item.valid==='1'?'True':'False'} ]
                                            </small>
                                        </ListGroup.Item>
                                    )
                                : 'No Coupons'
                            }
                            </ListGroup>
                        </Col>
                    }
                    </Row>
                </Col>

                <Col className="scrollview">
                    <Coupons
                        mode='create'
                        key={`create-coupons`}
                        show={mode === 'createCoupons'}
                        refresh={(type) => this.getCouponsByType(type)}
                    />

                    <Coupons
                        mode='update'
                        key={`update-coupons-${coupons ? coupons.pk : ''}`}
                        coupons={coupons}
                        show={mode === 'updateCoupons'}
                        refresh={(type) => this.getCouponsByType(type)}
                    />
                </Col>
            </Row>            
        </Container>
    }
}

export default DailyTipsView