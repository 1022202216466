import React, { Component } from 'react'
import { Form, Button, Toast, InputGroup, Image, Col} from "react-bootstrap"
import { API, graphqlOperation, Storage } from 'aws-amplify'
import Datetime from 'react-datetime'
import moment from 'moment-timezone'
import { backOff } from 'exponential-backoff';
import { createDailyTips, updateDailyTips } from '../../graphql/mutations'
import { getTipsByDate } from '../../graphql/queries'
import CMSImageUploader from './CMSImageUploader'
import { publish } from '../widgets/CMSModal'
import { TZ } from '../../utils/constants'
import * as utils from '../../utils'

moment.tz.setDefault(TZ);

class DailyTips extends Component {

    constructor(props) {
        super(props)

        const tip = props.data ? { ...props.data } : {};
        this.state = {
            item: {
                id: tip.id,
                day: tip.day ?? moment().format('dddd').toLowerCase(),
                category: tip.category ?? '',
                title: tip.title ?? '',
                description: tip.description ?? '',
                displayType: tip.displayType ?? 'deal',
                displayDate: tip.displayDate ?? moment().startOf('day').unix(),
                theme: tip.theme ?? '',
                icon: tip.icon ?? '',
                dealId: tip.dealId ?? '',
                cardId: tip.cardId ?? '',
                route: tip.route ?? ''
            },
            croppedImages: {},
            showToast: false,
        };
    }

    alert(msg, callback) {
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    async getCroppedImage(croppedImage, fieldname) {
        let croppedImages = this.state.croppedImages??{}
        croppedImages[fieldname] = croppedImage
        
        this.setState({
            croppedImages: croppedImages
        })
    }


    valuesVerified() {
        const { item } = this.state
        return new Promise(async (resolve, reject) => {

            // if (!croppedImages.tipIcon && !item.icon) {
            //     this.alert('Missing Tip Icon')
            //     return resolve(false)
            // }

            const result = await API.graphql(graphqlOperation(getTipsByDate, { displayDate: item.displayDate }))
            const temp = result.data.getTipsByDate.items
            
            for (let i of temp) {
                if (this.props.mode === 'create' && item.displayType === i.displayType) {
                    alert('Duplicate daily tip of the same type in the same day')
                    return resolve(false)
                } else if(this.props.mode === 'update' && item.id !== i.id && item.displayType === i.displayType) {
                    alert('Duplicate daily tip of the same type in the same day')
                    return resolve(false)
                }
            }
            

            resolve(true)
        })
    }

    async handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        // verify values if correct
        const isValid = await this.valuesVerified()
        if (!isValid) return

        // compile to make it ready for submission to BE
        const { item, croppedImages } = this.state
        let iconUrl = item.icon
        let iconPath = ''
        const id = item.id ? item.id : utils.guidGenerator()
        const monthDate = moment.unix(item.displayDate).format('MMM-DD-YYYY')
        
        if (croppedImages.tipIcon) {
            const ext = croppedImages.tipIcon.ext
            iconPath = `DailyTip/${monthDate}/${item.day}-${id}${ext}`
            iconUrl = utils.getAssetUrl(iconPath)
        }
        
        const tip = {
            id,
            day: item.day,
            category: item.category,
            title: item.title,
            description: item.description,
            displayType: item.displayType,
            displayDate: item.displayDate,
            theme: item.theme,
            icon: iconUrl,
            dealId: item.dealId,
            cardId: item.cardId,
            route: item.route
        };
        
        if (this.props.mode === 'create') {
            this.handleCreate(tip, iconPath);
        } else if (this.props.mode === 'update') {
            this.handleUpdate(tip, iconPath);
        }
    }

    handleCreate(tip, iconPath) {
        API.graphql(graphqlOperation(createDailyTips, { input: tip })).then(async (result) => {
            await this.handleImageUpload(iconPath)
            this.setState({ showToast: true });
            if (this.props.refresh) {
                this.props.refresh(result.data);
            }
        })
    }

    handleUpdate(tip, iconPath) {
        API.graphql(graphqlOperation(updateDailyTips, { input: tip })).then(async (result) => {
            if (iconPath) {
                await this.handleImageUpload(iconPath)
                const result = await backOff(() => API.post('CloudFront', '/invalidate', {
                    body: {
                        path: `/public/${iconPath}`
                    }
                }))
                console.log({result})
            }
            this.setState({ showToast: true });
            if (this.props.refresh) {
                this.props.refresh(result.data);
            }
        });
    }

    async handleImageUpload(path) {
        const { croppedImages } = this.state
        if (croppedImages.tipIcon && croppedImages.tipIcon.blob) {
            croppedImages.tipIcon.blob.name = path
            await Storage.put(path, croppedImages.tipIcon.blob)
        }
    }

    handleChange(e, fieldname) {
        const item = { ...this.state.item };
        item[fieldname] = e.target.value;
        if (fieldname === 'displayType' && item[fieldname] !== 'route') {
            item['route'] = ''
        }
        this.setState({ item: item });
    }

    onChangeDateTime(m, field) {
        const item = { ...this.state.item }
        const displayDate = moment(m).startOf('day').unix()
        const day = moment(m).format('dddd')
        item.day = day.toLowerCase()
        item.displayDate = displayDate
        this.setState({ item, datepickerState: false })
    }


    render() {
        const  { showToast, croppedImages, item } = this.state
        const { title, description, theme, category, displayType, displayDate, day, dealId, cardId, route } = item
        
        const yesterday = moment().subtract(1, 'day');
        const disablePastDt = current => {
            return current.isAfter(yesterday);
        };

        const isTipOnly = displayType === 'tipOnly'
        const isNoTip = displayType === 'noTip'
        const isDealTip = displayType === 'deal' 
        const isCardTip =  displayType === 'card'
        const isRoute =  displayType === 'route'
        
        return <div>
            {this.props.show ?
                <Form onSubmit={(e) => this.handleSubmit(e)}>

                    <h3>{this.props.mode==='create' ? 'Create User Daily Tip' : 'Update Daily Tip'}</h3>
                    <Form.Group>
                        <Form.Label>Display Date <small>({TZ})</small>*</Form.Label>
                        <Datetime required 
                            value={moment.unix(displayDate).format('MMM DD, YYYY')} 
                            closeOnSelect={true} isValidDate={disablePastDt} 
                            dateFormat={'MMM DD, YYYY'} 
                            timeFormat={false}  
                            inputProps={{required: true}} 
                            onChange={(m) => this.onChangeDateTime(m, 'displayDate')} />
                    </Form.Group>      
                    <Form.Group>
                        <Form.Label>Day *</Form.Label>
                        <Form.Control type="text" required disabled readOnly value={day} />
                    </Form.Group>  
                    <Form.Group>
                        <Form.Label>Category </Form.Label>
                        <Form.Control type="text" value={category} onChange={(e) => this.handleChange(e, 'category')} />
                    </Form.Group>         
                    <Form.Group>
                        <Form.Label>Theme</Form.Label>
                        <Form.Control type="text" value={theme} onChange={(e) => this.handleChange(e, 'theme')} />
                    </Form.Group>                                         
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" value={title} onChange={(e) => this.handleChange(e, 'title')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows={5} value={description} onChange={(e) => this.handleChange(e, 'description')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Type *</Form.Label>
                        <InputGroup>
                            <span style={{marginLeft: 50}} class="dealtypes"><InputGroup.Radio  value="deal" checked={isDealTip} name="displayType" aria-label="tip with deal" onClick={(e) => this.handleChange(e, 'displayType')}/>With Deal</span>
                            <span style={{marginLeft: 50}} class="dealtypes"><InputGroup.Radio value="card" checked={isCardTip} name="displayType" aria-label="tip with card" onClick={(e) => this.handleChange(e, 'displayType')}/>With Card</span>
                            <span style={{marginLeft: 50}} class="dealtypes"><InputGroup.Radio value="route" checked={isRoute} name="displayType" aria-label="tip with route" onClick={(e) => this.handleChange(e, 'displayType')}/>With Route</span>
                            <span style={{marginLeft: 50}} class="dealtypes"><InputGroup.Radio value="tipOnly" checked={isTipOnly} name="displayType" aria-label="tip only" onClick={(e) => this.handleChange(e, 'displayType')}/>Tip Only</span>
                            <span style={{marginLeft: 50}} class="dealtypes"><InputGroup.Radio value="noTip" checked={isNoTip} name="displayType" aria-label="no tip" onClick={(e) => this.handleChange(e, 'displayType')}/>No Tip</span>
                        </InputGroup>
                    </Form.Group>  
                    <Form.Group>
                        <Form.Label>Deal Id {isDealTip?'*':''}</Form.Label>
                        <Form.Control type="text" required={isDealTip} value={dealId} onChange={(e) => this.handleChange(e, 'dealId')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Card Id {isCardTip?'*':''}</Form.Label>
                        <Form.Control type="text" required={isCardTip} value={cardId} onChange={(e) => this.handleChange(e, 'cardId')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Route {isRoute?'*':''}</Form.Label>
                        <Form.Control type="text" required={isRoute} disabled={!isRoute} value={route} onChange={(e) => this.handleChange(e, 'route')} />
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <CMSImageUploader
                                width={60}
                                height={60}                           
                                disabled={this.state.readOnly} 
                                readOnly={this.state.readOnly} 
                                label="Deal Tip Icon" 
                                onOk={(croppedImage) => this.getCroppedImage(croppedImage, 'tipIcon')}
                            />
                        </Form.Group>
                        { (croppedImages.tipIcon && croppedImages.tipIcon.url) || (item && item.icon) ?
                            <Form.Group style={{width:'50%', textAlign: 'right'}}>
                                <Image fluid src={croppedImages.tipIcon?croppedImages.tipIcon.url:`${item.icon}?timestamp=${Date.now()}`} />
                            </Form.Group>
                        : null}
                    </Form.Row>
                    <Form.Group>
                        <Toast onClose={() => this.setState({ showToast: false })} show={showToast} delay={3000} autohide>
                            {this.props.mode==='create'?
                                <Toast.Body>Daily Tip Created.</Toast.Body>
                            : null}
                            {this.props.mode==='update'?
                                <Toast.Body>Daily Tip Updated.</Toast.Body>
                            : null}
                        </Toast>
                            {this.props.mode==='create'?
                                <Button type="submit">Create Daily Tip</Button>
                            : null}
                            {this.props.mode==='update'?
                                <Button type="submit">Update Daily Tip</Button>
                            : null}
                    </Form.Group>

                </Form>
                : null}
        </div>
            ;
    }
}

export default DailyTips