import React, { Component } from 'react';
import { Container, Row, Col, Spinner, ListGroup } from 'react-bootstrap';
import { FaPlus } from "react-icons/fa";
import { API, graphqlOperation } from 'aws-amplify';
import { listAdvertisements, getAdvertisement } from '../../graphql/queries';
import Ads from '../widgets/Advertisement';

class AdsView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            isLoading: false
        }
    }

    async componentDidMount() { //load spinner -> render() next
        this.setState({
            isLoading: true
        });

        API.graphql(graphqlOperation(listAdvertisements)).then((result) => { //call backend
            this.setState({ list: result.data.listAdvertisements.items, isLoading: false });
        })        
    }

    selectAdvertisement(e, advertisement) {
        e.preventDefault() 
        e.stopPropagation() 
        
        API.graphql(graphqlOperation(getAdvertisement,{advertisementId: advertisement.advertisementId})).then((result) => { //query advertisementId
            const items = result.data.getAdvertisement.items
            
            this.setState({ //set the updated values when click on 1 item in the list 
                advertisement: advertisement,
                mode: 'updateAdvertisement'
            })
        })        
    }

    createAdvertisement(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            mode: 'createAdvertisement'
        })
    }

    refreshAdvertisement() {
        this.setState({ isLoading: true });
        API.graphql(graphqlOperation(listAdvertisements)).then((result) => {
            this.setState({ list: result.data.listAdvertisements.items, isLoading: false });
        })   
    }

    render() {
        const { list, advertisement, mode } = this.state;
        
        return <Container fluid>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h3>Advertisement</h3>
                        </Col>
                        <Col>
                            <FaPlus className="float-right" onClick={(e) => this.createAdvertisement(e)} />
                        </Col>
                    </Row>
                    <Row>
                    { this.state.isLoading ? 
                        <Spinner animation="border" />
                    : 
                        <Col className="scrollview">
                            <ListGroup variant="flush"> 
                            {
                                list.length > 0 ? 
                                    list.map((item) => //map every item in the list 
                                        <ListGroup.Item key={`list-advertisement-${item.advertisementId}`} action onClick={(e) => this.selectAdvertisement(e, item)}>
                                            <div>
                                                { item.advertisementName ? item.advertisementName : item.advertisementId }
                                            </div>
                                            <small>Valid: { item.valid==="1" ? 'true' : 'false' }</small>
                                        </ListGroup.Item>
                                    )
                                : 'No Advertisement'
                            }
                            </ListGroup>
                        </Col>
                    }
                    </Row>
                </Col>

                <Col className="scrollview">
                    <Ads //component from widget
                        mode='create'
                        key={`create-advertisement`}
                        show={mode === 'createAdvertisement'}
                        refresh={() => this.refreshAdvertisement()}
                    />

                    <Ads 
                        mode='update' //update when click on any item in the list 
                        key={`update-advertisement-${advertisement ? advertisement.advertisementId : ''}`}
                        advertisement={advertisement}
                        show={mode === 'updateAdvertisement'}
                        refresh={() => this.refreshAdvertisement()}
                    />
                </Col>
            </Row>            
        </Container>
    }
}

export default AdsView;