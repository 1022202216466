import React, { Component } from 'react'
import { Form, Button, Col, Toast } from "react-bootstrap"
import Select from 'react-select';
import { API, graphqlOperation, Storage } from 'aws-amplify'
import moment from 'moment'
import Datetime from 'react-datetime'
import "react-datetime/css/react-datetime.css"
import { Auth } from '@aws-amplify/auth'
import { createTopicNotifications, updateTopicNotifications } from '../../graphql/mutations'
import { guidGenerator, getEnv } from '../../utils/index'
import { publish } from '../widgets/CMSModal'
import {FaUpload} from "react-icons/fa"
import * as utils from '../../utils';

class Notification extends Component {

    constructor(props) {
        super(props)
        
        const notification = props.notification ? { ...props.notification } : {};
        const dt = notification.publishTimestamp ?? null
        this.inputFileRef = React.createRef(null);
        
        this.state = {
            item: {
                notificationId: notification.notificationId ?? null,
                topicName: notification.topicName ?? null,
                title: notification.title ?? null,
                message: notification.message ?? null,
                bigText: notification.bigText ?? null,
                createdBy: notification.createdBy ?? null,
                createdAt: notification.createdAt ?? null,
                customUrl: notification.customUrl ?? null,
                publishTimestamp: dt,
                publishDT : dt,
                publishHR: dt,
                isActivated: notification.isActivated==='True',
                targetGroup: notification.csvFileName ? 'new' : 'existing',
                csvFileName: notification.csvFileName ? notification.csvFileName.split('/').pop() : null
            },
            showToast: false,
            newUpload : false
        };
    }

    alert(msg, callback) {
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    async componentDidMount() {
        const { item } = this.state
        const user = await Auth.currentAuthenticatedUser()
        item.createdBy = this.props.mode === 'update' ? item.createdBy :  user.attributes.email
        this.setState({ item: item });
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        const { notificationId, topicName, title, message, bigText, createdBy, publishTimestamp, customUrl, targetGroup, csvFileName } = this.state.item
        const notification = {
            notificationId: notificationId ?? guidGenerator(),
            topicName,
            title,
            message,
            bigText,
            createdBy,
            publishTimestamp,
            customUrl,
            csvFileName : targetGroup === 'new' ? csvFileName : null

        };

        if(targetGroup === 'new'){   
            if(this.state.newUpload)
                if(csvFileName){
                    notification.topicName = `subset_${getEnv()}`
                } else{
                    this.alert('Please upload a csv file containing device tokens.')
                    return false
                }
            else{
                if(!csvFileName){
                    this.alert('Please upload a csv file containing device tokens.')
                    return false
                }
            }
        }
        else if (!topicName) {
            this.alert('Please select the topic on where to send the notification.')
            return false
        }

    
        // Rule: Cannot create notification when current date is less than 2 hrs
        // This will give way for the inbox processing to finish in time
        const tm = moment.unix(publishTimestamp).subtract(2, "hours")
        if (moment(tm).isBefore()) {
            this.alert('Send schedule should be at least 2hrs ahead from the current date/time. Please adjust.')
            return false
        }

        if (this.props.mode === 'create') {
            this.handleCreate(notification);
        } else if (this.props.mode === 'update') {
            notification?.csvFileName ? this.handleFileUpload(notification) : this.handleUpdate(notification)
        }
    }

    handleCreate(notification) {
        API.graphql(graphqlOperation(createTopicNotifications, { input: notification })).then((result) => {
            this.setState({ showToast: true });
            if(notification.csvFileName)
                this.handleFileUpload(result.data?.createTopicNotifications)
            if (this.props.onCreate) {
                this.props.onCreate(result.data);
            }
        }).catch((error) => {
            console.debug('Error on handleCreate' , notification)
            console.error(error)
        })
    }

    handleUpdate(notification) {
        API.graphql(graphqlOperation(updateTopicNotifications, { input: notification })).then((result) => {
            this.setState({ showToast: true });
            if (this.props.onUpdate) {
                this.props.onUpdate(result.data);
            }
        });
    }

    handleChange(e, fieldname) {
        const { item } = this.state
        
        item[fieldname] = e.target.value;
        this.setState({ item: item });
    }

    handleDateTime(m, fieldname) {
        const item = { ...this.state.item }

        if (typeof m === 'string') {
            item[fieldname] = moment(m, 'YYYY-MM-DD HH:mm:ss').unix()
        } else {
            item[fieldname] = m.unix()
        }

        // we need to concat as there are two fields date and time
        const dt = moment.unix(item['publishDT']).format('YYYY-MM-DD')
        const hr = moment.unix(item['publishHR']).format('HH')
        const full = moment(dt + 'T' + hr + ':00:00').unix()
        item['publishTimestamp'] = full

        this.setState({
            item: item
        })
    }
    
    setNotificationTopic(topic) {
        const item = { ...this.state.item };
        if (topic) {
            item['topicName'] = topic.value
            this.setState({ item })
        }
    }

    getTopicOptions() {
        const env = getEnv()
        return [
            { 
                value: `guest_${env}`, 
                label: 'Topic for Guest users'
            }, 
            { 
                value: `register_${env}`, 
                label: 'Topic for Register users'
            },
            { 
                value: `Food_${env}`, 
                label: 'Topic for Food interest'
            },
            { 
                value: `Activities_${env}`, 
                label: 'Topic for Activities interest'
            },
            { 
                value: `Shopping_${env}`, 
                label: 'Topic for Shopping interest'
            },
            { 
                value: `Travel_${env}`, 
                label: 'Topic for Travel interest'
            },
            { 
                value: `Transport_${env}`, 
                label: 'Topic for Transport interest'
            },
            { 
                value: `Wellness_${env}`, 
                label: 'Topic for Wellness interest'
            },
        ]
    }

    uploadClick(inputFileRef){
        inputFileRef.current.click()
    }

    onFileChange(e){
        const { item } = this.state
        if(e.target.files[0]){
            item["csvFileName"] = e.target.files[0].name 
            this.setState({item:item})
            this.setState({ newUpload:true})
        }

    }

    async handleFileUpload(notification) {
        if (notification) {
            //upload file to S3
            const path = `TopicNotifs/${notification.notificationId}.csv`;
            notification.csvFileName = utils.getAssetUrl(path);
            let res = null
            try{
               res = await Storage.put(path, this.inputFileRef.current.files[0]);
            }catch(e){
                this.alert("Something happen while uploading csv file. Please try again later.")
                console.log(e)
            }
           if(res){
            utils.invalidateCloudFrontCache(notification.csvFileName)  //invalidate cache after file successfully uploaded
            this.handleUpdate(notification)
           } else return false
        } else return false
    }

    render() {
        const { notificationId, title, topicName, message, bigText, createdBy, createdAt, customUrl, publishTimestamp, isActivated, targetGroup, csvFileName } =  this.state.item
        const readOnly = isActivated
        const topicOptions = this.getTopicOptions()
        const selectedTopic = topicOptions.find(o => o.value ===  topicName)
        const yesterday = moment().subtract(1, 'day');
        const disablePastDt = current => {
            return current.isAfter(yesterday);
        };
        return <div>
            {this.props.show ?
                
                <Form onSubmit={(e) => this.handleSubmit(e)}>  
                    <h3>{this.props.title??this.props.mode==='create'?'Create':'Update'} Notification</h3>
                    
                    <Form.Group>
                        <Form.Label>Notification Id</Form.Label>
                        <Form.Control name="notificationId" type="text" disabled readOnly value={notificationId ? notificationId : ''} />
                    </Form.Group>
                    <Form.Group  controlId="targetGroup">
                        <Form.Label>Existing or new target group</Form.Label>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Check
                                value="existing"
                                type="radio"
                                label="existing"
                                disabled={readOnly}
                                onChange={(e) => this.handleChange(e, 'targetGroup')}
                                checked={targetGroup === "existing" }
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Check  
                                value="new"
                                type="radio"
                                label="new"
                                disabled={readOnly}
                                onChange={(e) => this.handleChange(e, 'targetGroup')}
                                checked={targetGroup === "new"}
                                />
                            </Form.Group>
                        </Form.Row>
                    </Form.Group>
                    {targetGroup === "new" &&
                    <Form.Group>
                        <Form.Label>Target segment* (Upload CSV with Device Token ID)</Form.Label>
                        <Form.Row>
                            <Form.Group as={Col} fluid={"true"}>
                                <input type="file" disabled={readOnly} ref={this.inputFileRef} accept=".csv" onChange={(e)=>{this.onFileChange(e)}} hidden/>
                                <Form.Control name="csvFileName" type="text" disabled readOnly value={ csvFileName ? csvFileName : '' } />
                                {this.state.newUpload && <Form.Label style={{color:'Red'}}>*File name will be updated upon topic {this.props.mode === "create" ? 'creation' : 'update'}</Form.Label>}
                            </Form.Group>
                            <Form.Group as={Col} xs={1}>
                                <Button type="button" disabled={readOnly} onClick={()=>{this.uploadClick(this.inputFileRef)}}><FaUpload/></Button>
                            </Form.Group>
                        </Form.Row>
                    </Form.Group>
                    }
                    {(targetGroup === "existing" || targetGroup === null) &&
                    <Form.Group>
                        <Form.Label>Topic *</Form.Label>
                        <Select
                            isDisabled={readOnly}
                            closeMenuOnSelect={true}
                            isClearable={true}
                            placeholder="Choose where to send this notification"
                            options={topicOptions}
                            value={selectedTopic}
                            onChange={(topic) => this.setNotificationTopic(topic)}
                        />
                    </Form.Group>
                    }
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Date to send *</Form.Label>
                            <Datetime isValidDate={disablePastDt} required dateFormat="YYYY-MM-DD" timeFormat={false} inputProps={{disabled: readOnly, readOnly: readOnly}} value={publishTimestamp ? moment.unix(publishTimestamp) : null} onChange={(m) => this.handleDateTime(m, 'publishDT')} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Hour to send (24hr format) *</Form.Label>
                            <Datetime isValidDate={disablePastDt} required dateFormat={false} timeFormat="HH"  inputProps={{disabled: readOnly, readOnly: readOnly}} value={publishTimestamp ? moment.unix(publishTimestamp) : null} onChange={(m) => this.handleDateTime(m, 'publishHR')} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>Title *</Form.Label>
                        <Form.Control disabled={readOnly} required type="text" value={title ? title : ''} onChange={(e) => this.handleChange(e, 'title')}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Message *</Form.Label>
                        <Form.Control disabled={readOnly} as="textarea" rows={3} required type="text" value={message ? message : ''} onChange={(e) => this.handleChange(e, 'message')}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Big Text</Form.Label>
                        <Form.Control disabled={readOnly} as="textarea" rows={3} value={bigText ? bigText : ''} onChange={(e) => this.handleChange(e, 'bigText')}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Custom Url</Form.Label>
                        <Form.Control disabled={readOnly} type="text" value={customUrl ? customUrl : ''} onChange={(e) => this.handleChange(e, 'customUrl')}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Created By (user id)</Form.Label>
                        <Form.Control disabled readOnly type="text" value={createdBy}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Created Date/Time</Form.Label>
                        <Form.Control disabled readOnly type="text" value={createdAt? moment(createdAt).format():''}/>
                    </Form.Group>
                    <Form.Group>
                        <Toast onClose={() => this.setState({ showToast: false })} show={this.state.showToast} delay={3000} autohide>
                            {this.props.mode==='create'?
                                <Toast.Body>Notification Created.</Toast.Body>
                            : null}
                            {this.props.mode==='update'?
                                <Toast.Body>Notification Updated.</Toast.Body>
                            : null}
                        </Toast>

                        {this.props.mode==='create'?
                            <Button type="submit" style={{marginTop: 10}}>Create Notification</Button>
                        : null}
                        {this.props.mode==='update'?
                            <Button disabled={readOnly} type="submit" style={{marginTop: 10}}>Update Notification</Button>
                        : null}


                    </Form.Group>

                </Form>
            : null}
        </div>
    }
}

export default Notification