import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from '../graphql/mutations'
import * as queries from '../graphql/queries'

export default class Model {

    truthValues = ['0', '1']
    indices = []
    excludes = ['createdAt', 'updatedAt']
    item = null

    constructor(item, typename, indices, truthValues, defaultValues, excludes) {
        this.item = {
            ...item,
        }
        this.gqlCreate = `create${typename}`
        this.gqlUpdate = `update${typename}`
        
        if (indices) {
            this.indices = indices
        }

        if (truthValues) {
            this.truthValues = truthValues
        }
       
        for (const key of Object.keys(defaultValues)) {
            if (!(key in this.item)) {
                this.item[key] = defaultValues[key]
            }
        }
        if (excludes) {
            this.excludes = this.excludes.concat(excludes)
        }        
        for (const exclude of this.excludes) {
            delete this.item[exclude]
        }
    }

    toJson() {
        return this.item
    }

    async invalidate() {
        this.item.valid = this.truthValues[0]
        return await this.update()
    }

    async validate() {
        this.item.valid = this.truthValues[1]
        return await this.update()
    }

    async create() {
        // remove indices with null values. ddb will throw error. cannot have null values in indices
        for (const index of this.indices) {
            if (index in this.item && (this.item[index] === null || this.item[index] === '' || this.item[index].toLowerCase() === 'null')) {
                delete this.item[index]
            }
        }

        const result = await API.graphql(graphqlOperation(mutations[this.gqlCreate], { input: this.item }))
     
        this.item = result.data[this.gqlCreate]

        return this.item
    }

    async update() {

        // nullify indices with empty space values. ddb will throw error. cannot have empty values in indices
        for (const index of this.indices) {           
            if (index in this.item && this.item[index] !== null && (typeof this.item[index] === 'string' && (this.item[index] === '' || (this.item[index].toLowerCase() === 'null')))) {
                this.item[index] = null
            }
        }
        const result = await API.graphql(graphqlOperation(mutations[this.gqlUpdate], { input: this.item }))

        this.item = result.data[this.gqlUpdate]
        return this.item
    }

    static async list(typename, params) {
        const result = await API.graphql(graphqlOperation(queries[`list${typename}s`], params))
        return result.data[`list${typename}s`]
    }

    static async listAll(typename, params) {
        let items = []
        do {
            const result = await Model.list(typename, params)
            params.nextToken = result.nextToken
            items = items.concat(result.items)
        } while (params.nextToken)
        return items
    }
}
