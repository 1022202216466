import React, { Component } from 'react'
import { Container, Form, Row, Col, Button, Spinner, Image } from "react-bootstrap"
import { FaChevronLeft, FaStore } from "react-icons/fa"
import { Auth } from 'aws-amplify'
import ListMerchants from '../widgets/ListMerchants'
import { publish } from '../widgets/CMSModal'
import aws_exports from '../../aws-exports'
import Merchant from '../widgets/Merchant'

class AuthView extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            user: null,
            mode: 'signup',
            mobile: '',
            email: '',
            password: '',
            firstname: '',
            lastname: '',
            needVerification: false,
            verificationCode: '',
            newMerchant: '',
            marketingConsent: false
        }
    }

    async componentDidMount() {        
        const user = await Auth.currentAuthenticatedUser()
        this.setState({
            user: user
        })

        if (!user.signInUserSession.idToken.payload['cognito:groups'] || user.signInUserSession.idToken.payload['cognito:groups'].length === 0) {
            this.setState({
                mode: 'access'
            })
        }      
    }

    async signUp(e) {
        e.preventDefault()
        e.stopPropagation()

        try {
            const result = await Auth.signUp({
                username: this.state.email,
                password: this.state.password,
                attributes: {
                    email: this.state.email,
                    given_name: this.state.firstname,
                    family_name: this.state.lastname,
                    'custom:mobile': this.state.mobile,
                    'custom:marketing_consent': this.state.marketingConsent?'1':'0'
                }
            })
            console.log('user', result)

            // const cmsUserModel = new CMSUserModel({
            //     email: this.state.email,
            //     marketingConsent: this.state.marketingConsent
            // })
            // console.log('cmsUserModel', cmsUserModel)
            // const createUserModelResult = await cmsUserModel.create()
            // console.log('createUserModelResult', createUserModelResult)

            this.setState({
                user: result.user,
                needVerification: !result.userConfirmed,
                verificationCode: ''
            })

        } catch (error) {
            console.log('error signing up:', error)

            if (error.code === "UsernameExistsException") {
                this.alert(error.message)
            } else {
                this.alert(error.message)
            }
        }
    }

    async signIn(e) {
        e.preventDefault()
        e.stopPropagation()

        try {
            const result = await Auth.signIn(this.state.email, this.state.password)
            
            this.setState({
                user: result
            })

            if (result.signInUserSession.idToken.payload['cognito:groups'] && result.signInUserSession.idToken.payload['cognito:groups'].length > 0) {
                window.location.reload()
            } else {
                this.setState({
                    mode: 'access'
                })
            }
        } catch(e) {
            console.log(e)

            if (e.code === "UserNotConfirmedException") {
                const result = await Auth.resendSignUp(this.state.email)
                console.log('resend', result)

                this.setState({
                    mode: '',
                    needVerification: true,
                    verificationCode: ''
                })
            } else {
                this.alert(e.message)
            }

        }
    }
    
    async verifyCode(e) {
        e.preventDefault()
        e.stopPropagation()

        console.log(this.state)
        try {
            const result = await Auth.confirmSignUp(this.state.email, this.state.verificationCode)
            console.log(result)

            if (result === 'SUCCESS') {
                const result = await Auth.signIn(this.state.email, this.state.password)
                console.log(result)
                this.setState({
                    user: result,
                    needVerification: false,
                    mode: 'access'
                })

            } else {
                this.alert(result)
            }

        } catch (error) {
            console.log('error verifying code:', error)
            this.alert(error.message)
        }
    }

    handleChange(e, fieldname, forcelowercase) {
        const val = forcelowercase?e.target.value.toLowerCase():e.target.value

        let kv = {}
        kv[fieldname] = val
        this.setState(kv)
    }

    handleCheckboxChange(e, fieldname) {
        const val = e.target.checked
        let kv = {}
        kv[fieldname] = val
        this.setState(kv)
    }

    async selectMerchant(e, merchant) {
        e.preventDefault()
        e.stopPropagation()

        this.confirm('Claim my Business', `Is ${merchant.merchant_name} your Business?`, async () => {
            const result = await this.grantMerchantAccess()
            if (result) {
                this.alert('Please wait while we grant you access to your business within 48 hours.')
                await Auth.signOut({global: true})
                this.setState({
                    user: null,
                    mode: 'login'
                })
            }
        })
    }

    async onCreateMerchant(merchant) {
        const result = await this.grantMerchantAccess(merchant)

        if (result) {
            // sign user out to login again to get groups
            this.alert('Your registration is successful. Please log in again to start adding your deal.')
            
            await Auth.signOut({global: true})

            this.setState({
                user: null,
                mode: 'login'
            })
        }
    }

    async grantMerchantAccess(m) {
        this.setState({
            processing: true
        })

        const merchant = m
        const response = await fetch(`${aws_exports.aws_cloud_logic_custom[0].endpoint}/access`, {
            method: 'POST',
            body: JSON.stringify({
                username: this.state.user.attributes.email,
                merchant_id: merchant?merchant.merchant_id:null
            }),
        })

        const json = await response.json()

        if (response.status === 200) {
            return true
        } else {
            this.alert(json.message)
            return false
        }
    }

    async forgot(e) {
        e.preventDefault()
        e.stopPropagation()

        try {
            const result = await Auth.forgotPassword(this.state.email)
            console.log(result)
            this.setState({
                mode: 'confirmForgot'
            })
        } catch(e) {
            console.log(e)
            this.alert(e.message)
        }
    }

    async confirmForgot(e) {
        e.preventDefault()
        e.stopPropagation()

        try {
            const result = await Auth.forgotPasswordSubmit(this.state.email, this.state.verificationCode, this.state.password)
            console.log(result)
            this.setState({
                mode: 'login'
            })
        } catch(e) {
            console.log(e)
            this.alert(e.message)
        }
    }

    clearSelection() {
        this.setState({
            merchant: null
        })
    }

    goto(mode) {
        this.setState({
            mode: mode
        })
    }

    searchedMerchants(merchants) {
    }

    confirm(title, msg, callback) {
        publish({
            mode: 'confirm',
            title: title,
            msg: msg,
            onConfirm: callback
        })
    }

    alert(msg, callback) {
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    render() {

        return (<Container fluid>
                <Row>
                    <Col md={2}>
                    </Col>
                    <Col>
                        { this.state.mode === 'login' ?
                            <Form onSubmit={(e) => this.signIn(e)}>
                                <h3>Login</h3>
                                <Form.Group>
                                    <Form.Control required type="email" placeholder="Email" value={this.state.email} onChange={(e) => this.handleChange(e, 'email')} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control required type="password" placeholder="Password" value={this.state.password} onChange={(e) => this.handleChange(e, 'password')} />
                                </Form.Group>
                                <Form.Group>
                                    <Button type="submit">Login</Button>
                                    <Form.Text>
                                        Forgot your password? <a href="#" onClick={() => this.goto('forgot')}>Reset password</a>
                                    </Form.Text>
                                </Form.Group>
                            </Form>
                        : this.state.mode === 'forgot' ?
                        <Form onSubmit={(e) => this.forgot(e)}>
                            <h3>Reset Password</h3>
                            <Form.Group>
                                <Form.Control required type="email" placeholder="Email" value={this.state.email} onChange={(e) => this.handleChange(e, 'email')} />
                            </Form.Group>
                            <Form.Group>
                                <Button type="submit">Send Code</Button>
                            </Form.Group>
                        </Form>
                        : this.state.mode === 'confirmForgot' ?
                            <Form onSubmit={(e) => this.confirmForgot(e)}>
                                <h3>Reset Password</h3>
                                <Form.Group>
                                    <Form.Control required type="text" placeholder="Verification Code" value={this.state.verificationCode} onChange={(e) => this.handleChange(e, 'verificationCode')} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control required type="password" placeholder="New password" value={this.state.password} onChange={(e) => this.handleChange(e, 'password')} />
                                </Form.Group>
                                <Form.Group>
                                    <Button type="submit">Submit</Button>
                                </Form.Group>
                            </Form>
                        : !this.state.user && this.state.mode === 'signup' ?
                            <Row>
                                <Col>
                                    <Form onSubmit={(e) => this.signUp(e)}>
                                        <h3>Sign up to create a free CardsPal for Business Account</h3>
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Control required placeholder="First Name" value={this.state.firstname} onChange={(e) => this.handleChange(e, 'firstname')} />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Control required placeholder="Last Name" value={this.state.lastname} onChange={(e) => this.handleChange(e, 'lastname')} />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Group>
                                            <Form.Control required type="tel" pattern="[0-9]*" placeholder="Mobile Number" value={this.state.mobile} onChange={(e) => this.handleChange(e, 'mobile', true)} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control required type="email" placeholder="Email" value={this.state.email} onChange={(e) => this.handleChange(e, 'email', true)} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control required type="password" placeholder="Password" value={this.state.password} onChange={(e) => this.handleChange(e, 'password')} />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Check type="checkbox" custom id="ask">
                                                <Form.Check.Input type="checkbox" required checked={this.state.agree} onChange={(e) => this.handleCheckboxChange(e, 'agree')} />
                                                <Form.Check.Label>
                                                    <Form.Text>By continuing, you agree to CardsPal's <a href="https://cardspal.com/merchant_terms/" target="__cardspal_terms">Merchant Terms &amp; Conditions</a> and <a href="https://cardspal.com/biz-privacy-statement/" target="__cardspal_terms">Merchant Privacy Policy.</a></Form.Text>
                                                </Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check type="checkbox" custom id="agree">
                                                <Form.Check.Input type="checkbox" checked={this.state.marketingConsent} onChange={(e) => this.handleCheckboxChange(e, 'marketingConsent')}/>
                                                <Form.Check.Label>
                                                    <Form.Text>I consent to receiving marketing emails regarding CardsPal's products, services and events. I am aware that I can unsubscribe at anytime.</Form.Text>
                                                </Form.Check.Label>
                                            </Form.Check>
                                        </Form.Group>

                                        <Form.Group className="text-right">
                                            <Button type="submit">Continue</Button>
                                            <Form.Text>
                                                Already on CardsPal? <a href="#" onClick={() => this.goto('login')}>Log in</a>
                                            </Form.Text>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col className="text-center">
                                    <Image src="app.png" />
                                </Col>
                            </Row>
                        : this.state.needVerification ?
                            <Row>
                                <Col>
                                    <Form onSubmit={(e) => this.verifyCode(e)}>
                                        <h3>Verify your email address</h3>
                                        <Form.Group>
                                            <Form.Control disabled readOnly type="email" value={this.state.email} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control required type="text" placeholder="Verification Code" value={this.state.verificationCode} onChange={(e) => this.handleChange(e, 'verificationCode')} />
                                        </Form.Group>
                                        <Form.Group className="text-right">
                                            <Button type="submit">Sign Up</Button>
                                            <Form.Text>
                                                Already on CardsPal? <a href="#" onClick={() => this.goto('login')}>Log in</a>
                                            </Form.Text>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col className="text-center">
                                    <Image src="app.png" />
                                </Col>
                            </Row>
                        : this.state.user && this.state.mode === 'access' ?
                            <Row>
                                <Col>
                                    <Form onSubmit={(e) => this.selectMerchant(e)}>
                                        <h3>Let's look up your business</h3>
                                        <p>Your business might already exists on CardsPal!</p>
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <ListMerchants searchable
                                                    onSelect={(e, merchant) => this.selectMerchant(e, merchant)} 
                                                    onChange={(e, text) => this.clearSelection(e, text)} 
                                                    onSearch={(merchants) => this.searchedMerchants(merchants)}
                                                />
                                            </Form.Group>
                                        </Form.Row>

                                        {this.state.merchant ?
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        <FaStore /> {this.state.merchant.merchant_name}
                                                    </Form.Label>
                                                    &nbsp;
                                                    <Button disabled={this.state.processing} onClick={() => this.grantMerchantAccess()}>
                                                        Continue
                                                        { this.state.processing ?
                                                            <span>
                                                                &nbsp;
                                                                <Spinner animation="border" size="sm" />
                                                            </span>
                                                        : null }
                                                    </Button>
                                                </Form.Group>
                                            </Form.Row>
                                        : null}

                                        <Form.Row className="mt-5">
                                            <Form.Group as={Col}>
                                                <Form.Label>Couldn't find your business? Fret not, you can add here!</Form.Label>
                                                &nbsp;
                                                <Button disabled={this.state.processing} onClick={() => this.goto('create')}>Create business</Button>
                                            </Form.Group>
                                        </Form.Row>
                                    </Form>
                                </Col>
                                <Col className="text-center">
                                    <Image src="search.png" />
                                </Col>
                            </Row>
                        : this.state.user && this.state.mode === 'create' ?
                            <div>
                                <a href="#">
                                    <h4 onClick={() => this.goto('access')}>
                                        <FaChevronLeft /> Back
                                    </h4>
                                </a>
                                <Row>
                                    <Col>
                                        <Merchant 
                                            mode='create'
                                            title='Create Business'
                                            btnText='Save changes'
                                            show={true}
                                            onCreate={(merchant) => this.onCreateMerchant(merchant)}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        : null }

                    </Col>
                    <Col md={2}>
                    </Col>
                </Row>
                
                <small className="text-center fixed-bottom">
                    <a href="https://cardspal.com/support/">Support Center</a>
                    &nbsp;|&nbsp;
                    <a href="https://cardspal.com/terms-of-use/">Terms of Use</a>
                    &nbsp;|&nbsp;
                    <a href="https://cardspal.com/privacy-policy/">Privacy Policy</a>
                </small>

            </Container>)
    }
}

export default AuthView