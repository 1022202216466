import React, { Component } from 'react'
import { Form } from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css'
import { API, graphqlOperation } from 'aws-amplify'

import { listMalls } from '../../graphql/custom'

class ListMalls extends Component {
  constructor(props) {
    super(props)

    this.state = {
      malls: []
    };
  }

  async componentDidMount() {
    let token = null
    let malls = []
    do {
      const result = await API.graphql(graphqlOperation(listMalls))
      console.log('malls', result)

      malls = malls.concat(result.data.listMalls.items)
      token = result.data.listMalls.nextToken
    } while (token)

    this.setState({
      malls: malls
    })
  }

  onChange(e) {
    e.preventDefault()
    e.stopPropagation()

    if (this.props.onSelect) {
      this.props.onSelect(e.target.value)
    }
  }

  render() {

    return <div>
      <Form.Control as="select" custom value={this.props.mallId} onChange={(e) => this.onChange(e)}>
        <option></option>
        {
          this.state.malls.map((item) =>
            <option value={item.searchField}>{item.name}</option>
          )
        }
      </Form.Control>
    </div>
  }
}

export default ListMalls
