import React, { Component } from 'react';
import { Form, Button, Spinner, InputGroup, Image, ListGroup } from "react-bootstrap";
import { FaSearch, FaStore, FaEye, FaEyeSlash, FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { debounce } from 'lodash';
import { deleteTags, searchMerchants } from '../../graphql/custom';
import { getTagsByTagName, getMerchant, getTags } from '../../graphql/queries'
import { createTags, updateTags } from '../../graphql/mutations';
import { publish } from '../widgets/CMSModal';
import * as utils from '../../utils';

class CategoryHiglight extends Component {
    apis = [];
    cancelDebounce = false;
    
    constructor(props) {
        super(props);
        const highlight = props.highlight ? { ...props.highlight } : {}
        this.state = {
            category: props.category,
            item: {
                id: highlight.id ?? null,
                title: highlight.title ?? '',
                sectionUrl: highlight.sectionUrl ?? '',
            },
            sectionImageExt: null,
            sectionImageFile: null,
            existingHighlightMerchants: [],
            listHighlightMerchants: [],
            searchText: '',
            merchants: [],
            loadingMerchants: false,
            processing: false
        }
    }

    async componentDidMount() {
        if (this.state.item.id) {
            try {
                this.setState({ loadingMerchants: true });
                const result = await API.graphql(graphqlOperation(getTagsByTagName, { tagName: this.state.item.id }));
                const tags = result.data.getTagsByTagName;
                const highlightMerchants = [];
                if (tags && tags.items && tags.items.length > 0) {
                    for (const item of tags.items) {
                        const merchantId = item.merchantId;
                        let merchantName = '';
        
                        const result = await API.graphql(graphqlOperation(getMerchant, { merchant_id: merchantId }));
                        const merchantData = result.data.getMerchant;
                        if (merchantData) {
                            merchantName = merchantData.merchant_name;
                        }
                
                        highlightMerchants.push({
                            merchantId,
                            merchantName
                        });
                    }
                }
                const existingHighlightMerchants = highlightMerchants.length > 0 ? highlightMerchants.map(i => i) : [];
                this.setState({ loadingMerchants: false, listHighlightMerchants: highlightMerchants, existingHighlightMerchants });
            } catch(err) {
                console.error(err);
                this.setState({ loadingMerchants: false });
            }
        }
    }

    handleSectionUrlChange(e) {
        const item = { ...this.state.item };
        const file = e.target.files[0];
        const url = URL.createObjectURL(file);
        const ext = file.name.substring(file.name.lastIndexOf('.'))

        item.sectionUrl = url
        this.setState({ 
            item: item,  
            sectionImageExt: ext,
            sectionImageFile: file
        });
    }

    async clearSearch() {
        this.setState({
            searchText: '',
            merchants: []
        });
    }

    debounceSearch = debounce((text) => {
        // must stop debounce function calling additional apis
        if (this.apis.length > 0) {
            this.cancelDebounce = true;
            this.waitCancelDebounce();
        }

        this.searchMerchants({
            text: text
        }).then(() => {
        })
    }, 1000);

    waitCancelDebounce() {
        setTimeout(() => {
            if (this.cancelDebounce) {
                this.waitCancelDebounce();
            }
        }, 1000);
    }

    async handleSearch(e) {
        const text = e.target.value;
        if (text.trim() !== '') {
            if (text.length >= 2) {
                this.debounceSearch(text);
            }
        } else {
            this.clearSearch();
        }

        this.setState({
            searchText: text,
            merchants: []
        });

        if (this.props.onChange) {
            // return original text value
            this.props.onChange(e, e.target.value);
        }
    }

    async searchMerchants(params) {
        const filters = []
        
        filters.push({
            merchant_name: {
                matchPhrasePrefix: params.text
            }
        });
        filters.push({
            merchant_id: {
                match: params.text
            }
        });
        filters.push({
            mainCat: {
                match: params.text
            }
        });

        const search = {
            filter: {
                or : filters
            }
        }

        this.setState({
            isLoading: true
        });

        let merchants = []
        do {
            const api = API.graphql(graphqlOperation(searchMerchants, search));
            this.apis.push(api);
            const result = await api;
            const items = result.data.searchMerchants.items;
            for (const item of items) {
                const found = this.state.listHighlightMerchants.find(m => m.merchantId === item.merchant_id);
                if (found) {
                    item.added = true;
                }
            }
            merchants = merchants.concat(items);
            search.nextToken = result.data.searchMerchants.nextToken;

            this.setState({
                merchants: merchants,
            });
        } while (search.nextToken && !this.cancelDebounce)
        if (this.cancelDebounce) {
            // cancel all existing api calls
            for (const api of this.apis) {
                API.cancel(api, 'cancelling api');
            }
    
            merchants = [];
            this.cancelDebounce = false;
        }
        this.apis = [];
        this.setState({
            merchants: merchants,
            isLoading: false
        });

        if (this.props.onSearch) {
            this.props.onSearch(merchants);
        }
    }

    removeHighlightMerchant(e, merchant) {
        e.preventDefault();
        e.stopPropagation();

        const listHighlightMerchants = this.state.listHighlightMerchants;
        const index = listHighlightMerchants.findIndex(m => m.merchantId === merchant.merchantId);
        listHighlightMerchants.splice(index, 1);
        this.setState({ listHighlightMerchants });        
    }

    addHighlightMerchant(e, merchant) {
        e.preventDefault();
        e.stopPropagation();

        const listHighlightMerchants = this.state.listHighlightMerchants;
        listHighlightMerchants.push({
            merchantId: merchant.merchant_id,
            merchantName: merchant.merchant_name
        });
        merchant.added = true;
        this.setState({ listHighlightMerchants });
    }

    handleChange(e, fieldname) {
        const item = { ...this.state.item }
        const target = e.target
        if (target.type === 'checkbox') {
            if (fieldname === 'valid') {
                item[fieldname] = e.target.checked ? 'Y' : 'N'
            } else {
                item[fieldname] = e.target.checked ? '1' : '0'
            }
        } else {
            item[fieldname] = e.target.value
        }
        this.setState({ item: item })
    }

    async handleSubmit(e) {
        e.preventDefault()
        e.stopPropagation()

        if (this.state.listHighlightMerchants.length === 0) {
            this.alert('Please add a merchant');
            return;
        }
        this.setState({ processing: true });

        try {
            if (this.props.mode === 'createItem') {
                await this.handleCreate();
            } else if (this.props.mode === 'updateItem') {
                await this.handleUpdate();
            } else {
                this.props.onClose();
            }
        } catch(error) {
            console.error(error);
            this.setState({ processing: false });
            this.alert('Error processing Highlight');
        }
    }

    async handleCreate() {
        const { listHighlightMerchants } = this.state;

        if (listHighlightMerchants) {
            for (const highlightMerchant of listHighlightMerchants) {
                await API.graphql(graphqlOperation(createTags, {
                    input: {
                        tagName: this.state.item.id,
                        id: highlightMerchant.merchantId, 
                        merchantId: highlightMerchant.merchantId,
                        tagType: 'merchant'
                    }
                }));
            }
        }

        await this.updateHighlights();

        this.alert('Highlight Created', () => {
            if (this.props.onCreate) {
                const item = this.state.item;
                this.props.onCreate({
                    id: item.id,
                    title: item.title,
                    sectionUrl: item.sectionUrl
                });
            }
        });
    }

    async handleUpdate() {
        const { existingHighlightMerchants, listHighlightMerchants } = this.state;
        if (existingHighlightMerchants) {
            for (const highlightMerchant of existingHighlightMerchants) {
                await API.graphql(graphqlOperation(deleteTags, { input: { id: highlightMerchant.merchantId, tagName: this.state.item.id }}));
            }
        }

        if (listHighlightMerchants) {
            for (const highlightMerchant of listHighlightMerchants) {
                await API.graphql(graphqlOperation(createTags, {
                    input: {
                        tagName: this.state.item.id,
                        id: highlightMerchant.merchantId, 
                        merchantId: highlightMerchant.merchantId,
                        tagType: 'merchant'
                    }
                }));
            }
        }

        await this.updateHighlights();

        this.alert('Highlight Updated', () => {
            const item = this.state.item;
            if (this.props.onUpdate) {
                this.props.onUpdate({
                    id: item.id,
                    title: item.title,
                    sectionUrl: item.sectionUrl
                });
            }
        });
    }

    async updateHighlights() {
        const categoryLowCase = this.state.category.toLowerCase();

        if (this.state.sectionImageFile) {
            const path = `MerchantImages/${categoryLowCase}highlights${this.state.sectionImageExt}`;
            this.state.item.sectionUrl = utils.getAssetUrl(path);
            await Storage.put(path, this.state.sectionImageFile);
        }
                
        const result = await API.graphql(graphqlOperation(getTags, { id: 'highlight', tagName: categoryLowCase + 'highlights' }));
        const tag = result.data.getTags;
        const highlights = JSON.parse(tag.highlights);
        highlights[this.state.item.id] = { title: this.state.item.title, sectionUrl: this.state.item.sectionUrl };
        tag.highlights = JSON.stringify(highlights);
        const resultUpdate = await API.graphql(graphqlOperation(updateTags, { input: tag }));
        return resultUpdate;
    }

    async deleteHiglight() {
        const categoryLowCase = this.state.category.toLowerCase();
        const result = await API.graphql(graphqlOperation(getTags, { id: 'highlight', tagName: categoryLowCase + 'highlights' }));
        const tag = result.data.getTags;
        const highlights = JSON.parse(tag.highlights);
        
        //prevent delete if only 1 entry left
        if (Object.entries(highlights).length === 1) {
            this.alert('Unable to delete, only 1 Highlight entry left');
            return;
        }

        const { listHighlightMerchants } = this.state;
        if (listHighlightMerchants) {
            for (const highlightMerchant of listHighlightMerchants) {
                await API.graphql(graphqlOperation(deleteTags, { input: { id: highlightMerchant.merchantId, tagName: this.state.item.id }}));
            }
        }

        // delete from highlights
        delete highlights[this.state.item.id];
        tag.highlights = JSON.stringify(highlights);
        await API.graphql(graphqlOperation(updateTags, { input: tag }));

        this.alert('Highlight Deleted', () => {
            if (this.props.onDelete) {
                const item = this.state.item;
                this.props.onDelete({
                    id: item.id,
                    title: item.title,
                    sectionUrl: item.sectionUrl
                });
            }
        });
    }

    generateMerchantItems(item) {
        return <ListGroup.Item key={`highlight-merchants-${item.merchant_id}`}>
            {item.pockCalMerchImage?
              <Image src={`${item.pockCalMerchImage}?timestamp=${Date.now()}`} fluid rectangle="true" style={{height: '1em'}} />
            : <FaStore />} &nbsp;
            {item.merchant_name} &nbsp;
            {item.valid && (item.valid.toUpperCase() === 'Y') ?
                <FaEye title="visible" color="green" />
            : <FaEyeSlash title="not visible" color="red" />}

            { !item.added ?
            <Button className="float-right" size="sm" onClick={(e) => this.addHighlightMerchant(e, item)}>
                <FaPlusCircle />
            </Button>
            : null}
        </ListGroup.Item>
    }

    alert(msg, callback) {
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    render() {
        const { sectionUrl } = this.state.item;
 
        return <div>
            {this.props.show ?
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <Form.Group>
                        <Form.Label>ID / Tag *</Form.Label>
                        <Form.Control required type="text" disabled={this.props.mode === 'updateItem'} value={this.state.item.id} onChange={(e) => this.handleChange(e, 'id')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Title *</Form.Label>
                        <Form.Control required type="text" value={this.state.item.title} onChange={(e) => this.handleChange(e, 'title')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Section Image (in See More page) *</Form.Label>
                        <Form.File required={!sectionUrl} accept="image/*" onChange={(e) => this.handleSectionUrlChange(e)}></Form.File>
                        { sectionUrl ? <Image src={sectionUrl} fluid width='160' height='150'></Image> : null }
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Merchants * ({this.state.listHighlightMerchants ? this.state.listHighlightMerchants.length : '' })</Form.Label>
                        <ListGroup style={{ maxHeight: 240, overflowY: 'scroll' }}>
                            {
                                this.state.loadingMerchants ? <Spinner animation="border" size="sm" className="ml-1" /> :
                                this.state.listHighlightMerchants.length > 0 ?
                                    this.state.listHighlightMerchants.map((item) =>
                                        <ListGroup.Item key={`listHighlightMerchants-${item.merchantId}`}>
                                            { item.merchantName }

                                            <Button className="float-right" size="sm" variant="danger" onClick={(e) => this.removeHighlightMerchant(e, item)}>
                                                <FaTrashAlt />
                                            </Button>
                                        </ListGroup.Item>
                                    )
                                : 'No Merchant'
                            }
                            </ListGroup>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Search merchant</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text>
                                <FaSearch />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="text" placeholder='Merchant Name' value={this.state.searchText} onChange={(e) => this.handleSearch(e)} />
                        </InputGroup>
                        <ListGroup className="scrollview" variant="flush" key={`highlight-merchants`}>
                        {
                            this.state.merchants.map(item => this.generateMerchantItems(item))
                        }
                        </ListGroup>
                    </Form.Group>

                    <Form.Group>
                        {this.props.mode === 'createItem' ? 
                            <Button disabled={ this.state.processing } type="submit">
                                {this.props.btnText??'Create Highlight'}
                            </Button>
                        : this.props.mode === 'updateItem' ?
                            <div>
                                <Button disabled={ this.state.processing } type="submit">
                                    {this.props.btnText??'Update Highlight'}
                                </Button>
                                <Button disabled={ this.state.processing } type="button" variant="danger" style={{ marginLeft: 40 }} onClick={() => this.deleteHiglight()}>
                                    {this.props.btnText??'Delete Highlight'}
                                </Button>
                            </div>
                        : null}
                            
                        {this.state.processing ?
                            <Spinner animation="border" size="sm" className="ml-1" />
                        : null}
                    </Form.Group>

                </Form>
            : null}
        </div>
    }    
}

export default CategoryHiglight