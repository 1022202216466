import React, { Component } from 'react'
import { Form, Button, Toast, Col, Image } from "react-bootstrap"
import { API, graphqlOperation, Storage } from 'aws-amplify'
import Select from 'react-select';
import { createBanner, updateBanner } from '../../graphql/mutations'
import { publish } from '../widgets/CMSModal'
import { guidGenerator } from '../../utils/index'
import { backOff } from 'exponential-backoff';
import * as utils from '../../utils'
class Banner extends Component {

    constructor(props) {
        super(props)
        const banner = props.banner ? { ...props.banner } : {};
        const campaignList = props.campaignList ?? []
        const campaigns = campaignList?campaignList.map((list) => ( { 
            value: list.campaignId, 
            label: list.campaignName ? list.campaignName : list.campaignId, 
            isDisabled: banner.campaignId!==list.campaignId ? list.attached : false // do not disable campaign if attached to owner
        } )):[]
        const selectedCampaign = campaigns.find(o => o.value ===  banner.campaignId )
        
        this.state = {
            item: {
                autoId: false,
                bannerId: banner.bannerId ?? '',
                title: banner.title ?? '',
                valid: banner.valid ?? 'false',
                order: banner.order ?? '',
                type: banner.type ?? '',
                homeBannerImageUrl: banner.homeBannerImageUrl ?? '',
                bannerPageImageUrl: banner.bannerPageImageUrl ?? '',
                content: banner.content ?? '',
                buttonText: banner.buttonText ?? '',
                externalLink: banner.externalLink ?? '',
                externalLinkActive: banner.externalLinkActive ?? false,
                campaignId: banner.campaignId ?? null,
            },
            
            showToast: false,
            campaigns,
            selectedCampaign
        };
    }

    getAssetUrl(path) {
        return utils.getAssetUrl(path)
    }
        
    alert(msg, callback) {
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    handleHomeBannerImageChange(e) {
        const item = { ...this.state.item };
        const file = e.target.files[0]
        const url = URL.createObjectURL(file)
        const ext = file.name.substring(file.name.lastIndexOf('.'))

        item['homeBannerImageUrl'] = url
        this.setState({ 
            item: item,  
            homeBannerImageExt: ext,
            homeBannerImageFile: file
        });
    }

    handleBannerPageImageChange(e) {
        const item = { ...this.state.item };
        const file = e.target.files[0]
        const url = URL.createObjectURL(file)
        const ext = file.name.substring(file.name.lastIndexOf('.'))
        item['bannerPageImageUrl'] = url
        this.setState({ 
            item: item,  
            bannerPageImageExt: ext,
            bannerPageImageFile: file
        });
    }
        
    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        const banner = {
            bannerId: this.state.item.bannerId ?? guidGenerator(),
            title: this.state.item.title,
            valid: this.state.item.valid,
            order: this.state.item.order,
            type: this.state.item.type,
            content: this.state.item.content,
            buttonText: this.state.item.buttonText,
            externalLink: this.state.item.externalLink,
            externalLinkActive: this.state.item.externalLinkActive
        };

        if (this.state.item.campaignId) {
            banner.campaignId = this.state.item.campaignId
        }

        // Do not allow to activate a banner when the attached campaign is not enabled
        if (banner.campaignId && !this.isValidCampaign(banner.campaignId) && banner.valid==="true") {
            this.alert('Please enable the campaign first that is attached to this banner.')
            return
        }

        if (this.props.mode === 'create') {
            this.handleCreate(banner);
        } else if (this.props.mode === 'update') {
            this.handleUpdate(banner);
        }
    }

    async handleCreate(banner) {
        const result = await API.graphql(graphqlOperation(createBanner, { input: banner }));

        const bannerId = result.data.createBanner.bannerId;
        banner.bannerId = bannerId;
        const pathHomeBanner = `HomeBannerImages/${bannerId}_homeBanner${this.state.homeBannerImageExt}`;
        const pathBannerPage = `HomeBannerImages/${bannerId}_bannerPage${this.state.bannerPageImageExt}`;

        if (this.state.homeBannerImageFile) {
            banner.homeBannerImageUrl = this.getAssetUrl(pathHomeBanner);
        }
        if (this.state.bannerPageImageFile) {
            banner.bannerPageImageUrl = this.getAssetUrl(pathBannerPage);
        }

        await API.graphql(graphqlOperation(updateBanner, { input: banner }));

        if (this.state.homeBannerImageFile) {
            const resultUpload = await Storage.put(pathHomeBanner, this.state.homeBannerImageFile);
            const result = await backOff(() => API.post('CloudFront', '/invalidate', {
                body: {
                    path: `/public/${pathHomeBanner}`
                }
            }))
            console.log(result)
        }
        if (this.state.bannerPageImageFile) {
            const resultUpload = await Storage.put(pathBannerPage, this.state.bannerPageImageFile);
            const result = await backOff(() => API.post('CloudFront', '/invalidate', {
                body: {
                    path: `/public/${pathBannerPage}`
                }
            }))
            console.log(result)
        }
        
        this.setState({ showToast: true });
        if (this.props.refresh) {
            this.props.refresh(result.data);
        }
    }

    
    async handleUpdate(banner) {
        console.log({banner})
        const bannerId = banner.bannerId;
        const pathHomeBanner = `HomeBannerImages/${bannerId}_homeBanner${this.state.homeBannerImageExt}`;
        const pathBannerPage = `HomeBannerImages/${bannerId}_bannerPage${this.state.bannerPageImageExt}`;

        if (this.state.homeBannerImageFile) {
            banner.homeBannerImageUrl = this.getAssetUrl(pathHomeBanner);
        }
        if (this.state.bannerPageImageFile) {
            banner.bannerPageImageUrl = this.getAssetUrl(pathBannerPage);
        }

        const result = await API.graphql(graphqlOperation(updateBanner, { input: banner }));

        if (this.state.homeBannerImageFile) {
            const resultUpload = await Storage.put(pathHomeBanner, this.state.homeBannerImageFile);
            const result = await backOff(() => API.post('CloudFront', '/invalidate', {
                body: {
                    path: `/public/${pathHomeBanner}`
                }
            }))
            console.log(result)
        }
        if (this.state.bannerPageImageFile) {
            const resultUpload = await Storage.put(pathBannerPage, this.state.bannerPageImageFile);
            const result = await backOff(() => API.post('CloudFront', '/invalidate', {
                body: {
                    path: `/public/${pathBannerPage}`
                }
            }))
            console.log(result)
        }

        this.setState({ showToast: true });
        if (this.props.refresh) {
            // this.props.refresh(result.data);
        }
    }

    handleChange(e, fieldname) {
        const item = { ...this.state.item };
        const target = e.target;

        if (target.type === 'checkbox') {
            item[fieldname] = e.target.checked ? 'true' : 'false';
        } else {
            item[fieldname] = e.target.value;
        }

        if (fieldname === 'autoId') {
            if (item[fieldname] === 'true') {
                item['bannerId'] = guidGenerator()
            } else {
                item['bannerId'] = ''
            }
        }

        if (fieldname === 'bannerId') {
            item['autoId'] = false
        }
        this.setState({ item: item });
    }

    isValidCampaign(campaignId) {
        return this.props.isValidCampaign(this.props.campaignList, campaignId)
    }

    setCampaignId(campaign) {
        const item = { ...this.state.item };
        item.campaignId = campaign ? campaign.value : null
        this.setState({ item, selectedCampaign: campaign })
    }

    render() {
        const { campaigns: campaignOptions } = this.state
        const { 
            autoId, 
            homeBannerImageUrl, 
            bannerPageImageUrl, 
            bannerId, 
            title, 
            valid,
            order,
            type,
            content,
            buttonText,
            externalLink,
            externalLinkActive
        } = this.state.item

        const isAutoId = autoId==='true'
        const isBannerIdDisabled = this.props.mode === 'create' && !isAutoId ? false : true

        let homeBannerImageSrc = homeBannerImageUrl.startsWith('http') ? homeBannerImageUrl : this.getAssetUrl(homeBannerImageUrl);
        if (homeBannerImageSrc.startsWith('http')) {
            homeBannerImageSrc = homeBannerImageSrc + `?date=${new Date()}`;
        }

        let bannerPageImageSrc = bannerPageImageUrl.startsWith('http') ? bannerPageImageUrl : this.getAssetUrl(bannerPageImageUrl);
        if (bannerPageImageSrc.startsWith('http')) {
            bannerPageImageSrc = bannerPageImageSrc + `?date=${new Date()}`;
        }        
        
        return <div>
            {this.props.show ?
                <Form onSubmit={(e) => this.handleSubmit(e)}>

                    <h3>{this.props.title??this.props.mode==='create' ? 'Create':'Update'} Banner</h3>
                    <Form.Group>
                        <Form.Check type="checkbox" label="auto id"
                            disabled={this.props.mode === 'create'?false:true}
                            onChange={(e) => this.handleChange(e, 'autoId')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Banner Id *</Form.Label>
                        <Form.Control required type="text" disabled={isBannerIdDisabled} readOnly={isBannerIdDisabled} value={bannerId??''} onChange={(e) => this.handleChange(e, 'bannerId')}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Title *</Form.Label>
                        <Form.Control required type="text" value={title} onChange={(e) => this.handleChange(e, 'title')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check label="Valid" checked={valid === 'true'} onChange={(e) => this.handleChange(e, 'valid')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Order *</Form.Label>
                        <Form.Control required type="text" value={order} onChange={(e) => this.handleChange(e, 'order')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Type *</Form.Label>
                        <Form.Control required type="text" value={type} onChange={(e) => this.handleChange(e, 'type')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Home Banner Image *</Form.Label>
                        <Form.File required={!homeBannerImageUrl} accept="image/*" onChange={(e) => this.handleHomeBannerImageChange(e)}></Form.File>
                        <Image src={homeBannerImageUrl} fluid></Image>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Banner Page Image *</Form.Label>
                        <Form.File required={!bannerPageImageUrl} accept="image/*" onChange={(e) => this.handleBannerPageImageChange(e)}></Form.File>
                        <Image src={bannerPageImageUrl} fluid></Image>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Content</Form.Label>
                        <Form.Control as="textarea" rows={5} value={content} onChange={(e) => this.handleChange(e, 'content')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Button Text</Form.Label>
                        <Form.Control type="text" value={buttonText} onChange={(e) => this.handleChange(e, 'buttonText')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>External Link</Form.Label>
                        <Form.Control type="text" value={externalLink} onChange={(e) => this.handleChange(e, 'externalLink')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check label="External Link Active" checked={externalLinkActive} onChange={(e) => this.handleChange(e, 'externalLinkActive')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Campaign ID</Form.Label>
                        <Select
                            closeMenuOnSelect={true}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Choose your campaign for this banner"
                            options={campaignOptions}
                            value={this.state.selectedCampaign} 
                            onChange={(id) => this.setCampaignId(id)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Toast onClose={() => this.setState({ showToast: false })} show={this.state.showToast} delay={3000} autohide>
                            {this.props.mode==='create'?
                                <Toast.Body>Banner Created.</Toast.Body>
                            : null}
                            {this.props.mode==='update'?
                                <Toast.Body>Banner Updated.</Toast.Body>
                            : null}
                        </Toast>
                            {this.props.mode==='create'?
                                <Button type="submit">Create Banner</Button>
                            : null}
                            {this.props.mode==='update'?
                                <Button type="submit">Update Banner</Button>
                            : null}
                    </Form.Group>

                </Form>
                : null}
        </div>
            ;
    }
}

export default Banner