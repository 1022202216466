import React, { Component } from 'react'
import { Container, Row, Col, ListGroup, Spinner, Button } from "react-bootstrap"
import DynamicIcon from '../widgets/DynamicIcon';
import { FaPlus } from "react-icons/fa";
import { API, graphqlOperation } from 'aws-amplify'
import { listDynamicContents } from '../../graphql/queries'

class DynamicIconView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            mode:'',
            dynamic:'',
            list: [],
        }
    }

    async componentDidMount() {
        this.setState({
            isLoading: true
        });
        this.loadDynamicIcon()
    }

    loadDynamicIcon = () => {
        API.graphql(graphqlOperation(listDynamicContents, {
            filter: {
                type: {
                    eq: "icon"
                }
            }
        })).then((result)=>{
            this.loadList(result)
        });
    }

    loadList = (result) => {
       let res = result.data.listDynamicContents.items
            res.sort((a, b) => {
                let fieldA = a.valid.toLowerCase(),
                    fieldB = b.valid.toLowerCase();
            
                if (fieldA > fieldB) {
                    return -1;
                }
                if (fieldA < fieldB) {
                    return 1;
                }
                return 0;
            });
            this.setState({ list: res, isLoading: false });
    }

    selectDynamicContent = (e, data) => {
        e.preventDefault();
        e.stopPropagation();
        
        this.setState({
            dynamic: data,
            mode: 'updateDynamicContent',
        });
    }

    createDynamicContent = (e) => {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            mode: 'createDynamicContent'
        })
    }

    refreshDynamicContent = () => {
        this.setState({ isLoading: true });

        this.loadDynamicIcon()
    }

    render() {
        const { list, dynamic, mode } = this.state;
        return(
            <Container fluid>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <h3>Dynamic Icon</h3>
                            </Col>
                            <Col>
                                <FaPlus className="float-right" onClick={(e) => this.createDynamicContent(e)} />
                            </Col>
                        </Row>
                        <Row>
                            { this.state.isLoading ? 
                                <Spinner animation="border" />
                            : 
                                <Col className="scrollview">
                                    <ListGroup variant="flush">
                                    {
                                        list.length > 0 ?
                                            list.map((item) =>
                                                <ListGroup.Item key={`list-dynamicContent-${item.contentId}`} action onClick={(e) => this.selectDynamicContent(e, item)}>
                                                    <div>
                                                        { item.title }
                                                    </div>
                                                    <small>Type: {item.type} &nbsp;&nbsp; Valid: {String(item.valid)}</small>
                                                    
                                                </ListGroup.Item>
                                            )
                                        : 'No Dynamic content found'
                                    }
                                    </ListGroup>
                                </Col>
                            }
                        </Row>
                    </Col>

                    <Col className="scrollview">
                    <DynamicIcon
                        mode='create'
                        key={`createDynamicContent`}
                        onCreate={(data) => this.refreshDynamicContent(data)}
                        show={mode === 'createDynamicContent'}
                        allList = {list}
                    />

                    <DynamicIcon
                        mode='update'
                        key={`update-dynamicContent-${dynamic ? dynamic.contentId : ''}`}
                        dynamic={dynamic}
                        onUpdate={(data) => this.refreshDynamicContent(data)}
                        show={mode === 'updateDynamicContent'}
                        allList = {list}
                    />
                </Col>
                </Row>
            </Container>
        )
    }

}
export default DynamicIconView
