import React, { Component } from 'react'
import { Row, Col, Container, Form, Spinner, Button } from "react-bootstrap"
import Select from 'react-select';
import { API, graphqlOperation } from 'aws-amplify'
import moment from 'moment'

import { updateDealApproval, updateDealMaster } from '../../graphql/mutations'
import { stripeAccountByMerchantId, listDealApprovals, getCMSUser, getDealMaster, getMerchant, approvalByYearMonth } from '../../graphql/queries'

import Deal from '../widgets/Deal'

class ApprovalDeal extends Component {

    constructor(props) {
        super(props)

        const m = moment()

        this.months = [
            {value: '01', label: 'Jan'},
            {value: '02', label: 'Feb'},
            {value: '03', label: 'Mar'},
            {value: '04', label: 'Apr'},
            {value: '05', label: 'May'},
            {value: '06', label: 'Jun'},
            {value: '07', label: 'Jul'},
            {value: '08', label: 'Aug'},
            {value: '09', label: 'Sep'},
            {value: '10', label: 'Oct'},
            {value: '11', label: 'Nov'},
            {value: '12', label: 'Dec'},
        ]
        this.years = []
        for (let i=m.year(); i>=2020; i--) {
            this.years.push({value: i, label: i})
        }
        this.state = {
            list: [],
            selectedMonth: {value: m.format('MM'), label: m.format('MMM')},
            selectedYear: {value: m.year(), label: m.year()},
        }
    }

    async componentDidMount() {
        // this.setState({ searching: true })

        // let approvals = []
        // let token = null
        // do {
        //     // let options = {filter: {isApproved: {eq: false}}}
        //     let options = {}
        //     if (token) {
        //         options.nextToken = token
        //     }
        //     const result = await API.graphql(graphqlOperation(listDealApprovals, options))
        //     approvals = approvals.concat(result.data.listDealApprovals.items)
        //     token = result.data.listDealApprovals.nextToken
        // } while (token)
        
        // approvals.sort((a, b) => {
        //     if (b.createdAt === null || a.createdAt > b.createdAt) {
        //         return -1
        //     } else if (a.createdAt === null || a.createdAt < b.createdAt) {
        //         return 1
        //     }
        //     return 0
        // })
        
        // this.setState({ list: approvals })

        // // get deal details
        // const promises = await approvals.map(async (approval) => {
        //     try {

        //         // populate createdYearMonth if does not already exists 
        //         if (approval.createdAt && !approval.createdYearMonth) {
        //             const createdYearMonth = approval.createdAt.substring(0, 7)
        //             console.log('createdYearMonth', createdYearMonth)
        //             API.graphql(graphqlOperation(updateDealApproval, {input: {pk: approval.pk, createdYearMonth: createdYearMonth}}))
        //         }

        //         const _approval = {...approval} // avoid unintended mutation effect
        //         const result = await API.graphql(graphqlOperation(getDealMaster, { pk: _approval.pk }))
        //         const deal = result.data.getDealMaster
        //         _approval.deal = deal

        //         if (deal && deal.merchant_id) {
        //             // check if merchant level has the stripe connected account ID
        //             const merchantResult = await API.graphql(graphqlOperation(getMerchant, { merchant_id: deal.merchant_id }))
        //             const merchant = merchantResult.data.getMerchant
        //             _approval.merchant = merchant

        //             // get stripe
        //             const result = await API.graphql(graphqlOperation(stripeAccountByMerchantId, { merchantId: deal.merchant_id }))
        //             const stripe = result.data.stripeAccountByMerchantId.items[0]
        //             _approval.stripe = stripe

        //             if (stripe) {
        //                 // get cms user
        //                 const result = await API.graphql(graphqlOperation(getCmsUser, { email: stripe.email }))
        //                 const user = result.data.getCMSUser
        //                 _approval.user = user

        //             }
        //         }
        //         // return the new approval object with possible additional keys
        //         return _approval
        //     } catch(e) {
        //         console.error('error compiling approval data ', e)
        //     }
        // })
        
        // const addMoreApprovalData = await Promise.all(promises)
        
        // this.setState({ 
        //     list: addMoreApprovalData,
        //     searching: false 
        // })
    }

    async handleChange(e, index) {
        e.preventDefault()
        e.stopPropagation()

        const isApproved = e.target.value==='1'?true:false
        console.log(isApproved)
        const pk = this.state.list[index].pk
        const valid = isApproved?this.state.list[index].deal.valid:'N'

        await API.graphql(graphqlOperation(updateDealApproval, { input: { pk: pk, isApproved: isApproved } }))
        this.state.list[index].isApproved = isApproved

        await API.graphql(graphqlOperation(updateDealMaster, { input: { pk: pk, valid: valid } }))
        this.state.list[index].deal.valid = valid

        this.setState({ list: this.state.list })
    }

    selectDeal(pk) {
        if (this.state.selectedPk === pk) {
            this.setState({
                selectedPk: null
            })    
        } else {
            this.setState({
                selectedPk: pk
            })    
        }
    }

    updateDeal(deal, index) {
        this.state.list[index].deal = deal

        this.setState({
            list: this.state.list
        })
    }

    showPendingOnly(e) {
        const showPendingOnly = e.target.checked
        this.setState({
            showPendingOnly
        })
    }

    async listApprovals() {
        this.setState({ searching: true })

        let approvals = []
        let token = null
        console.log('`${this.state.selectedYear}-${this.state.selectedMonth}`', `${this.state.selectedYear.value}-${this.state.selectedMonth.value}`) //eslint-disable-line
        do {
            let options = {
                createdYearMonth: `${this.state.selectedYear.value}-${this.state.selectedMonth.value}`,
                sortDirection: 'DESC'
            }
            if (token) {
                options.nextToken = token
            }
            const result = await API.graphql(graphqlOperation(approvalByYearMonth, options))
            console.log('result', result)
            approvals = approvals.concat(result.data.approvalByYearMonth.items)
            token = result.data.approvalByYearMonth.nextToken
        } while (token)
        
        this.setState({ list: approvals })

        // get deal details
        const promises = await approvals.map(async (approval) => {
            try {
                const _approval = {...approval} // avoid unintended mutation effect
                const result = await API.graphql(graphqlOperation(getDealMaster, { pk: _approval.pk }))
                const deal = result.data.getDealMaster
                _approval.deal = deal

                if (deal && deal.merchant_id) {
                    // check if merchant level has the stripe connected account ID
                    const merchantResult = await API.graphql(graphqlOperation(getMerchant, { merchant_id: deal.merchant_id }))
                    const merchant = merchantResult.data.getMerchant
                    _approval.merchant = merchant

                    // get stripe
                    const result = await API.graphql(graphqlOperation(stripeAccountByMerchantId, { merchantId: deal.merchant_id }))
                    const stripe = result.data.stripeAccountByMerchantId.items[0]
                    _approval.stripe = stripe

                    if (stripe) {
                        // get cms user
                        const result = await API.graphql(graphqlOperation(getCMSUser, { email: stripe.email }))
                        const user = result.data.getCMSUser
                        _approval.user = user

                    }
                }
                // return the new approval object with possible additional keys
                return _approval
            } catch(e) {
                console.error('error compiling approval data ', e)
            }
        })
        
        const addMoreApprovalData = await Promise.all(promises)
        
        this.setState({ 
            list: addMoreApprovalData,
            searching: false 
        })
    }

    setMonth(month) {
        console.log('month', month)
        this.setState({
            selectedMonth: month
        })
    }

    setYear(year) {
        console.log('year', year)
        this.setState({
            selectedYear: year
        })
    }

    render() {
        const list = this.state.list.map((item, index) => {
            item.createDateTime = moment(item.createdAt).format('Do MMM YYYY, h:mm:ss a')
            item.updateDateTime = moment(item.updatedAt).format('Do MMM YYYY, h:mm:ss a')
            
            if (!this.state.showPendingOnly || (this.state.showPendingOnly && !item.isApproved)) {
                
                return <span>
                <Row key={`approval-deals-${item.pk}`} onClick={(e) => this.selectDeal(item.pk)} >
                    <Col>
                        {item.createDateTime}
                    </Col>
                    <Col>
                        {item.updateDateTime}
                    </Col>
                    <Col>
                        {item.user && item.user.corpName}
                    </Col>
                    <Col>
                        {item.user && item.user.verified}
                    </Col>
                    <Col>
                        {item.user && item.user.subPlan}
                    </Col>
                    <Col>
                        {item.deal && item.deal.merchant_name}
                    </Col>
                    <Col>
                        {item.stripe && item.stripe.status}
                    </Col>
                    <Col>
                        {item.merchant && item.merchant.stripeExpressConnectedAccountId}
                    </Col>
                    <Col>
                        {item.pk}
                    </Col>
                    <Col>
                        <Form.Control as="select" id={`approval-${item.pk}`} value={item.isApproved?'1':'0'} onChange={(e) => this.handleChange(e, index)} onClick={(e) => e.stopPropagation()}>
                            <option value="0">N</option>
                            <option value="1">Y</option>
                        </Form.Control>
                    </Col>
                    <Col>
                        {item.deal && item.deal.valid}
                    </Col>
                </Row>
                {item.deal && item.deal.pk === this.state.selectedPk ?
                    <Row>
                        <Col>
                            <Deal
                                mode='update'
                                key={`approve-deal-${item.deal.pk}`}
                                deal={item.deal}
                                show={this.state.selectedPk === item.deal.pk}
                                onUpdate={(deal) => this.updateDeal(deal, index)}
                            />
                        </Col>
                    </Row>
                : null}
            </span>
            } else  {
                return null
            }
        })

        return <div>
            <Row>
                <Col>
                    <h3>Approve Deals</h3>
                    <Form.Check type="checkbox" id="showPendingOnly" custom label="show pending only" checked={this.state.showPendingOnly} onChange={(e) => this.showPendingOnly(e)} />

                    <Select 
                        options={this.months}
                        value={this.state.selectedMonth}
                        onChange={(month) => this.setMonth(month)}
                    />

                    <Select 
                        options={this.years}
                        value={this.state.selectedYear}
                        onChange={(year) => this.setYear(year)}
                    />

                    <Button onClick={() => this.listApprovals()}>Show!</Button>

                    { this.state.searching ?
                        <Spinner animation="border" role="status" size="sm">
                        </Spinner>
                    : 
                    <div className="scrollview">
                        <Container as="table">
                            <Row>
                                <Col><strong>Create Date/Time</strong></Col>
                                <Col><strong>Modify Date/Time</strong></Col>
                                <Col><strong>Corp Name</strong></Col>
                                <Col><strong>Verified?</strong></Col>
                                <Col><strong>Plan</strong></Col>
                                <Col><strong>Brand</strong></Col>
                                <Col><strong>Stripe Status</strong></Col>
                                <Col><strong>Stripe Account Id</strong></Col>
                                <Col><strong>Deal Id</strong></Col>
                                <Col><strong>Approve?</strong></Col>
                                <Col><strong>Visible on app</strong></Col>
                            </Row>
                            {list}
                        </Container>
                    </div>
                    }
                </Col>
            </Row>
        </div>
    }
}

export default ApprovalDeal