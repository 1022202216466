import React, { Component } from 'react'
import { Form, Button, Col, Toast, Image, Table, Spinner } from "react-bootstrap"
import { API, graphqlOperation, Storage } from 'aws-amplify'
import moment from 'moment-timezone'
import Datetime from 'react-datetime'
import { publish } from '../widgets/CMSModal'
import Select from 'react-select';
import _ from 'lodash'
import { HexColorPicker } from 'react-colorful'
import "react-datetime/css/react-datetime.css"
import { createCampaign, updateCampaign, createPromoCodeMaster, updatePromoCodeMaster } from '../../graphql/mutations'
import { listPromoCodeMasters, promoCodeByCampaignId } from '../../graphql/queries'
import CMSImageUploader from './CMSImageUploader'
import { TZ } from '../../utils/constants'
import * as utils from '../../utils'
import { CAMPAIGN_NOTIF_SCHEDULE } from '../../utils/constants'
import CMSUploadCodes from './CMSUploadCodes'

moment.tz.setDefault(TZ);

class Campaign extends Component {

    constructor(props) {
        super(props)

        const defaultEligibility = {
            register: {
                type: 'registerAccount',
                name: 'Register Account',
                enabled: true,
                title: 'Register Account',
                text: '',
            },
            referFriend: {
                type: 'referFriends',
                name: 'Refer Friends',
                enabled: true,
                title: 'Refer Friends',
                text: '',
                numberReferral: 2
            },
            acknowledgement: {
                type: 'acknowledgement',
                name: 'Acknowledgement',
                enabled: false,
                title: 'I acknowledge the term',
                text: 'I confirm',
            }            
        };

        const campaign = props.campaign ? { ...props.campaign } : {};
        const eligibility = campaign.eligibility ? JSON.parse(campaign.eligibility) : defaultEligibility;
        let notificationSchedule = []
        // console.log({campaign})
        if (campaign.notificationSchedule) {
            // _.find(campaign.notificationSchedule, (o) => { return _.isMatch(o, entry) });
            notificationSchedule = campaign.notificationSchedule.filter((item) => {
                console.log({item})
                return _.find(CAMPAIGN_NOTIF_SCHEDULE, (o) => _.isMatch(o, item))
            })
            console.log({notificationSchedule})
        }

        // to sync existing data.
        if (eligibility.register && !eligibility.register.type) eligibility.register.type = 'registerAccount'
        if (eligibility.register && !eligibility.register.name) eligibility.register.name = 'Register Account'
        if (eligibility.referFriend && !eligibility.referFriend.type) eligibility.referFriend.type = 'referFriends'
        if (eligibility.referFriend && !eligibility.referFriend.name) eligibility.referFriend.name = 'Refer Friends'
        if (!eligibility.acknowledgement) eligibility.acknowledgement = defaultEligibility.acknowledgement;

        this.state = {
            item: {
                campaignId: campaign.campaignId ?? utils.guidGenerator(),
                campaignName: campaign.campaignName ?? null,
                dealId: campaign.dealId ?? null,
                valid: campaign.valid ?? '0',
                countAssigned: campaign.countAssigned ?? 1,
                title: campaign.title ?? '', //this is Redeem text
                redeemButtonText: campaign.redeemButtonText ?? '', //this is Redeem button text
                eventTrackerName: campaign.eventTrackerName ?? 'campaign_events',
                startTimestamp: campaign.startTimestamp ?? null,
                endTimestamp: campaign.endTimestamp ?? null,
                eligibility,
                killSwitch: campaign.killSwitch ?? 0,
                cssCode: campaign.cssCode ?? '',
                helpCenterUrl: campaign.helpCenterUrl ?? '',
                howToRedeemUrl: campaign.howToRedeemUrl ?? '',
                popUpImageUrl: campaign.popUpImageUrl ?? '',
                enableNotification: campaign.enableNotification ?? '0',
                notificationSchedule: notificationSchedule
            },
            croppedImages: {},
            showToast: false,
            banner: props.banner,
            mode: props.mode,
            numberOfCodesToGenerate: 1,
            loadingCodes: true,
            codeType: 'uploaded',
            promoCodes: []
        };
    }

    async componentDidMount() {
        try{
            if (this.state.item.campaignId) {
                const result = await API.graphql(graphqlOperation(promoCodeByCampaignId, {campaignId: this.state.item.campaignId}))
                const items = result.data.promoCodeByCampaignId.items
                if (items.length > 0) {
                    this.setState({promoCodes: items, loadingCodes: false, numberOfCodesToGenerate: items.length, codeType: items[0].codeType})
                } else {
                    this.setState({loadingCodes: false})
                }
            }
        } catch(e) {
            this.setState({loadingCodes: false})
            console.error(e)
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        if (!this.state.promoCodes.length === 0) {
            this.alert('Please upload or generated promo code for this campaign.')
            return
        }

        const { item, croppedImages } = this.state
        const id = item.campaignId ? item.campaignId : utils.guidGenerator()
        let popUpImageUrl = item.popUpImageUrl
        let imagePath = ''

        if (croppedImages.popUpImage) {
            const ext = croppedImages.popUpImage.ext
            imagePath = `CampaignPopUpImages/${item.dealId}-${id}${ext}`
            popUpImageUrl = utils.getAssetUrl(imagePath)
        }

        const campaign = {
            campaignId: item.campaignId ?? '',
            campaignName: item.campaignName ?? null,
            dealId: item.dealId,
            valid: item.valid,
            countAssigned: item.countAssigned,
            title: item.title,
            redeemButtonText: item.redeemButtonText ?? '',
            eventTrackerName: item.eventTrackerName,
            startTimestamp: item.startTimestamp,
            endTimestamp: item.endTimestamp,
            eligibility: JSON.stringify(item.eligibility),
            killSwitch: item.killSwitch,
            cssCode: item.cssCode,
            helpCenterUrl: item.helpCenterUrl,
            howToRedeemUrl: item.howToRedeemUrl,
            popUpImageUrl: popUpImageUrl,
            enableNotification: item.enableNotification,
            notificationSchedule: JSON.stringify(item.notificationSchedule)
        };

        if (this.state.mode === 'create') {
            this.handleCreate(campaign, imagePath);
        } else if (this.state.mode === 'update') {
            this.handleUpdate(campaign, imagePath);
        }
    }

    handleCreate(campaign, imagePath) {
        API.graphql(graphqlOperation(createCampaign, { input: campaign })).then(async (result) => {
            await this.handleImageUpload(imagePath)

            const {promoCodes} = this.state
            for(let i=0; i < promoCodes.length; i++) {  
                await API.graphql(graphqlOperation(createPromoCodeMaster, {input: {
                    promoCodeId: promoCodes[i].promoCodeId,
                    campaignId: campaign.campaignId,
                    codeType: promoCodes[i].codeType || 'uploaded',
                    countAssigned: promoCodes[i].countAssigned,
                    countRedeemed: promoCodes[i].countRedeemed,
                    startTimestamp: promoCodes[i].startTimestamp,
                    endTimestamp: promoCodes[i].endTimestamp,
                    usageStatus: promoCodes[i].usageStatus,
                    valid: promoCodes[i].valid,
                    validityType: promoCodes[i].validityType,
                }}))
            }

            this.setState({ showToast: true });
            if (this.props.refresh) {
                this.props.refresh(result.data);
            }
            this.setState({mode: 'update'})
        }).catch((error) => {
            console.debug('Error on handleCreate' , campaign)
            if (error.errors[0].message.indexOf('An additional exception was encountered handling this exception')) {
                this.alert('Error saving. Might be duplicate campaign id')
            }
            console.error(error)
        })
    }

    handleUpdate(campaign, imagePath) {
        API.graphql(graphqlOperation(updateCampaign, { input: campaign })).then(async (result) => {
            if (imagePath) {
                await this.handleImageUpload(imagePath)
            }

            const {promoCodes} = this.state
            for(let i=0; i < promoCodes.length; i++) {  
                await API.graphql(graphqlOperation(updatePromoCodeMaster, {input: {
                    promoCodeId: promoCodes[i].promoCodeId,
                    campaignId: campaign.campaignId,
                    codeType: promoCodes[i].codeType || 'uploaded',
                    countAssigned: promoCodes[i].countAssigned,
                    countRedeemed: promoCodes[i].countRedeemed,
                    startTimestamp: promoCodes[i].startTimestamp,
                    endTimestamp: promoCodes[i].endTimestamp,
                    usageStatus: promoCodes[i].usageStatus,
                    valid: promoCodes[i].valid,
                    validityType: promoCodes[i].validityType,
                }}))
            }

            this.setState({ showToast: true });
            if (this.props.refresh) {
                console.log('refreshing')
                this.props.refresh(result.data);
            }
        });
    }

    handleChange(e, fieldname) {
        let { banner, item, codeType, numberOfCodesToGenerate } = this.state
        const target = e.target;

        if (fieldname==='valid' && banner && banner.valid==="true") {
            e.preventDefault()
            e.stopPropagation()
            this.alert(`Please disable the banner (${banner.title}) first.`)
            return
        } else if (fieldname==='codeType') {
            codeType = target.value
        } else if (fieldname==='numberOfCodesToGenerate') {
            numberOfCodesToGenerate = target.value
        }

        
        if (target.type === 'checkbox') {
            item[fieldname] = e.target.checked ? '1' : '0';
            if (fieldname === 'enableNotification') {
                item['notificationSchedule'] = []
            }
        } else {
            item[fieldname] = e.target.value;
        }
        this.setState({ item, codeType, numberOfCodesToGenerate });
    }

    handleDateTime(m, fieldname) {
        const item = { ...this.state.item }

        if (typeof m === 'string') {
            item[fieldname] = moment(m, 'YYYY-MM-DD HH:mm:ss').unix()
        } else {
            item[fieldname] = m.unix()
        }

        this.setState({ item: item })
    }

    handleEligibilityChanged(e, fieldname, eligibilityKey) {
        const item = { ...this.state.item };
        const eligibility = item.eligibility[eligibilityKey];
        const target = e.target;

        if (target.type === 'checkbox') {
            eligibility[fieldname] = e.target.checked;
        } else {
            eligibility[fieldname] = e.target.value;
        }

        this.setState({
            item: item
        })        
    }

    setCampaignColor(color){
        const item = { ...this.state.item };
        item.cssCode = color
        this.setState({
            item: item
        })
        
    }
    
    alert(msg, callback) {
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    async handleImageUpload(path) {
        const { croppedImages } = this.state
        if (croppedImages.popUpImage && croppedImages.popUpImage.blob) {
            croppedImages.popUpImage.blob.name = path
            await Storage.put(path, croppedImages.popUpImage.blob)
        }
    }

    async getCroppedImage(croppedImage, fieldname) {
        let croppedImages = this.state.croppedImages??{}
        croppedImages[fieldname] = croppedImage
        
        this.setState({
            croppedImages: croppedImages
        })
    }

    async _onChangeNotifSchedule(value, action) {
        const item = { ...this.state.item }
        item.notificationSchedule = value
        this.setState({ item: item })
    }

    async generateCodes() {
        let {numberOfCodesToGenerate, item} = this.state

        try {
            // check restriction
            if (!item.startTimestamp || !item.endTimestamp) {
                this.alert('Please set start and end dates first before generating.')
                return
            } else if (!numberOfCodesToGenerate || numberOfCodesToGenerate < 1 || numberOfCodesToGenerate > 1000) {
                this.alert('Number of voucher that can be generated is between 1-1000 only')
                return
            }

            this.setState({loadingVouchers: true})
            const codes = await utils.generatePromoCodes(numberOfCodesToGenerate)
            const promoCodes = await this.verifyCodes(codes, 'generated')

            this.setState({item, promoCodes, loadingVouchers: false})

        } catch(e) {
            console.error('error on generatePromoCode ', e)
        }
    }

    async verifyCodes(codes, type) {
        let {item} = this.state
        const duplicate = await this.checkDuplicateCodes(codes)
        
        let promoCodes = []
        if (duplicate.length === 0) {
            promoCodes = codes.map((code, index) => {
                return {
                    promoCodeId: code,
                    campaignId: item.campaignId,
                    countAssigned: item.countAssigned || "1",
                    countRedeemed: "0",
                    usageStatus: "0",
                    valid: "1",
                    validityType: "1",
                    codeType: type,
                    startTimestamp: item.startTimestamp,
                    endTimestamp: item.endTimestamp,
                }
            })  
        } else {
            this.alert(`Duplicate code detected: ${duplicate.toString()}`)
        }

        return promoCodes
    }

    async checkDuplicateCodes(codes){
        try {
            const duplicateCodes = []
            const processCodes = []
            for (let i=0; i < codes.length; i++){
                if (codes[i]) {
                    const result = await this.getPromoCodeMasterByCode(codes[i])
                    console.log('result getPromoCodeMasterByCode ', result)
                    if (result && result.items.length > 0) {
                        duplicateCodes.push(codes[i])
                    }

                    if (processCodes.indexOf(codes[i]) >= 0) {
                        duplicateCodes.push(codes[i])
                    }

                    processCodes.push(codes[i])
                }
            }
    
            return duplicateCodes
        } catch (e) {
            console.error('error on checkDuplicateCodes ', e)
            throw e ? e.message : 'Please contact administrator'
        }
    }

    async getPromoCodeMasterByCode(code) {
        try {
            console.log({code})
            const result = await API.graphql(graphqlOperation(listPromoCodeMasters, { promoCodeId: code }))
            return result.data.getPromoCodeMaster
        } catch (e) {
            console.error('Error on getPromoCodeMasterByCode', e)
            throw new Error('Error getting promo code', code)
        }
    }

    async onUploadCodes(codes) {
        
        try{
            this.setState({loadingVouchers: true})
            const promoCodes = await this.verifyCodes(codes, 'uploaded')

            this.setState({promoCodes, loadingVouchers: false})
        } catch(e) {
            console.error('Error on onUploadCodes', e)
            throw new Error('Error processing upload of promo codes')
        }
    }


    render() {
        const { eligibility, cssCode, campaignId, campaignName, dealId, popUpImageUrl } = this.state.item;
        const { croppedImages } = this.state
        const colorPickerStyle = {
            width: '120px',
            height: '120px'
        }
        
        return <div>
            {this.props.show ?
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    
                    <h3>{this.props.title??this.state.mode==='create'?'Create':'Update'} Campaign</h3>
                    
                    <Form.Group>
                        <Form.Check label="Valid" checked={this.state.item.valid==='1'} onChange={(e) => this.handleChange(e, 'valid')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Campaign Id*</Form.Label>
                        <Form.Control type="text" disabled={this.state.mode==='update'} value={campaignId ? campaignId : ''} onChange={(e) => this.handleChange(e, 'campaignId')}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Campaign Name</Form.Label>
                        <Form.Control required type="text" value={campaignName ? campaignName : ''} onChange={(e) => this.handleChange(e, 'campaignName')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Deal Id *</Form.Label>
                        <Form.Control required type="text" value={dealId ? dealId : ''} onChange={(e) => this.handleChange(e, 'dealId')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Max Allowed Redemption Per User  *</Form.Label>
                        <Form.Control required type="number" disabled min="1" value={this.state.item.countAssigned} onChange={(e) => this.handleChange(e, 'countAssigned')} />
                    </Form.Group>                    
                    <Form.Group>
                        <Form.Label>Event Tracker Name *</Form.Label>
                        <Form.Control required type="text" disabled value={this.state.item.eventTrackerName} onChange={(e) => this.handleChange(e, 'eventTrackerName')} />
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Start Date/Time *</Form.Label>
                            <Datetime closeOnSelect dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" inputProps={{disabled: this.state.readOnly, readOnly: this.state.readOnly}} value={this.state.item.startTimestamp ? moment.unix(this.state.item.startTimestamp) : null} onChange={(m) => this.handleDateTime(m, 'startTimestamp')} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>End Date/Time *</Form.Label>
                            <Datetime closeOnSelect dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" inputProps={{disabled: this.state.readOnly, readOnly: this.state.readOnly}} value={this.state.item.endTimestamp ? moment.unix(this.state.item.endTimestamp) : null} onChange={(m) => this.handleDateTime(m, 'endTimestamp')} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Label>Eligibility *</Form.Label>
                    </Form.Row>
                    {
                        Object.entries(eligibility).map(([key, el]) =>
                            <Form.Group key={key}>
                                <div className="container-eligibility">
                                <Form.Label>{el.name}</Form.Label>
                                {
                                /** This is temporary to force a disable checkbox on this particular eligibility item(CD-256) */
                                el.type==='registerAccount' ?
                                <Form.Check label="Enabled"  disabled checked="checked" onChange={(e) => this.handleEligibilityChanged(e, 'enabled', key)} />
                                :
                                <Form.Check label="Enabled" checked={el.enabled} onChange={(e) => this.handleEligibilityChanged(e, 'enabled', key)} />
                                }

                                <Form.Label>Title *</Form.Label>
                                <Form.Control required={el.enabled} type="text" value={el.title} onChange={(e) => this.handleEligibilityChanged(e, 'title', key)} />

                                <Form.Label>Button Text *</Form.Label>
                                <Form.Control required={el.enabled} type="text" value={el.text} onChange={(e) => this.handleEligibilityChanged(e, 'text', key)} />
                                {
                                    el.type==='referFriends' ?
                                        <React.Fragment>
                                            <Form.Label style={{marginTop: 10}}>Number of Referral *</Form.Label>
                                            <Form.Control required={el.enabled} type="text" value={el.numberReferral} onChange={(e) => this.handleEligibilityChanged(e, 'numberReferral', key)} />
                                        </React.Fragment>
                                    : null
                                }
                                </div>
                            </Form.Group>
                        )
                    }

                    <Form.Group>
                        <Form.Label>Redeem text *</Form.Label>
                        <Form.Control required type="text" value={this.state.item.title} onChange={(e) => this.handleChange(e, 'title')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Redeem Button text *</Form.Label>
                        <Form.Control required type="text" value={this.state.item.redeemButtonText} onChange={(e) => this.handleChange(e, 'redeemButtonText')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check label="Pause to prevent distribution of promo codes" checked={this.state.item.killSwitch==='1'} onChange={(e) => this.handleChange(e, 'killSwitch')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check label="Enable notification for this campaign" checked={this.state.item.enableNotification==='1'} onChange={(e) => this.handleChange(e, 'enableNotification')} />
                    </Form.Group>
                    {this.state.item.enableNotification==='1'?
                    <Form.Group>
                        <Select
                            isMulti
                            value={this.state.item.notificationSchedule}
                            name="notifSchedule"
                            options={CAMPAIGN_NOTIF_SCHEDULE}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(v, a) => this._onChangeNotifSchedule(v, a)}
                        />
                    </Form.Group>
                    :null}
                    <Form.Group>
                        <Form.Label>CSS Code</Form.Label>
                        <Form.Control type="text" value={this.state.item.cssCode} onChange={(e) => this.handleChange(e, 'cssCode')} />
                        <section>
                            <HexColorPicker color={cssCode} style={colorPickerStyle} onChange={(e) => this.setCampaignColor(e)}/>
                        </section>
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <CMSImageUploader
                                width={278}
                                height={399}                           
                                disabled={this.state.readOnly} 
                                readOnly={this.state.readOnly} 
                                label="Campaign PopUp Image" 
                                onOk={(croppedImage) => this.getCroppedImage(croppedImage, 'popUpImage')}
                            />
                        </Form.Group>
                        { (croppedImages.popUpImage && croppedImages.popUpImage.url) || popUpImageUrl ?
                            <Form.Group style={{width:'50%', textAlign: 'right'}}>
                                <Image fluid src={croppedImages.popUpImage?croppedImages.popUpImage.url:`${popUpImageUrl}?timestamp=${Date.now()}`} />
                            </Form.Group>
                        : null}
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>Help Center URL</Form.Label>
                        <Form.Control type="text" value={this.state.item.helpCenterUrl} onChange={(e) => this.handleChange(e, 'helpCenterUrl')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>How to redeem URL</Form.Label>
                        <Form.Control type="text" value={this.state.item.howToRedeemUrl} onChange={(e) => this.handleChange(e, 'howToRedeemUrl')} />
                    </Form.Group>

                    <div>
                        <div style={{border:"1px solid rgb(204, 204, 204)", marginBottom:"20px",padding:"10px"}}>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <input type="radio" disabled={this.state.mode!=='create'} checked={this.state.codeType!=='generated'} value="uploaded" name="codeType" onChange={(e) => this.handleChange(e, 'codeType')}/>{" "}
                                    Upload Promo Codes <br/>
                                    {this.state.codeType==='uploaded' && (!this.state.item.startTimestamp || !this.state.item.endTimestamp) ? (<span className="red" style={{fontSize:"10px"}}>Please select start and end date first</span>) : null}
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <input type="radio" disabled={this.state.mode!=='create'} checked={this.state.codeType==='generated'} value="generated" name="codeType" onChange={(e) => this.handleChange(e, 'codeType')}/>{" "}
                                    Generate <br/>
                                    {this.state.codeType==='generated' && (!this.state.item.startTimestamp || !this.state.item.endTimestamp) ? (<span className="red" style={{fontSize:"10px"}}>Please select start and end date first</span>) : null}
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    {this.state.codeType==='uploaded'?
                                    <CMSUploadCodes
                                        isEnabled={this.state.mode!=='create' || !this.state.item.startTimestamp || !this.state.item.endTimestamp}
                                        onComplete={(codes) => this.onUploadCodes(codes)}
                                    />
                                    : null}
                                </Form.Group>
                                <Form.Group as={Col}>
                                    {this.state.codeType==='generated'?
                                    <div>
                                        <input disabled={this.state.mode!=='create'} size="5" height="35px" type="text" required={true} value={this.state.numberOfCodesToGenerate} onChange={(e) => this.handleChange(e, 'numberOfCodesToGenerate')} />{" "}
                                        <Button disabled={this.state.mode!=='create' || this.state.processing || this.state.readOnly || this.state.loadingCodes} type="button" onClick={(e) =>this.generateCodes(e)}>Generate {this.state.loadingCodes ? <Spinner animation="border" size="sm" /> : null}</Button>
                                    </div>
                                    : null}
                                </Form.Group>
                            </Form.Row>
                        </div>
                        <div className="scrollview">
                            <Table striped bordered hover variant="white">
                            <thead>
                                <th>#</th>
                                <th>code</th>
                                <th>type</th>
                                <th>start</th>
                                <th>end</th>
                                <th>valid</th>
                            </thead>

                            <tbody>
                                {this.state.promoCodes && this.state.promoCodes.map((code, index) => 
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>
                                        {code.promoCodeId}
                                    </td>
                                    <td>
                                        {code.codeType || 'uploaded'}
                                    </td>
                                    <td>
                                        {moment.unix(code.startTimestamp).format('Do MMM YYYY, h:mm:ss a')}
                                    </td>
                                    <td>
                                        {moment.unix(code.endTimestamp).format('Do MMM YYYY, h:mm:ss a')}
                                    </td>
                                    <td>
                                        <span className={code.valid === '1'?'green':'red'}>{code.valid === '1'? 'Valid': 'Invalid'}</span>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                            </Table>
                        </div>
                    </div>

                    <Form.Group>
                        <Toast onClose={() => this.setState({ showToast: false })} show={this.state.showToast} delay={3000} autohide>
                            {this.state.mode==='create'?
                                <Toast.Body>Campaign Created.</Toast.Body>
                            : null}
                            {this.state.mode==='update'?
                                <Toast.Body>Campaign Updated.</Toast.Body>
                            : null}
                        </Toast>

                        {this.state.mode==='create'?
                            <Button type="submit" style={{marginTop: 10}}>Create Campaign</Button>
                        : null}
                        {this.state.mode==='update'?
                            <Button type="submit" style={{marginTop: 10}}>Update Campaign</Button>
                        : null}
                    </Form.Group>
                    
                </Form>
            : null}
        </div>
    }
}

export default Campaign