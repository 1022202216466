import React, { Component } from 'react';
import { Container, Row, Col, ListGroup, Button, Modal } from 'react-bootstrap';
import { FaPlus, FaEdit } from "react-icons/fa";
import { API, graphqlOperation } from 'aws-amplify';
import { getCategoryBestDealsByMainCat, getTags } from '../../graphql/queries'
import CategoryHiglight from '../widgets/CategoryHighlight';
import CategoryBestDeal from '../widgets/CategoryBestDeal';
class CategoriesView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            listCategories: [
                { id: 'Food', label: 'Food' },
                { id: 'Shopping', label: 'Shopping' },
                { id: 'Activities', label: 'Activities' },
                { id: 'Travel', label: 'Travel' },
                { id: 'Transport', label: 'Transport' },
                { id: 'Wellness', label: 'Wellness' }
            ],
            listSections: ['Highlights', 'Best Deals'],
            categoryForSection: null,
            section: null,
            listItems: null,
            subCategoryLoading: false
        }
    }

    async componentDidMount() {
        this.setState({
            categoryForSection: null,
            section: null,
            listItems: null,
            subCategoryLoading: false
        });      
    }

    selectCategoryForSection(e, category) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            categoryForSection: category,
            section: null,
        });
    }   

    async selectSection(e, section) {
        e.preventDefault();
        e.stopPropagation();

        const category = this.state.categoryForSection.id;
        const listItems = [];

        if (section === 'Highlights') {
            const result = await API.graphql(graphqlOperation(getTags, { id: 'highlight', tagName: category.toLowerCase() + 'highlights' }));
            const tag = result.data.getTags;    
            const highlights = JSON.parse(tag.highlights);
            for (const [key, value] of Object.entries(highlights)) {
                listItems.push({
                    id: key,
                    title: value.title,
                    sectionUrl: value.sectionUrl
                });
            }    
        } else if (section === 'Best Deals') {
            const result = await API.graphql(graphqlOperation(getCategoryBestDealsByMainCat, { mainCat: category }));
            const bestDeals = result.data.getCategoryBestDealsByMainCat;
            if (bestDeals && bestDeals.items && bestDeals.items.length > 0) {
                for (const bestDeal of bestDeals.items) {
                    listItems.push({
                        id: bestDeal.categoryBestDealsId,
                        ...bestDeal
                    });
                }
            }
        }

        this.setState({
            section,
            listItems,
        });
    }

    createItem(e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.section === 'Highlights') {
            this.setState({
                mode: 'createItem',
                highlight: null,
                showHighlight: true
            });
        } else if (this.state.section === 'Best Deals') {
            this.setState({
                mode: 'createItem',
                bestDeal: null,
                showBestDeal: true
            });
        }
    }

    editItem(e, item) {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.section === 'Highlights') {
            this.setState({
                mode: 'updateItem',
                highlight: item,
                showHighlight: true
            });
        } else if (this.state.section === 'Best Deals') {
            this.setState({
                mode: 'updateItem',
                bestDeal: item,
                showBestDeal: true
            });
        }
    }

    handleCloseHighlight() {
        this.setState({
            showHighlight: false,
        });
    }

    onCreateHighlight(highlight) {
        const listItems = this.state.listItems.concat(highlight);
        this.setState({
            listItems,
            showHighlight: false,
        });
    }

    onUpdateHighlight(highlight) {
        const listItems = this.state.listItems;
        const index = listItems.findIndex(i => i.id === highlight.id);
        if (index >= 0) {
            listItems[index] = highlight;
        }
        this.setState({
            listItems,
            showHighlight: false,
        });        
    }

    onDeleteHighlight(highlight) {
        const listItems = this.state.listItems;
        const index = listItems.findIndex(i => i.id === highlight.id);      
        if (index >= 0) {
            listItems.splice(index, 1);
        }          
        this.setState({
            listItems,
            showHighlight: false,
        });        
    }

    handleCloseBestDeal() {
        this.setState({
            showBestDeal: false,
        });
    }

    onCreateBestDeal(bestDeal) {
        const listItems = this.state.listItems.concat(bestDeal);
        this.setState({
            listItems,
            showBestDeal: false,
        });        
    }

    onUpdateBestDeal(bestDeal) {
        const listItems = this.state.listItems;
        const index = listItems.findIndex(i => i.id === bestDeal.id);
        if (index >= 0) {
            listItems[index] = bestDeal;
        }        
        this.setState({
            listItems,
            showBestDeal: false,
        });        
    }

    render() {
        const { listCategories, listSections, listItems } = this.state;
        
        return <Container fluid>
            <Row>
                <Col>
                    <Row>
                        <Col><h3>Categories</h3></Col>
                    </Row>
                    <Row>
                        <Col>
                            <ListGroup variant="flush">
                            {
                                listCategories.length > 0 ?
                                    listCategories.map((item) =>
                                        <ListGroup.Item key={`listCategories-${item.id}`} action onClick={(e) => this.selectCategoryForSection(e, item)}
                                        active={this.state.categoryForSection ? this.state.categoryForSection.id === item.id : false}>
                                            { item.label }
                                        </ListGroup.Item>
                                    )
                                : 'No Category'
                            }
                            </ListGroup>
                        </Col>
                    </Row>
                </Col>

                {this.state.categoryForSection ?
                <Col>
                    <Row>
                        <Col><h3>Sections</h3></Col>
                    </Row>
                    <Row>
                        <Col >
                            <ListGroup variant="flush">
                            {
                                listSections.length > 0 ?
                                    listSections.map((item) =>
                                        <ListGroup.Item key={`listSections-${item}`} action onClick={(e) => this.selectSection(e, item)}
                                        active={this.state.section ? this.state.section === item : false}>
                                            { item }
                                        </ListGroup.Item>
                                    )
                                : 'No Section'
                            }
                            </ListGroup>
                        </Col>
                    </Row>
                </Col>
                : null}

                {this.state.section ?
                <Col>
                    <Row>
                        <Col><h3>Items</h3></Col>
                        <Col><FaPlus className="float-right" onClick={(e) => this.createItem(e)} /></Col>
                    </Row>
                    <Row>
                        <Col>
                            <ListGroup variant="flush">
                            {
                                listItems.length > 0 ?
                                    listItems.map((item) =>
                                        <ListGroup.Item key={`listItems-${item.id}`}>
                                            <div>
                                                { item.title }
                                                <Button className="float-right" size="sm" onClick={(e) => this.editItem(e, item)}>
                                                    <FaEdit /> Edit
                                                </Button>
                                            </div>
                                            <small>{ this.state.section === 'Best Deals' ? (item.valid === '1' ? 'Valid: true' : 'Valid: false') : item.id }</small>
                                        </ListGroup.Item>
                                    )
                                : 'No Item'
                            }
                            </ListGroup>
                        </Col>
                    </Row>
                </Col>
                : null}
            </Row>

            <Modal show={this.state.showHighlight} onHide={() => this.handleCloseHighlight()} size="xl" backdrop='static'>
                <Modal.Header closeButton>
                    <Modal.Title>{(this.state.mode==='createItem')?'Create':(this.state.mode==='updateItem'?'Update':'')} Highlight</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CategoryHiglight show={this.state.showHighlight} mode={this.state.mode} key={`highlight-${this.state.highlight ? this.state.highlight.id : ''}`}
                        highlight={this.state.highlight} 
                        category={this.state.categoryForSection ? this.state.categoryForSection.id : ''}
                        onClose={() => this.handleCloseHighlight()}
                        onCreate={(highlight) => this.onCreateHighlight(highlight)}
                        onUpdate={(highlight) => this.onUpdateHighlight(highlight)}
                        onDelete={(highlight) => this.onDeleteHighlight(highlight)}
                    />
                </Modal.Body>
            </Modal>

            <Modal show={this.state.showBestDeal} onHide={() => this.handleCloseBestDeal()} size="xl" backdrop='static'>
                <Modal.Header closeButton>
                    <Modal.Title>{(this.state.mode==='createItem')?'Create':(this.state.mode==='updateItem'?'Update':'')} Best Deal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CategoryBestDeal show={this.state.showBestDeal} mode={this.state.mode} key={`bestDeal-${this.state.bestDeal ? this.state.bestDeal.id : ''}`}
                        bestDeal={this.state.bestDeal} 
                        category={this.state.categoryForSection ? this.state.categoryForSection.id : ''}
                        onClose={() => this.handleCloseBestDeal()}
                        onCreate={(bestDeal) => this.onCreateBestDeal(bestDeal)}
                        onUpdate={(bestDeal) => this.onUpdateBestDeal(bestDeal)}
                    />
                </Modal.Body>
            </Modal>            
        </Container>
    }
}

export default CategoriesView