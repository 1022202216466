import { API, Auth } from 'aws-amplify'
import React, { Component } from 'react'
import { Form, ListGroup } from "react-bootstrap"
import { debounce } from 'lodash'

import aws_exports from '../../aws-exports'

class CMSPostalCode extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            address:this.props.value??'',
            places: [],
            invalidPostalCode: false
        }
    }

    debounceLookupAddress = debounce((address) => {
        this.lookupAddress(address)
    }, 1000)

    async lookupAddress(address) {
        // IMPT :: should not have body object, otherwise api will throw InvalidSignatureException
        // http://5.9.10.113/48086977/aws-amplify-react-native-throwing-403-invalidsignatureexception-when-passing-da
        this.props.processing(true)

        // do not call if not needed
        if (this.props.showList) {
            const result = await API.get('GAPI', `/getAddressAutocomplete`, {
                queryStringParameters: {
                    address: address
                }
            })
            if (result.status === 'OK') {

                const places = result.predictions
                console.log('places', places)
    
                this.setState({
                    places: places
                })
            }
        }

        if (this.props.onChange) {
            const latlng = await this.getLatLng(address)
            console.log('latlng change', latlng)
            this.props.onChange(address, latlng)
        }       
        this.props.processing(false)
    }

    handleAddressChange(e) {
        e.preventDefault()
        e.stopPropagation()
        
        let _address = e.target.value
        this.setState({ address: _address })
        
        if (_address.length >= 6) {
            this.debounceLookupAddress(_address)
            this.setState({ address: _address })
        }else if (_address.length === 0){
            this.setState({ address: _address })
        }

    }

    inputValidity = () =>{
        let postalCode = this.state.address
        if(postalCode?.length >=6){
            if(isNaN(postalCode)){
                this.setState({ invalidPostalCode: true })
                this.props.invalidPostalCode(true)
            }else{
                this.setState({ invalidPostalCode: false })
                this.props.invalidPostalCode(false)
            }

        }else{
            this.setState({ invalidPostalCode: true })
            this.props.invalidPostalCode(true)
        }
    }

    async handlePlaceSelect(e, place) {
        e.preventDefault()
        e.stopPropagation()

        const address = place.description
        this.setState({
            address: address,
            places: []
        })

        if (this.props.onChange) {
            const latlng = await this.getLatLng(address)
            console.log('latlng select', latlng)
            this.props.onChange(address, latlng)
        }
    }

    async getLatLng(address) {
        // IMPT :: should not have body object, otherwise api will throw InvalidSignatureException
        // http://5.9.10.113/48086977/aws-amplify-react-native-throwing-403-invalidsignatureexception-when-passing-da
        const result = await API.get('GAPI', `/geocode`, {
            queryStringParameters: {
                address: address
            }
        })

        if (result.status === 'OK') {
            const loc = result.results[0].geometry.location
            console.log('loc', loc)
            return loc
        }
    }

    hidePlaces(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            places: []
        })
    }

    render() {
        return (
            <div>
                <Form.Control
                placeholder='e.g. 122354' 
                type='text' 
                disabled={this.props.disabled}
                required={this.props.required}
                value={this.state.address}
                onChange={(e) => this.handleAddressChange(e)}
                onBlur={()=> this.inputValidity()}
                isInvalid={this.state.invalidPostalCode}
                autoComplete={'off'}
                />
                { this.state.invalidPostalCode &&  <span style={{ color:'#EC536C', fontSize: 12 }}>Input must have atleast 6 numbers</span> }
                { this.props.showList && 
                    <ListGroup variant="flush">
                    { this.state.places.map((place) => 
                        <ListGroup.Item key={`list-addresses-${place.place_id}`} action onMouseDown={(e) => this.handlePlaceSelect(e, place)}>
                            {place.description}
                        </ListGroup.Item>
                    )}
                    </ListGroup>
                }
            </div>
        )
    }
}

export default CMSPostalCode