import React, { Component } from 'react'
import { Form, Button, Toast } from "react-bootstrap"
import { API, graphqlOperation } from 'aws-amplify'
import { createSearchKeyword, updateSearchKeyword } from '../../graphql/mutations'
import { getByKeyword, listSearchKeywords } from  '../../graphql/queries'

class SearchKeyword extends Component {

    constructor(props) {
        super(props)

        const searchKeyword = props.searchKeyword ? { ...props.searchKeyword } : {};
        this.state = {
            item: {
                searchKeywordId: searchKeyword.searchKeywordId ?? null,
                keyword: searchKeyword.keyword ?? null,
                keywordLowerCase: searchKeyword.keywordLowerCase ?? null,
                valid: searchKeyword.valid ?? '0',
                hot: searchKeyword.hot ?? '0',
                new: searchKeyword.new ?? '0',
            },
            errorMsg: '',
            showToast: false
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        const searchKeyword = {
            searchKeywordId: this.state.item.searchKeywordId ?? '',
            keyword: this.state.item.keyword,
            keywordLowerCase: this.state.item.keyword.toLowerCase(),
            valid: this.state.item.valid,
            hot: this.state.item.hot,
            new: this.state.item.new,            
        };

        if (this.props.mode === 'create') {
            this.handleCreate(searchKeyword);
        } else if (this.props.mode === 'update') {
            this.handleUpdate(searchKeyword);
        }
    }

    async handleCreate(searchKeyword) {
        const filter = {
            keywordLowerCase: { contains: searchKeyword.keywordLowerCase}
        }
        const isDuplicate = await this.isDuplicateKeyword(filter)

        if (isDuplicate) {
            this.setState({errorMsg: 'Duplicate keyword not allowed.'})
            return
        } else {
            API.graphql(graphqlOperation(createSearchKeyword, { input: searchKeyword })).then((result) => {
                this.setState({ showToast: true, errorMsg: '' });
                if (this.props.onCreate) {
                    this.props.onCreate(result.data);
                }
            })
        }
    }

    async handleUpdate(searchKeyword) {
        const filter = {
            keywordLowerCase: { eq: searchKeyword.keywordLowerCase },
            searchKeywordId: { ne: searchKeyword.searchKeywordId }
        }
        const isDuplicate = await this.isDuplicateKeyword(filter)
        
        if (isDuplicate) {
            this.setState({errorMsg: 'Duplicate keyword not allowed.'})
        } else {
            API.graphql(graphqlOperation(updateSearchKeyword, { input: searchKeyword })).then((result) => {
                this.setState({ showToast: true, errorMsg: '' });
                if (this.props.onUpdate) {
                    this.props.onUpdate(result.data);
                }
            });
        }
    }

    async isDuplicateKeyword(filter) {
        let params = {filter}
        
        return new Promise((resolve, reject) => {
            API.graphql(graphqlOperation(listSearchKeywords, params)).then((result) => {
                resolve(result.data.listSearchKeywords.items.length > 0)
            }).catch((e) => {
                console.error('error on isDuplicateKeyword ', e)
                this.setState({errorMsg: `Error: ${e.errors[0].message}`})
                reject(e)
            })
        })
    }

    async handleChange(e, fieldName) {
        const item = { ...this.state.item };
        const target = e.target;

        if (target.type === 'checkbox') {
            item[fieldName] = e.target.checked ? '1' : '0';
        } else {
            item[fieldName] = e.target.value;
        }

        this.setState({ item: item });
    }

    render() {
        return <div>
            {this.props.show ?
                <Form onSubmit={(e) => this.handleSubmit(e)}>

                    <h3>{this.props.title ?? this.props.mode==='create' ? 'Create Search Keyword' : 'Update Search Keyword'}</h3>
                    <Form.Group>
                        <Form.Label>Search Keyword Id</Form.Label>
                        <Form.Control type="text" disabled readOnly value={this.state.item.searchKeywordId || ''} />
                    </Form.Group>                    
                    <Form.Group>
                        <Form.Label>Keyword</Form.Label>
                        <Form.Control type="text" required value={this.state.item.keyword || ''} onChange={(e) => this.handleChange(e, 'keyword')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check label="Valid" checked={this.state.item.valid === '1'} onChange={(e) => this.handleChange(e, 'valid')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check label="Hot" checked={this.state.item.hot === '1'} onChange={(e) => this.handleChange(e, 'hot')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check label="New" checked={this.state.item.new === '1'} onChange={(e) => this.handleChange(e, 'new')} />
                    </Form.Group>

                    <Form.Group>
                        <Toast onClose={() => this.setState({ showToast: false })} show={this.state.showToast} delay={3000} autohide>
                            {this.props.mode==='create'?
                                <Toast.Body>Search Keyword Created.</Toast.Body>
                            : null}
                            {this.props.mode==='update'?
                                <Toast.Body>Search Keyword Updated.</Toast.Body>
                            : null}
                        </Toast>
                        
                        {this.props.mode==='create'?
                            <Button type="submit">Create Search Keyword</Button>
                        : null}
                        {this.props.mode==='update'?
                            <Button type="submit">Update Search Keyword</Button>
                        : null}
                        <br/>
                        <Form.Text style={{color: "red"}}>{this.state.errorMsg}</Form.Text>
                    </Form.Group>

                </Form>
                : null}
        </div>
            ;
    }
}

export default SearchKeyword