import React, { Component } from 'react'
import { Form, Button, Spinner, Col, Image } from "react-bootstrap"
import { API, Storage } from 'aws-amplify'
import { backOff } from 'exponential-backoff';
import { publish } from '../widgets/CMSModal'
import ACL from '../../utils/ACL'
import CardModel from '../../models/CardModel'
import CMSImageUploader from './CMSImageUploader'
import * as utils from '../../utils'
class Card extends Component {

    config = {
        addCardsVisible: {
            type: 'checkbox',
            values: {
                true: '1',
                false: '0'
            }
        },
        applyNowVisible: {
            type: 'checkbox',
            values: {
                true: '1',
                false: '0'
            }
        },
        compareNowVisible: {
            type: 'checkbox',
            values: {
                true: '1',
                false: '0'
            }
        },
        bank: {
            readOnly: true,
            required: true
        },
        cardspal_rating: {
            adminOnly: true
        },
        card_detail_id: {
            readOnly: true,
            required: true
        },
        deal_handler: {
            type: 'delimitedItems'
        },
        feature1_type: {
            type: 'dropdown',
            values: ['money', 'travel', 'service', 'reward', 'shopping', 'transport', 'insurance']
        },
        feature2_type: {
            type: 'dropdown',
            values: ['money', 'travel', 'service', 'reward', 'shopping', 'transport', 'insurance']
        },
        feature3_type: {
            type: 'dropdown',
            values: ['money', 'travel', 'service', 'reward', 'shopping', 'transport', 'insurance']
        },
        payment_network: {
            type: 'dropdown',
            values: ['amex', 'master', 'visa', 'dinersclub', 'NULL', 'unionpay', 'jcb']
        },
        promo_ranking: {
            adminOnly: true
        },
        singsaver_name: {
            hide: true
        },
        valid: {
            type: 'checkbox',
            values: {
                true: 'True', 
                false: 'False'
            }
        },
        welcome_offers: {
            type: 'textarea'
        },
        image_url: {
            type: 'imageUploader'
        }
    }

    constructor(props) {
        super(props)

        const card = props.card ? { ...props.card } : {}
        this.config.card_detail_id.readOnly = props.mode === 'create' ? false : true
        this.config.bank.readOnly = props.mode === 'create' ? false : true
        this.state = {
            item: new CardModel(card).toJson(),
            croppedImages: {}
        }
    }

    async componentDidMount() {
        this.setState({
            isAdmin: await ACL.isAdmin(),
        })       
    }

    handleInputChange(e, fieldname) {
        const item = { ...this.state.item }
        item[fieldname] = e.target.value

        this.setState({ item: item })
    }

    handleCheckboxChange(e, fieldname) {
        const item = { ...this.state.item }
        item[fieldname] = e.target.checked ? this.config[fieldname].values.true : this.config[fieldname].values.false

        this.setState({ item: item })
    }

    handleDelimitedItemsChange(e, fieldname) {
        const item = { ...this.state.item }
        item[fieldname] = e.target.value.split('\n')

        this.setState({ item: item })
    }

    async handleSubmit(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            processing: true
        })

        const { croppedImages, item } = this.state
        const id = item.card_detail_id ? item.card_detail_id : utils.guidGenerator()
        let cardImage = item.image_url
        let imagePath = ''

        if (croppedImages.cardImage) {
            const ext = croppedImages.cardImage.ext
            imagePath = `CreditCardImages/${item.bank}/${id}${ext}`
            cardImage = utils.getAssetUrl(imagePath)
        }

        this.state.item.image_url = cardImage

        try {
            if (this.props.mode === 'create') {
                await this.handleCreate(imagePath)
            } else if (this.props.mode === 'update') {
                await this.handleUpdate(imagePath)
            }
        } catch(error) {
            console.error(error)
            this.alert('Error processing Card')
        }
    }


    async handleCreate(imagePath) {

        const item = { ...this.state.item }
        item.specialFeatures = { NULL: "NULL"}

        const cardModel = new CardModel(this.state.item)
        const createdCard = await cardModel.create()

        this.alert('Card Created', async () => {
            if (this.props.onCreate) {
                if (imagePath) {
                    await this.handleImageUpload(imagePath)
                    await backOff(() => API.post('CloudFront', '/invalidate', {
                        body: {
                            path: `/public/${imagePath}`
                        }
                    }))
                }
                this.props.onCreate(createdCard)
            }    
        })
    }

    async handleUpdate(imagePath) {
        
        const cardModel = new CardModel(this.state.item)
        const updatedCard = await cardModel.update()

        this.alert('Card Updated', async () => {
            if (this.props.onUpdate) {
                if (imagePath) {
                    await this.handleImageUpload(imagePath)
                    await backOff(() => API.post('CloudFront', '/invalidate', {
                        body: {
                            path: `/public/${imagePath}`
                        }
                    }))
                }
                this.props.onUpdate(updatedCard)
            }
        })
    }

    alert(msg, callback) {
        this.setState({
            processing: false
        })
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    async getCroppedImage(croppedImage, fieldname) {
        let croppedImages = this.state.croppedImages ?? {}
        croppedImages[fieldname] = croppedImage

        this.setState({
            croppedImages: croppedImages
        })
    }

    async handleImageUpload(path) {
        const { croppedImages } = this.state
        if (croppedImages.cardImage && croppedImages.cardImage.blob) {
            croppedImages.cardImage.blob.name = path
            await Storage.put(path, croppedImages.cardImage.blob)
        }
    }

    render() {
        const { croppedImages, item } = this.state
        const { image_url: cardImage  } = item
        
        const fields = Object.keys(this.state.item).map((key) => {           
            const isCheckbox = this.config[key] && this.config[key].type === 'checkbox'
            const isTextarea = this.config[key] && this.config[key].type === 'textarea'
            const isDropdown = this.config[key] && this.config[key].type === 'dropdown'
            const isDelimitedItems = this.config[key] && this.config[key].type === 'delimitedItems'
            const isImageUploader = this.config[key] && this.config[key].type ==='imageUploader'
            const show = !this.config[key] || !this.config[key].hide
            const adminOnly = this.config[key] && this.config[key].adminOnly
            const required = this.config[key] && this.config[key].required
            const readOnly = this.config[key] && this.config[key].readOnly
            const adminInvalidAccess = adminOnly && !this.state.isAdmin

            if (show && !adminInvalidAccess) {
                return <Form.Group key={key}>
                    { !isCheckbox ? 
                        <Form.Label>{key} 
                        {required ?
                            '*'
                        : null}
                        </Form.Label>
                    : null}

                    { isTextarea ? 
                        <Form.Control 
                            id={key} 
                            as="textarea" 
                            required={required} 
                            disabled={readOnly} 
                            readOnly={readOnly} 
                            rows={3} 
                            value={this.state.item[key]} 
                            onChange={(e) => this.handleInputChange(e, key)} />
                    : isDelimitedItems ? 
                        <Form.Control 
                            id={key} 
                            as="textarea" 
                            required={required} 
                            disabled={readOnly} 
                            readOnly={readOnly} 
                            rows={3} 
                            value={this.state.item[key].join('\n')} 
                            onChange={(e) => this.handleDelimitedItemsChange(e, key)} />
                    : isCheckbox ?
                        <Form.Check 
                            id={key} 
                            type="checkbox" 
                            required={required} 
                            disabled={readOnly} 
                            readOnly={readOnly} 
                            custom 
                            label={key} 
                            checked={this.state.item[key] === this.config[key].values.true} 
                            onChange={(e) => this.handleCheckboxChange(e, key)} />
                    : isDropdown ?
                        <Form.Control 
                            id={key}
                            as="select" 
                            required={required} 
                            disabled={readOnly} 
                            readOnly={readOnly} 
                            custom 
                            value={this.state.item[key]} 
                            onChange={(e) => this.handleInputChange(e, key)}>
                                <option value="">-- Select --</option>
                                {this.config[key].values.map(val => 
                                    <option value={val} selected={val===this.state.item[key]}>{val}</option>
                                )}
                        </Form.Control>
                    : isImageUploader ?
                        <Form.Row>
                            <Form.Group as={Col}>
                                <CMSImageUploader
                                    width={600}
                                    height={378}                           
                                    disabled={this.state.readOnly} 
                                    readOnly={this.state.readOnly} 
                                    label="Card Image" 
                                    doNotCrop={true}
                                    onOk={(croppedImage) => this.getCroppedImage(croppedImage, 'cardImage')}
                                />
                            </Form.Group>
                                {(croppedImages.cardImage && croppedImages.cardImage.url) || cardImage ?
                                <Form.Group style={{width:'50%', textAlign: 'right'}}>
                                    <Image fluid src={croppedImages.cardImage ? croppedImages.cardImage.url : `${cardImage}?timestamp=${Date.now()}`} />
                                </Form.Group>
                            : null}
                        </Form.Row>
                    : 
                        <Form.Control 
                            id={key} 
                            as="input" 
                            required={required} 
                            readOnly={readOnly} 
                            value={this.state.item[key]} 
                            onChange={(e) => this.handleInputChange(e, key)} />
                    }
                </Form.Group>
            }

            return null
        })

        return <div>
            {this.props.show ?
                <Form onSubmit={(e) => this.handleSubmit(e)}>

                    { this.state.isAdmin ?
                        <div>
                            {fields}
                        </div>
                    : null}

                    <Form.Group>
                        <Button disabled={ this.state.processing || this.state.readOnly } type="submit">
                            {this.props.mode === 'create' ? 
                                <span>{this.props.btnText??'Create Card'}</span>
                            : this.props.mode === 'update' ?
                                <span>{this.props.btnText??'Update Card'}</span>
                            : null}
                            &nbsp;
                            {this.state.processing ?
                                <Spinner animation="border" size="sm" />
                            : null}
                        </Button>
                    </Form.Group>

                </Form>
                : null}
        </div>
            ;
    }
}

export default Card
