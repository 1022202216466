import React, { Component } from 'react'

import { API, Auth, graphqlOperation } from 'aws-amplify'
import { Button } from 'react-bootstrap'

import { getMerchant } from '../../graphql/queries'
import OutletModel from '../../models/OutletModel'
import * as utils from '../../utils'

import { CSVReader } from 'react-papaparse'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

import { backOff } from "exponential-backoff"
export default class CMSUploadOutlet extends Component {
    
    buttonRef = React.createRef()

    constructor(props) {
        super(props)

        this.state = {
            data: []
        }
    }

    handleOnDrop = (data) => {
        console.log('---------------------------')
        console.log(data)
        console.log('---------------------------')
       
        this.setState({
            data: data.map(row => row.data)

        })
    }

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
    }

    handleOnRemoveFile = (data) => {
        console.log('---------------------------')
        console.log(data)
        console.log('---------------------------')
        this.setState({
            data: []
        })
    }

    async save(e) {
        const merchantId = this.props.merchantId
        const merchant = (await API.graphql(graphqlOperation(getMerchant, {merchant_id: merchantId}))).data.getMerchant
        console.log(merchant)

        let promises = this.state.data.map(async (outletInfo) => {
            console.log('outletInfo', outletInfo)
            outletInfo.mcc = outletInfo.mcc.split('\n')
            outletInfo.subc_online = (outletInfo.subc_online && outletInfo.subc_online.toLowerCase() === 'y')?'TRUE':'FALSE'
            outletInfo.valid = (outletInfo.valid && outletInfo.valid.toLowerCase() === 'y')?'Y':'N'
            if (outletInfo.address.trim() !== '') {
                // IMPT :: should not have body object, otherwise api will throw InvalidSignatureException
                // http://5.9.10.113/48086977/aws-amplify-react-native-throwing-403-invalidsignatureexception-when-passing-da
                const geocodeResult = await backOff(() => API.get('GAPI', `/geocode`, {
                    queryStringParameters: {
                        address: outletInfo.address
                    }
                }))

                if (geocodeResult.status === 'OK') {
                    console.log('geocodeResult', geocodeResult)
                    const latlng = geocodeResult.results[0].geometry.location
                    outletInfo.latitude = latlng.lat
                    outletInfo.longitude = latlng.lng
                    utils.updateGeohash(outletInfo)
                }
            }
            
            return (new Promise(async (resolve, reject) => {
                const outletModel = new OutletModel(outletInfo, merchant)
                const result = await outletModel.create()
                console.log('outletModel create', result)
                resolve(result)
            }))
        })

        const result = await Promise.all(promises)
        console.log(result)
        if (this.props.onSave) {
            this.props.onSave(result)
        }

        if (this.buttonRef.current) {
            this.buttonRef.current.removeFile(e)
        }
    }

    columns = ['outlet_name', 'address', 'postal_code', 'phone', 'mcc', 'subc_online', 'valid']

    render() {
        return (
            <div>
                <CSVReader
                    config={{
                        header: true,
                        skipEmptyLines: 'greedy'
                    }}
                    ref={this.buttonRef}
                    onDrop={this.handleOnDrop}
                    onError={this.handleOnError}
                    addRemoveButton
                    removeButtonColor='#659cef'
                    onRemoveFile={this.handleOnRemoveFile}
                >
                    <span>Drop .csv file here or click to upload.</span>
                </CSVReader>

                {this.state.data && this.state.data.length > 0 ?
                    <div className="ag-theme-alpine" style={{height: 200}}>
                        <Button onClick={(e) => this.save(e)}>Save</Button>
                        <AgGridReact
                            rowData={this.state.data}>
                                {this.columns.map(field =>
                                    <AgGridColumn field={field}></AgGridColumn>
                                )}
                        </AgGridReact>
                    </div>
                : null}
            </div>
        )
    }
}