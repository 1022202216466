import Model from './Model'
export default class CardModel extends Model {

    constructor(item) {
        super(item, 'CardMaster', ['country', 'valid'], ['False', 'True'], 
            {
                annual_fee: null,
                annual_fee_waiver: null,
                bank: null,
                card_detail_id: null,
                card_rep_fee: null,
                card_type: null,
                cash_adv_fee: null,
                cash_adv_int: null,
                ccbuddy_card_name: '',
                cont_payment: '',
                country: 'SGP',
                cred_limit: null,
                deal_handler: [],
                exceed_fee: null,
                extra_doc: null,
                feature: null,
                feature1: null,
                feature1_type: null,
                feature2: null,
                feature2_type: null,
                feature3: null,
                feature3_type: null,
                feature_cnt: null,
                for_trans_fee: null,
                interest: null,
                interest_free: null,
                image_url: null,
                late_fee: null,
                max_income_sp_num: null,
                min_age: null,
                min_income_for: null,
                min_income_sp: null,
                min_income_sp_num: null,
                min_pay: null,
                min_spend: null,
                mob_payment: null,
                payment_network: '',
                proof_identity: null,
                proof_sal_emp: null,
                proof_self_emp: null,
                rtob_links: null,
                singsaver_name: null,
                six_interest: null,
                six_interest_online: null,
                six_process: null,
                six_process_online: null,
                supp_card_annual_fee: null,
                supp_card_annual_fee_waiver: null,
                three_interest: null,
                three_interest_online: null,
                three_process: null,
                three_process_online: null,
                twelve_interest: null,
                twelve_interest_online: null,
                twelve_process: null,
                twelve_process_online: null,
                valid: 'True',
                welcome_offers: [],
                addCardsVisible: null,
                applyNowVisible: null,
                cardspal_rating: null,
                compareNowVisible: 1,
                ctaButtonText: null,
                htmlContent: null,
                promo_ranking: null
            },
            ['cardParams']
        )
    }
}
