import React, { Component } from 'react'
import { Form, Button, Col, Spinner } from "react-bootstrap"

import { publish } from '../widgets/CMSModal'
// import CMSSubCat from '../widgets/CMSSubCat'
import ListMalls from '../widgets/ListMalls'
import ACL from '../../utils/ACL'

import OutletModel from '../../models/OutletModel'
import DealModel from '../../models/DealModel'
import CMSPostalCode from './CMSPostalCode'

import * as utils from '../../utils'
class Outlet extends Component {

    protectedAttributes = ['phone', 'outletWebsite']

    constructor(props) {
        super(props)
        const outlet = props.outlet ? { ...props.outlet } : props.merchant ? {
            merchant_id: props.merchant.merchant_id,
            mainCat: props.merchant.mainCat,
            display_merchant_name: props.merchant.display_merchant_name,
            google_type: props.merchant.google_type,
            merchant_name: props.merchant.merchant_name,
            merchant_namelist: JSON.stringify([props.merchant.merchant_name]),
            merchant_name_lowercase: props.merchant.merchant_name_lowercase,
            merchantImageUrlV2: props.merchant.merchantImageUrlV2,
            // online_company: props.merchant.online_company, // no longer needed
        } : {}
        const defaultImageUrl = `https://assets.${utils.getEnv()}.cardspal.com/DefaultImages/MerchantImages/defaultMerchant.png`
        this.state = {
            item: {
                merchant_unique_id: outlet.merchant_unique_id??null,
                store_id: outlet.store_id??null,
                merchant_id: outlet.merchant_id??null,
                mainCat: outlet.mainCat??'Food',
                display_merchant_name: outlet.display_merchant_name??'',
                google_type: outlet.google_type??'[]',
                mallId: outlet.mallId??null,
                merchant_name: outlet.merchant_name??'',
                merchant_namelist: outlet.merchant_namelist??JSON.stringify([outlet.merchant_name]),
                merchant_name_lowercase: outlet.merchant_name_lowercase??(outlet.merchant_name?outlet.merchant_name.toLowerCase():''),
                // online_company: outlet.online_company??'FALSE', // no longer needed
                subcategory: outlet.subcategory??'',
                geohashlong: outlet.geohashlong??'',
                geohashshort: outlet.geohashshort??'',
                geohashsix: outlet.geohashsix??'',
                google_id: outlet.google_id??'',
                google_placeid: outlet.google_placeid??'',
                image_src: outlet.image_src??'[]',
                image_path: outlet.image_path??'',
                merchantImageUrlV2: outlet.merchantImageUrlV2??'',
                defaultImageUrl: defaultImageUrl??'',
                outlet_name: outlet.outlet_name??'',
                general_location: outlet.general_location??'',
                address: outlet.address??'',
                phone: outlet.phone??'',
                latitude: outlet.latitude??'null',
                longitude: outlet.longitude??'null',
                postal_code: outlet.postal_code??null,
                postal_district: outlet.postal_district??'',
                postal_district_name: outlet.postal_district_name??'',
                postal_sector: outlet.postal_sector??'',
                subc_online: outlet.subc_online??'FALSE',
                businessSummary: outlet.businessSummary??'',
                outletWebsite: outlet.outletWebsite??'',
                valid: outlet.valid??'Y',
                mcc: outlet.mcc??[],
                protectedAttributes: outlet.protectedAttributes?JSON.parse(outlet.protectedAttributes):{},
                blkAptNum: outlet.blkAptNum??'',
                roadName: outlet.roadName??'',
                country: outlet.country??'Singapore',
                buildingName: outlet.buildingName??'',
                unitNum: outlet.unitNum??'',
                type: outlet.type??'',
                hideOperatingHours: outlet.hideOperatingHours??'0'
            },
            invalidBlkAptNo: false,
            invalidRoadName: false,
            invalidUnitNum: false,
            invalidPostalCode: false,
            processingLngLat: false,
            invalidInputs: false
        }
    }

    async componentDidMount() {
        if (!this.state.item.postal_code && await ACL.isPostal()) {
            console.log('groups', await ACL.getPostalsFromGroups())
            this.state.item.postal_code = (await ACL.getPostalsFromGroups())[0]
            console.log('outlet mount', this.state.item)
            this.setState({
                item: this.state.item
            })
        }

        this.setState({
            isAdmin: await ACL.isAdmin(),
            readOnly: (this.props.mode !== 'create') && !(await ACL.canWriteOutlet(this.state.item))
        })       
    }

    handleChange(e, fieldname) {
        const item = { ...this.state.item }

        if (e.target.type === 'checkbox') {
            if (fieldname === 'valid') {
                item[fieldname] = e.target.checked ? 'Y' : 'N'
            } else if (fieldname === 'hideOperatingHours') {
                item[fieldname] = e.target.checked ? '1' : '0'
            } else {
                item[fieldname] = e.target.checked ? 'True' : 'False';
            }
        } else {
            item[fieldname] = e.target.value
        }

        if (fieldname === 'latitude' || fieldname === 'longitude') {
            utils.updateGeohash(item)
        }

        if (fieldname === 'mcc') {
            // empty string split will return [""]. use filter to filter empty string. will also help in filtering "" elements due to empty lines in textbox
            item.mcc = e.target.value.split('\n').filter((s) => s.trim()!=='')
        }

        let blkAptNum = item.blkAptNum || ''
        let roadName =  item.roadName ? ', ' + item.roadName : ''
        let buildingName = item.buildingName ? ', ' + item.buildingName : ''
        let unitNum = item.unitNum ? ', ' + item.unitNum : ''
        let country =  item.address ? ', Singapore' : ''
        let postal_code = item.postal_code ? ' ' + item.postal_code : ''
        
        let combineAddress = blkAptNum.concat(roadName, buildingName, unitNum, country, postal_code)
        let address = blkAptNum.length > 0 ? combineAddress : combineAddress.replace(', ', '') //remove extra comma
        item.address = address
        
        if (e.target.type === 'checkbox') {
            if (fieldname === 'subc_online') {
                if (e.target.checked) {
                    this.clearFields(item)
                }
            }
            
        }

        if (this.protectedAttributes.includes(fieldname)) {
            item.protectedAttributes[fieldname] = '1'
        }

        this.setState({ item: item })
    }

     clearFields = (item) => {
        item.blkAptNum = ''
        item.roadName = ''
        item.buildingName = ''
        item.unitNum = ''
        item.country = ''
        item.postal_code = ''
        item.address = ''

        item.geohashlong = '';
        item.geohashshort = '';
        item.geohashsix = '';
        item.latitude = 'null'
        item.longitude = 'null'

        this.setState({ invalidBlkAptNo: false, invalidPostalCode: false, invalidRoadName: false, invalidUnitNum: false})
    }

    handleAddressChange(address, latlng) {
        const item = { ...this.state.item}
        item.address = address
        console.log('latlng', latlng)

        // if address is removed, then need to reset geohashlong, geohashshort, geohashsix
        if (!address) {
            item.geohashlong = '';
            item.geohashshort = '';
            item.geohashsix = '';
            item.latitude = 'null'
            item.longitude = 'null'
        } else if (latlng) {
            item.latitude = latlng.lat
            item.longitude = latlng.lng
            utils.updateGeohash(item)
        }
        this.setState({
            item: item
        })
    }

    handlePostalCodeChange(postal_code, latlng, e) {
        const item = { ...this.state.item}
        let address = item.address ?  item.address.replace(item.postal_code, '') :''
        item.address = address.trim().concat(' ', postal_code)
        item.postal_code = postal_code
        // if postal_code is removed, then need to reset geohashlong, geohashshort, geohashsix
        if (!postal_code) {
            item.geohashlong = '';
            item.geohashshort = '';
            item.geohashsix = '';
            item.latitude = 'null'
            item.longitude = 'null'
            //item.postal_code = ''
        } else if (latlng) {
            item.latitude = latlng.lat
            item.longitude = latlng.lng
            utils.updateGeohash(item)
            this.setState({ invalidPostalCode: false })
            //item.postal_code = postal_code
        }
        
        this.setState({
            item: item
        })
    }

    async handleSubmit(e) {
        e.preventDefault()
        e.stopPropagation()

        const { invalidBlkAptNo, invalidPostalCode, invalidUnitNum, invalidRoadName } = this.state

        if (!invalidBlkAptNo && !invalidPostalCode && !invalidUnitNum && !invalidRoadName){
            this.setState({
                processing: true
            })

            try {
                if (this.props.mode === 'create') {
                    await this.handleCreate(e)
                } else if (this.props.mode === 'update') {
                    await this.handleUpdate(e)
                }
            } catch(error) {
                console.error(error)
                this.alert('Error processing Outlet')
            }
        }
    }

    async handleCreate() {
        const outletModel = new OutletModel(this.state.item)
        const createdOutlet = await outletModel.create()

        this.alert('Outlet Created', () => {
            if (this.props.onCreate) {
                this.props.onCreate(createdOutlet)
            }
        })
    }

    async handleUpdate(e) {
        const outletModel = new OutletModel(this.state.item)
        const updatedOutlet = await outletModel.update()

        // update merchant info into deals
        const deals = updatedOutlet.deals.items
        for (const deal of deals) {
            // ONLY automatically invalidates all deals when outlet is invalidated but leave it when user make it valid again - DP-1912
            if (outletModel.item.valid === 'N'){
                deal.valid = outletModel.item.valid
            }
            const dealModel = new DealModel(deal)
            dealModel.updateOutletInfo(this.state.item)
        }

        this.alert('Outlet Updated', () => {
            if (this.props.onUpdate) {
                this.props.onUpdate(null, updatedOutlet)
            }  
        })
    }

    async onSelectMall(mallId) {
        this.state.item.mallId = mallId
        this.setState({
            item: this.state.item
        })
    }

    alert(msg, callback) {
        this.setState({
            processing: false
        })
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    inputValidity = (fieldname) =>{

        switch (fieldname) {
            case 'blkAptNum':
                let blkAptNum = this.state.item.blkAptNum
                let regexp_blkAptNum =  new RegExp('^[A-Za-z0-9 ]+$')
                let pattern = regexp_blkAptNum.test(blkAptNum);
                this.setState({ invalidBlkAptNo: !pattern })
                break;

            case 'roadName':
                let roadName = this.state.item.roadName
                this.setState({ invalidRoadName: roadName.length < 4 ? true : false })
                break;

            case 'unitNum':
                let unitNum = this.funcValidateUnitNum(this.state.item.unitNum)
                console.log(this.state.item.subc_online)
                if(this.state.item.unitNum.length === 0){
                    this.setState({ invalidUnitNum: false })
                }else{
                    this.setState({ invalidUnitNum: !unitNum })
                }
                
                break;

            case 'postal_code':
                let postalCode = this.state.item.postal_code
                console.log('postalCode', postalCode)
                if(postalCode?.length >=6){
                    if(isNaN(postalCode)){
                        this.setState({ invalidPostalCode: true })
                    }else{
                        this.setState({ invalidPostalCode: false })
                    }

                }else{
                    this.setState({ invalidPostalCode: true })
                }
                
                break;

            default:
        }
    }

    renderInvalidLabel = (text) => (
        <span style={{ color:'#EC536C', fontSize: 12 }}>{text}</span>
    )

    funcValidateUnitNum = (unitNum) => {
        if (unitNum) {
            let splitUnit = unitNum.split("-")
            let getNum = splitUnit[0].replace(/\D/g, '')
            
            if (getNum.length <= 2) {
                return true
            }else{
                return false
            }
        }   
    }

    render() {
        const { item } = this.state
        return <div>
            {this.props.show ?
                <Form onSubmit={(e) => this.handleSubmit(e)}>

                    {/* <h3>{this.props.title??this.props.mode==='create'?'Create Outlet':'Update Outlet'}</h3> */}

                    {this.state.isAdmin ?
                        <div>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Merchant Unique Id</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.merchant_unique_id} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Store Id</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.store_id} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Merchant Id</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.merchant_id} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Merchant Name</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.merchant_name} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Merchant Name Lowercase</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.merchant_name_lowercase} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Group>
                                <Form.Label>Display Merchant Name</Form.Label>
                                <Form.Control type="text" disabled readOnly value={this.state.item.display_merchant_name} />
                            </Form.Group>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Deals Category</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.mainCat} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Google Type</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.google_type} />
                                </Form.Group>
                            </Form.Row>
                            {/* <Form.Group>
                                <Form.Check label="Online Company" disabled readOnly checked={this.state.item.online_company.toUpperCase() === 'Y'} />
                            </Form.Group> */}
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Geo Hash Long</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.geohashlong} onChange={(e) => this.handleChange(e, 'geohashlong')} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Geo Hash Short</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.geohashshort} onChange={(e) => this.handleChange(e, 'geohashshort')} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Geo Hash Six</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.geohashsix} onChange={(e) => this.handleChange(e, 'geohashsix')} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Group>
                                <Form.Label>Sub Category</Form.Label>
                                <Form.Control type="text" value={this.state.item.subcategory} onChange={(e) => this.handleChange(e, 'subcategory')} />
                            </Form.Group>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Google Id</Form.Label>
                                    <Form.Control type="text" value={this.state.item.google_id} onChange={(e) => this.handleChange(e, 'google_id')} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Google Place Id</Form.Label>
                                    <Form.Control type="text" value={this.state.item.google_placeid} onChange={(e) => this.handleChange(e, 'google_placeid')} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Group>
                                <Form.Label>Image Src</Form.Label>
                                <Form.Control type="text" value={this.state.item.image_src} onChange={(e) => this.handleChange(e, 'image_src')} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Image Path</Form.Label>
                                <Form.Control type="text" value={this.state.item.image_path} onChange={(e) => this.handleChange(e, 'image_path')} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Merchant Name List</Form.Label>
                                <Form.Control type="text" value={this.state.item.merchant_namelist} onChange={(e) => this.handleChange(e, 'merchant_namelist')} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>General Location</Form.Label>
                                <Form.Control type="text" value={this.state.item.general_location} onChange={(e) => this.handleChange(e, 'general_location')} />
                            </Form.Group>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label >Postal District</Form.Label>
                                    <Form.Control type="text" value={this.state.item.postal_district} onChange={(e) => this.handleChange(e, 'postal_district')} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label >District Name</Form.Label>
                                    <Form.Control type="text" value={this.state.item.postal_district_name} onChange={(e) => this.handleChange(e, 'postal_district_name')} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label >Postal Sector</Form.Label>
                                    <Form.Control type="text" value={this.state.item.postal_sector} onChange={(e) => this.handleChange(e, 'postal_sector')} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Latitude</Form.Label>
                                    <Form.Control type="text" value={this.state.item.latitude} onChange={(e) => this.handleChange(e, 'latitude')} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Longitude</Form.Label>
                                    <Form.Control type="text" value={this.state.item.longitude} onChange={(e) => this.handleChange(e, 'longitude')} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Group>
                                <Form.Label>Mall</Form.Label>
                                <ListMalls mallId={this.state.item.mallId} onSelect={(mallId) => this.onSelectMall(mallId)} />
                            </Form.Group>
                        </div>
                    : null}

                    <Form.Group>
                        <Form.Check type="checkbox" id="subc_online" custom label="Online Outlet" disabled={this.state.readOnly} readOnly={this.state.readOnly} checked={this.state.item.subc_online.toUpperCase() === 'TRUE'} onChange={(e) => this.handleChange(e, 'subc_online')} />
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col} lg={6} xl={6} md={12}>
                            <Form.Label >Outlet Name *</Form.Label>
                            <Form.Control required type="text" disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.outlet_name} onChange={(e) => this.handleChange(e, 'outlet_name')} />
                        </Form.Group>
                        <Form.Group as={Col} lg={6} xl={6} md={12}>
                            <Form.Label >Outlet Type [purchasable, events, etc]</Form.Label>
                            <Form.Control type="text" disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.type} onChange={(e) => this.handleChange(e, 'type')} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Address</Form.Label>
                            <Form.Control disabled type="text" value={item.address} onChange={(e) => this.handleChange(e, 'address')} />
                        </Form.Group>
                        
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>{item.subc_online === 'True'? 'BLK/Apt No.' : 'BLK/Apt No.*'}</Form.Label>
                            <Form.Control disabled={item.subc_online === 'True'? true : false} placeholder='e.g. 68 or BLK 18' required={item.subc_online === 'True'? false : true} isInvalid={this.state.invalidBlkAptNo} onBlur={()=> this.inputValidity('blkAptNum')} type="text" value={item.blkAptNum} onChange={(e) => this.handleChange(e, 'blkAptNum')} />
                            { this.state.invalidBlkAptNo && this.renderInvalidLabel('Input must be digit or alphanumeric') }
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>{item.subc_online === 'True'? 'Road Name' : 'Road Name*' }</Form.Label>
                            <Form.Control disabled={item.subc_online === 'True'? true : false} placeholder='e.g. Orchard Road' required={item.subc_online === 'True'? false : true} isInvalid={this.state.invalidRoadName} onBlur={()=> this.inputValidity('roadName')} type="text" value={item.roadName} onChange={(e) => this.handleChange(e, 'roadName')} />
                            { this.state.invalidRoadName && this.renderInvalidLabel('Input must have atleast 4 characters') }
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Building Name</Form.Label>
                            <Form.Control disabled={item.subc_online === 'True'? true : false} placeholder='e.g. Plaza Singapura' type="text" value={item.buildingName} onChange={(e) => this.handleChange(e, 'buildingName')} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label >Unit No.</Form.Label>
                            <Form.Control disabled={item.subc_online === 'True'? true : false} isInvalid={this.state.invalidUnitNum} onBlur={()=> this.inputValidity('unitNum')} placeholder='e.g. #04-17' type="text" value={item.unitNum} onChange={(e) => this.handleChange(e, 'unitNum')} />
                            { this.state.invalidUnitNum && this.renderInvalidLabel('Input seems wrong. Check unit number again') }
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Country</Form.Label>
                            <Form.Control disabled type="text" value={item.country || 'Singapore'} onChange={(e) => this.handleChange(e, 'blkAptNum')} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>{item.subc_online === 'True'? 'Postal Code' : 'Postal Code*'}</Form.Label>
                            { item.subc_online === 'True' ? 
                            <Form.Control disabled placeholder='e.g. 122354' type="text" value={item.postal_code} onChange={(postal_code, latlng) => this.handlePostalCodeChange(postal_code, latlng)} />
                            :<CMSPostalCode
                                required={item.subc_online === 'True'? false : true}
                                disabled={item.subc_online === 'True'? true : false}
                                processing={(e)=> this.setState({ processingLngLat:e })}
                                value={item.postal_code} 
                                showList={false}
                                isInvalid={this.state.invalidPostalCode} 
                                //onBlur={()=> this.inputValidity('postal_code')}
                                invalidPostalCode={(e)=>this.setState({ invalidPostalCode:e })}
                                onChange={(postal_code, latlng) => this.handlePostalCodeChange(postal_code, latlng)} 
                            /> }
                             {/* { this.state.invalidPostalCode && this.renderInvalidLabel('Input must have atleast 6 numbers') } */}
                        </Form.Group>
                    </Form.Row>


                    <Form.Group>
                        <Form.Label >MCC</Form.Label>
                        <Form.Control as="textarea" value={this.state.item.mcc.join('\n')} onChange={(e) => this.handleChange(e, 'mcc')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Business Summary</Form.Label>
                        <Form.Control as="textarea" value={this.state.item.businessSummary} onChange={(e) => this.handleChange(e, 'businessSummary')} />
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Website</Form.Label>
                            <Form.Control type="text" value={this.state.item.outletWebsite} onChange={(e) => this.handleChange(e, 'outletWebsite')} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label >Phone</Form.Label>
                            <Form.Control type="text" disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.phone} onChange={(e) => this.handleChange(e, 'phone')} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Check type="checkbox" id="valid" custom label="Visible in CardsPal App" disabled={this.state.readOnly} readOnly={this.state.readOnly} checked={this.state.item.valid.toUpperCase() === 'Y'} onChange={(e) => this.handleChange(e, 'valid')} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Check type="checkbox" id="hideOperatingHours" custom label="Hide Operating Hours" disabled={this.state.readOnly} readOnly={this.state.readOnly} checked={this.state.item.hideOperatingHours === '1'} onChange={(e) => this.handleChange(e, 'hideOperatingHours')}/>
                        </Form.Group>
                    </Form.Row>


                    <Form.Group>
                        <Button disabled={ this.state.processing || this.state.readOnly || this.state.processingLngLat } type="submit">
                            {this.props.mode === 'create' ? 
                                <span>Create Outlet</span>
                            : this.props.mode === 'update' ?
                                <span>Update Outlet</span>
                            : null}
                            &nbsp;
                            {this.state.processing ?
                                <Spinner animation="border" size="sm" />
                            : null}
                        </Button>
                    </Form.Group>
                </Form>
                : null}
        </div>
    }
}

export default Outlet