import React, { Component } from 'react'
import { Button, Form, Col, Image, Spinner, Row, InputGroup, Table } from "react-bootstrap"
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { FaTag, FaEye, FaEyeSlash } from "react-icons/fa"
import moment from 'moment'
import Datetime from 'react-datetime'
import "react-datetime/css/react-datetime.css"
import 'react-image-crop/dist/ReactCrop.css'
import { publish } from '../widgets/CMSModal'
import CMSImageUploader from './CMSImageUploader'
import CMSTag from '../widgets/CMSTag'
import ListMerchants from './ListMerchants'
import DealModel from '../../models/DealModel'
import { voucherByDealId, voucherByCode } from '../../graphql/queries'
import { createDealApproval, updateVoucherMaster, createVoucherMaster, updateDealApproval } from '../../graphql/mutations'
import * as utils from '../../utils'
import ACL from '../../utils/ACL'
import Model from '../../models/Model'
import CMSUploadCodes from  '../widgets/CMSUploadCodes'
class Deal extends Component {

    constructor(props) {
        super(props)
        const defaultPurchasableTexts = {
            preSwipeText: 'Code is for one-time use only.',
            atStoreConfirmation: {
                confirmationTitle: 'Are you at store now?',
                confirmationText: 'Make sure to redeem in the presence of an outlet staff.',
                agreeOption: 'Yes, I am!',
                denyOption: 'Not yet!'
            }
        }

        const deal = props.deal ? { ...props.deal } : props.outlet ? {
            address: props.outlet.address,
            display_merchant_name: props.outlet.display_merchant_name,
            general_location: props.outlet.general_location,
            google_id: props.outlet.google_id,
            google_placeid: props.outlet.google_placeid,
            google_type: props.outlet.google_type,
            geohashlong: props.outlet.geohashlong,
            geohashshort: props.outlet.geohashshort,
            latitude: props.outlet.latitude,
            longitude: props.outlet.longitude,
            merchant_id: props.outlet.merchant_id,
            merchant_name: props.outlet.merchant_name,
            merchant_name_lowercase: props.outlet.merchant_name_lowercase,
            merchant_unique_id: props.outlet.merchant_unique_id,
            phone: props.outlet.phone,
            postal_code: props.outlet.postal_code,
            district: props.outlet.postal_district,
            district_name: props.outlet.postal_district_name,
            sector: props.outlet.postal_sector,
            store_id: props.outlet.store_id,
            mainCat: props.outlet.mainCat,
            isPinned: props.outlet.isPinned,
            pinPageLocation: props.outlet.pinPageLocation,
            pinOrder: props.outlet.pinOrder,
            purchasable: props.outlet.purchasable,
            actualPrice: props.outlet.actualPrice,
            discount: props.outlet.discount,
            discountPrice: props.outlet.discountPrice,
            purchasePrice: props.outlet.purchasePrice,
            purchaseLimitPerCustomer: props.outlet.purchaseLimitPerCustomer,
            minQtyTx: props.outlet.minQtyTx,
            purchasableTexts: props.outlet.purchasableTexts ?? null,
            ticketType: props.outlet.ticketType
        } : {}
        
        
        if (deal.purchasableTexts && typeof deal.purchasableTexts === 'string') {
            deal.purchasableTexts = JSON.parse(deal.purchasableTexts)
        }
        
        this.state = {
            croppedImage: {},
            mode: props.mode ?? 'create',
            showApplyToOutlets: props.showApplyToOutlets,
            applyToOutlets: false,
            autoId: true,
            hasStart: (props.mode === 'create' || deal.start_timestamp),
            hasEnd: (props.mode === 'create' || deal.end_timestamp),
            isPurchasable: deal.purchasable === "1" ? true : false,
            discountPlaceHolder: deal.discount ? (deal.discount * 100).toFixed(2) : '',
            defaultPurchasableTexts,
            voucherType: 'uploaded',
            voucherStartDate: null,
            voucherEndDate: null,
            numberOfVouchersToGenerate: 1,
            loadingVouchers: false,
            generatedVouchers: [],
            uploadedVouchers: [],
            additionalPromotionInfo: deal.additionalPromotionInfo ?? [],
            item: {
                pk: deal.pk ?? utils.guidGenerator(),
                address: deal.address ?? '',
                aggregator: deal.aggregator ?? null,
                mainCat: deal.mainCat ?? 'Food',
                display_merchant_name: deal.display_merchant_name ?? '',
                general_location: deal.general_location ?? '',
                google_id: deal.google_id ?? '',
                google_placeid: deal.google_placeid ?? '',
                google_type: deal.google_type ?? '[]',
                geohashlong: deal.geohashlong ?? '',
                geohashshort: deal.geohashshort ?? '',
                latitude: deal.latitude ?? 'null',
                longitude: deal.longitude ?? 'null',
                merchant_id: deal.merchant_id ?? null,
                merchant_name: deal.merchant_name ?? '',
                merchant_name_lowercase: deal.merchant_name_lowercase??(deal.merchant_name ? deal.merchant_name.toLowerCase():''),
                merchant_unique_id: deal.merchant_unique_id ?? null,
                // online_company: deal.online_company ?? 'FALSE', // no longer needed
                phone: deal.phone ?? '',
                postal_code: deal.postal_code ?? '',
                district: deal.district ?? '',
                district_name: deal.district_name ?? '',
                sector: deal.sector ?? '',
                store_id: deal.store_id ?? null,
                bank: deal.bank ?? 'DEALS FOR EVERYONE',
                cc_buddy_category: deal.cc_buddy_category ?? 'Dining',
                ccbuddy_card_name: deal.ccbuddy_card_name ?? 'NULL',
                deal_handler: deal.deal_handler ?? 'exclusive_all',
                subcategory: deal.subcategory ?? '',
                rank: deal.rank ?? '',
                rank_updated: deal.rank_updated ?? '',
                score: deal.score ?? '',
                image_path: deal.image_path ?? '',
                usable_promocode: deal.usable_promocode ?? false,
                start: deal.start ?? '',
                end: deal.end ?? '',
                is_online: deal.is_online ?? 'False',
                valid: deal.valid ?? 'N',
                area: deal.area ?? '',
                country: deal.country ?? 'SGP',
                promotion: deal.promotion ?? '',
                promo_code: deal.promo_code ?? '',
                promotion_analytic: deal.promotion_analytic ?? '',
                promotion_caption: deal.promotion_caption ?? '',
                terms: deal.terms ?? '',
                image_url: deal.image_url ?? '',
                website: deal.website ?? '',
                brand: deal.brand ?? null,
                start_timestamp: deal.start_timestamp ?? null,
                end_timestamp: deal.end_timestamp ?? null,
                originalTags: [],
                tags: [],
                isSponsored: deal.isSponsored ?? "0",
                sponsoredRank: deal.sponsoredRank ?? "0",
                isPinned: deal.isPinned ?? "0",
                pinPageLocation: deal.pinPageLocation ?? [],
                pinOrder: deal.pinOrder ?? "0",
                purchasable: deal.purchasable ?? "0",
                actualPrice: deal.actualPrice ?? "",
                discount: deal.discount ?? "",
                discountPrice: deal.discountPrice ?? "",
                purchasePrice: deal.purchasePrice ?? "0",
                purchaseLimitPerCustomer: deal.purchaseLimitPerCustomer ?? "1",
                minQtyTx: deal.minQtyTx ?? "1",
                purchasableTexts: deal.purchasableTexts && Object.keys(deal.purchasableTexts).length > 0 ? deal.purchasableTexts : {...defaultPurchasableTexts},
                ticketType: deal.ticketType ?? "",
            },
        }
    }

    async componentDidMount() {
        
        this.config = (await (await fetch(`https://assets.${utils.getEnv()}.cardspal.com/.config/cms.config.json?timestamp=${Date.now()}`)).json())
        const {item, additionalPromotionInfo} = this.state
        this.loadOutlets()

        let promotionDetails = ''
        let terms = ''
        additionalPromotionInfo.map(item => {
            const _item = JSON.parse(item)
            if (_item.iconUrl.indexOf('notification.png') >= 0) {
                // using this icons means is the Promotion Details field; cannot used any identifier as it changes from one environment
                promotionDetails = _item.termsText
            } else {
                terms += `● ${_item.termsText}\n`
            }
        })
        
        // load voucher
        try {
            const voucherResult = await API.graphql(graphqlOperation(voucherByDealId, {dealId: this.state.item.pk}))
            item['vouchers'] = voucherResult.data.voucherByDealId.items || []

            if (voucherResult.data.voucherByDealId.items.length > 0) {
                const voucherStartDate = Number(voucherResult.data.voucherByDealId.items[0].startTimestamp)
                const voucherEndDate = voucherResult.data.voucherByDealId.items[0].endTimestamp
                const voucherType = voucherResult.data.voucherByDealId.items[0].codeType
                const numberOfVouchersToGenerate = voucherResult.data.voucherByDealId.items.length
                this.setState({item, voucherStartDate, voucherEndDate, voucherType, numberOfVouchersToGenerate, promotionDetails, terms})
            } else {
                this.setState({item, promotionDetails, terms})
            }

        } catch(voucherError) {
            console.error(voucherError)
        }

        const g = await ACL.getGroups()
        if (await ACL.isBank(g)) {
            const bank = (await ACL.getBanksFromGroups(g))[0]
            const deal_handler = bank.toLowerCase()+'_all'
            
            this.state.item.bank = bank
            this.state.item.deal_handler = deal_handler
        }

        this.loadTags()

        this.setState({
            isAdmin: await ACL.isAdmin(g),
            readOnly: (this.props.mode !== 'create') && !(await ACL.canWriteDeal(this.state.item, g))
        })

        if (!this.state.item.aggregator && await ACL.isAggregator(g)) {
            this.state.item.aggregator = (await ACL.getAggregatorsFromGroups(g))[0]
            this.setState({
                item: this.state.item
            })
        }
    }

    async loadOutlets() {
        const selectedOutletId = this.state.item.store_id
        let selectedOutlet = {}
        let otherOutlets = []
        let outlets = await Model.listAll('MerchantOutlet', {
            merchant_id: this.state.item.merchant_id
        })
        
        if (outlets && outlets.length > 0) {
            //filter out selected outlet and other outlet not this deal outlet
            outlets.forEach(o => {
                if (o.store_id !== selectedOutletId) {
                    otherOutlets.push(o)
                } else {
                    selectedOutlet = o
                }
            })
            // if (selectedOutletId) {
            //     outlets = outlets.filter(o => o.store_id !== selectedOutletId); // we get the other outlets, not this deal outlet
            // }

            //update apply to outlets list to default checked
            for (const o of outlets) {
                o.applySelected = true;
            }
        }
        
        this.setState({
            outlets: otherOutlets,
            selectedOutlet
        });
    }

    async loadTags() {
        try {
            if (this.state.item.pk) {
                // load tags
                // const search = {
                //     id: this.state.item.pk
                // }
                // let listTags = [];
                // do {
                //     const result = await API.graphql(graphqlOperation(tagById, search))
                //     listTags = listTags.concat(result.data.tagById.items)
                //     search.nextToken = result.data.tagById.nextToken
                // } while (search.nextToken);

                // listTags = listTags.map(tag => tag.tagName)
                const listTags = await new DealModel(this.state.item).getTags()
                this.state.item.originalTags = listTags
                this.state.item.tags = listTags
                this.setState({
                    item: this.state.item
                });
            }
        } catch (err) {
            console.error(err);
        }        
    }

    handleAutoIdChange(e) {
        const pk = e.target.checked ? utils.guidGenerator() : this.state.item.pk
        const item = {...this.state.item, pk}

        this.setState({
            autoId: e.target.checked,
            item
        })
    }

    handleHasStartChange(e) {
        const hasStart = !e.target.checked
        if (hasStart) {
            this.state.item.start_timestamp = this.state.tempStart
        } else {
            this.setState({
                tempStart: this.state.item.start_timestamp,
            })
            this.state.item.start_timestamp = null
        }
        this.setState({
            item: this.state.item,
            hasStart: hasStart
        })
    }

    handleHasEndChange(e) {
        const hasEnd = !e.target.checked
        if (hasEnd) {
            this.state.item.end_timestamp = this.state.tempEnd
        } else {
            this.setState({
                tempEnd: this.state.item.end_timestamp
            })
            this.state.item.end_timestamp = null
        }
        this.setState({
            item: this.state.item,
            hasEnd: hasEnd
        })
    }

    handleDateTime(m, fieldname) {
        let {item, voucherStartDate, voucherEndDate} =  this.state

        if (typeof m === 'string') {
            item[fieldname] = moment(m, 'YYYY-MM-DD HH:mm:ss').unix()
        } else {
            item[fieldname] = m.unix()
        }

        // don't allow changing the redemption dates when in update mode
        if (this.state.mode==='create') {
            if (fieldname==='start_timestamp') {
                voucherStartDate = item[fieldname] //follow the deal start date
            } else if (fieldname==='end_timestamp') {
                voucherEndDate = moment(m).add(24, 'hours').unix(); 
            }
        }

        this.setState({
            item: item,
            voucherStartDate,
            voucherEndDate
        })
    }

    handleVoucherRedemptionDateTime(m, fieldname, index) {
        const item = { ...this.state.item }

        if (item.vouchers) {
            if (typeof m === 'string') {
                item.vouchers[index][fieldname] = moment(m, 'YYYY-MM-DD HH:mm:ss').unix()
            } else {
                item.vouchers[index][fieldname] = m.unix()
            }

            this.setState({
                item: item
            })
        }
    }

    handleVoucherDateTime(m, fieldname) {
        let {voucherStartDate, voucherEndDate} = this.state

            let dt
            if (typeof m === 'string') {
                dt = moment(m, 'YYYY-MM-DD HH:mm:ss').unix()
            } else {
                dt = m.unix()
            }

            if (fieldname==='voucherStartDate'){
                voucherStartDate = dt
            } else if (fieldname==='voucherEndDate'){
                voucherEndDate = dt
            }

            this.setState({voucherStartDate, voucherEndDate})
        
    }
    

    handleChange(e, fieldname) {
        const target = e.target
        const item = { ...this.state.item }
        let {voucherType, numberOfVouchersToGenerate, discountPlaceHolder, voucherStartDate, voucherEndDate, isPurchasable} = this.state
        const purchasableTextFields = ['preSwipeText', 'confirmationTitle', 'confirmationText', 'agreeOption', 'denyOption']
        
        if (target.type === 'checkbox') {
            if (fieldname === 'valid') {
                item[fieldname] = e.target.checked ? 'Y' : 'N'
            } else if (fieldname === 'isSponsored' || fieldname === 'purchasable'){
                item[fieldname] = e.target.checked ? '1' : '0'
                if (fieldname === 'purchasable'){
                    isPurchasable = item[fieldname] === '1'  ? true : false
                }
            } else if (fieldname === 'usable_promocode') {
                item[fieldname] = e.target.checked
            } else {
                item[fieldname] = e.target.checked ? 'True' : 'False'
            }
        } else if (target.type === 'date') {
            item[fieldname] = e.target.value.split('-').reverse().join('/')
        } else if (target.type === 'datetime-local') {
            item[fieldname] = moment(e.target.value).unix()
        } else if (fieldname === 'minQtyTx') {
            const re = /^\d+$/
            item[fieldname] = re.test(e.target.value) ? e.target.value : item[fieldname]
        } else if (fieldname === 'discount') {
            const re = /^[0-9]*\.?[0-9]*$/
            item[fieldname] = re.test(e.target.value) ? e.target.value/100 : item[fieldname]
            discountPlaceHolder = re.test(e.target.value) ? e.target.value : discountPlaceHolder
        } else if (fieldname === 'actualPrice'){
            const re = /^[0-9]*\.?[0-9]*$/
            item[fieldname] = re.test(e.target.value) ? e.target.value : item[fieldname]
        } else if (purchasableTextFields.includes(fieldname)) {
            if (fieldname === 'preSwipeText') {
                item['purchasableTexts'][fieldname] = e.target.value
            } else {
                if (!item['purchasableTexts']['atStoreConfirmation']) item['purchasableTexts'] = {'atStoreConfirmation' : {}}
                item['purchasableTexts']['atStoreConfirmation'][fieldname] = e.target.value
            }
        } else if (fieldname==='voucherType'){
            voucherType = target.value
        } else if (fieldname==='numberOfVouchersToGenerate') {
            const re = /^[0-9]*$/
            numberOfVouchersToGenerate = re.test(target.value) ? target.value : numberOfVouchersToGenerate
        } else {
            item[fieldname] = target.value
        }

        if (fieldname === 'promotion_caption') {
            item['promotion_analytic'] = item[fieldname]
        }

        if (fieldname === 'actualPrice' || fieldname === 'discount') {
            item['purchasePrice'] = (item['actualPrice'] - (item['actualPrice']  * item['discount'])).toFixed(2)
            item['discountPrice'] = (item['actualPrice'] * item['discount']).toFixed(2)
        }

        this.setState({ item: item, isPurchasable, discountPlaceHolder, voucherType, numberOfVouchersToGenerate, voucherStartDate, voucherEndDate })
    }

    handleTagChange(tags) {
        this.state.item.tags = tags
        this.setState({
            item: this.state.item
        })
    }

    async handleSubmit(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            processing: true
        })

        if (this.state.item.purchasable==="1" && (!this.state.item.vouchers || this.state.item.vouchers.length===0)) {
            this.alert('Purchasable deals should have voucher codes. Upload or Generate them')
            return
        }

        if (this.state.item.minQtyTx && (this.state.item.minQtyTx < 1 || this.state.item.minQtyTx > this.state.item.purchaseLimitPerCustomer)) {
            this.alert('Invalid minimum qty per customer input. It should be between 1 and purchase limit per customer')
            return
        }
        
        if (this.state.item.purchasable === '1') {
            this.state.item.actualPrice = parseFloat(this.state.item.actualPrice).toFixed(2)
            this.state.item.discount = parseFloat(this.state.item.discount).toFixed(2)
            this.state.item.discountPrice = parseFloat(this.state.item.discountPrice).toFixed(2)
            this.state.item.purchasePrice = parseFloat(this.state.item.purchasePrice).toFixed(2)
        } else {
            this.state.item.actualPrice = ''
            this.state.item.discount = ''
            this.state.item.discountPrice = ''
            this.state.item.purchasePrice = ''
        }

        try {
            if (this.props.mode === 'create') {
                await this.handleCreate()
            } else if (this.props.mode === 'update') {
                await this.handleUpdate()
            }
        } catch (error) {
            console.error(error);
            this.alert('Error processing Deal')
        }
    }

    async createDeal(deal) {
        const dealModel = new DealModel(deal)
        const createdDeal = await dealModel.create()
        const pk = createdDeal.pk
        const path = `DealImages/${deal.merchant_unique_id}/${pk}${this.state.croppedImage.ext}`
         
        deal.pk = pk
        deal.image_url = utils.getAssetUrl(path)

        const dealModel2 = new DealModel(deal)
        const updatedDeal = await dealModel2.update()
        await this.handleImageUpload(path)

        // TODO :: move this to lambda
        if (!this.state.isAdmin) {
            const resultApproval = await API.graphql(graphqlOperation(createDealApproval, { input: { pk: pk, isApproved: false } }))
            const approval = resultApproval.data.createDealApproval
            updatedDeal.approval = approval

            const createdYearMonth = approval.createdAt.substring(0, 7)    
            API.graphql(graphqlOperation(updateDealApproval, {input: {pk: approval.pk, createdYearMonth: createdYearMonth}}))

        }

        {
            const result = await dealModel2.setTags(deal.originalTags, deal.tags)
        }

        return updatedDeal;
    }

    createDealInfoFromOutlet(outlet, masterDealInfo) {
        const deal = {
            ...masterDealInfo,
            address: outlet.address,
            mainCat: outlet.mainCat,
            display_merchant_name: outlet.display_merchant_name,
            general_location: outlet.general_location,
            google_id: outlet.google_id,
            google_placeid: outlet.google_placeid,
            google_type: outlet.google_type,
            geohashlong: outlet.geohashlong??'',
            geohashshort: outlet.geohashshort??'',
            latitude: outlet.latitude,
            longitude: outlet.longitude,
            merchant_id: outlet.merchant_id,
            merchant_name: outlet.merchant_name,
            merchant_name_lowercase: outlet.merchant_name_lowercase,
            merchant_unique_id: outlet.merchant_unique_id,
            phone: outlet.phone,
            postal_code: outlet.postal_code,
            district: outlet.postal_district,
            district_name: outlet.postal_district_name,
            sector: outlet.postal_sector,
            store_id: outlet.store_id,
            pk: utils.guidGenerator()
        };
        if (outlet.mainCat) {
            deal.mainCat = outlet.mainCat;
        }
        delete deal.image_url;


        return deal;
    }

    async handleCreate() {
        // we will remove the purchasableTexts from schema when its not a purchasable deal
        const deal = { ...this.state.item }
        if (deal.purchasable === '0' || deal.is_online==='True') delete deal.purchasableTexts
        else deal.purchasableTexts = JSON.stringify(this.state.item.purchasableTexts)
        // console.log('handleCreate ', deal)
        const result = await this.createDeal(deal);

        //apply to other outlets
        if (this.state.applyToOutlets) {
            const outlets = this.state.outlets;
            
            for (const outlet of outlets) {
                if (outlet.applySelected) {
                    const dealInfoOutlet = this.createDealInfoFromOutlet(outlet, deal);
                    // console.log('apply multi', dealInfoOutlet)
                    await this.createDeal(dealInfoOutlet);
                }
            }
        }

        if (this.state.item.vouchers && this.state.item.vouchers.length >0) {
            this.state.item.vouchers.forEach((voucher) => {
                API.graphql(graphqlOperation(createVoucherMaster, {input: {
                    id: voucher.id,
                    code: voucher.code,
                    codeType: voucher.codeType,
                    dealId: voucher.dealId,
                    status: "0",
                    valid: "1",
                    startTimestamp: voucher.startTimestamp,
                    endTimestamp: voucher.endTimestamp
                }}))
            })
        }

        this.alert(`Deal Created. ${!this.state.isAdmin ? 'Your deal will be approved within 48 hrs.' : ''}`, () => {
            if (this.props.onCreate) {
                this.props.onCreate(result)
            }    
        })
    }

    async handleUpdate() {
        try {
            const invalidation = []
            let path = ''
            if (this.state.croppedImage.blob) {
                path = `DealImages/${this.state.item.merchant_unique_id}/${this.state.item.pk}${this.state.croppedImage.ext}`
                this.state.item.image_url = utils.getAssetUrl(path)
                invalidation.push('image_url')
            }

            const deal = { ...this.state.item }
            // we will remove the purchasableTexts from schema when its not a purchasable deal
            if (deal.purchasable === '0' || deal.is_online === 'True') delete deal.purchasableTexts
            else deal.purchasableTexts = JSON.stringify(this.state.item.purchasableTexts)
            

            const dealModel = new DealModel(deal)
            const result = await dealModel.update({invalidateCloudFrontCache: invalidation})

            await this.handleImageUpload(path)
            await dealModel.setTags(this.state.item.originalTags, this.state.item.tags)

            if (deal.vouchers && deal.vouchers.length>0) {
                for(let i=0; i < deal.vouchers.length; i++) {
                    const voucher = deal.vouchers[i]
                    API.graphql(graphqlOperation(updateVoucherMaster, {input: {
                        id: voucher.id,
                        code: voucher.code,
                        codeType: voucher.codeType,
                        dealId: voucher.dealId,
                        status: voucher.status,
                        startTimestamp: voucher.startTimestamp,
                        endTimestamp: voucher.endTimestamp
                    }}))
                }
            }

            this.alert('Deal Updated', () => {
                if (this.props.onUpdate) {
                    this.props.onUpdate(result)
                }
            })
        } catch (error) {
            console.error(error)
            throw error.message
        }
    }

    onSelectAggregator(merchant) {
        this.state.item.aggregator = merchant.merchant_id
        this.setState({
            item: this.state.item
        })
    }

    onSelectBrand(merchant) {
        this.state.item.brand = merchant.merchant_id
        this.setState({
            item: this.state.item
        })
    }

    async handleImageUpload(path) {
        if (this.state.croppedImage.blob) {
            this.state.croppedImage.blob.name = path
            const resultUpload = await Storage.put(path, this.state.croppedImage.blob)
            // console.log('result upload to S3', resultUpload)
        }
    }

    async getCroppedImage(croppedImage) {
        this.setState({
            croppedImage: croppedImage,
        })
    }

    handleApplyToOutletsChanged(e) {
        const isChecked = e.target.checked;
        this.setState({ applyToOutlets: isChecked });
    }

    handleApplyOutletSelected(e, outlet, outlets) {
        const isChecked = e.target.checked;
        outlet.applySelected = isChecked;
        this.setState({ outlets: outlets });
    }

    async generateVoucherCode() {
        let {numberOfVouchersToGenerate, generatedVouchers, voucherStartDate, voucherEndDate, item} = this.state

        try {
            // check restriction
            if (!voucherStartDate || !voucherEndDate) {
                this.alert('Voucher redemption dates missing')
                return
            } else if (!numberOfVouchersToGenerate || numberOfVouchersToGenerate < 1 || numberOfVouchersToGenerate > 1000) {
                this.alert('Number of voucher that can be generated is between 1-1000 only')
                return
            }

            this.setState({loadingVouchers: true})
            const codes = await utils.generatePromoCodes(numberOfVouchersToGenerate)
            generatedVouchers = await this.verifyVouchers(codes, 'generated')

            item['vouchers'] = [...generatedVouchers]
            this.setState({generatedVouchers, item, loadingVouchers: false})

        } catch(e) {
            console.error('error on generatePromoCode ', e)
        }
    }

    async verifyVouchers(codes, type) {
        let {voucherStartDate, voucherEndDate, item} = this.state
        const duplicate = await this.checkDuplicateVoucherCodes(codes)
        
        let voucherCodes = []
        if (duplicate.length > 0) {
            this.alert(`Duplicate code detected: ${duplicate.toString()}`)
        }

        voucherCodes = codes.map((code, index) => {
            return {
                id: utils.guidGenerator(),
                code: code,
                dealId: item.pk,
                codeType: type,
                startTimestamp: voucherStartDate,
                endTimestamp: voucherEndDate,
                status: "0"
            }
        })  

        return voucherCodes
    }

    async checkDuplicateVoucherCodes(codes){
        try {
            const duplicateCodes = []
            const processCodes = []
            for (let i=0; i < codes.length; i++){
                if (codes[i]) {
                    const result = await this.getVoucherMasterByCode(codes[i])
                    
                    if (result && result.items.length > 0) {
                        duplicateCodes.push(codes[i])
                    }

                    if (processCodes.indexOf(codes[i]) >= 0) {
                        duplicateCodes.push(codes[i])
                    }

                    processCodes.push(codes[i])
                }
            }
    
            return duplicateCodes
        } catch (e) {
            console.error('error on checkDuplicateVoucherCodes ', e)
            throw e ? e.message : 'Please contact administrator'
        }
    }

    async getVoucherMasterByCode(code) {
        try {
            const result = await API.graphql(graphqlOperation(voucherByCode, { code }))
            return result.data.voucherByCode
        } catch (e) {
            console.error('Error on getVoucherMasterByCode', e)
            throw new Error('Error getting voucher code', code)
        }
    }

    async onUploadVouchers(codes) {
        const { item } = this.state
        try{
            this.setState({loadingVouchers: true})
            const uploadedVouchers = await this.verifyVouchers(codes, 'uploaded')

            item['vouchers'] = [...uploadedVouchers]
            this.setState({uploadedVouchers, item, loadingVouchers: false})
        } catch(e) {
            console.error('Error on onUploadVouchers', e)
            throw new Error('Error processing upload of vouchers')
        }
    }

    alert(msg, callback) {
        this.setState({
            processing: false
        })
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    isValidStartDate(currentDate, selectedDate) {
        if (this.state.item.end_timestamp) {
            return currentDate.isBefore( moment.unix(this.state.item.end_timestamp) )
        } else {
            return true
        }
    }

    isValidEndDate(currentDate, selectedDate) {
        if (this.state.item.start_timestamp) {
            return currentDate.isAfter( moment.unix(this.state.item.start_timestamp) )
        } else {
            return true
        }
    }

    render() {
        let imageSrc = this.state.croppedImage.url || (this.state.item.image_url.startsWith('http') ? this.state.item.image_url : utils.getAssetUrl(this.state.item.image_url));
        if (imageSrc.startsWith('http') || imageSrc.startsWith('https')) {
            imageSrc = imageSrc + `?timestamp=${Date.now()}`;
        }
        
        let isOutletDisabled = this.state.readOnly
        if (this.state.selectedOutlet) {
            isOutletDisabled = this.state.selectedOutlet.valid === 'Y' ? this.state.readOnly : true
        }

        let purchasableTexts = {}
        if (Object.keys(this.state.item.purchasableTexts).length > 0 ) {
            purchasableTexts = { ...this.state.item.purchasableTexts }
        } else {
            purchasableTexts = { ...this.state.defaultPurchasableTexts }
        }

        const { preSwipeText, atStoreConfirmation: { confirmationTitle, confirmationText, agreeOption, denyOption } } = purchasableTexts

        return <div>
            {this.props.show ?
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    {/* <h3>{this.props.title ?? this.props.mode === 'create' ? 'Create Deal' : 'Update Deal'}</h3> */}
                    
                    {
                        this.state.showApplyToOutlets ?
                            <div>
                                <Form.Row>
                                    <Form.Check type="checkbox" key="apply-to-outlets" id="apply-to-outlets" label="Apply to other Outlets"
                                        custom
                                        checked={this.state.applyToOutlets}
                                        onChange={(e) => this.handleApplyToOutletsChanged(e)} />
                                </Form.Row>
                                <Form.Row>
                                    {
                                        this.state.applyToOutlets ?
                                            <div className="checkboxes-container mb-3">
                                                {
                                                    this.state.outlets && this.state.outlets.length > 0 ?
                                                        this.state.outlets.map((item) =>
                                                            <Form.Check type="checkbox" key={`outlet-${item.store_id}`} id={item.store_id} label={`${item.outlet_name} [${item.store_id}]`}
                                                                custom
                                                                checked={item.applySelected}
                                                                onChange={(e) => this.handleApplyOutletSelected(e, item, this.state.outlets)} />
                                                        )
                                                        : 'No other outlets'
                                                }
                                            </div>
                                            : null
                                    }
                                </Form.Row>
                            </div>
                            : null
                    }

                    {this.state.isAdmin ?
                        <div>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Deal Id</Form.Label>
                                    <Form.Check type="checkbox" id="autoId" custom label="auto id" disabled={this.props.mode==='update'} checked={this.state.autoId} onChange={(e) => this.handleAutoIdChange(e)}/>
                                    <Form.Control type="text" disabled={this.state.autoId } readOnly={this.state.autoId} value={this.state.item.pk} onChange={(e) => this.handleChange(e, 'pk')}/>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Merchant Unique Id</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.merchant_unique_id} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Store Id</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.store_id} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Merchant Id</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.merchant_id} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Merchant Name</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.merchant_name} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Merchant Name (Lowercase)</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.merchant_name_lowercase} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Display Merchant Name</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.display_merchant_name} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Category</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.mainCat} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.phone} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.address} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Postal Code</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.postal_code} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>General Location</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.general_location} />
                                </Form.Group>
                            </Form.Row>

                            {/* <Form.Group>
                                <Form.Check label="Online Company" disabled readOnly checked={this.state.item.online_company.toUpperCase() === 'TRUE'} />
                            </Form.Group> */}

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Google Id</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.google_id} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Google Place Id</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.google_placeid} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Google Type</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.google_type} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Geohash Long</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.geohashlong} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Geohash Short</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.geohashshort} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Latitude</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.latitude} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Longitude</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.longitude} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Sector</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.sector} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>District</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.district} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>District Name</Form.Label>
                                    <Form.Control type="text" disabled readOnly value={this.state.item.district_name} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Group>
                                <Form.Label>Promotion Analytic</Form.Label>
                                <Form.Control type="text" disabled readOnly value={this.state.item.promotion_analytic} />
                            </Form.Group>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Bank</Form.Label>
                                    <Form.Control type="text" value={this.state.item.bank} onChange={(e) => this.handleChange(e, 'bank')} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Deal Handler</Form.Label>
                                    <Form.Control type="text" value={this.state.item.deal_handler} onChange={(e) => this.handleChange(e, 'deal_handler')} />
                                </Form.Group>
                            </Form.Row>
            
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Sub Category</Form.Label>
                                    <Form.Control type="text" value={this.state.item.subcategory} onChange={(e) => this.handleChange(e, 'subcategory')} />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Aggregator</Form.Label>
                                    <ListMerchants
                                        merchantId={this.state.item.aggregator}
                                        label="Aggregator"
                                        onSelect={(e, merchant) => this.onSelectAggregator(merchant)}
                                        replace
                                    />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>CC Buddy Category</Form.Label>
                                    <Form.Control as="select" custom value={this.state.item.cc_buddy_category} onChange={(e) => this.handleChange(e, 'cc_buddy_category')}>
                                        <option value="Dining">Dining</option>
                                        <option value="Entertainment">Entertainment</option>
                                        <option value="Lifestyle">Lifestyle</option>
                                        <option value="Online_Deals">Online Deals</option>
                                        <option value="Petrol_Transport">Petrol Transport</option>
                                        <option value="Retail">Retail</option>
                                        <option value="Travel">Travel</option>
                                        <option value="Wellness">Wellness</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>CC Buddy Card Name</Form.Label>
                                    <Form.Control type="text" value={this.state.item.ccbuddy_card_name} onChange={(e) => this.handleChange(e, 'ccbuddy_card_name')} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Rank</Form.Label>
                                    <Form.Control type="text" value={this.state.item.rank} onChange={(e) => this.handleChange(e, 'rank')} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Rank Updated</Form.Label>
                                    <Form.Control type="text" value={this.state.item.rank_updated} onChange={(e) => this.handleChange(e, 'rank_updated')} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Score</Form.Label>
                                    <Form.Control type="text" value={this.state.item.score} onChange={(e) => this.handleChange(e, 'score')} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Group>
                                <Form.Label>Image Path</Form.Label>
                                <Form.Control type="text" value={this.state.item.image_path} onChange={(e) => this.handleChange(e, 'image_path')} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Check type="checkbox" id="usable_promocode" custom label="Usable PromoCode" checked={this.state.item.usable_promocode} onChange={(e) => this.handleChange(e, 'usable_promocode')} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Area</Form.Label>
                                <Form.Control type="text" value={this.state.item.area} onChange={(e) => this.handleChange(e, 'area')} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Country *</Form.Label>
                                <Form.Control required type="text" value={this.state.item.country} onChange={(e) => this.handleChange(e, 'country')} />
                            </Form.Group>
                        </div>
                        : null}

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Promotion Title *</Form.Label>
                            <Form.Control required type="text" disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.promotion_caption} onChange={(e) => this.handleChange(e, 'promotion_caption')} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Promo Code</Form.Label>
                            <Form.Control type="text" disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.promo_code} onChange={(e) => this.handleChange(e, 'promo_code')} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>Promotion Details *</Form.Label>
                        {this.state.additionalPromotionInfo.length === 0 ?
                        <Form.Control required as="textarea" rows={5} disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.promotion} onChange={(e) => this.handleChange(e, 'promotion')} />
                        :
                        <Form.Control required as="textarea" rows={5} disabled={true} readOnly={true} value={this.state.promotionDetails} />
                        }
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Terms & Conditions *</Form.Label>
                        {this.state.additionalPromotionInfo.length === 0 ?
                        <Form.Control required as="textarea" rows={5} disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.terms} onChange={(e) => this.handleChange(e, 'terms')} />
                        :
                        <Form.Control required as="textarea" rows={5} disabled={true} readOnly={true} value={this.state.terms} />
                        }
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Start Date/Time *</Form.Label>
                            <Datetime closeOnSelect={true} 
                                dateFormat="YYYY-MM-DD" 
                                timeFormat="HH:mm:ss" 
                                inputProps={{placeholder: 'Please select a date', required: this.state.hasStart, disabled: this.state.readOnly || !this.state.hasStart, readOnly: this.state.readOnly || !this.state.hasStart}} 
                                value={this.state.item.start_timestamp?moment.unix(this.state.item.start_timestamp):null} 
                                onChange={(m) => this.handleDateTime(m, 'start_timestamp')} 
                                isValidDate={(currentDate, selectedDate) => this.isValidStartDate(currentDate, selectedDate)} />
                            <Form.Check type="checkbox" id="hasStart" custom label="I confirm my deal has no start date" checked={!this.state.hasStart} onChange={(e) => this.handleHasStartChange(e)}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>End Date/Time *</Form.Label>
                            <Datetime closeOnSelect={true} 
                                dateFormat="YYYY-MM-DD" 
                                timeFormat="HH:mm:ss" 
                                initialViewDate={moment().endOf('day')} 
                                inputProps={{placeholder: 'Please select a date', required: this.state.hasEnd, disabled: this.state.readOnly || !this.state.hasEnd, readOnly: this.state.readOnly || !this.state.hasEnd}} 
                                value={this.state.item.end_timestamp?moment.unix(this.state.item.end_timestamp):null} 
                                onChange={(m) => this.handleDateTime(m, 'end_timestamp')} 
                                isValidDate={(currentDate, selectedDate) => this.isValidEndDate(currentDate, selectedDate)} />
                            <Form.Check type="checkbox" id="hasEnd" custom label="I confirm my deal has no end date" checked={!this.state.hasEnd} onChange={(e) => this.handleHasEndChange(e)}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            {this.config?
                                <CMSTag predefinedTags={this.config.predefinedTags} tags={this.state.item.tags} onChange={(tags) => this.handleTagChange(tags)} />
                            : null}
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>Brand</Form.Label>
                        <ListMerchants
                            merchantId={this.state.item.brand}
                            label="e.g. ASICS, Nike"
                            onSelect={(e, merchant) => this.onSelectBrand(merchant)}
                            disabled={this.state.readOnly} readOnly={this.state.readOnly}
                            replace
                        />
                    </Form.Group>
                    <Form.Row>
                    <Form.Group as={Col}>
                            <Form.Label>Website</Form.Label>
                            <Form.Control type="text" disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.website} onChange={(e) => this.handleChange(e, 'website')} />
                        </Form.Group>
                        {this.state.isAdmin?
                        <Form.Group as={Col}>
                            <Form.Label>Ticket Type</Form.Label>
                            <Form.Control type="text" disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.ticketType} onChange={(e) => this.handleChange(e, 'ticketType')} />
                        </Form.Group>
                        :null}
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Check type="checkbox" disabled={this.state.readOnly} readOnly={this.state.readOnly} id="is_online" custom label="Online Deal" checked={this.state.item.is_online.toUpperCase() === 'TRUE'} onChange={(e) => this.handleChange(e, 'is_online')} />
                        </Form.Group>
                        {this.state.isAdmin?
                            <Form.Group as={Col}>
                                <Form.Check type="checkbox" disabled={this.state.readOnly} readOnly={this.state.readOnly} id="isSponsored" custom label="Sponsored Deal" checked={this.state.item.isSponsored === '1'} onChange={(e) => this.handleChange(e, 'isSponsored')} />
                            </Form.Group>
                        :null}
                        {this.state.isAdmin?
                            <Form.Group as={Col}>
                                <Form.Check type="checkbox" disabled={isOutletDisabled} readOnly={this.state.readOnly} id="valid" custom label="Visible in CardsPal App" checked={this.state.item.valid.toUpperCase() === 'Y'} onChange={(e) => this.handleChange(e, 'valid')} />
                                { this.state.item.valid.toUpperCase() === 'Y' ?
                                    <FaEye title="visible" color="green" />
                                : <FaEyeSlash title="not visible" color="red" />}
                            </Form.Group>
                        : null}
                    </Form.Row>
                    {this.state.isAdmin?
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Check type="checkbox" disabled={this.state.readOnly} readOnly={this.state.readOnly} id="purchasable" custom label="Purchasable" checked={this.state.item.purchasable === '1'} onChange={(e) => this.handleChange(e, 'purchasable')} />
                        </Form.Group>
                    </Form.Row>
                    : null}
                    {this.state.isPurchasable?
                        <div>
                            <div style={{border: 'solid #ccc 1px', borderRadius: 3, padding: 10, marginBottom:20}} >
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Actual Price(S$) *</Form.Label>
                                        <Form.Control type="text" required={this.state.isPurchasable} disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.actualPrice} onChange={(e) => this.handleChange(e, 'actualPrice')} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Discount(%)</Form.Label>
                                        <Form.Control type="text" disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.discountPlaceHolder} onChange={(e) => this.handleChange(e, 'discount')} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Discount Price(S$) *</Form.Label>
                                        <Form.Control type="text" required={this.state.isPurchasable} disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.discountPrice} onChange={(e) => this.handleChange(e, 'discountPrice')} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Purchase Price(S$) *</Form.Label>
                                        <Form.Control type="text" required={this.state.isPurchasable} disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.purchasePrice} onChange={(e) => this.handleChange(e, 'purchasePrice')} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Min qty per transaction *</Form.Label>
                                        <Form.Control type="text" required={this.state.isPurchasable} disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.minQtyTx} onChange={(e) => this.handleChange(e, 'minQtyTx')} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Max qty Per Customer *</Form.Label>
                                        <Form.Control type="text" required={this.state.isPurchasable} disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.purchaseLimitPerCustomer} onChange={(e) => this.handleChange(e, 'purchaseLimitPerCustomer')} />
                                    </Form.Group>
                                    <Form.Group as={Col}></Form.Group>
                                    <Form.Group as={Col}></Form.Group>
                                </Form.Row>
                            </div>
                            {this.state.isAdmin && this.state.item.is_online==='False'?
                                <div style={{ border: 'solid #ccc 1px', borderRadius: 3, padding: 10, marginBottom: 20 }} >
                                    <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Pre-swipe text</Form.Label>
                                                <Form.Control type="text" required={true} value={preSwipeText} onChange={(e) => this.handleChange(e, 'preSwipeText')} />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Confirmation title</Form.Label>
                                                <Form.Control type="text" required={true} value={confirmationTitle} onChange={(e) => this.handleChange(e, 'confirmationTitle')} />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Confirmation Text</Form.Label>
                                                <Form.Control as="textarea" rows={3} required={true} value={confirmationText} onChange={(e) => this.handleChange(e, 'confirmationText')} />
                                            </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Agree option</Form.Label>
                                                <Form.Control type="text" required={true} value={agreeOption} onChange={(e) => this.handleChange(e, 'agreeOption')} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>Deny option</Form.Label>
                                                <Form.Control type="text" required={true} value={denyOption} onChange={(e) => this.handleChange(e, 'denyOption')} />
                                        </Form.Group>
                                        <Form.Group as={Col}></Form.Group>
                                        <Form.Group as={Col}></Form.Group>
                                    </Form.Row>
                                </div>
                            : null}
                            
                            {this.state.isAdmin ?
                                <div>
                                    <div style={{border:"1px solid rgb(204, 204, 204)", marginBottom:"20px",padding:"10px"}}>
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <Form.Label>Voucher Redemption Date/Time*</Form.Label> 
                                                <Datetime 
                                                    inputProps={{placeholder: 'Please select a date', disabled: this.state.mode!=='create'}}
                                                    closeOnSelect={true}
                                                    dateFormat="YYYY-MM-DD" 
                                                    timeFormat="HH:mm:ss" 
                                                    value={this.state.voucherStartDate?moment.unix(this.state.voucherStartDate):null}
                                                    onChange={(m) => this.handleVoucherDateTime(m, 'voucherStartDate')} 
                                                    isValidDate={(currentDate, selectedDate) => this.isValidStartDate(currentDate, selectedDate)}
                                                    />
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>Voucher Redemption Date/Time*</Form.Label>
                                                <Datetime 
                                                    inputProps={{placeholder: 'Please select a date', disabled: this.state.mode!=='create'}}
                                                    dateFormat="YYYY-MM-DD" 
                                                    closeOnSelect={true}
                                                    timeFormat="HH:mm:ss" 
                                                    value={this.state.voucherEndDate?moment.unix(this.state.voucherEndDate):null}
                                                    initialViewDate={moment().endOf('day')} 
                                                    onChange={(m) => this.handleVoucherDateTime(m, 'voucherEndDate')} 
                                                    isValidDate={(currentDate, selectedDate) => this.isValidEndDate(currentDate, selectedDate)}
                                                    />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                <input type="radio" disabled={this.state.mode!=='create'} checked={this.state.voucherType==='uploaded'} value="uploaded" name="voucherType" onChange={(e) => this.handleChange(e, 'voucherType')}/>{" "}
                                                Upload Promo Codes {this.state.voucherType==='uploaded' && (!this.state.voucherEndDate || !this.state.voucherStartDate) ? (<span className="red">Please select Redemption dates first</span>) : null}
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <input type="radio" disabled={this.state.mode!=='create'} checked={this.state.voucherType==='generated'} value="generated" name="voucherType" onChange={(e) => this.handleChange(e, 'voucherType')}/>{" "}
                                                Generate {this.state.voucherType==='generated' && (!this.state.voucherEndDate || !this.state.voucherStartDate) ? (<span className="red">Please select Redemption dates first</span>) : null}
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col}>
                                                {this.state.voucherType==='uploaded'?
                                                <CMSUploadCodes
                                                    isEnabled={this.state.mode!=='create' || !this.state.voucherStartDate || !this.state.voucherEndDate}
                                                    dealId={this.state.item.pk}
                                                    onComplete={(outlets) => this.onUploadVouchers(outlets)}
                                                />
                                                : null}
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                {this.state.voucherType==='generated'?
                                                <div>
                                                    <input size="5" height="35px" disabled={this.state.mode!=='create'} type="text" required={true} value={this.state.numberOfVouchersToGenerate} onChange={(e) => this.handleChange(e, 'numberOfVouchersToGenerate')} />{" "}
                                                    <Button disabled={this.state.mode!=='create' || this.state.processing || this.state.readOnly || this.state.loadingVouchers} type="button" onClick={(e) =>this.generateVoucherCode(e)}>Generate {this.state.loadingVouchers ? <Spinner animation="border" size="sm" /> : null}</Button>
                                                </div>
                                                : null}
                                            </Form.Group>
                                        </Form.Row>
                                    </div>
                                    <div className="scrollview">
                                        <Table striped bordered hover variant="white">
                                        <thead>
                                            <th>#</th>
                                            <th>code</th>
                                            <th>type</th>
                                            <th>start</th>
                                            <th>end</th>
                                            <th>status</th>
                                            <th>redemption</th>
                                        </thead>

                                        <tbody>
                                            {this.state.item.vouchers && this.state.item.vouchers.map((voucher, index) => 
                                            <tr>
                                                <td>{index+1}</td>
                                                <td>
                                                    {voucher.code}
                                                </td>
                                                <td>
                                                    {voucher.codeType}
                                                </td>
                                                <td>
                                                    <Form.Group as={Col}>
                                                        <Datetime 
                                                            dateFormat="YYYY-MM-DD" 
                                                            timeFormat="HH:mm:ss" 
                                                            inputProps={{
                                                                required: true, 
                                                                disabled: this.state.readOnly || true, 
                                                                readOnly: this.state.readOnly
                                                            }} 
                                                            value={voucher.startTimestamp?moment.unix(voucher.startTimestamp):null} 
                                                            onChange={(m) => this.handleVoucherRedemptionDateTime(m, 'startTimestamp', index)} 
                                                        />
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group as={Col}>
                                                        <Datetime 
                                                            dateFormat="YYYY-MM-DD" 
                                                            timeFormat="HH:mm:ss" 
                                                            initialViewDate={moment().endOf('day')} 
                                                            inputProps={{
                                                                required: true, 
                                                                disabled: this.state.readOnly || true, 
                                                                readOnly: this.state.readOnly
                                                            }} 
                                                            value={voucher.endTimestamp?moment.unix(voucher.endTimestamp):null} 
                                                            onChange={(m) => this.handleVoucherRedemptionDateTime(m, 'endTimestamp', index)} 
                                                        />
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <span className={voucher.status === '0'?'green':'red'}>{voucher.status === '0'? 'Active':(voucher.status === '1'?'Sold':'Refund')}</span>
                                                </td>
                                                <td>
                                                    <span className={voucher.status === '0'?'green':'red'}>{voucher.status === '0'? 'Unused': 'Redeemed'}</span>
                                                </td>
                                            </tr>
                                            )}
                                        </tbody>
                                        </Table>
                                    </div>
                                </div>
                            :null}
                        </div>
                    : null}
                    <Form.Row>
                        <Form.Group as={Col}>
                            <CMSImageUploader 
                                width={375}
                                height={266}
                                required={!this.state.item.image_url} 
                                disabled={this.state.readOnly} 
                                readOnly={this.state.readOnly} 
                                label="Deal Image *" 
                                onOk={(croppedImage) => this.getCroppedImage(croppedImage)}
                            />
                        </Form.Group>
                    </Form.Row>
                    { this.state.croppedImage.url || this.state.item.image_url ?
                        <Form.Group>
                            <h4><small>Preview (Approximate look in app)</small></h4>
                            <Row style={{border: 'solid #ccc 1px', borderRadius: 3, padding: 10}}>
                                <Col>
                                    <div style={{ float: 'left', marginRight: '1em', width: 104, height: 94, backgroundImage: `url("${imageSrc}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                                    {/* <Image fluid width="104" height="94" src={imageSrc} /> */}
                                    <div>
                                        { this.state.item.promotion_caption }
                                        <br /><br />
                                        <span style={{border: 'solid #ccc 1px', borderRadius: '0.5em', padding: 5}}>
                                            <FaTag /> { this.state.item.bank }
                                        </span>
                                    </div>
                                </Col>
                                {/* <Col md={1}>
                                    <FaPlus />
                                </Col> */}
                            </Row>
                            <Row style={{border: 'solid #ccc 1px', borderRadius: 3, padding: 10}}>
                                <Col style={{ textAlign: 'middle'}}>
                                    <Image fluid width="110" height="75" src={imageSrc} style={{ borderRadius: 5, float: 'left', marginRight: '1em' }} />
                                    <strong style={{ overflow: 'hidden' }}>{ this.state.item.promotion_caption }</strong>
                                </Col>
                            </Row>
                        </Form.Group>
                    : null}

                    <Form.Group>
                        <Button disabled={this.state.processing || this.state.readOnly} type="submit">
                            {this.props.mode === 'create' ?
                                <span>Create Deal</span>
                                : this.props.mode === 'update' ?
                                    <span>Update Deal</span>
                                    : null}
                            &nbsp;
                            {this.state.processing ?
                                <Spinner animation="border" size="sm" />
                                : null}
                        </Button>
                    </Form.Group>
                </Form>
                : null}
        </div>
    }
}

export default Deal