import React, { Component } from 'react';
import { Button, Container, Row, Col, Spinner, ListGroup, Modal } from 'react-bootstrap';
import { FaEdit, FaPlus } from "react-icons/fa";
import { Auth } from '@aws-amplify/auth'
import Card from '../widgets/Card'
import CardParam from '../widgets/CardParam'
import Model from '../../models/Model';

class CardView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cards: [],
            cardParams: [],
            card: null,
            isLoading: true
        }
    }

    async componentDidMount() {
        this.setState({
            isLoading: true
        });
        const user = await Auth.currentAuthenticatedUser()
        const cards = await Model.listAll('CardMaster', {})
        
        cards.sort((a, b) => {
            return (a.ccbuddy_card_name < b.ccbuddy_card_name) ? -1 : 1
        })
        this.setState({
            user: user.attributes,
            cards,
            card: cards.length>0?cards[0]:null,
            isLoading: false
        });

        if (cards.length > 0) {
            this.selectCard(null, cards[0])
        }
    }

    createCardParam(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            mode: 'createCardParam',
            show: true
        })
    }

    updateCardParam(e, cardParam) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            cardParam: cardParam,
            mode: 'updateCardParam',
            show: true
        })
    }

    updateCard(e, card) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            card: card,
            mode: 'updateCard',
            show: true
        })
    }

    createCard(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            mode: 'createCard',
            show: true
        })
    }

    async selectCard(e, item) {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }

        this.setState({
            card: item,
            isParamsLoading: true
        })

        const cardParams = await Model.listAll('CardParam', {
            filter: {
                cardId: {
                    eq: item.card_detail_id
                }
            }
        })

        this.setState({
            cardParams,
            isParamsLoading: false
        })
    }

    onCreateCard(card) {
        this.state.cards.unshift(card)
        this.setState({
            cards: this.state.cards,
            show: false,
        })
    }

    onUpdateCard(card) {
        this.refreshCard(card)
        this.setState({
            show: false,
        })
    }

    refreshCard(c) {
        const cards = this.state.cards
        for (const [index, card] of cards.entries()) {
            if (c.card_detail_id === card.card_detail_id) {
                cards[index] = c
            }
        }
        this.setState({
            cards: cards
        })
    }

    onCreateCardParam(cardParam) {
        this.state.cardParams.unshift(cardParam)
        this.setState({
            cardParams: this.state.cardParams,
            show: false,
        })
    }

    onUpdateCardParam(cardParam) {
        this.refreshCardParam(cardParam)
        this.setState({
            show: false,
        })
    }

    refreshCardParam(cp) {
        const cardParams = this.state.cardParams
        cardParams.forEach((item, index) => {
            if (item.id===cp.id) {
                cardParams[index] = cp
            }
        })

        this.setState({
            cardParams
        })
    }

    handleClose() {
        this.setState({
            show: false
        })
    }

    render() {
        const { cards, user } = this.state
        return <Container fluid>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h3>Card</h3>
                        </Col>
                        <Col>
                            <Button className="float-right" size="sm" onClick={(e) => this.createCard(e)}>
                                <FaPlus /> Add Card
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        {this.state.isLoading ?
                            <Spinner animation="border" size="sm" />
                            :
                            <Col className="scrollview">
                                <ListGroup variant="flush">
                                    {
                                        cards.length > 0 ?
                                            cards.map((item) =>
                                                <ListGroup.Item action active={this.state.card?(this.state.card.card_detail_id === item.card_detail_id):false} key={`list-card-${item.card_detail_id}`} onClick={(e) => this.selectCard(e, item)}>
                                                    <div>
                                                        {item.ccbuddy_card_name}
                                                        <Button className="float-right" size="sm" onClick={(e) => this.updateCard(e, item)}>
                                                            <FaEdit /> Edit
                                                        </Button>
                                                    </div>
                                                </ListGroup.Item>
                                            )
                                            : 'No Card'
                                    }
                                </ListGroup>
                            </Col>
                        }
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <h3>Card Param</h3>
                        </Col>
                        <Col>
                            <Button className="float-right" size="sm" onClick={(e) => this.createCardParam(e)}>
                                <FaPlus /> Add Card Param
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        { this.state.card?
                        (this.state.isParamsLoading ?
                            <Spinner animation="border" size="sm" />
                            :
                            <Col className="scrollview">
                                <ListGroup variant="flush">
                                    {
                                        this.state.cardParams.length > 0 ?
                                            this.state.cardParams.map((item) =>
                                                <ListGroup.Item key={`list-cardparams-${item.id}`}>
                                                    <p>
                                                        <small>
                                                            Id: {item.id}
                                                        </small>
                                                    </p>
                                                    <p>
                                                        <small>
                                                            cardId: {item.cardId}
                                                        </small>
                                                    </p>
                                                    <p>
                                                        <small>
                                                            bonus: {item.bonus}
                                                        </small>
                                                    </p>
                                                    <p>
                                                        <small>
                                                            discount: {item.discount}
                                                        </small>
                                                    </p>
                                                    <p>
                                                        <small>
                                                            min: {item.minSpend}
                                                        </small>
                                                    </p>
                                                    <p>
                                                        <small>
                                                            max: {item.maxSpend}
                                                        </small>
                                                    </p>
                                                    <p>
                                                        <small>
                                                            cap: {item.cap}
                                                        </small>
                                                    </p>
                                                    <p>
                                                        <small>
                                                            capFrequency: {item.capFrequency}
                                                        </small>
                                                    </p>
                                                    <p>
                                                        <Button className="float-right" size="sm" onClick={(e) => this.updateCardParam(e, item)}>
                                                            <FaEdit /> Edit
                                                        </Button>
                                                    </p >
                                                </ListGroup.Item>
                                            )
                                            : 'No CardParams'
                                    }
                                </ListGroup>
                            </Col>
                        ): null}
                    </Row>
                </Col>

                <Modal show={this.state.show} onHide={() => this.handleClose()} size="xl" backdrop='static'>
                    <Modal.Header closeButton>
                        <Modal.Title>{(this.state.mode==='createCard')?'Create Card':(this.state.mode==='updateCard'?'Update Card':(this.state.mode==='createCardParam')?'Create Card Param':(this.state.mode==='updateCardParam')?'Update Card Param':'')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card
                            show={this.state.mode==='createCard'}
                            user={user}
                            mode='create'
                            key='card-create'
                            onCreate={(card) => this.onCreateCard(card)}
                        />

                        <Card
                            show={this.state.mode==='updateCard'}
                            mode='update'
                            user={user}
                            card={this.state.card}
                            key={`card-${this.state.card?this.state.card.card_detail_id : ''}`}
                            onUpdate={(card) => this.onUpdateCard(card)}
                        />

                        <CardParam
                            show={this.state.mode==='createCardParam'}
                            mode='create'
                            card={this.state.card}
                            user={user}
                            type='cardParam-create'
                            key='cardParam-create'
                            onCreate={(cardParam) => this.onCreateCardParam(cardParam)}
                        />

                        <CardParam
                            show={this.state.mode==='updateCardParam'}
                            mode='update'
                            user={user}
                            cardParam={this.state.cardParam}
                            key={`cardParam-${this.state.cardParam ? this.state.cardParam.id : ''}`}
                            onUpdate={(cardParam) => this.onUpdateCardParam(cardParam)}
                        />

                    </Modal.Body>
                </Modal>
            </Row>
        </Container>
    }
}

export default CardView