/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listCards = /* GraphQL */ `
  query ListCards {
    listCards {
      annual_fee
      annual_fee_waiver
      bank
      card_detail_id
      cardParams {
        items {
          id
          cardId
          discount
          bonus
          minSpend
          maxSpend
          cap
          capFrequency
          mcc
          mccMainCat
          mccSubcat
          merchant
          merchantId
          merchantMainCat
          merchantSubcat
          valid
          createdAt
          updatedAt
          createdByUser
          updatedByUser
          createdByGroups
        }
        nextToken
      }
      card_rep_fee
      card_type
      cash_adv_fee
      cash_adv_int
      ccbuddy_card_name
      cont_payment
      country
      cred_limit
      deal_handler
      exceed_fee
      extra_doc
      feature
      feature1
      feature1_type
      feature2
      feature2_type
      feature3
      feature3_type
      feature_cnt
      for_trans_fee
      interest
      interest_free
      image_url
      late_fee
      max_income_sp_num
      min_age
      min_income_for
      min_income_sp
      min_income_sp_num
      min_pay
      min_spend
      mob_payment
      payment_network
      proof_identity
      proof_sal_emp
      proof_self_emp
      rtob_links
      singsaver_name
      six_interest
      six_interest_online
      six_process
      six_process_online
      supp_card_annual_fee
      supp_card_annual_fee_waiver
      three_interest
      three_interest_online
      three_process
      three_process_online
      twelve_interest
      twelve_interest_online
      twelve_process
      twelve_process_online
      valid
      welcome_offers
      addCardsVisible
      applyNowVisible
      cardspal_rating
      compareNowVisible
      ctaButtonText
      htmlContent
      promo_ranking
      createdAt
      updatedAt
    }
  }
`;
export const searchDealMasters = /* GraphQL */ `
  query SearchDealMasters(
    $filter: SearchableDealMasterFilterInput
    $sort: [SearchableDealMasterSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDealMasterAggregationInput]
  ) {
    searchDealMasters(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        address
        aggregator
        approval {
          pk
          isApproved
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          createdYearMonth
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        purchasableTexts
        showInOutletIds
        additionalPromotionInfo
        ticketType
        minQtyTx
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchMerchants = /* GraphQL */ `
  query SearchMerchants(
    $filter: SearchableMerchantFilterInput
    $sort: [SearchableMerchantSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMerchantAggregationInput]
  ) {
    searchMerchants(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        mainCat
        outlets {
          nextToken
        }
        display_merchant_name
        google_type
        merchant_id
        merchant_name
        merchant_name_lowercase
        online_company
        valid
        acceptCC
        merchantImageUrl
        merchantImageUrlV2
        defaultImageUrl
        pockCalMerchImage
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        website
        summary
        cmsPlan
        stripeExpressConnectedAccountId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getSubPlan = /* GraphQL */ `
  query GetSubPlan($email: String!) {
    getSubPlan(email: $email) {
      email
      plan
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const listSubPlans = /* GraphQL */ `
  query ListSubPlans(
    $email: String
    $filter: ModelSubPlanFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubPlans(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        plan
        startDate
        endDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMerchantMccMapping = /* GraphQL */ `
  query GetMerchantMccMapping($mcc: String!, $merchantId: String!) {
    getMerchantMccMapping(mcc: $mcc, merchantId: $merchantId) {
      mcc
      merchantId
      createdAt
      updatedAt
    }
  }
`;
export const listMerchantMccMappings = /* GraphQL */ `
  query ListMerchantMccMappings(
    $mcc: String
    $merchantId: ModelStringKeyConditionInput
    $filter: ModelMerchantMccMappingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMerchantMccMappings(
      mcc: $mcc
      merchantId: $merchantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        mcc
        merchantId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCardParam = /* GraphQL */ `
  query GetCardParam($id: ID!) {
    getCardParam(id: $id) {
      id
      cardId
      discount
      bonus
      minSpend
      maxSpend
      cap
      capFrequency
      mcc
      mccMainCat
      mccSubcat
      merchant
      merchantId
      merchantMainCat
      merchantSubcat
      valid
      createdAt
      updatedAt
      createdByUser
      updatedByUser
      createdByGroups
    }
  }
`;
export const listCardParams = /* GraphQL */ `
  query ListCardParams(
    $filter: ModelCardParamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCardParams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cardId
        discount
        bonus
        minSpend
        maxSpend
        cap
        capFrequency
        mcc
        mccMainCat
        mccSubcat
        merchant
        merchantId
        merchantMainCat
        merchantSubcat
        valid
        createdAt
        updatedAt
        createdByUser
        updatedByUser
        createdByGroups
      }
      nextToken
    }
  }
`;
export const getCardMaster = /* GraphQL */ `
  query GetCardMaster($card_detail_id: ID!) {
    getCardMaster(card_detail_id: $card_detail_id) {
      annual_fee
      annual_fee_waiver
      bank
      card_detail_id
      cardParams {
        items {
          id
          cardId
          discount
          bonus
          minSpend
          maxSpend
          cap
          capFrequency
          mcc
          mccMainCat
          mccSubcat
          merchant
          merchantId
          merchantMainCat
          merchantSubcat
          valid
          createdAt
          updatedAt
          createdByUser
          updatedByUser
          createdByGroups
        }
        nextToken
      }
      card_rep_fee
      card_type
      cash_adv_fee
      cash_adv_int
      ccbuddy_card_name
      cont_payment
      country
      cred_limit
      deal_handler
      exceed_fee
      extra_doc
      feature
      feature1
      feature1_type
      feature2
      feature2_type
      feature3
      feature3_type
      feature_cnt
      for_trans_fee
      interest
      interest_free
      image_url
      late_fee
      max_income_sp_num
      min_age
      min_income_for
      min_income_sp
      min_income_sp_num
      min_pay
      min_spend
      mob_payment
      payment_network
      proof_identity
      proof_sal_emp
      proof_self_emp
      rtob_links
      singsaver_name
      six_interest
      six_interest_online
      six_process
      six_process_online
      supp_card_annual_fee
      supp_card_annual_fee_waiver
      three_interest
      three_interest_online
      three_process
      three_process_online
      twelve_interest
      twelve_interest_online
      twelve_process
      twelve_process_online
      valid
      welcome_offers
      addCardsVisible
      applyNowVisible
      cardspal_rating
      compareNowVisible
      ctaButtonText
      htmlContent
      promo_ranking
      createdAt
      updatedAt
    }
  }
`;
export const listCardMasters = /* GraphQL */ `
  query ListCardMasters(
    $card_detail_id: ID
    $filter: ModelCardMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCardMasters(
      card_detail_id: $card_detail_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        annual_fee
        annual_fee_waiver
        bank
        card_detail_id
        cardParams {
          nextToken
        }
        card_rep_fee
        card_type
        cash_adv_fee
        cash_adv_int
        ccbuddy_card_name
        cont_payment
        country
        cred_limit
        deal_handler
        exceed_fee
        extra_doc
        feature
        feature1
        feature1_type
        feature2
        feature2_type
        feature3
        feature3_type
        feature_cnt
        for_trans_fee
        interest
        interest_free
        image_url
        late_fee
        max_income_sp_num
        min_age
        min_income_for
        min_income_sp
        min_income_sp_num
        min_pay
        min_spend
        mob_payment
        payment_network
        proof_identity
        proof_sal_emp
        proof_self_emp
        rtob_links
        singsaver_name
        six_interest
        six_interest_online
        six_process
        six_process_online
        supp_card_annual_fee
        supp_card_annual_fee_waiver
        three_interest
        three_interest_online
        three_process
        three_process_online
        twelve_interest
        twelve_interest_online
        twelve_process
        twelve_process_online
        valid
        welcome_offers
        addCardsVisible
        applyNowVisible
        cardspal_rating
        compareNowVisible
        ctaButtonText
        htmlContent
        promo_ranking
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMall = /* GraphQL */ `
  query GetMall($mallId: ID!) {
    getMall(mallId: $mallId) {
      mallId
      address
      imageUrl
      name
      order
      searchField
      valid
      createdAt
      updatedAt
    }
  }
`;
export const listMalls = /* GraphQL */ `
  query ListMalls(
    $mallId: ID
    $filter: ModelMallFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMalls(
      mallId: $mallId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        mallId
        address
        imageUrl
        name
        order
        searchField
        valid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($campaignId: ID!) {
    getCampaign(campaignId: $campaignId) {
      campaignId
      campaignName
      dealId
      valid
      countAssigned
      title
      redeemButtonText
      eventTrackerName
      startTimestamp
      endTimestamp
      eligibility
      killSwitch
      cssCode
      helpCenterUrl
      howToRedeemUrl
      createdAt
      updatedAt
      popUpImageUrl
      enableNotification
      notificationSchedule
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $campaignId: ID
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCampaigns(
      campaignId: $campaignId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        campaignId
        campaignName
        dealId
        valid
        countAssigned
        title
        redeemButtonText
        eventTrackerName
        startTimestamp
        endTimestamp
        eligibility
        killSwitch
        cssCode
        helpCenterUrl
        howToRedeemUrl
        createdAt
        updatedAt
        popUpImageUrl
        enableNotification
        notificationSchedule
      }
      nextToken
    }
  }
`;
export const getAnnouncement = /* GraphQL */ `
  query GetAnnouncement($announcementId: ID!) {
    getAnnouncement(announcementId: $announcementId) {
      announcementId
      active
      message
      createdAt
      updatedAt
    }
  }
`;
export const listAnnouncements = /* GraphQL */ `
  query ListAnnouncements(
    $announcementId: ID
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAnnouncements(
      announcementId: $announcementId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        announcementId
        active
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSearchKeyword = /* GraphQL */ `
  query GetSearchKeyword($searchKeywordId: ID!) {
    getSearchKeyword(searchKeywordId: $searchKeywordId) {
      searchKeywordId
      keyword
      keywordLowerCase
      valid
      hot
      new
      createdAt
      updatedAt
    }
  }
`;
export const listSearchKeywords = /* GraphQL */ `
  query ListSearchKeywords(
    $searchKeywordId: ID
    $filter: ModelSearchKeywordFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSearchKeywords(
      searchKeywordId: $searchKeywordId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        searchKeywordId
        keyword
        keywordLowerCase
        valid
        hot
        new
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBanner = /* GraphQL */ `
  query GetBanner($bannerId: ID!) {
    getBanner(bannerId: $bannerId) {
      bannerId
      buttonText
      valid
      homeBannerImageUrl
      bannerPageImageUrl
      type
      order
      title
      content
      externalLinkActive
      externalLink
      campaignId
      createdAt
      updatedAt
    }
  }
`;
export const listBanners = /* GraphQL */ `
  query ListBanners(
    $bannerId: ID
    $filter: ModelBannerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBanners(
      bannerId: $bannerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        bannerId
        buttonText
        valid
        homeBannerImageUrl
        bannerPageImageUrl
        type
        order
        title
        content
        externalLinkActive
        externalLink
        campaignId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTags = /* GraphQL */ `
  query GetTags($id: ID!, $tagName: String!) {
    getTags(id: $id, tagName: $tagName) {
      id
      merchantId
      tagName
      tagType
      highlights
      createdAt
      updatedAt
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $id: ID
    $tagName: ModelStringKeyConditionInput
    $filter: ModelTagsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTags(
      id: $id
      tagName: $tagName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        merchantId
        tagName
        tagType
        highlights
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDealApproval = /* GraphQL */ `
  query GetDealApproval($pk: ID!) {
    getDealApproval(pk: $pk) {
      pk
      isApproved
      deal {
        address
        aggregator
        approval {
          pk
          isApproved
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          createdYearMonth
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        purchasableTexts
        showInOutletIds
        additionalPromotionInfo
        ticketType
        minQtyTx
      }
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      createdYearMonth
    }
  }
`;
export const listDealApprovals = /* GraphQL */ `
  query ListDealApprovals(
    $pk: ID
    $filter: ModelDealApprovalFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDealApprovals(
      pk: $pk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        isApproved
        deal {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          purchasableTexts
          showInOutletIds
          additionalPromotionInfo
          ticketType
          minQtyTx
        }
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        createdYearMonth
      }
      nextToken
    }
  }
`;
export const getDealMaster = /* GraphQL */ `
  query GetDealMaster($pk: ID!) {
    getDealMaster(pk: $pk) {
      address
      aggregator
      approval {
        pk
        isApproved
        deal {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          purchasableTexts
          showInOutletIds
          additionalPromotionInfo
          ticketType
          minQtyTx
        }
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        createdYearMonth
      }
      area
      bank
      brand
      mainCat
      cc_buddy_category
      ccbuddy_card_name
      country
      deal_handler
      display_merchant_name
      district
      district_name
      end
      end_timestamp
      festive
      general_location
      geohashlong
      geohashshort
      google_id
      google_placeid
      google_type
      image_path
      image_url
      is_online
      isSponsored
      sponsoredRank
      latitude
      longitude
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_unique_id
      online_company
      phone
      pk
      postal_code
      promo_code
      promotion
      promotion_analytic
      promotion_caption
      rank
      rank_updated
      score
      sector
      start
      start_timestamp
      store_id
      subcategory
      TR_tags
      tags {
        items {
          id
          merchantId
          tagName
          tagType
          highlights
          createdAt
          updatedAt
        }
        nextToken
      }
      terms
      usable_promocode
      valid
      website
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      isPinned
      pinPageLocation
      pinOrder
      purchasable
      actualPrice
      discount
      discountPrice
      purchasePrice
      purchaseLimitPerCustomer
      purchasableTexts
      showInOutletIds
      additionalPromotionInfo
      ticketType
      minQtyTx
    }
  }
`;
export const listDealMasters = /* GraphQL */ `
  query ListDealMasters(
    $pk: ID
    $filter: ModelDealMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDealMasters(
      pk: $pk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        address
        aggregator
        approval {
          pk
          isApproved
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          createdYearMonth
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        purchasableTexts
        showInOutletIds
        additionalPromotionInfo
        ticketType
        minQtyTx
      }
      nextToken
    }
  }
`;
export const getMerchantOutlet = /* GraphQL */ `
  query GetMerchantOutlet($merchant_id: ID!, $store_id: ID!) {
    getMerchantOutlet(merchant_id: $merchant_id, store_id: $store_id) {
      address
      deals {
        items {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          purchasableTexts
          showInOutletIds
          additionalPromotionInfo
          ticketType
          minQtyTx
        }
        nextToken
      }
      mainCat
      display_merchant_name
      distance
      general_location
      geohashlong
      geohashshort
      geohashsix
      google_id
      google_placeid
      google_type
      image_path
      image_src
      latitude
      longitude
      mallId
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_namelist
      merchant_unique_id
      online_company
      outlet_name
      phone
      postal_code
      postal_district
      postal_district_name
      postal_sector
      protectedAttributes
      store_id
      subc_online
      subcategory
      valid
      merchantImageUrl
      merchantImageUrlV2
      defaultImageUrl
      businessSummary
      outletWebsite
      mcc
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      blkAptNum
      roadName
      buildingName
      unitNum
      country
      type
      hideOperatingHours
    }
  }
`;
export const listMerchantOutlets = /* GraphQL */ `
  query ListMerchantOutlets(
    $merchant_id: ID
    $store_id: ModelIDKeyConditionInput
    $filter: ModelMerchantOutletFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMerchantOutlets(
      merchant_id: $merchant_id
      store_id: $store_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        address
        deals {
          nextToken
        }
        mainCat
        display_merchant_name
        distance
        general_location
        geohashlong
        geohashshort
        geohashsix
        google_id
        google_placeid
        google_type
        image_path
        image_src
        latitude
        longitude
        mallId
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_namelist
        merchant_unique_id
        online_company
        outlet_name
        phone
        postal_code
        postal_district
        postal_district_name
        postal_sector
        protectedAttributes
        store_id
        subc_online
        subcategory
        valid
        merchantImageUrl
        merchantImageUrlV2
        defaultImageUrl
        businessSummary
        outletWebsite
        mcc
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        blkAptNum
        roadName
        buildingName
        unitNum
        country
        type
        hideOperatingHours
      }
      nextToken
    }
  }
`;
export const getMerchant = /* GraphQL */ `
  query GetMerchant($merchant_id: ID!) {
    getMerchant(merchant_id: $merchant_id) {
      mainCat
      outlets {
        items {
          address
          mainCat
          display_merchant_name
          distance
          general_location
          geohashlong
          geohashshort
          geohashsix
          google_id
          google_placeid
          google_type
          image_path
          image_src
          latitude
          longitude
          mallId
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_namelist
          merchant_unique_id
          online_company
          outlet_name
          phone
          postal_code
          postal_district
          postal_district_name
          postal_sector
          protectedAttributes
          store_id
          subc_online
          subcategory
          valid
          merchantImageUrl
          merchantImageUrlV2
          defaultImageUrl
          businessSummary
          outletWebsite
          mcc
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          blkAptNum
          roadName
          buildingName
          unitNum
          country
          type
          hideOperatingHours
        }
        nextToken
      }
      display_merchant_name
      google_type
      merchant_id
      merchant_name
      merchant_name_lowercase
      online_company
      valid
      acceptCC
      merchantImageUrl
      merchantImageUrlV2
      defaultImageUrl
      pockCalMerchImage
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      website
      summary
      cmsPlan
      stripeExpressConnectedAccountId
    }
  }
`;
export const listMerchants = /* GraphQL */ `
  query ListMerchants(
    $merchant_id: ID
    $filter: ModelMerchantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMerchants(
      merchant_id: $merchant_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        mainCat
        outlets {
          nextToken
        }
        display_merchant_name
        google_type
        merchant_id
        merchant_name
        merchant_name_lowercase
        online_company
        valid
        acceptCC
        merchantImageUrl
        merchantImageUrlV2
        defaultImageUrl
        pockCalMerchImage
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        website
        summary
        cmsPlan
        stripeExpressConnectedAccountId
      }
      nextToken
    }
  }
`;
export const getTopicNotifications = /* GraphQL */ `
  query GetTopicNotifications($notificationId: ID!) {
    getTopicNotifications(notificationId: $notificationId) {
      notificationId
      topicName
      title
      message
      customUrl
      publishTimestamp
      bigText
      createdAt
      updatedAt
      createdBy
      isActivated
      csvFileName
    }
  }
`;
export const listTopicNotifications = /* GraphQL */ `
  query ListTopicNotifications(
    $notificationId: ID
    $filter: ModelTopicNotificationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTopicNotifications(
      notificationId: $notificationId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        notificationId
        topicName
        title
        message
        customUrl
        publishTimestamp
        bigText
        createdAt
        updatedAt
        createdBy
        isActivated
        csvFileName
      }
      nextToken
    }
  }
`;
export const getCoupons = /* GraphQL */ `
  query GetCoupons($pk: ID!) {
    getCoupons(pk: $pk) {
      pk
      type
      merchantId
      merchantName
      merchantImage
      title
      promoCode
      terms
      valid
      createdAt
      updatedAt
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $pk: ID
    $filter: ModelCouponsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCoupons(
      pk: $pk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        type
        merchantId
        merchantName
        merchantImage
        title
        promoCode
        terms
        valid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategoryBestDeals = /* GraphQL */ `
  query GetCategoryBestDeals($categoryBestDealsId: ID!) {
    getCategoryBestDeals(categoryBestDealsId: $categoryBestDealsId) {
      categoryBestDealsId
      content
      seeMoreShown
      mainCat
      valid
      title
      createdAt
      updatedAt
    }
  }
`;
export const listCategoryBestDeals = /* GraphQL */ `
  query ListCategoryBestDeals(
    $categoryBestDealsId: ID
    $filter: ModelCategoryBestDealsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCategoryBestDeals(
      categoryBestDealsId: $categoryBestDealsId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        categoryBestDealsId
        content
        seeMoreShown
        mainCat
        valid
        title
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($activityId: ID!) {
    getActivity(activityId: $activityId) {
      activityId
      activityDesc
      coinValue
      bonusValue
      order
      repeat
      route
      subTitle
      title
      type
      createdAt
      updatedAt
    }
  }
`;
export const listActivities = /* GraphQL */ `
  query ListActivities(
    $activityId: ID
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listActivities(
      activityId: $activityId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        activityId
        activityDesc
        coinValue
        bonusValue
        order
        repeat
        route
        subTitle
        title
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDailyTips = /* GraphQL */ `
  query GetDailyTips($id: ID!) {
    getDailyTips(id: $id) {
      id
      cardId
      dealId
      route
      category
      day
      title
      description
      displayType
      displayDate
      icon
      theme
      createdAt
      updatedAt
    }
  }
`;
export const listDailyTips = /* GraphQL */ `
  query ListDailyTips(
    $id: ID
    $filter: ModelDailyTipsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDailyTips(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        cardId
        dealId
        route
        category
        day
        title
        description
        displayType
        displayDate
        icon
        theme
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCMSUser = /* GraphQL */ `
  query GetCMSUser($email: ID!) {
    getCMSUser(email: $email) {
      email
      firstName
      lastName
      mobile
      roles
      marketingConsent
      agreeToTerms
      subPlan
      subPlanStartDate
      subPlanEndDate
      createdAt
      updatedAt
      profileImage
      stripe {
        items {
          accountId
          email
          merchantId
          type
          journeyStep
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      corpName
      uen
      verified
    }
  }
`;
export const listCMSUsers = /* GraphQL */ `
  query ListCMSUsers(
    $email: ID
    $filter: ModelCMSUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCMSUsers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        firstName
        lastName
        mobile
        roles
        marketingConsent
        agreeToTerms
        subPlan
        subPlanStartDate
        subPlanEndDate
        createdAt
        updatedAt
        profileImage
        stripe {
          nextToken
        }
        corpName
        uen
        verified
      }
      nextToken
    }
  }
`;
export const getCMSUserStripeAccount = /* GraphQL */ `
  query GetCMSUserStripeAccount($accountId: ID!) {
    getCMSUserStripeAccount(accountId: $accountId) {
      accountId
      email
      merchantId
      type
      journeyStep
      status
      createdAt
      updatedAt
    }
  }
`;
export const listCMSUserStripeAccounts = /* GraphQL */ `
  query ListCMSUserStripeAccounts(
    $accountId: ID
    $filter: ModelCMSUserStripeAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCMSUserStripeAccounts(
      accountId: $accountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accountId
        email
        merchantId
        type
        journeyStep
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVoucherMaster = /* GraphQL */ `
  query GetVoucherMaster($id: ID!) {
    getVoucherMaster(id: $id) {
      id
      dealId
      code
      codeType
      startTimestamp
      endTimestamp
      userId
      status
      valid
      transactionId
      retrievalTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const listVoucherMasters = /* GraphQL */ `
  query ListVoucherMasters(
    $id: ID
    $filter: ModelVoucherMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVoucherMasters(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        dealId
        code
        codeType
        startTimestamp
        endTimestamp
        userId
        status
        valid
        transactionId
        retrievalTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVoucherUser = /* GraphQL */ `
  query GetVoucherUser($masterId: String!) {
    getVoucherUser(masterId: $masterId) {
      masterId
      dealId
      userId
      code
      startTimestamp
      endTimestamp
      status
      transactionId
      redeemTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const listVoucherUsers = /* GraphQL */ `
  query ListVoucherUsers(
    $masterId: String
    $filter: ModelVoucherUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVoucherUsers(
      masterId: $masterId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        masterId
        dealId
        userId
        code
        startTimestamp
        endTimestamp
        status
        transactionId
        redeemTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDynamicContent = /* GraphQL */ `
  query GetDynamicContent($contentId: String!) {
    getDynamicContent(contentId: $contentId) {
      contentId
      valid
      type
      order
      title
      tag
      pinPageLocation
      seeMoreEnabled
      startTimestamp
      endTimestamp
      bannerLinked
      bannerContent
      bannerPageImageUrl
      iconImageUrl
      route
      createdAt
      updatedAt
    }
  }
`;
export const listDynamicContents = /* GraphQL */ `
  query ListDynamicContents(
    $contentId: String
    $filter: ModelDynamicContentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDynamicContents(
      contentId: $contentId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        contentId
        valid
        type
        order
        title
        tag
        pinPageLocation
        seeMoreEnabled
        startTimestamp
        endTimestamp
        bannerLinked
        bannerContent
        bannerPageImageUrl
        iconImageUrl
        route
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDiscountMaster = /* GraphQL */ `
  query GetDiscountMaster($id: String!) {
    getDiscountMaster(id: $id) {
      id
      valid
      status
      campaignId
      createdAt
      updatedAt
    }
  }
`;
export const listDiscountMasters = /* GraphQL */ `
  query ListDiscountMasters(
    $id: String
    $filter: ModelDiscountMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDiscountMasters(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        valid
        status
        campaignId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDiscountCampaign = /* GraphQL */ `
  query GetDiscountCampaign($campaignId: String!, $code: String!) {
    getDiscountCampaign(campaignId: $campaignId, code: $code) {
      campaignId
      code
      discountType
      terms
      usageLimitPerUser
      usageCapacity
      description
      discountLevel
      provider
      amount
      minimumSpend
      valid
      startTimestamp
      endTimestamp
      createdAt
      updatedAt
      multiplier
      dealSpecific
      dealExclusion
      merchantExclusion
      merchantSpecific
      capped
      appliesTo
      applicableOn
      multiplierLifetimeQty
    }
  }
`;
export const listDiscountCampaigns = /* GraphQL */ `
  query ListDiscountCampaigns(
    $campaignId: String
    $code: ModelStringKeyConditionInput
    $filter: ModelDiscountCampaignFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDiscountCampaigns(
      campaignId: $campaignId
      code: $code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        campaignId
        code
        discountType
        terms
        usageLimitPerUser
        usageCapacity
        description
        discountLevel
        provider
        amount
        minimumSpend
        valid
        startTimestamp
        endTimestamp
        createdAt
        updatedAt
        multiplier
        dealSpecific
        dealExclusion
        merchantExclusion
        merchantSpecific
        capped
        appliesTo
        applicableOn
        multiplierLifetimeQty
      }
      nextToken
    }
  }
`;
export const getPromoCodeMaster = /* GraphQL */ `
  query GetPromoCodeMaster($promoCodeId: String!, $campaignId: String!) {
    getPromoCodeMaster(promoCodeId: $promoCodeId, campaignId: $campaignId) {
      promoCodeId
      campaignId
      codeType
      countAssigned
      countRedeemed
      endTimestamp
      retrievalTimestamp
      startTimestamp
      usageStatus
      userId
      valid
      validityType
      createdAt
      updatedAt
    }
  }
`;
export const listPromoCodeMasters = /* GraphQL */ `
  query ListPromoCodeMasters(
    $promoCodeId: String
    $campaignId: ModelStringKeyConditionInput
    $filter: ModelPromoCodeMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPromoCodeMasters(
      promoCodeId: $promoCodeId
      campaignId: $campaignId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        promoCodeId
        campaignId
        codeType
        countAssigned
        countRedeemed
        endTimestamp
        retrievalTimestamp
        startTimestamp
        usageStatus
        userId
        valid
        validityType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAdvertisement = /* GraphQL */ `
  query GetAdvertisement($advertisementId: String!) {
    getAdvertisement(advertisementId: $advertisementId) {
      advertisementId
      assetUrl
      buttonText
      category
      frequency
      name
      order
      route
      guest
      loggedIn
      fromAd
      skipTime
      type
      valid
      videoDuration
      createdAt
      updatedAt
    }
  }
`;
export const listAdvertisements = /* GraphQL */ `
  query ListAdvertisements(
    $advertisementId: String
    $filter: ModelAdvertisementFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAdvertisements(
      advertisementId: $advertisementId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        advertisementId
        assetUrl
        buttonText
        category
        frequency
        name
        order
        route
        guest
        loggedIn
        fromAd
        skipTime
        type
        valid
        videoDuration
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getByKeyword = /* GraphQL */ `
  query GetByKeyword(
    $keyword: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSearchKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getByKeyword(
      keyword: $keyword
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        searchKeywordId
        keyword
        keywordLowerCase
        valid
        hot
        new
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getKeywordByValidStatus = /* GraphQL */ `
  query GetKeywordByValidStatus(
    $valid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSearchKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getKeywordByValidStatus(
      valid: $valid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        searchKeywordId
        keyword
        keywordLowerCase
        valid
        hot
        new
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBannerByValiditySortedOrder = /* GraphQL */ `
  query GetBannerByValiditySortedOrder(
    $valid: String!
    $order: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBannerByValiditySortedOrder(
      valid: $valid
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bannerId
        buttonText
        valid
        homeBannerImageUrl
        bannerPageImageUrl
        type
        order
        title
        content
        externalLinkActive
        externalLink
        campaignId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBannerByCampaign = /* GraphQL */ `
  query GetBannerByCampaign(
    $campaignId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBannerByCampaign(
      campaignId: $campaignId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bannerId
        buttonText
        valid
        homeBannerImageUrl
        bannerPageImageUrl
        type
        order
        title
        content
        externalLinkActive
        externalLink
        campaignId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tagById = /* GraphQL */ `
  query TagById(
    $id: ID!
    $tagName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagById(
      id: $id
      tagName: $tagName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantId
        tagName
        tagType
        highlights
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTagsByTagName = /* GraphQL */ `
  query GetTagsByTagName(
    $tagName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTagsByTagName(
      tagName: $tagName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantId
        tagName
        tagType
        highlights
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const approvalByYearMonth = /* GraphQL */ `
  query ApprovalByYearMonth(
    $createdYearMonth: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealApprovalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    approvalByYearMonth(
      createdYearMonth: $createdYearMonth
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        isApproved
        deal {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          purchasableTexts
          showInOutletIds
          additionalPromotionInfo
          ticketType
          minQtyTx
        }
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        createdYearMonth
      }
      nextToken
    }
  }
`;
export const dealByAggregator = /* GraphQL */ `
  query DealByAggregator(
    $aggregator: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDealMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealByAggregator(
      aggregator: $aggregator
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        address
        aggregator
        approval {
          pk
          isApproved
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          createdYearMonth
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        purchasableTexts
        showInOutletIds
        additionalPromotionInfo
        ticketType
        minQtyTx
      }
      nextToken
    }
  }
`;
export const dealByDealHandler = /* GraphQL */ `
  query DealByDealHandler(
    $deal_handler: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDealMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealByDealHandler(
      deal_handler: $deal_handler
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        address
        aggregator
        approval {
          pk
          isApproved
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          createdYearMonth
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        purchasableTexts
        showInOutletIds
        additionalPromotionInfo
        ticketType
        minQtyTx
      }
      nextToken
    }
  }
`;
export const dealByMerchantId = /* GraphQL */ `
  query DealByMerchantId(
    $merchant_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDealMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealByMerchantId(
      merchant_id: $merchant_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        address
        aggregator
        approval {
          pk
          isApproved
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          createdYearMonth
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        purchasableTexts
        showInOutletIds
        additionalPromotionInfo
        ticketType
        minQtyTx
      }
      nextToken
    }
  }
`;
export const dealByMerchantUniqueId = /* GraphQL */ `
  query DealByMerchantUniqueId(
    $merchant_unique_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDealMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealByMerchantUniqueId(
      merchant_unique_id: $merchant_unique_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        address
        aggregator
        approval {
          pk
          isApproved
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          createdYearMonth
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        purchasableTexts
        showInOutletIds
        additionalPromotionInfo
        ticketType
        minQtyTx
      }
      nextToken
    }
  }
`;
export const dealByMerchantUniqueIdUpdatedAt = /* GraphQL */ `
  query DealByMerchantUniqueIdUpdatedAt(
    $merchant_unique_id: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealByMerchantUniqueIdUpdatedAt(
      merchant_unique_id: $merchant_unique_id
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        address
        aggregator
        approval {
          pk
          isApproved
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          createdYearMonth
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        purchasableTexts
        showInOutletIds
        additionalPromotionInfo
        ticketType
        minQtyTx
      }
      nextToken
    }
  }
`;
export const getPinnedDeals = /* GraphQL */ `
  query GetPinnedDeals(
    $isPinned: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDealMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPinnedDeals(
      isPinned: $isPinned
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        address
        aggregator
        approval {
          pk
          isApproved
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          createdYearMonth
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        purchasableTexts
        showInOutletIds
        additionalPromotionInfo
        ticketType
        minQtyTx
      }
      nextToken
    }
  }
`;
export const outletByPostalCode = /* GraphQL */ `
  query OutletByPostalCode(
    $postal_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMerchantOutletFilterInput
    $limit: Int
    $nextToken: String
  ) {
    outletByPostalCode(
      postal_code: $postal_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        address
        deals {
          nextToken
        }
        mainCat
        display_merchant_name
        distance
        general_location
        geohashlong
        geohashshort
        geohashsix
        google_id
        google_placeid
        google_type
        image_path
        image_src
        latitude
        longitude
        mallId
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_namelist
        merchant_unique_id
        online_company
        outlet_name
        phone
        postal_code
        postal_district
        postal_district_name
        postal_sector
        protectedAttributes
        store_id
        subc_online
        subcategory
        valid
        merchantImageUrl
        merchantImageUrlV2
        defaultImageUrl
        businessSummary
        outletWebsite
        mcc
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        blkAptNum
        roadName
        buildingName
        unitNum
        country
        type
        hideOperatingHours
      }
      nextToken
    }
  }
`;
export const couponsByType = /* GraphQL */ `
  query CouponsByType(
    $type: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCouponsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    couponsByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        type
        merchantId
        merchantName
        merchantImage
        title
        promoCode
        terms
        valid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategoryBestDealsByMainCat = /* GraphQL */ `
  query GetCategoryBestDealsByMainCat(
    $mainCat: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryBestDealsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCategoryBestDealsByMainCat(
      mainCat: $mainCat
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        categoryBestDealsId
        content
        seeMoreShown
        mainCat
        valid
        title
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTipsByDate = /* GraphQL */ `
  query GetTipsByDate(
    $displayDate: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDailyTipsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTipsByDate(
      displayDate: $displayDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cardId
        dealId
        route
        category
        day
        title
        description
        displayType
        displayDate
        icon
        theme
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const stripeAccountByEmail = /* GraphQL */ `
  query StripeAccountByEmail(
    $email: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCMSUserStripeAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stripeAccountByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        email
        merchantId
        type
        journeyStep
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const stripeAccountByMerchantId = /* GraphQL */ `
  query StripeAccountByMerchantId(
    $merchantId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCMSUserStripeAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stripeAccountByMerchantId(
      merchantId: $merchantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        email
        merchantId
        type
        journeyStep
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const voucherByDealId = /* GraphQL */ `
  query VoucherByDealId(
    $dealId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVoucherMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    voucherByDealId(
      dealId: $dealId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dealId
        code
        codeType
        startTimestamp
        endTimestamp
        userId
        status
        valid
        transactionId
        retrievalTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const voucherByCode = /* GraphQL */ `
  query VoucherByCode(
    $code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVoucherMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    voucherByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dealId
        code
        codeType
        startTimestamp
        endTimestamp
        userId
        status
        valid
        transactionId
        retrievalTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const voucherUserByDealId = /* GraphQL */ `
  query VoucherUserByDealId(
    $dealId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVoucherUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    voucherUserByDealId(
      dealId: $dealId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        masterId
        dealId
        userId
        code
        startTimestamp
        endTimestamp
        status
        transactionId
        redeemTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const voucherByUserId = /* GraphQL */ `
  query VoucherByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVoucherUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    voucherByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        masterId
        dealId
        userId
        code
        startTimestamp
        endTimestamp
        status
        transactionId
        redeemTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const promoCodeByCampaignId = /* GraphQL */ `
  query PromoCodeByCampaignId(
    $campaignId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPromoCodeMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    promoCodeByCampaignId(
      campaignId: $campaignId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        promoCodeId
        campaignId
        codeType
        countAssigned
        countRedeemed
        endTimestamp
        retrievalTimestamp
        startTimestamp
        usageStatus
        userId
        valid
        validityType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const promoCodeByValidity = /* GraphQL */ `
  query PromoCodeByValidity(
    $valid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPromoCodeMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    promoCodeByValidity(
      valid: $valid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        promoCodeId
        campaignId
        codeType
        countAssigned
        countRedeemed
        endTimestamp
        retrievalTimestamp
        startTimestamp
        usageStatus
        userId
        valid
        validityType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
