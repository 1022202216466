import React, { Component } from 'react'
import { Form, Button, Toast } from "react-bootstrap"
import { API, graphqlOperation } from 'aws-amplify'
import Select from 'react-select';
// import CreatableSelect from 'react-select/creatable';
import { createActivity, updateActivity } from '../../graphql/mutations'
import { getActivity } from '../../graphql/queries'
import { USERCHALLENGE_TYPES, USERCHALLENGE_ACTIVITIES } from '../../utils/constants'
import { publish } from '../widgets/CMSModal'

class UserChallenge extends Component {

    constructor(props) {
        super(props)

        const activity = props.activity ? { ...props.activity } : {};
        this.state = {
            item: {
                type: activity.type ?? USERCHALLENGE_TYPES[0].value,
                title: activity.title ?? null,
                subTitle: activity.subTitle ?? null,
                activityId: activity.activityId ?? USERCHALLENGE_ACTIVITIES[0].value,
                activityDesc: activity.activityDesc ?? null,
                coinValue: activity.coinValue ?? '0',
                bonusValue: activity.bonusValue ?? '0',
                order: activity.order ?? '0',
                repeat: activity.repeat==="1" ?? false,
                route: activity.route ?? null,
            },
            showToast: false,
            selectedType: activity.type ? USERCHALLENGE_TYPES.find(o => o.value === activity.type) : USERCHALLENGE_TYPES[0],
            selectedActivity: activity.activityId ? USERCHALLENGE_ACTIVITIES.find(o => o.value === activity.activityId) : USERCHALLENGE_ACTIVITIES[0]
        };
    }

    alert(msg, callback) {
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    setChallengeType(activity) {
        this.state.item.type = activity.value
        this.setState({
            item: this.state.item,
            selectedType: activity
        })
    }

    valuesVerified() {
        const { activityId } = this.state.item
        
        return new Promise(async (resolve, reject) => {
            if (activityId) {
                const result = await API.graphql(graphqlOperation(getActivity, { activityId: activityId }))
                const temp = result.data.getActivity
                if (temp && temp.activityId) {
                    if (this.props.mode === 'create') {
                        alert('Duplicate activity id.')
                        return resolve(false)
                    } else if(this.props.mode === 'update' && activityId.toLowerCase() !== temp.activityId.toLowerCase()) {
                        alert('Duplicate activity id.')
                        return resolve(false)
                    }
                }
            } else {
                alert('Missing activity id')
                return resolve(false)
            }

            const coinValue = Number(this.state.item.coinValue)
            if (!coinValue || !Number.isInteger(coinValue) || coinValue <= 0) {
                alert('Invalid value for coin. Should only be integer and greater than zero')
                return resolve(false)
            }

            const order = Number(this.state.item.order)
            if (!Number.isInteger(order) || order < 0) {
                alert('Invalid value for order. Should only be integer and greater or equal to zero')
                return resolve(false)
            }

            resolve(true)
        })
    }

    async handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        // verify values if correct
        const isValid = await this.valuesVerified()
        if (!isValid) return

        // compile to make it ready for submission to BE
        const activity = {
            type: this.state.item.type,
            title: this.state.item.title,
            subTitle: this.state.item.subTitle || '',
            activityId: this.state.item.activityId,
            activityDesc: this.state.item.activityDesc,
            coinValue: this.state.item.coinValue.toString(),
            bonusValue: this.state.item.bonusValue.toString(),
            order: this.state.item.order.toString(),
            repeat: this.state.item.repeat ? "1" : "0",
            route: this.state.item.route || ''
        };
        console.log({activity})
        if (this.props.mode === 'create') {
            this.handleCreate(activity);
        } else if (this.props.mode === 'update') {
            this.handleUpdate(activity);
        }
    }

    handleCreate(activity) {
        API.graphql(graphqlOperation(createActivity, { input: activity })).then((result) => {
            this.setState({ showToast: true });
            if (this.props.onCreate) {
                this.props.onCreate(result.data);
            }
        })
    }

    handleUpdate(activity) {
        API.graphql(graphqlOperation(updateActivity, { input: activity })).then((result) => {
            this.setState({ showToast: true });
            if (this.props.onUpdate) {
                this.props.onUpdate(result.data);
            }
        });
    }

    handleChange(e, fieldname) {
        const item = { ...this.state.item };
        const target = e.target;
        if (target.type === 'checkbox') {
            item[fieldname] = e.target.checked ? true : false;
        } else {
            if (fieldname === 'coinValue' || fieldname === 'order' || fieldname === 'bonusValue') {
                const numbers = fieldname === 'coinValue' ? /^[1-9][0-9]*$/ : /[0-9]*$/
                const max = /^\d{1,9}$/
                if (target.value === '' || (numbers.test(target.value) && max.test(target.value)))
                    item[fieldname] = e.target.value;    
            } else {
                item[fieldname] = e.target.value;
            }
        }
        this.setState({ item: item });
    }

    getActivityById(activityId) {
        API.graphql(graphqlOperation(getActivity, { activityId: activityId })).then((result) => {
            return result.data.getActivity.items ||  {}
        });
    }

    handleSelectActivityChange(i, meta) {
        if (i && i.value) {
            const item = { ...this.state.item };
            item.activityId = i.value
            item.activityDesc = i.label
            this.setState({ 
                item,
                selectedActivity:  { value: item.activityId, label: item.activityDesc }
            })
        } else if (meta.action==='clear') {
            const item = { ...this.state.item };
            item.activityId = USERCHALLENGE_TYPES[0].value
            item.activityDesc = USERCHALLENGE_TYPES[0].label
            this.setState({ 
                item,
                selectedActivity:  USERCHALLENGE_TYPES[0]
            })
        }
    }

    // handleInputActivityChange(i) {
    //     if (i) {
    //         const item = { ...this.state.item };
    //         if (item) {
    //             item.activityDesc = i
    //             item.activityId = i
    //             this.setState({ 
    //                 item,
    //                 selectedActivity: i
    //             })
    //         }
    //     }
    // }

    render() {
        const  { showToast, selectedType, selectedActivity, item } = this.state
        const {title, subTitle, activityId, bonusValue, activityDesc, coinValue, order, repeat, route} = item
        
        let activityStyles = { color: 'red' }
        if (this.props.mode==='update') {
            activityStyles = {
                fontWeight: "700",
                color: '#440099'
            }
        }
        return <div>
            {this.props.show ?
                <Form onSubmit={(e) => this.handleSubmit(e)}>

                    <h3>{this.props.mode==='create' ? 'Create User Challenge' : 'Update User Challenge'}</h3>
                    <Form.Group>
                        <Form.Label>Type *</Form.Label>
                        <Select
                            closeMenuOnSelect={true}
                            placeholder="Choose the type of user challenge"
                            options={USERCHALLENGE_TYPES}
                            value={selectedType || USERCHALLENGE_TYPES[0]}
                            onChange={(type) => this.setChallengeType(type)}
                        />
                    </Form.Group>    
                    <Form.Group>
                        <Form.Label>Activity Id (should be unique regardless of Type and non-editable after saved) *</Form.Label>
                        <Select 
                            isClearable
                            closeMenuOnSelect={true}
                            value = {selectedActivity}
                            onChange = {(activity, action) => this.handleSelectActivityChange(activity, action)}
                            // onInputChange = {(activity, action) => this.handleInputActivityChange(activity, action)}
                            options = {USERCHALLENGE_ACTIVITIES}
                            isDisabled = {this.props.mode==='update'}
                            styles = {{
                                singleValue: base => ({ ...base, ...activityStyles })
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Activity Description *</Form.Label>
                        <Form.Control type="text" required value={activityDesc} onChange={(e) => this.handleChange(e, 'activityDesc')} />
                    </Form.Group>              
                    <Form.Group>
                        <Form.Label>Title *</Form.Label>
                        <Form.Control type="text" required value={title} onChange={(e) => this.handleChange(e, 'title')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>SubTitle</Form.Label>
                        <Form.Control type="text" value={subTitle} onChange={(e) => this.handleChange(e, 'subTitle')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Coin Value *</Form.Label>
                        <Form.Control type="text" required value={coinValue ?? '0'} onChange={(e) => this.handleChange(e, 'coinValue')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Bonus Value *</Form.Label>
                        <Form.Control type="text" required value={bonusValue ?? '0'} onChange={(e) => this.handleChange(e, 'bonusValue')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Order</Form.Label>
                        <Form.Control type="text" value={order ?? '0'} onChange={(e) => this.handleChange(e, 'order')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check label="Does this challenge repeat?" checked={repeat} onChange={(e) => this.handleChange(e, 'repeat')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Route</Form.Label>
                        <Form.Control type="text" value={route} placeholder="route=signUp" onChange={(e) => this.handleChange(e, 'route')} />
                    </Form.Group>
                    <Form.Group>
                        <Toast onClose={() => this.setState({ showToast: false })} show={showToast} delay={3000} autohide>
                            {this.props.mode==='create'?
                                <Toast.Body>User Challenge Created.</Toast.Body>
                            : null}
                            {this.props.mode==='update'?
                                <Toast.Body>User Challenge Updated.</Toast.Body>
                            : null}
                        </Toast>
                            {this.props.mode==='create'?
                                <Button type="submit">Create User Challenge</Button>
                            : null}
                            {this.props.mode==='update'?
                                <Button type="submit">Update User Challenge</Button>
                            : null}
                    </Form.Group>

                </Form>
                : null}
        </div>
            ;
    }
}

export default UserChallenge