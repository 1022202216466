
import React, { Component } from 'react'
import { Card } from "react-bootstrap"
import { FaEye, FaHourglassHalf, FaUserLock, FaEyeSlash } from 'react-icons/fa'

class CMSLegend extends Component {

    constructor() {
        super()
        
        this.state = {
            show: true
        }
    }

    async open() {
        this.setState({
            show: true
        })
    }

    async close() {
        this.setState({
            show: false
        })
    }

    render() {
        return (
            <div>
                { this.state.show ?
                    <Card style={{ width: '13rem', position: 'fixed', bottom: 0 }}>
                        <Card.Body>
                            <Card.Title>
                                <p className="float-right" onClick={() => this.close()}>x</p>
                            </Card.Title>
                            <Card.Text>
                                    <FaEye color="green" /> Visible in App
                            </Card.Text>
                            <Card.Text>
                                    <FaEyeSlash color="red" /> Not visible in App
                            </Card.Text>
                            <Card.Text>
                                    <FaUserLock color="red" /> No update access
                            </Card.Text>
                            <Card.Text>
                                    <FaHourglassHalf color="red" /> Pending Approval
                            </Card.Text>
                        </Card.Body>
                    </Card>
                : <div  style={{ position: 'fixed', bottom: 0 }} onClick={() => this.open() }>
                    ...
                </div>
                }
            </div>
        )
    }
}

export default CMSLegend