import { Auth } from '@aws-amplify/auth'
export default class ACL {

    static async getAttributes() {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
        console.log(user)
        return user.attributes
    }

    static async getGroups() {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
        const groups = user.signInUserSession.accessToken.payload['cognito:groups']

        return groups
    }

    static async isAdmin(g) {

        const groups = g || await this.getGroups()
        if (groups) {
            return groups.includes('admin')
        }

        return false
    }

    static async isBank(g) {

        const groups = g || await this.getGroups()
        if (groups) {
            return groups.includes('bank')
        }

        return false
    }

    static async isMerchant(g) {

        const groups = g || await this.getGroups()
        if (groups) {
            return groups.includes('merchant')
        }

        return false
    }

    static async isHandler(g) {

        const groups = g || await this.getGroups()
        if (groups) {
            return groups.includes('handler')
        }

        return false
    }

    static async isAggregator(g) {

        const groups = g || await this.getGroups()
        if (groups) {
            return groups.includes('aggregator')
        }

        return false
    }

    static async isPostal(g) {

        const groups = g || await this.getGroups()
        if (groups) {
            return groups.includes('postal')
        }

        return false
    }

    static async isOwner(item, g) {
        const groups = g || await this.getGroups()
        const itemGroups = item ? item.createdByGroups : null
        if (itemGroups) {
            for (const group of groups) {
                if (itemGroups.includes(group)) {
                    return true
                }
            }
        }

        return false
    }

    static async getBanksFromGroups(g) {
        const groups = g || await this.getGroups()
        const merchantGroups = groups.filter((group) => group.startsWith('bank_'))
        const merchants = merchantGroups.map(group => group.substring('bank_'.length))
        return merchants
    }

    static async getMerchantsFromGroups(g) {
        const groups = g || await this.getGroups()
        const merchantGroups = groups.filter((group) => group.startsWith('merchant_'))
        const merchants = merchantGroups.map(group => group.substring('merchant_'.length))
        return merchants
    }

    static async getAggregatorsFromGroups(g) {
        const groups = g || await this.getGroups()
        const aggregatorGroups = groups.filter((group) => group.startsWith('aggregator_'))
        const aggregators = aggregatorGroups.map(group => group.substring('aggregator_'.length))
        return aggregators
    }

    static async getHandlersFromGroups(g) {
        const groups = g || await this.getGroups()
        const handlerGroups = groups.filter((group) => group.startsWith('handler_'))
        const handlers = handlerGroups.map(group => group.substring('handler_'.length))
        return handlers
    }

    static async getPostalsFromGroups(g) {
        const groups = g || await this.getGroups()
        const postalGroups = groups.filter((group) => group.startsWith('postal_'))
        const postals = postalGroups.map(group => group.substring('postal_'.length))
        return postals
    }

    static async canWriteMerchant(merchant, g) {
        
        // pass in groups to avoid excessive calls if this function is called repeatedly in a short time
        const groups = g || await this.getGroups()

        if (await this.isAdmin(groups)) {
            return true
        }

        if (await this.isOwner(merchant, groups)) {
            return true
        }

        if (await this.isMerchant(groups)) {
            return groups.includes(`merchant_${merchant.merchant_id}`)
        }

        return false
    }

    static async canWriteOutlet(outlet, g) {

        // pass in groups to avoid excessive calls if this function is called repeatedly in a short time
        const groups = g || await this.getGroups()

        if (await this.isAdmin(groups)) {
            return true
        }

        if (await this.isOwner(outlet, groups)) {
            return true
        }

        if (await this.isMerchant(groups)) {
            return groups.includes(`merchant_${outlet.merchant_id}`)
        }

        if (await this.isPostal(groups)) {
            return groups.includes(`postal_${outlet.postal_code}`)
        }

        return false
    }

    static async canWriteDeal(deal, g) {

        // pass in groups to avoid excessive calls if this function is called repeatedly in a short time
        const groups = g || await this.getGroups()

        if (await this.isAdmin(groups)) {
            return true
        }

        if (await this.isOwner(deal, groups)) {
            return true
        }

        if (await this.isHandler(groups)) {
            return groups.includes(`handler_${deal.deal_handler}`)
        }

        if (await this.isAggregator(groups)) {
            return groups.includes(`aggregator_${deal.aggregator}`)
        }

        if (await this.isBank(groups)) {
            return groups.includes(`bank_${deal.bank}`)
        }

        if (await this.isMerchant(groups)) {
            return groups.includes(`merchant_${deal.merchant_id}`)
        }

        if (await this.isPostal(groups)) {
            return groups.includes(`postal_${deal.postal_code}`)
        }

        return false
    }
}