import React, { Component } from 'react';
import { Container, Row, Col, Spinner, ListGroup } from 'react-bootstrap';
import { FaPlus } from "react-icons/fa";
import moment from 'moment'
import { API, graphqlOperation } from 'aws-amplify'
import { listTopicNotifications, getTopicNotifications } from '../../graphql/queries';
import Notification from '../widgets/Notification'

class NotificationView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            notification: null,
            isLoading: false
        }
    }

    async componentDidMount() {
        this.setState({
            isLoading: true
        });

        API.graphql(graphqlOperation(listTopicNotifications)).then((result) => {
            result.data.listTopicNotifications.items.sort((a,b)=>parseInt(b.publishTimestamp) - parseInt(a.publishTimestamp))
            this.setState({ list: result.data.listTopicNotifications.items, isLoading: false });
        }).catch(e => this.setState({isLoading: false}))       
    }

    selectNotification(e, notification) {
        e.preventDefault()
        e.stopPropagation()
        
        API.graphql(graphqlOperation(getTopicNotifications,{notificationId: notification.notificationId})).then((result) => {
            const notifications = result.data.getTopicNotifications ?? null
            this.setState({
                notification: notification,
                mode: 'updateNotification'
            })
        })        
    }

    createNotification(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            mode: 'createNotification'
        })
    }

    render() {
        const { list, notification, mode } = this.state;
        
        return <Container fluid>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h3>Notification</h3>
                        </Col>
                        <Col>
                            <FaPlus className="float-right" onClick={(e) => this.createNotification(e)} />
                        </Col>
                    </Row>
                    <Row>
                    { this.state.isLoading ? 
                        <Spinner animation="border" />
                    : 
                        <Col className="scrollview">
                            <ListGroup variant="flush">
                            {
                                list.length > 0 ?
                                    list.map((item) =>
                                        <ListGroup.Item key={`list-campaign-${item.notificationId}`} action onClick={(e) => this.selectNotification(e, item)}>
                                            <div>
                                                { item.title } [ {item.topicName} ]
                                            </div>
                                            { item.isActivated === 'True' ?
                                                <small style={{ textDecorationLine: 'line-through' }}>Publish: { moment.unix(item.publishTimestamp).format('YYYY-MM-DD @ HH:00:00')}</small>
                                            :
                                                <small>Publish: { moment.unix(item.publishTimestamp).format('YYYY-MM-DD @ HH:00:00')}</small>
                                            }
                                        </ListGroup.Item>
                                    )
                                : 'No Notification'
                            }
                            </ListGroup>
                        </Col>
                    }
                    </Row>
                </Col>

                <Col className="scrollview">
                    <Notification
                        mode='create'
                        key={`create-notification`}
                        show={mode === 'createNotification'}
                    />

                    <Notification
                        mode='update'
                        key={`update-notification-${notification ? notification.notificationId : ''}`}
                        notification={notification}
                        show={mode === 'updateNotification'}
                    />
                </Col>
            </Row>            
        </Container>
    }
}

export default NotificationView