export const COUPON_TYPES = [
    {value: 'food', label: 'Food'}, 
    {value: 'grocery', label: 'Grocery'}, 
    {value: 'online', label: 'Online'}
]

export const USERCHALLENGE_TYPES = [
    {value: 'dailyLogin', label: 'Daily Login'},
    {value: 'challenge', label: 'Challenge'}
]

export const USERCHALLENGE_ACTIVITIES = [
    {value: 'dailyLogin1', label: 'First login of the week'},
    {value: 'dailyLogin2', label: 'Second login of the week'},
    {value: 'dailyLogin3', label: 'Third login of the week'},
    {value: 'userRegistered', label: 'User register'},
    {value: 'tryCardculator', label: 'Try Cardculator'},
    {value: 'saveDeal', label: 'Save a deal'},
    {value: 'addCard', label: 'Add 1 card'},
]

export const DAILY_TIPS_CATEGORY = [
    {value: 'wellness', label: 'Wellness'},
    {value: 'finance', label: 'Finance'},
    {value: 'chill', label: 'Chill'},
    {value: 'dining', label: 'Dining'},
    {value: 'activity', label: 'Activity'},
]

export const CAMPAIGN_NOTIF_SCHEDULE = [
    {value: '7_DAYS_AFTER_AVAILMENT', label:'7th day after user added the voucher'},
    {value: '3_DAYS_BEFORE_EXPIRY', label:'3 days before voucher expiry'}
]

export const TZ = 'Asia/Singapore'

export const DYNAMIC_CONTENT_TYPE = [
    {value: 'flash', label: 'flash'},
    {value: 'regular', label: 'regular'},
    {value: 'article', label: 'article'}
]

export const DISCOUNT_LEVEL = [
    {value: "public", label: "Public"},
    {value: "merchant", label: "Merchant"},
    {value: "outlet", label: "Outlet"},
    {value: "deal", label: "Deal"}
]
export const DISCOUNT_TYPE = [
    { value: "fixed", label: "Fixed amount ($ discount)" },
    { value: "percentage", label: "Percentage (% discount)" }
]

export const APPLIES_TO = [
    { value: "site-wide", label: "Site-wide orders" },
    { value: "specific-deal", label: "Specific deals orders" },
    { value: "specific-merchant", label: "Specific merchants orders"}
]

export const APPLICABLE_ON = [
    { value: "transaction", label: "Per transaction" },
    { value: "quantity", label: "Per quantity" }
]