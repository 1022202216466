import React, { Component } from 'react'
import { Col, Row, Image, Spinner, Form, ListGroup, InputGroup, Button, Modal } from "react-bootstrap"
import { FaSearch, FaStore, FaEye, FaEyeSlash, FaUserLock, FaEdit } from "react-icons/fa"
import 'bootstrap/dist/css/bootstrap.min.css'
import { API, graphqlOperation } from 'aws-amplify'
import { debounce } from 'lodash'
import ACL from '../../utils/ACL'

import { getMerchant } from '../../graphql/queries'
import { searchMerchants } from '../../graphql/custom'

import Merchant from './Merchant'
class ListMerchants extends Component {
  apis = []
  cancelDebounce = false

  constructor(props) {
    super(props)
    
    this.state = {
      merchants: this.props.merchants??[],
      merchant: (this.props.merchants&&this.props.merchants.length>0)?this.props.merchants[0]:null,
      searchText: '',
    };
  }

  async componentDidMount() {

    this.setState({
      isAdmin: await ACL.isAdmin(),
      isBank: await ACL.isBank()
    })

    if (this.props.merchantId) {
      const result = await API.graphql(graphqlOperation(getMerchant, {merchant_id: this.props.merchantId}))
      
      if (result.data.getMerchant) {
        this.setState({
          searchText: result.data.getMerchant.merchant_name
        })
      }
    }
  }

  async clearSearch() {
    this.setState({
      searchText: '',
      merchants: []
    })
  }

  debounceSearch = debounce((text) => {
    // must stop debounce function calling additional apis
    if (this.apis.length > 0) {
      this.cancelDebounce = true
      this.waitCancelDebounce()
    }

    this.searchMerchants({
      text: text
    }).then(() => {
    })
  }, 1000)

  waitCancelDebounce() {
    setTimeout(() => {
      if (this.cancelDebounce) {
        this.waitCancelDebounce()
      }
    }, 1000)
  }

  async handleSearch(e) {
    const text = e.target.value
    if (text.trim() !== '') {
      if (text.length >= 2) {
        this.debounceSearch(text)
      }
    } else {
      this.clearSearch()
    }

    this.setState({
      searchText: text,
      selectedSearchMerchant: null,
      merchants: []
    })

    if (this.props.onChange) {
      // return original text value
      this.props.onChange(e, e.target.value)
    }
  }

  async searchMerchants(params) {
    const terms = params.text.toLowerCase().split(' ')
    const filters = []
    
    filters.push({
      merchant_name: {
        matchPhrasePrefix: params.text
      }
    })
    filters.push({
      merchant_id: {
        match: params.text
      }
    })
    filters.push({
      mainCat: {
        match: params.text
      }
    })

    const search = {
      filter: {
        or : filters
      }
    }

    this.setState({
      isLoading: true
    })

    console.log('search filter', search)
    let merchants = []
    do {
      const api = API.graphql(graphqlOperation(searchMerchants, search))
      this.apis.push(api)
      const result = await api
      console.log('search result', result)
      const items = result.data.searchMerchants.items
      const groups = await ACL.getGroups()
      for(const item of items) {
          item.readOnly = !(await ACL.canWriteMerchant(item, groups))
      }
      merchants = merchants.concat(items)
      search.nextToken = result.data.searchMerchants.nextToken

      this.setState({
        merchants: merchants,
      })  
    } while (search.nextToken && !this.cancelDebounce)
    console.log('cancelDebounce', this.cancelDebounce)
    if (this.cancelDebounce) {
      // cancel all existing api calls
      for (const api of this.apis) {
        console.log('cancelling api', api)
        API.cancel(api, 'cancelling api')
      }
      
      merchants = []
      this.cancelDebounce = false
      console.log('debounce cancelled', this.cancelDebounce, this.apis)
    }
    this.apis = []
    this.setState({
      merchants: merchants,
      isLoading: false
    })

    if (this.props.onSearch) {
      this.props.onSearch(merchants)
    }
  }

  onSelect(e, item) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    if (this.props.replace) {
      this.setState({
        searchText: item.merchant_name,
        selectedSearchMerchant: item
      })
    }

    this.setState({
      merchant: item
    })

    if (this.props.onSelect) {
        this.props.onSelect(e, item)
    }
  }

  addMerchant(m) {
    const merchants = this.state.merchants
    merchants.unshift(m)
    this.setState({
      merchants: merchants
    })
  }

  updateMerchant(e, merchant) {
    e.preventDefault()
    e.stopPropagation()

    this.setState({
      show: true,
      mode: 'update',
      merchant: merchant
    })
  }

  viewMerchant(e, merchant) {
    e.preventDefault()
    e.stopPropagation()

    this.setState({
      show: true,
      mode: 'view',
      merchant: merchant
    })
  }

  onUpdateMerchant(merchant) {
    this.refreshMerchant(merchant)
    this.setState({
      show: false,
    })
  }

  onCloseMerchant() {
    this.setState({
      show: false,
    })
  }

  refreshMerchant(m) {
    const merchants = this.state.merchants
    for (const [index, merchant] of merchants.entries()) {
      if (m.merchant_id === merchant.merchant_id) {
        merchants[index] = m
      }
    }
    this.setState({
      merchants: merchants
    })
  }

  handleClose() {
    this.setState({
      show: false
    })
  }

  render() {
    const generateMerchantItems = (item) => {

        return <ListGroup.Item key={`list-merchants-${item.merchant_id}`} action active={this.state.merchant?(item.merchant_id==this.state.merchant.merchant_id):false} onClick={(e) => this.onSelect(e, item)}>
            {item.pockCalMerchImage?
              <Image src={`${item.pockCalMerchImage}?timestamp=${Date.now()}`} fluid rectangle="true" style={{height: '1em'}} />
            : <FaStore />}&nbsp;
            {item.merchant_name}&nbsp;
            {item.valid && (item.valid.toUpperCase() === 'Y') ?
                <FaEye title="visible" color="green" />
            : <FaEyeSlash title="not visible" color="red" />}

            {this.props.editable && !item.readOnly ? 
              <Button className="float-right" size="sm" onClick={(e) => this.updateMerchant(e, item)}>
                <FaEdit /> Edit
              </Button>
            :
              <Button className="float-right" size="sm" onClick={(e) => this.viewMerchant(e, item)}>
                View
              </Button>
            }
        </ListGroup.Item>
    }

    return <div>
        { this.state.isLoading || this.props.isLoading ? 
          <Spinner animation="border" size="sm" />
        : (this.state.isAdmin || this.state.isBank) || this.props.searchable ?
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FaSearch />
                </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control type="text" placeholder={this.props.label??'Business Name'} disabled={this.props.disabled} readOnly={this.props.readOnly} value={this.state.searchText} onChange={(e) => this.handleSearch(e)} />
          </InputGroup>
        : null}
        { !this.state.selectedSearchMerchant ?
          <ListGroup className="scrollview" variant="flush" key={`list-merchants`}>
          {
              this.state.merchants.map(generateMerchantItems)
          }
          </ListGroup>
        : null }

        <Modal show={this.state.show} onHide={() => this.handleClose()} size="xl" backdrop='static'>
          <Modal.Header closeButton>
                <Modal.Title>{(this.state.mode==='create')?'Create':(this.state.mode==='update'?'Update':'')} Merchant</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Merchant
              show={this.state.show}
              mode={this.state.mode}
              merchant={this.state.merchant}
              key={`merchant-${this.state.merchant ? this.state.merchant.merchant_id : ''}`}
              onUpdate={(merchant) => this.onUpdateMerchant(merchant)}
              onClose={() => this.onCloseMerchant()}
            />
          </Modal.Body>
        </Modal>

    </div>
  } 
}

export default ListMerchants
