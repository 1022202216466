import React, { Component } from 'react';
import { Container, Row, Col, Form, Spinner, ListGroup, Button } from 'react-bootstrap';
import { FaPlus } from "react-icons/fa";
import { API, graphqlOperation } from 'aws-amplify'
import Select from 'react-select'
import { getByKeyword, getKeywordByValidStatus, listSearchKeywords } from '../../graphql/queries';
import SearchKeyword from '../widgets/SearchKeyword'

class SearchKeywordView extends Component {

    constructor(props) {
        super(props);

        this.validity = [
            {value: '1', label: 'Valid Keyword'},
            {value: '0', label: 'Invalid Keyword'},
        ]

        this.state = {
            list: [],
            isLoading: true,
            selectedValidity: this.validity[0],
            nextToken: null,
            tokens: [],
            pageNo: 0,
            searchTerm: ''
        }
    }

    async componentDidMount() {
        const {keywordData, nextToken} = await this.getKeywords(this.state.selectedValidity.value)
        this.setState({ list: keywordData, isLoading: false, nextToken });      
    }

    selectSearchKeyword(e, searchKeyword) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            searchKeyword: searchKeyword,
            mode: 'updateSearchKeyword',
        });
    }

    createSearchKeyword(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            mode: 'createSearchKeyword'
        })
    }

    async setValidity(data) {
        const {tokens} = this.state
        this.setState({selectedValidity: data, isLoading: true})

        const {keywordData, nextToken} = await this.getKeywords(data.value)
        tokens.push(nextToken)

        this.setState({ list: keywordData, isLoading: false, tokens, nextToken});
    }

    async getKeywords(valid, token) {
        return new Promise(async (resolve, reject) => {
            let keywordData = []
            let nextToken = null
            let params = {
                valid
            }

            if (token) {
                params.nextToken = token
            }

            try {
                const result = await API.graphql(graphqlOperation(getKeywordByValidStatus, params))
                const keywords = result.data.getKeywordByValidStatus.items
                
                keywords.sort((a, b) => {
                    if (b.updatedAt === null || a.updatedAt > b.updatedAt) {
                        return -1
                    } else if (a.updatedAt === null || a.updatedAt < b.updatedAt) {
                        return 1
                    }
                    return 0
                })
                keywordData = keywordData.concat(keywords)

                nextToken = result.data.getKeywordByValidStatus.nextToken
            } catch(e) {
                console.error('Error on getKeywords ', e)
                this.setState({isLoading: false})
                reject(e.errors[0].message)
            }
            
            resolve({keywordData, nextToken})
        })
    }

    async next() {
        const {keywordData, nextToken} = await this.getKeywords(this.state.selectedValidity.value, this.state.nextToken)
        const {tokens} = this.state

        if (nextToken) tokens.push(nextToken)

        this.setState({ list: keywordData, isLoading: false, tokens, nextToken,pageNo: this.state.pageNo + 1})
    }

    async prev() {
        const { tokens } = this.state
        tokens.pop() // remove the last token and will push the new nextToken receive

        const {keywordData, nextToken} = await this.getKeywords(this.state.selectedValidity.value, tokens[tokens.length-1])
        if (nextToken) tokens.push(nextToken)

        this.setState({ list: keywordData, isLoading: false, tokens, nextToken, pageNo: this.state.pageNo - 1});
    }

    searchTerm(e) {
        this.setState({searchTerm: e.target.value})
    }

    triggerSearch(e) {
        if (e.nativeEvent.key==='Enter' && this.state.searchTerm) this.search(e)
    }

    async search(e) {
        const {searchTerm} = this.state
        if (!searchTerm) return
        this.setState({isLoading: true, tokens: [], nextToken: null, pageNo: 0})
        
        let keywordData = []
        const params = {
            filter: {
                keywordLowerCase: {
                    contains: searchTerm.toLowerCase()
                }
            }
        }

        // get all keywords
        do {
            try {
                const result = await API.graphql(graphqlOperation(listSearchKeywords, params))
                const keywords = result.data.listSearchKeywords.items
            
                keywords.sort((a, b) => {
                    if (b.updatedAt === null || a.updatedAt > b.updatedAt) {
                        return -1
                    } else if (a.updatedAt === null || a.updatedAt < b.updatedAt) {
                        return 1
                    }
                    return 0
                })
                keywordData = keywordData.concat(keywords)

                params.nextToken = result.data.listSearchKeywords.nextToken
            } catch(e) {
                console.error('Error on search ', e)
            }
        } while(params.nextToken)

        this.setState({list: keywordData, isLoading: false})
    }

    render() {
        const { list, searchKeyword, mode } = this.state;
        
        return <Container fluid>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h3>Search Keyword</h3>
                            
                        </Col>
                        <Col>
                            <FaPlus className="float-right" onClick={(e) => this.createSearchKeyword(e)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Select 
                                options={this.validity}
                                closeMenuOnSelect={true}
                                value={this.state.selectedValidity}
                                onChange={(data) => this.setValidity(data)}
                            />
                        </Col>
                        <Col>
                            <Button disabled={this.state.pageNo<=0?true:false} type="submit" onClick={(e) => this.prev(e)}>Previous</Button>
                            {"  "}
                            <Button disabled={this.state.nextToken?false:true} type="submit" onClick={(e) => this.next(e)}>Next</Button>
                        </Col>
                        <Col style={{marginTop:"8px"}}>
                            Page: {this.state.pageNo+1}
                        </Col>
                    </Row>
                    <Row style={{marginTop: "10px"}}>
                        <Col>
                            <Form.Control type="text" onKeyDown={(e) => this.triggerSearch(e)} onChange={(e) => this.searchTerm(e)} placeholder="Search keyword"/>
                        </Col>
                        <Col>
                            <Button type="submit" onClick={(e) => this.search(e)}>Search</Button>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row><Col><hr style={{height: 5}}></hr></Col></Row>
                    
                    <Row>
                    { this.state.isLoading ? 
                        <Spinner animation="border" />
                    : 
                        <Col className="scrollview">
                            <ListGroup variant="flush">
                            {
                                list.length > 0 ?
                                    list.map((item) =>
                                        <ListGroup.Item key={`list-searchKeyword-${item.keyword}`} action onClick={(e) => this.selectSearchKeyword(e, item)}>
                                            <div>
                                                { item.keyword }
                                            </div>
                                            <small>Valid: { item.valid==="1" ? 'true' : 'false' }</small>
                                        </ListGroup.Item>
                                    )
                                : 'No Search Keyword'
                            }
                            </ListGroup>
                        </Col>
                    }
                    </Row>
                </Col>

                <Col className="scrollview">
                    <SearchKeyword
                        mode='create'
                        key={`create-searchKeyword`}
                        show={mode === 'createSearchKeyword'}
                    />

                    <SearchKeyword
                        mode='update'
                        key={`update-searchKeyword-${searchKeyword ? searchKeyword.keyword : ''}`}
                        searchKeyword={searchKeyword}
                        show={mode === 'updateSearchKeyword'}
                    />
                </Col>
            </Row>            
        </Container>
    }
}

export default SearchKeywordView