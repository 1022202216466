import React, { Component } from 'react';
import { Container, Row, Col, Spinner, ListGroup } from 'react-bootstrap';
import { FaPlus } from "react-icons/fa";
import { API, graphqlOperation } from 'aws-amplify'
import { listAnnouncements } from '../../graphql/queries';
import Announcement from '../widgets/Announcement'

class AnnouncementView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            isLoading: false
        }
    }

    async componentDidMount() {
        this.setState({
            isLoading: true
        });

        API.graphql(graphqlOperation(listAnnouncements)).then((result) => {
            this.setState({ list: result.data.listAnnouncements.items, isLoading: false });
        })        
    }

    selectAnnouncement(e, announcement) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            announcement: announcement,
            mode: 'updateAnnouncement'
        })
    }

    createAnnouncement(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            mode: 'createAnnouncement'
        })
    }

    render() {
        const { list } = this.state;

        return <Container fluid>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h3>Announcement</h3>
                        </Col>
                        <Col>
                            <FaPlus className="float-right" onClick={(e) => this.createAnnouncement(e)} />
                        </Col>
                    </Row>
                    <Row>
                    { this.state.isLoading ? 
                        <Spinner animation="border" />
                    : 
                        <Col className="scrollview">
                            <ListGroup variant="flush">
                            {
                                list.length > 0 ?
                                    list.map((item) =>
                                        <ListGroup.Item key={`list-announcement-${item.announcementId}`} action onClick={(e) => this.selectAnnouncement(e, item)}>
                                            <div>
                                                { item.message }
                                            </div>
                                            <small>Active: { item.active }</small>
                                        </ListGroup.Item>
                                    )
                                : 'No Announcement'
                            }
                            </ListGroup>
                        </Col>
                    }
                    </Row>
                </Col>

                <Col className="scrollview">
                    <Announcement
                        mode='create'
                        key={`create-announcement`}
                        show={this.state.mode === 'createAnnouncement'}
                    />

                    <Announcement
                        mode='update'
                        key={`update-announcement-${this.state.announcement ? this.state.announcement.announcementId : ''}`}
                        announcement={this.state.announcement}
                        show={this.state.mode === 'updateAnnouncement'}
                    />
                </Col>
            </Row>            
        </Container>
    }
}

export default AnnouncementView