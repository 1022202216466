import React, { Component } from 'react';
import { Form, Button, Spinner, Image } from "react-bootstrap";
import { FaPlus, FaTrash } from "react-icons/fa";
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { publish } from '../widgets/CMSModal';
import { guidGenerator } from '../../utils/index'
import { createCategoryBestDeals, updateCategoryBestDeals } from '../../graphql/mutations';
import * as utils from '../../utils';

class CategoryBestDeal extends Component {
    constructor(props) {
        super(props);
        const bestDeal = props.bestDeal ? { ...props.bestDeal } : {}
        this.state = {
            category: props.category,
            item: {
                categoryBestDealsId: bestDeal.categoryBestDealsId ?? null,
                title: bestDeal.title ?? '',
                valid: bestDeal.valid ?? '0',
                seeMoreShown: bestDeal.seeMoreShown ?? false,
                content: bestDeal.content ? JSON.parse(bestDeal.content) : [],
                mainCat: props.category
            },
            imageFiles: {},
            processing: false
        }
    }

    addContent(e) {
        e.preventDefault();
        e.stopPropagation();
        
        const item = this.state.item;
        let content = item.content;
        content = content.concat({ id: guidGenerator() });
        item.content = content;
        this.setState({ item: item });
    }

    removeContent(e, contentItem) {
        e.preventDefault();
        e.stopPropagation();
        
        const item = this.state.item;
        let content = item.content;
        const index = content.findIndex(i => i.id === contentItem.id);
        if (index >= 0) {
            content.splice(index, 1);
        }
        item.content = content;
        this.setState({ item: item });
    }

    handleChange(e, fieldname) {
        const item = { ...this.state.item };
        const target = e.target;
        if (target.type === 'checkbox') {
            item[fieldname] = e.target.checked ? '1' : '0';
        } else {
            item[fieldname] = e.target.value;
        }
        this.setState({ item: item });
    }

    handleContentChange(e, contentItem, fieldname) {
        const item = { ...this.state.item };
        if (fieldname === 'image') {
            const file = e.target.files[0];
            const url = URL.createObjectURL(file);
            const ext = file.name.substring(file.name.lastIndexOf('.'));
            
            contentItem.image = url;
            contentItem.imageExt = ext;
            const imageFiles = this.state.imageFiles;
            imageFiles[contentItem.id] = file;
            this.setState({ item: item, imageFiles });
        } else {
            contentItem[fieldname] = e.target.value;
            this.setState({ item: item });
        }
    }

    async handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.item.content.length === 0) {
            this.alert('Please add a content');
            return;
        }
        this.setState({ processing: true });

        try {
            if (this.props.mode === 'createItem') {
                await this.handleCreate();
            } else if (this.props.mode === 'updateItem') {
                await this.handleUpdate();
            } else {
                this.props.onClose();
            }
        } catch(error) {
            console.error(error);
            this.setState({ processing: false });
            this.alert('Error processing Best Deal');
        }
    }

    async handleCreate() {
        const item = { ...this.state.item };
        const category = item.mainCat;
        for (const c of item.content) {
            const imageFile = this.state.imageFiles[c.id];
            if (imageFile) {
                const path = `CategoryImages/${category}_${c.id}${c.imageExt}`;
                c.image = utils.getAssetUrl(path);
                await Storage.put(path, imageFile);
                delete c.imageExt;
            }
        }
        item.content = JSON.stringify(item.content);
        item.categoryBestDealsId = guidGenerator();
        const resultCreate = await API.graphql(graphqlOperation(createCategoryBestDeals, { input: item }));
        const createdItem = resultCreate.data.createCategoryBestDeals;

        this.alert('Best Deal Created', () => {
            if (this.props.onCreate) {
                createdItem.id = createdItem.categoryBestDealsId;
                this.props.onCreate(createdItem);
            }
        });
    }

    async handleUpdate() {
        const item = { ...this.state.item };
        const category = item.mainCat;
        for (const c of item.content) {
            const imageFile = this.state.imageFiles[c.id];
            if (imageFile) {
                const path = `CategoryImages/${category}_${c.id}${c.imageExt}`;
                c.image = utils.getAssetUrl(path);
                await Storage.put(path, imageFile);
                utils.invalidateCloudFrontCache(c.image);
                delete c.imageExt;
            }
        }
        item.content = JSON.stringify(item.content);
        const resultUpdate = await API.graphql(graphqlOperation(updateCategoryBestDeals, { input: item }));
        const updatedItem = resultUpdate.data.updateCategoryBestDeals;

        this.alert('Best Deal Updated', () => {
            if (this.props.onUpdate) {
                updatedItem.id = updatedItem.categoryBestDealsId;
                this.props.onUpdate(updatedItem);
            }
        });
    }

    alert(msg, callback) {
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    render() {
        const dateNow = `?date=${new Date()}`;
        return <div>
            {this.props.show ?
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <Form.Group>
                        <Form.Label>ID *</Form.Label>
                        <Form.Control required type="text" disabled value={this.state.item.categoryBestDealsId ?? 'Auto ID'} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Title *</Form.Label>
                        <Form.Control required type="text" value={this.state.item.title} onChange={(e) => this.handleChange(e, 'title')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check label="Valid" checked={this.state.item.valid === '1'} onChange={(e) => this.handleChange(e, 'valid')} />
                    </Form.Group>

                    <Form.Label>Content *</Form.Label>
                    <Button size="sm" style={{ marginLeft: 10 }} onClick={(e) => this.addContent(e)}>
                        <FaPlus /> Add
                    </Button>
                    {this.state.item.content && this.state.item.content.length > 0 ?
                        this.state.item.content.map((item) =>
                            <div key={`content-${item.id}`} className="container-eligibility" style={{ marginBottom: 10 }}>
                                <Form.Group >
                                    <Form.Label>Type *</Form.Label>
                                    <Form.Control required as="select" type="select" value={item.type} onChange={(e) => this.handleContentChange(e, item, 'type')} >
                                        <option value="">Select Type</option>
                                        <option value="externalUrl">External URL</option>
                                        <option value="deal">Deal</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Image *</Form.Label>
                                    <Form.File required={!item.image} accept="image/*" onChange={(e) => this.handleContentChange(e, item, 'image')}></Form.File>
                                    { item.image ? 
                                        item.image.startsWith('http') ?
                                        <Image src={item.image + dateNow} fluid width='136' height='126'></Image> :
                                        <Image src={item.image} fluid width='136' height='126'></Image>
                                    : null }
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Path (URL / Deal ID) *</Form.Label>
                                    <Form.Control required type="text" value={item.route} onChange={(e) => this.handleContentChange(e, item, 'route')} />
                                </Form.Group>

                                <Button size="sm" variant="danger" onClick={(e) => this.removeContent(e, item)}>
                                    <FaTrash /> Remove this content
                                </Button>
                            </div>
                        )
                    : 'No Content'}

                    <Form.Group>
                        <Button disabled={ this.state.processing } type="submit">{this.props.mode === 'createItem' ? 'Create' : 'Update'} Best Deal</Button>                            
                        {this.state.processing ?
                            <Spinner animation="border" size="sm" className="ml-1" />
                        : null}
                    </Form.Group>

                </Form>
            : null}
        </div>
    }
}

export default CategoryBestDeal