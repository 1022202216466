import React, { Component } from 'react'
import { Form, Button, Toast, Image } from "react-bootstrap"
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { createAdvertisement, updateAdvertisement } from '../../graphql/mutations' 
import { guidGenerator } from '../../utils/index'
import { backOff } from 'exponential-backoff'
import { publish } from '../widgets/CMSModal'
import * as utils from '../../utils'

class Ads extends Component {

    constructor(props) { //Initialize class instance -> call & render first 
        super(props) //call parent constructor

        //Initialize variables
        const advertisement = props.advertisement ? { ...props.advertisement } : {}; 
        const routes = advertisement.route ? JSON.parse(advertisement.route) : {}; //convert to object
        //console.log(routes);
        
        //Set initial state, fields defined similar to schema.graphql 
        this.state = { 
            item: {
                valid: advertisement.valid ?? '0', //null or undefined will return right value
                advertisementId: advertisement.advertisementId, 
                assetUrl: advertisement.assetUrl ?? '',
                buttonText: advertisement.buttonText ?? '',
                category: advertisement.category ?? 'feature',
                frequency: advertisement.frequency ?? '',
                name: advertisement.name ?? null,
                order: advertisement.order ?? '',
                route: advertisement.route ?? '',
                guest: routes.guest ?? '',
                loggedIn: routes.loggedIn ?? '',
                fromAd: routes.params ? routes.params.fromAd : false, 
                skipTime: advertisement.skipTime ?? '',
                type: advertisement.type ?? 'gif',
                videoDuration: advertisement.videoDuration ?? ''
            },
            showToast: false,
            mode: props.mode
        };
    }

    handleSubmit(e) {
        e.preventDefault(); //prevents page refresh
        e.stopPropagation(); //prevents event bubbling to parent

        const { item } = this.state //destructure variable
        const routes = { 
            guest: item.guest,
            loggedIn: item.loggedIn,
            params: {
                fromAd: item.fromAd
            }
        }
        item.route = routes //print routes object key & values
        //console.log(item.route);

        const advertisement = {
            valid: item.valid,
            advertisementId: item.advertisementId ?? guidGenerator(), //autogenerate if not filled
            assetUrl: item.assetUrl,
            buttonText: item.buttonText,
            category: item.category,
            frequency: item.frequency,
            name: item.name,
            order: item.order,
            route: JSON.stringify(item.route), //convert to string before upload to server
            skipTime: item.skipTime,
            type: item.type,
            videoDuration: item.videoDuration
        };
        //console.log(advertisement);
        
        //Mode Type
        if (this.state.mode === 'create') { 
            this.handleCreate(advertisement); //upload to S3 bucket
        } else if (this.state.mode === 'update') {
            this.handleUpdate(advertisement); 
        }
    }

    async handleCreate(advertisement) {
        const result = await API.graphql(graphqlOperation(createAdvertisement, { input: advertisement }));
        
        const advertisementId = result.data.createAdvertisement.advertisementId;
        advertisement.advertisementId = advertisementId;

        const pathAdvertisement = `AdvertisementImages/${advertisementId}${this.state.advertisementImageExt}`; //ImageExt: .png or .jpg
        //console.log(pathAdvertisement)
        if (this.state.advertisementImageFile) {
            advertisement.assetUrl = this.getAssetUrl(pathAdvertisement); //save images in cardspal-assets-dev -> public -> AdvertisementImages folder 
        }

        await API.graphql(graphqlOperation(updateAdvertisement, { input: advertisement })); 

        if (this.state.advertisementImageFile) {
            const resultUpload = await Storage.put(pathAdvertisement, this.state.advertisementImageFile); //create S3 bucket
            //console.log(resultUpload);
            const result = await backOff(() => API.post('CloudFront', '/invalidate', { //invalidate cache of previous uploaded images
                body: {
                    path: `/public/${pathAdvertisement}` //invalidate cache based on this path
                }
            }))
            console.log(result);
        }
        
        this.setState({ showToast: true }); //show modal
        if (this.props.refresh) { 
            this.props.refresh(result.data);
        }
    }

    async handleUpdate(advertisement) {
        const advertisementId = advertisement.advertisementId;
        const pathAdvertisement = `AdvertisementImages/${advertisementId}${this.state.advertisementImageExt}`;

        if (this.state.advertisementImageFile) {
            advertisement.assetUrl = this.getAssetUrl(pathAdvertisement);
        }
    
        const result = await API.graphql(graphqlOperation(updateAdvertisement, { input: advertisement }));

        if (this.state.advertisementImageFile) {
            const resultUpload = await Storage.put(pathAdvertisement, this.state.advertisementImageFile);
            const result = await backOff(() => API.post('CloudFront', '/invalidate', { 
                body: {
                    path: `/public/${pathAdvertisement}`
                }
            }))
            console.log(result);
        }

        this.setState({ showToast: true });
    }

    handleChange(e, fieldname) {
        const { item } = this.state //destructure
        const target = e.target

        if (target.type === 'checkbox') { 
            item[fieldname] = target.checked ? '1' : '0'; //checkbox
        } else {
            item[fieldname] = target.value; //input field
        }

        this.setState({ item }); 
        //console.log('item', item)
    }

    handleAdsImageChange(e) { 
        const item = { ...this.state.item }; //print the object
        //console.log(item); 
        const file = e.target.files[0] //image object details
        //console.log(file);
        const url = URL.createObjectURL(file) //local URL
        //console.log(url);
        const ext = file.name.substring(file.name.lastIndexOf('.')) //image extension .png or .jpg
        //console.log(ext);
        item['assetUrl'] = url 
        
        this.setState({  //set updated state 
            item: item,  
            advertisementImageExt: ext,
            advertisementImageFile: file
        });
    }

    getAssetUrl(path) { //generate image file path in DynamoDB
        return utils.getAssetUrl(path) 
    }

    preventKeyboardInput(event) { //prevent keyboard input
        event.preventDefault();
    }

    alert(msg, callback) {
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    render() {
        const { advertisementId, buttonText, assetUrl } = this.state.item;
        
        return <div>
            {this.props.show ?
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    
                    <h3>{this.props.title??this.state.mode==='create'?'Create':'Update'} Advertisement</h3>
                    
                    <Form.Group>
                        <Form.Check 
                            label="Valid" 
                            value={this.state.item.valid}
                            checked={this.state.item.valid==='1'} //checked={true}
                            onChange={(e) => this.handleChange(e, 'valid')}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Advertisement Id *</Form.Label>
                        <Form.Control 
                            required 
                            type="text" 
                            disabled={this.state.mode==='update'} 
                            value={advertisementId ? advertisementId : ''} 
                            onChange={(e) => this.handleChange(e, 'advertisementId')}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Asset URL *</Form.Label>
                        <Form.File 
                            required={!assetUrl} 
                            accept="image/*" 
                            onChange={(e) => this.handleAdsImageChange(e)}>
                        </Form.File>
                        <Image src={assetUrl} fluid></Image>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Button Text *</Form.Label>
                        <Form.Control 
                            required 
                            type="text" 
                            value={buttonText} 
                            onChange={(e) => this.handleChange(e, 'buttonText')} 
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Category *</Form.Label>
                        <Form.Control 
                            required
                            disabled
                            type="text" 
                            value={this.state.item.category} 
                            onChange={(e) => this.handleChange(e, 'category')} 
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Frequency *</Form.Label>
                        <Form.Control 
                            required 
                            type="number" 
                            min="1" 
                            max='100'
                            onKeyDown={this.preventKeyboardInput} 
                            value={this.state.item.frequency} 
                            onChange={(e) => this.handleChange(e, 'frequency')} 
                        />
                    </Form.Group>                    
                    <Form.Group>
                        <Form.Label>Name *</Form.Label>
                        <Form.Control 
                            required 
                            type="text" 
                            value={this.state.item.name} 
                            onChange={(e) => this.handleChange(e, 'name')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Order *</Form.Label>
                        <Form.Control 
                            required 
                            type="number" 
                            min="1" 
                            onKeyDown={this.preventKeyboardInput} 
                            value={this.state.item.order} 
                            onChange={(e) => this.handleChange(e, 'order')} 
                        />
                    </Form.Group> 

                    <Form.Row>
                        <Form.Label>Route *</Form.Label>
                    </Form.Row>
                    {
                        <Form.Group>
                            <div className="container-route"> 
                                <Form.Label>Guest *</Form.Label>
                                <Form.Control 
                                    required 
                                    type="text" 
                                    value={this.state.item.guest} 
                                    onChange={(e) => this.handleChange(e, 'guest')} 
                                />
                                <Form.Label style={{marginTop: 10}}>Login *</Form.Label>
                                <Form.Control 
                                    required 
                                    type="text" 
                                    value={this.state.item.loggedIn} 
                                    onChange={(e) => this.handleChange(e, 'loggedIn')} 
                                />
                                <Form.Label style={{marginTop: 10}}>From Ad *</Form.Label>
                                <Form.Check 
                                    label="True" 
                                    value={this.state.item.fromAd} 
                                    checked={this.state.item.fromAd==='1'} 
                                    onChange={(e) => this.handleChange(e, 'fromAd')}
                                />
                            </div>
                        </Form.Group>
                    }

                    <Form.Group>
                        <Form.Label>Skip Time *</Form.Label>
                        <Form.Control 
                            required 
                            type="number" 
                            min="1"
                            max='100'
                            onKeyDown={this.preventKeyboardInput} 
                            value={this.state.item.skipTime} 
                            onChange={(e) => this.handleChange(e, 'skipTime')} 
                        />
                    </Form.Group>   
                    <Form.Group>
                        <Form.Label>Type</Form.Label>
                        <Form.Check 
                            label="GIF" 
                            checked disabled 
                            value={this.state.item.gif} 
                            onChange={(e) => this.handleChange(e, 'gif')} 
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Video Duration *</Form.Label>
                        <Form.Control 
                            required 
                            type="number" 
                            min="1"
                            max='100'
                            onKeyDown={this.preventKeyboardInput} 
                            value={this.state.item.videoDuration} 
                            onChange={(e) => this.handleChange(e, 'videoDuration')} 
                        />
                    </Form.Group>   
                    
                    <Form.Group>
                        <Toast onClose={() => this.setState({ showToast: false })} show={this.state.showToast} delay={3000} autohide>
                            {this.state.mode==='create'?
                                <Toast.Body>Advertisement Created.</Toast.Body>
                            : null}
                            {this.state.mode==='update'?
                                <Toast.Body>Advertisment Updated.</Toast.Body>
                            : null}
                        </Toast>

                        {this.state.mode==='create'?
                            <Button type="submit" style={{marginTop: 10}}>Create Advertisement</Button>
                        : null}
                        {this.state.mode==='update'?
                            <Button type="submit" style={{marginTop: 10}}>Update Advertisement</Button>
                        : null}
                    </Form.Group>
                </Form>
            : null}
        </div>
    }
}

export default Ads;