import React, { Component } from 'react'

import GA4React, { useGA4React } from 'ga-4-react'
import IdleTimer from 'react-idle-timer'
import moment from 'moment-timezone'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Amplify, API } from 'aws-amplify'
import AdminView from './components/views/AdminView'
import AuthView from './components/views/AuthView'

import Header from './components/widgets/Header'
import { CMSModal, publish } from './components/widgets/CMSModal'

import * as utils from './utils'
import ACL from './utils/ACL'
import aws_exports from './aws-exports'
import { Carousel, Image } from 'react-bootstrap'

Amplify.configure(aws_exports)
// looks like new requirement by Amplify https://docs.amplify.aws/lib/storage/getting-started/q/platform/js#manual-setup-import-storage-bucket
Amplify.configure({
  Auth: {
      identityPoolId: aws_exports.aws_cognito_identity_pool_id, //REQUIRED - Amazon Cognito Identity Pool ID
      region: aws_exports.aws_cognito_region, // REQUIRED - Amazon Cognito Region
      userPoolId: aws_exports.aws_user_pools_id, //OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: aws_exports.aws_user_pools_web_client_id, //OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
      AWSS3: {
          bucket: `cardspal-assets-${utils.getEnv()}`, //REQUIRED -  Amazon S3 bucket name
          region: 'ap-southeast-1', //OPTIONAL -  Amazon service region
      }
  }
});

const IDLE_HR = 1
class App extends Component {

  constructor() {

    super()
    this.state = {
      timeout: 1000 * 60 * 60 * IDLE_HR,
    }
  }

  async componentDidMount() {
    // const iploc = await API.get('GAPI', '/country')
    const config = (await (await fetch(`https://assets.${utils.getEnv()}.cardspal.com/.config/cms.config.json?timestamp=${Date.now()}`)).json())

    const ga4react = new GA4React(config.ga_code)
    this.ga = await ga4react.initialize()
    this.ga.pageview('landing') // or your custom gtag event

    // set this separately as the ACL calls below will fail if user not logged in
    // TODO :: need to fix this double call
    this.setState({
      config: config,
      countryCode: 'SG'//iploc.countryCode
    })

    this.setState({
      isAdmin: await ACL.isAdmin(),
      isBank: await ACL.isBank(),
      isMerchant: await ACL.isMerchant(),
      isHandler: await ACL.isHandler(),
      isAggregator: await ACL.isAggregator(),
      isPostal: await ACL.isPostal(),
    })
  }

  onClickAd(e, ad) {

    this.ga.gtag('event', 'cms_event', {
      event_label: 'banner_click',
      event_category: 'home_page',
      non_interaction: false,
      banner_name: ad.alt,
      banner_url: ad.href
    })
  }

  onAction(e) {
    // console.log('user did something', e)
    this.setState({isTimedOut: false})
  }
 
  onActive(e) {
    // console.log('user is active', e)
    this.setState({isTimedOut: false})
  }
 
  onIdle(e) {
    console.log('user is idle', e)
    const isTimedOut = this.state.isTimedOut
    if (!isTimedOut) {
      this.idleTimer.reset();
      this.setState({isTimedOut: true})

      publish({
        mode: 'alert',
        msg: `You have been idle for more than ${IDLE_HR} hr. The page will be reloaded.`,
        onClose: () => {
          window.location.reload()
        }
      })
    } 
  }

  render() {
    const AdBanners = this.state.config?this.state.config.ads.map(ad =>
      <Carousel.Item className="text-center">
        <a href={ad.href} target={ad.target}>
          <Image alt={ad.alt} src={ad.image} height={ad.height} onClick={(e) => this.onClickAd(e, ad)} />
        </a>
      </Carousel.Item>
    ):[]

    return (
      <div>
        { this.state.countryCode ?
          <div>
            { this.state.countryCode === 'SG' || this.state.countryCode === 'PH' ?
              <div>
                {/* banner */}
                { (!this.state.isAdmin && !this.state.isBank && !this.state.isMerchant && !this.state.isPostal && !this.state.isHandler && !this.state.isAggregator) ?
                  <Carousel fade>
                    {AdBanners}
                  </Carousel>
                : null}

                <IdleTimer
                  ref={ref => { this.idleTimer = ref }}
                  element={document}
                  onActive={(e) => this.onActive(e)}
                  onIdle={(e) => this.onIdle(e)}
                  onAction={(e) => this.onAction(e)}
                  debounce={this.state.timeout/2}
                  timeout={this.state.timeout} />

                <Header />
                { (this.state.isAdmin || this.state.isBank || this.state.isMerchant || this.state.isPostal || this.state.isHandler || this.state.isAggregator) ?
                  <AdminView />
                  // : this.state.isAggregator ?
                  //   <AggregatorView />
                  // : (this.state.isMerchant || this.state.isPostal || this.state.isHandler || this.state.isAggregator) ?
                  //   <MerchantView />
                  :
                  <AuthView />
                }

                <CMSModal />
              </div>
            : `Access in your country ${this.state.countryCode} is denied`}
          </div>
        : null}
      </div>
    )
  }
}

export default App
// export default withAuthenticator(App, { includeGreetings: true })
