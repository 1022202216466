import React, { Component } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { Button, Modal, Spinner, ListGroup } from 'react-bootstrap'
import { FaEdit, FaEye, FaEyeSlash, FaUserLock } from "react-icons/fa"
import { listMerchantOutlets } from '../../graphql/queries'
import CMSUploadOutlet from '../widgets/CMSUploadOutlet'
import ACL from '../../utils/ACL'
import Outlet from './Outlet'
class ListOutlets extends Component {

    constructor(props) {
        super(props)

        this.state = {
            outlets: [],
            outlet: null
        }
    }

    async componentDidMount() {
        this.listOutlets()

        this.setState({
            isAdmin: await ACL.isAdmin()
        })
    }

    async listOutlets() {
        let result = []
        let params = {
            limit: 100,
            merchant_id: this.props.merchantId
        }

        if (await ACL.isPostal()) {
            const postals = await ACL.getPostalsFromGroups()
            const postalConditions = postals.map((postal) => {
                console.log(postal)
                return {
                    postal_code: {
                        eq: postal
                    }
                }
            })
            params.filter = {
                or: postalConditions
            }
            console.log('filter', params)
        }

        this.setState({
            isLoading: true
        })

        // loop through outlets with tokens
        do {
            const outlets = await API.graphql(graphqlOperation(listMerchantOutlets, params))
            const items = outlets.data.listMerchantOutlets.items
            if (items.length > 0) {

                result = result.concat(items)
                result.sort((a, b) => {
                    if (a && b && a.outlet_name.toLowerCase() < b.outlet_name.toLowerCase()) {
                        return -1
                    } else if (a && b && a.outlet_name.toLowerCase() > b.outlet_name.toLowerCase()) {
                        return 1
                    }

                    return 0
                })
                this.setState({ outlets: result })
            }
            
            const nextToken = outlets.data.listMerchantOutlets.nextToken
            params.nextToken = nextToken
        } while (params.nextToken)

        this.setState({
            isLoading: false
        })

        const groups = await ACL.getGroups()
        result.forEach((item, index) => {
            ACL.canWriteOutlet(item, groups).then((canWrite) => {
                item.readOnly = !canWrite
                result[index] = item
                this.setState({
                    outlets: result
                })
            })
        })

        this.onSelect(null, result[0])
        console.log('result', result)
    }

    onSelect(e, item) {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }

        this.setState({
            outlet: item
        })
    
        if (this.props.onSelect) {
            this.props.onSelect(e, item)
        }
    }

    addOutlet(o) {
        const outlets = this.state.outlets
        outlets.unshift(o)
        this.setState({
            outlets: outlets
        })
    }

    refreshOutlet(o) {
        const outlets = this.state.outlets
        for (const [index, outlet] of outlets.entries()) {
            if (o.merchant_unique_id === outlet.merchant_unique_id) {
                outlets[index] = o
            }
        }
        
        this.setState({
            outlets: outlets
        })
    }

    updateOutlet(e, outlet) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            show: true,
            mode: 'update',
            outlet: outlet
        })
    }

    onCreateOutlets(os) {
        const outlets = os.concat(this.state.outlets)
        this.setState({
            outlets: outlets
        })
    }

    onUpdateOutlet(e, outlet) {
        this.refreshOutlet(outlet)
        this.onSelect(e, outlet) // simulate a click so the deal listing will be refresh
        this.setState({
            show: false
        })
    }
    
    handleClose() {
        this.setState({
            show: false
        })
    }
    
    render() {

        return <div>

            { this.state.isAdmin ?
                <CMSUploadOutlet 
                    merchantId={this.props.merchantId}
                    onSave={(outlets) => this.onCreateOutlets(outlets)}
                />
            : null}

            { this.state.isLoading ? 
                <Spinner animation="border" size="sm" />
            : 
                <ListGroup className="scrollview" variant="flush">
                {
                    this.state.outlets.length > 0 ?
                        this.state.outlets.map((item) =>
                            <ListGroup.Item key={`list-outlets-${item.merchant_unique_id}`} action active={this.state.outlet?(item.merchant_unique_id===this.state.outlet.merchant_unique_id):false} onClick={(e) => this.onSelect(e, item)}>
                                <div>
                                    {item.outlet_name}&nbsp;
                                    {item.readOnly?
                                        <FaUserLock title="view only" color="red" />
                                    : null}
                                    {item.valid && (item.valid.toUpperCase() === 'Y') ?
                                        <FaEye title="visible" color="green" />
                                    : <FaEyeSlash title="not visible" color="red" />}
                                    
                                    <Button className="float-right" size="sm" onClick={(e) => this.updateOutlet(e, item)}>
                                        <FaEdit /> Edit
                                    </Button>
                                </div>
                                <div>
                                    <small>{(item.address&&item.address.toLowerCase()!=='null')?item.address:'no address'}</small>
                                </div>
                                <div>
                                    <small>{(item.postal_code&&item.postal_code.toLowerCase()!=='null')?item.postal_code:null}</small>
                                </div>
                            </ListGroup.Item>
                        )
                    : <ListGroup.Item variant="danger">
                        <p>No Outlets.</p>
                        <p>Add Outlets and Deals so that your listings will appear in our app.</p>
                    </ListGroup.Item>
                }
                </ListGroup>
            }

            <Modal show={this.state.show} onHide={() => this.handleClose()} size="xl" backdrop='static'>
                <Modal.Header closeButton>
                    <Modal.Title>{(this.state.mode==='create')?'Create':(this.state.mode==='update'?'Update':'')} Outlet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Outlet
                        show={this.state.show}
                        mode='update'
                        outlet={this.state.outlet}
                        key={`update-outlet-${this.state.outlet ? this.state.outlet.merchant_unique_id : ''}`}
                        onUpdate={(e,outlet) => this.onUpdateOutlet(e,outlet)}
                    />
                </Modal.Body>
            </Modal>
        </div>
    }
}

export default ListOutlets