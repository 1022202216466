import React, { Component } from 'react';
import { Container, Row, Col, Spinner, ListGroup } from 'react-bootstrap';
import { FaPlus } from "react-icons/fa";
import { API, graphqlOperation } from 'aws-amplify'
import _ from 'lodash'
import { getBannerByValiditySortedOrder, listCampaigns} from '../../graphql/queries';
import Banner from '../widgets/Banner'

class BannerView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bannerList: [],
            campaignList: [],
            isLoading: true,
            isValidCampaign: false
        }
    }

    setDisableToAttachedCampaign(bannerList, campaignList) {
        const attachedCampaigns = []
        let newCampaignList = campaignList

        bannerList.map((item) => { //eslint-disable-line
            if (item.campaignId) attachedCampaigns.push(item.campaignId)
            newCampaignList  = campaignList.map((item) => {
                const _item = {...item}
                if (attachedCampaigns.includes(item.campaignId)) _item.attached = true
                else _item.attached = false
                return _item
            })
        })
        return newCampaignList
    }

    async componentDidMount() {
        this.refresh()
    }

    selectBanner(e, banner) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            banner: banner,
            mode: 'updateBanner'
        })
    }

    isValidCampaign(campaignList, campaignId) {
        let isValid = false
        try {
            if (campaignId) {
                const campaign = campaignList.filter(item => item.campaignId === campaignId)
                isValid = campaign.length > 0 ? campaign[0].valid === "1": false
            }
        } catch(e) {
            console.error(e)
        } finally {
            return isValid
        }
    }

    createBanner(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            mode: 'createBanner'
        })
    }

    refresh() {
        this.setState({ isLoading: true });

        Promise.all([
            API.graphql(graphqlOperation(getBannerByValiditySortedOrder, {
                valid: 'true',
                sortDirection: 'ASC'
            })).then((result) => Promise.resolve({ validBannerList: result.data.getBannerByValiditySortedOrder.items })),
            API.graphql(graphqlOperation(getBannerByValiditySortedOrder, {
                valid: 'false',
                sortDirection: 'ASC'
            })).then((result) => Promise.resolve({ invalidBannerList: result.data.getBannerByValiditySortedOrder.items })),
            API.graphql(graphqlOperation(listCampaigns)).then((result) => Promise.resolve({ campaignList: result.data.listCampaigns.items }))
        ]).then((result) => {
            const bannerList = result[0].validBannerList.concat(_.orderBy(result[1].invalidBannerList, ['updatedAt', 'title'], ['desc', 'asc']))
            const campaignList = this.setDisableToAttachedCampaign(bannerList, result[2].campaignList)

            this.setState({ 
                bannerList, 
                campaignList,
                isLoading: false 
            });
        })
    }

    render() {
        const { bannerList } = this.state;
        let bannerUpdate, bannerCreate

        if (!this.state.isLoading) {
            bannerCreate = <Banner
                            mode='create'
                            key={`create-banner`}
                            campaignList={this.state.campaignList}
                            isValidCampaign={this.isValidCampaign}
                            refresh={() => this.refresh()}
                            show={this.state.mode === 'createBanner'}
                        />
            bannerUpdate = <Banner
                            mode='update'
                            key={`update-banner-${this.state.banner ? this.state.banner.bannerId : ''}`}
                            banner={this.state.banner}
                            campaignList={this.state.campaignList}
                            isValidCampaign={this.isValidCampaign}
                            refresh={() => this.refresh()}
                            show={this.state.mode === 'updateBanner'}
                        />
        }
        return <Container fluid>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h3>Banner</h3>
                        </Col>
                        <Col>
                            <FaPlus className="float-right" onClick={(e) => this.createBanner(e)} />
                        </Col>
                    </Row>
                    <Row>
                    { this.state.isLoading ? 
                        <Spinner animation="border" />
                    : 
                        <Col className="scrollview">
                            <ListGroup variant="flush">
                            {
                                bannerList.length > 0 ?
                                    bannerList.map((item) =>
                                        <ListGroup.Item key={`list-banner-${item.bannerId}`} action onClick={(e) => this.selectBanner(e, item)}>
                                            <div>
                                                { item.title }
                                            </div>
                                            <small>Valid: { item.valid } Order: { item.order }</small>
                                        </ListGroup.Item>
                                    )
                                : 'No Banner'
                            }
                            </ListGroup>
                        </Col>
                    }
                    </Row>
                </Col>

                <Col className="scrollview">
                    {bannerCreate}
                    {bannerUpdate}
                </Col>
            </Row>            
        </Container>
    }
}

export default BannerView