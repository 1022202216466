import { API, graphqlOperation } from 'aws-amplify'
import { createMerchantOutlet, updateMerchantOutlet } from '../graphql/mutations'
import { dealByMerchantUniqueId } from '../graphql/queries'

export default class OutletModel {

    indices = ['geohashlong', 'geohashsix', 'geohashshort', 'subc_online', 'mallId', 'merchant_name_lowercase', 'postal_code', 'google_placeid']
    item = null

    constructor(item, merchant) {
        this.item = {
            merchant_unique_id: item.merchant_unique_id??'',
            store_id: item.store_id??'',
            merchant_id: item.merchant_id,
            mainCat: item.mainCat,
            display_merchant_name: item.display_merchant_name,
            google_type: item.google_type,
            mallId: item.mallId,
            merchant_name: item.merchant_name,
            merchant_namelist: item.merchant_namelist,
            merchant_name_lowercase: item.merchant_name_lowercase,
            subcategory: item.subcategory,
            geohashlong: item.geohashlong,
            geohashshort: item.geohashshort,
            geohashsix: item.geohashsix,
            google_id: item.google_id,
            google_placeid: item.google_placeid,
            image_src: item.image_src,
            image_path: item.image_path,
            merchantImageUrlV2: item.merchantImageUrlV2,
            defaultImageUrl: item.defaultImageUrl,
            outlet_name: item.outlet_name,
            general_location: item.general_location,
            address: item.address,
            phone: item.phone,
            latitude: item.latitude,
            longitude: item.longitude,
            postal_code: item.postal_code,
            postal_district: item.postal_district,
            postal_district_name: item.postal_district_name,
            postal_sector: item.postal_sector,
            subc_online: item.subc_online,
            businessSummary: item.businessSummary??'',
            outletWebsite: item.outletWebsite??'',            
            valid: item.valid,
            mcc: item.mcc,
            protectedAttributes: item.protectedAttributes?((typeof item.protectedAttributes  === 'string')?item.protectedAttributes:JSON.stringify(item.protectedAttributes)):'{}',
            blkAptNum: item.blkAptNum,
            roadName: item.roadName,
            country: item.country,
            buildingName: item.buildingName,
            unitNum: item.unitNum,
            type: item.type,
            hideOperatingHours: item.hideOperatingHours
        }
        if (merchant) {
            this.applyMerchantInfo(merchant)
        }
    }

    async invalidate() {
        this.item.valid = 'N'
        return await this.update()
    }

    async validate() {
        this.item.valid = 'Y'
        return await this.update()
    }

    async getDeals() {
console.log('item', this.item)
        const result = await API.graphql(graphqlOperation(dealByMerchantUniqueId, { merchant_unique_id: this.item.merchant_unique_id }))

        const deals = result.data.dealByMerchantUniqueId.items
        return deals
    }

    applyMerchantInfo(merchant) {
        const delta = {
            merchant_id: merchant.merchant_id,
            display_merchant_name: merchant.display_merchant_name,
            google_type: merchant.google_type,
            merchant_name: merchant.merchant_name,
            merchant_name_lowercase: merchant.merchant_name_lowercase,
            mainCat: merchant.mainCat,
            merchantImageUrl: merchant.merchantImageUrl,
            merchantImageUrlV2: merchant.merchantImageUrlV2,
            defaultImageUrl: merchant.defaultImageUrl
        }
        this.item = { ...this.item, ...delta }
    }

    async updateMerchantInfo(merchant) {
        this.applyMerchantInfo(merchant)
        return await this.update()
    }

    async create() {
        // remove indices with null values. ddb will throw error. cannot have null values in indices
        for (const index of this.indices) {
            console.log(index, this.item[index])
            if (index in this.item && (this.item[index] === undefined || this.item[index] === null || this.item[index] === '')) {
                delete this.item[index]
            }
        }

        const result = await API.graphql(graphqlOperation(createMerchantOutlet, { input: this.item }))

        this.item = result.data.createMerchantOutlet
        return this.item
    }

    async update() {
        // nullify indices with empty space values. ddb will throw error. cannot have empty values in indices
        for (const index of this.indices) {
            if (index in this.item && this.item[index] !== null && (this.item[index] === '' || this.item[index].toLowerCase() === 'null')) {
                this.item[index] = null
            }
        }
            
        const result = await API.graphql(graphqlOperation(updateMerchantOutlet, { input: this.item }))
        this.item = result.data.updateMerchantOutlet
        return this.item    
    }   
}
