import React, { Component } from 'react'
import { Form, Button, Toast, Col, Image } from "react-bootstrap"
import Datetime from 'react-datetime'
import moment from 'moment-timezone'
import { TZ } from '../../utils/constants'
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { createDynamicContent, updateDynamicContent } from '../../graphql/mutations'
import { listDynamicContents } from '../../graphql/queries'
import { publish } from '../widgets/CMSModal'
import Select from 'react-select';
import { DYNAMIC_CONTENT_TYPE } from '../../utils/constants'
import * as utils from '../../utils';
import { guidGenerator } from '../../utils/index'

moment.tz.setDefault(TZ);

class DynamicContent extends Component {
    constructor(props) {
        super(props);
        const dynamic = this.props.dynamic ? { ...this.props.dynamic } : {};
        this.state = {
            isLoading: false,
            item: {
                contentId: dynamic.contentId ?? '',
                tag: dynamic.tag ?? null,
                title: dynamic.title ?? null,
                valid: dynamic.valid === 'true' ? true : false,
                startTimestamp: dynamic.startTimestamp ?? '',//moment().startOf('day').unix(),
                endTimestamp: dynamic.endTimestamp ?? '',//moment().endOf('day').unix(),
                type: dynamic.type ?? DYNAMIC_CONTENT_TYPE[0].value,
                bannerContent: dynamic.bannerContent ?? '',
                order: dynamic.order ?? '',
                bannerLinked: dynamic.bannerLinked ?? false,
                seeMoreEnabled: dynamic.seeMoreEnabled ?? '',
                pinPageLocation: dynamic.pinPageLocation ?? '',
                iconImageUrl: dynamic.iconImageUrl ?? null,
                route: dynamic.route ?? null

            },
            showToast: false,
            list:[],
            selectedType: dynamic.type ? DYNAMIC_CONTENT_TYPE.find(o => o.value === dynamic.type) : DYNAMIC_CONTENT_TYPE[0],
            uType: dynamic.type,
            uValid: dynamic.valid === 'true' ? true : false,
            imageFile: null,
            imageExt: null
        }
    }

    componentDidMount(){
        API.graphql(graphqlOperation(listDynamicContents)).then((result) => {
            this.setState({ list: result.data.listDynamicContents.items });
        })
    }

    handleChange = (e, fieldname) => {
        const item = { ...this.state.item };
        const target = e.target;

        if (target.type === 'checkbox') {
            item[fieldname] = e.target.checked ? true : false;
        } else {
            item[fieldname] = e.target.value;
        }

        if (fieldname ==='bannerContent') {
            if (item.bannerContent.length === 0) {
                item['bannerLinked'] = false
            }
        }

        this.setState({ item: item });
        
    }

    handleContentChange(e, fieldname) {
        const item = { ...this.state.item };
        if (fieldname === 'image') {
            const file = e.target.files[0];
            const url = URL.createObjectURL(file);
            const ext = file.name.substring(file.name.lastIndexOf('.'));
            
            item.iconImageUrl = url;
            this.setState({ item: item, imageFile: file, imageExt: ext });
        } else {
            item[fieldname] = e.target.value;
            this.setState({ item: item });
        }
    }

    handleDateTime(m, fieldname) {
        const item = { ...this.state.item }

        if (typeof m === 'string') {
            item[fieldname] = moment(m, 'YYYY-MM-DD HH:mm:ss').unix()
        } else {
            item[fieldname] = m.unix()
        }

        this.setState({ item: item })
    }

    endDateOnOpen = () => {
        const item = { ...this.state.item }
        
        if (item['endTimestamp'].length === 0){
            item['endTimestamp'] = moment().endOf('day').unix()
        }

        this.setState({ item: item })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let flashValid = this.checkActiveFlash()
        const {item} = this.state
        let contentId = this.props.mode==='create' ? guidGenerator() : item.contentId

        const dynamic = item.type === "article" ?  {
            contentId: contentId,
            bannerContent: '',
            tag: item.tag ?? '',
            startTimestamp: item.startTimestamp,
            endTimestamp: item.endTimestamp,
            title: item.title ?? '',
            type: item.type ?? '',
            valid: item.valid.toString(),
            order: item.order.toString() || '0',
            bannerLinked: false,
            seeMoreEnabled: false,
            pinPageLocation: item.pinPageLocation ?? '',
            iconImageUrl: item.iconImageUrl ?? '',
            route: item.route ?? ''

        } : {
            contentId: contentId,
            bannerContent: item.bannerContent ?? '',
            tag: item.tag ?? '',
            startTimestamp: item.startTimestamp,
            endTimestamp: item.endTimestamp,
            title: item.title ?? '',
            type: item.type ?? '',
            valid: item.valid.toString(),
            order: item.order.toString() || '0',
            bannerLinked: item.bannerLinked,
            seeMoreEnabled: item.seeMoreEnabled,
            pinPageLocation: item.pinPageLocation ?? ''

        }
        
        if (item.startTimestamp >= item.endTimestamp && item.type === 'flash'){
            this.alert('Start Time must not equal or after End Time')
        } else {

            if (this.props.mode === 'create') {
                if (item.type === 'flash' && item.valid === true && flashValid === true){
                    this.alert('There is an Active Flash Deal')
                }else{
                    this.handleCreate(dynamic);
                }
                
            } else if (this.props.mode === 'update') {

                if (flashValid === true){

                    if (this.state.uType === 'flash' && this.state.uValid === true){
                        this.handleUpdate(dynamic);
                    }else if (item.type === 'flash' && item.valid === true && flashValid === true){
                        this.alert('There is an Active Flash Deal')
                    }else {
                        this.handleUpdate(dynamic);
                    }
                    
                }else{
                    this.handleUpdate(dynamic);
                }
            }
        }
        
    }

    async handleCreate(dynamic) {
        if (dynamic.type === "article") {
            const path = `ArticleImages/article_${dynamic.contentId}${this.state.imageExt}`;
            dynamic.iconImageUrl = utils.getAssetUrl(path);
            await Storage.put(path, this.state.imageFile);         
        }

        API.graphql(graphqlOperation(createDynamicContent, { input: dynamic })).then((result) => {
            this.setState({ showToast: true });
            if (this.props.onCreate) {
                this.props.onCreate(result.data);
                
            }
        })
    }
    
    handleUpdate = async (dynamic) => {
        if (dynamic.type === "article" && this.state.imageExt) {
            const path = `ArticleImages/article_${dynamic.contentId}${this.state.imageExt}`;
            dynamic.iconImageUrl = utils.getAssetUrl(path);
            await Storage.put(path, this.state.imageFile);
            utils.invalidateCloudFrontCache(dynamic.iconImageUrl);              
        }

        API.graphql(graphqlOperation(updateDynamicContent, { input: dynamic })).then((result) => {
            this.setState({ showToast: true });
            if (this.props.onUpdate) {
                this.props.onUpdate(result.data);

            }
        })
    }

    disablePastDT = (current) => {
        const yesterday = moment().subtract(1, 'day');
        return current.isAfter(yesterday); 
    }
    
    setContentId = () => {
        this.props.allList.sort((a,b)=> Number(a.contentId) - Number(b.contentId))
        let last_element = this.props.allList[this.props.allList.length - 1];
        let contentId =  parseInt(last_element.contentId) + 1
        return contentId
    }

    alert(msg, callback) {
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    setType(type) {
        this.state.item.type = type.value
        this.setState({
            item: this.state.item,
            selectedType: type
        })
    }

    checkActiveFlash = () => {
        let filter = {
            type: 'flash',
            valid: 'true'
          };
       let flash = this.props.allList.filter(obj => obj.type === filter.type && obj.valid === filter.valid)
       return flash.length >=1 ? true : false
    }

    render() {
        const { tag, title, valid, startTimestamp, endTimestamp, bannerContent, type, bannerLinked, order, seeMoreEnabled, pinPageLocation, iconImageUrl, route} = this.state.item
        const dateNow = `?date=${new Date()}`
        return(
           <div>
               {this.props.show &&
            
                <Form onSubmit={(e) => this.handleSubmit(e)}>  
                    <h3>{this.props.mode==='create'?'Create':'Update'} Dynamic Content</h3>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Start Date/Time</Form.Label>
                            <Datetime 
                                dateFormat="YYYY-MM-DD" 
                                timeFormat="HH:mm:ss" 
                                value={startTimestamp ? moment.unix(startTimestamp) : null}
                                onChange={(m) => this.handleDateTime(m, 'startTimestamp')} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>End Date/Time</Form.Label>
                            <Datetime 
                                dateFormat="YYYY-MM-DD" 
                                timeFormat="HH:mm:ss" 
                                value={endTimestamp ? moment.unix(endTimestamp) : null}
                                onChange={(m) => this.handleDateTime(m, 'endTimestamp')}
                                onOpen={()=> this.endDateOnOpen()}
                                 />
                        </Form.Group>
                    </Form.Row>

                    <Form.Group>
                    <Form.Label>Type *</Form.Label>
                    <Select
                        closeMenuOnSelect={true}
                        placeholder="Choose the type"
                        options={DYNAMIC_CONTENT_TYPE}
                        value={this.state.selectedType || DYNAMIC_CONTENT_TYPE[0]}
                        onChange={(type) => this.setType(type)}
                        required
                    />
                </Form.Group>

                    <Form.Group>
                        <Form.Label>Tag *</Form.Label>
                        <Form.Control type="text" required value={tag} onChange={(e) => this.handleChange(e, 'tag')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Title *</Form.Label>
                        <Form.Control type="text" required value={title} onChange={(e) => this.handleChange(e, 'title')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check label="Valid?" checked={valid} onChange={(e) => this.handleChange(e, 'valid')} />
                    </Form.Group>  

                    {this.state.selectedType && this.state.selectedType.value === "article" ? null : 
                    <div>
                    <Form.Group>
                        <Form.Label>Banner Content</Form.Label>
                    <Form.Control type="text" value={bannerContent} onChange={(e) => this.handleChange(e, 'bannerContent')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check label="Banner Linked?" disabled={bannerContent.length >0 ? false : true } checked={bannerLinked} onChange={(e) => this.handleChange(e, 'bannerLinked')} />
                    </Form.Group>  
                    </div>
                    }
                    {this.state.selectedType && this.state.selectedType.value === "article" ? 
                    <div>
                    <Form.Group>
                        <Form.Label>Image *</Form.Label>
                            <Form.File required={!iconImageUrl} accept="image/*" onChange={(e) => this.handleContentChange(e, 'image')}></Form.File>
                            { iconImageUrl ? 
                                iconImageUrl.startsWith('http') ?
                                <Image src={iconImageUrl + dateNow} fluid width='136' height='126'></Image> :
                                <Image src={iconImageUrl} fluid width='136' height='126'></Image>
                            : null }
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Path (URL) *</Form.Label>
                        <Form.Control required type="text" value={route} onChange={(e) => this.handleContentChange(e, 'route')} />
                    </Form.Group>
                    </div>
                    : null
                    }


                    <Form.Group>
                        <Form.Label>Order</Form.Label>
                        <Form.Control type="text" value={order} onChange={(e) => this.handleChange(e, 'order')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Pin Page Location</Form.Label>
                        <Form.Control type="text" value={pinPageLocation} onChange={(e) => this.handleChange(e, 'pinPageLocation')} />
                    </Form.Group>
                    {this.state.selectedType && this.state.selectedType.value === "article" ? null : 
                    <Form.Group>
                        <Form.Check label="See More Enabled?" checked={seeMoreEnabled} onChange={(e) => this.handleChange(e, 'seeMoreEnabled')} />
                    </Form.Group>  
                    }      
                    
                   

                    <Form.Group>
                        <Toast onClose={() => this.setState({ showToast: false })} show={this.state.showToast} delay={3000} autohide>
                            {this.props.mode==='create'?
                                <Toast.Body>Dynamic Content Created.</Toast.Body>
                            : null}
                            {this.props.mode==='update'?
                                <Toast.Body>Dynamic Content Updated.</Toast.Body>
                            : null}
                        
                        </Toast>
                            {this.props.mode==='create'?
                                <Button type="submit">Create Dynamic Content</Button>
                            : null}
                            {this.props.mode==='update'?
                                <Button type="submit">Update Dynamic Content</Button>
                            : null}
                    </Form.Group>

                </Form>  
                }  
           
           </div>
        )
    }

}
export default DynamicContent
