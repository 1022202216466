import React, { Component } from 'react'
import { Form, Button, Toast, Col, Row, Image } from "react-bootstrap"
import moment from 'moment-timezone'
import { backOff } from 'exponential-backoff';
import { TZ } from '../../utils/constants'
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { createDynamicContent, updateDynamicContent } from '../../graphql/mutations'
import { listDynamicContents } from '../../graphql/queries'
import { publish } from '../widgets/CMSModal'
import CMSImageUploader from './CMSImageUploader'
import * as utils from '../../utils'

moment.tz.setDefault(TZ);

class DynamicIcon extends Component {
    constructor(props) {
        super(props);
        const dynamic = this.props.dynamic ? { ...this.props.dynamic } : {};
        this.state = {
            isLoading: false,
            item: {
                contentId: dynamic.contentId ?? '',
                title: dynamic.title ?? null,
                valid: dynamic.valid === 'true' ? true : false,
                type: dynamic.type ?? 'icon',
                order: dynamic.order ?? '',
                route: dynamic.route ?? '',
                iconImageUrl: dynamic.iconImageUrl ?? ''
            },
            showToast: false,
            list:[],
            croppedImages: {}
        }
    }

    componentDidMount(){
        API.graphql(graphqlOperation(listDynamicContents)).then((result) => {
            this.setState({ list: result.data.listDynamicContents.items });
        })
    }

    handleChange = (e, fieldname) => {
        const item = { ...this.state.item };
        const target = e.target;

        if (target.type === 'checkbox') {
            item[fieldname] = e.target.checked ? true : false;
        } else {
            item[fieldname] = e.target.value;
        }

        this.setState({ item: item });
        
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { item, croppedImages } = this.state
        let contentId = this.props.mode==='create' ? this.setContentId().toString() : item.contentId
        let iconUrl = item.iconImageUrl
        let iconPath = ''
    
        if (croppedImages.dynamicIcon) {
            const ext = croppedImages.dynamicIcon.ext
            iconPath = `DynamicIcon/${contentId}${ext}`
            iconUrl = utils.getAssetUrl(iconPath)
        }

        const dynamic = {
            contentId: contentId,
            title: item.title,
            type: item.type,
            valid: item.valid.toString(),
            order: item.order.toString(),
            route: item.route,
            iconImageUrl: iconUrl
        }
        
        if (this.props.mode === 'create') {
                this.handleCreate(dynamic, iconPath);
        } else if (this.props.mode === 'update') {
            this.handleUpdate(dynamic, iconPath);
        }  
    }

    handleCreate(dynamic, iconPath) {
        API.graphql(graphqlOperation(createDynamicContent, { input: dynamic })).then(async (result) => {
            await this.handleImageUpload(iconPath)
            this.setState({ showToast: true });
            if (this.props.onCreate) {
                this.props.onCreate(result.data);
                
            }
        })
    }
    
    handleUpdate = (dynamic, iconPath) => {
        API.graphql(graphqlOperation(updateDynamicContent, { input: dynamic })).then(async (result) => {
            
            if (iconPath) {
                await this.handleImageUpload(iconPath)
                const result = await backOff(() => API.post('CloudFront', '/invalidate', {
                    body: {
                        path: `/public/${iconPath}`
                    }
                }))
                console.log({result})
            }

            this.setState({ showToast: true });
            if (this.props.onUpdate) {
                this.props.onUpdate(result.data);
                
            }
        })
    }
    
    setContentId = () => {
        const { list } = this.state
        list.sort((a,b)=> Number(a.contentId) - Number(b.contentId))
        let last_element = list[list.length - 1];
        let contentId =  parseInt(last_element.contentId) + 1
        return contentId
    }

    alert(msg, callback) {
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    getCroppedImage = (croppedImage, fieldname) => {
        let croppedImages = this.state.croppedImages??{}
        croppedImages[fieldname] = croppedImage
        
        this.setState({
            croppedImages: croppedImages
        })
    }

    async handleImageUpload(path) {
        const { croppedImages } = this.state
        if (croppedImages.dynamicIcon && croppedImages.dynamicIcon.blob) {
            croppedImages.dynamicIcon.blob.name = path
            await Storage.put(path, croppedImages.dynamicIcon.blob)
        }
    }

    render() {
        const { title, valid, order, type, route } = this.state.item
        const { croppedImages, item } = this.state
        
        return(
           <div>
               {this.props.show &&
            
                <Form onSubmit={(e) => this.handleSubmit(e)}>  
                    <h3>{this.props.mode==='create'?'Create':'Update'} Dynamic Icon</h3>

                    <Form.Group>
                        <Form.Label>Type</Form.Label>
                        <Form.Control type="text" disabled required value={type} onChange={(e) => this.handleChange(e, 'type')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Title *</Form.Label>
                        <Form.Control type="text" required value={title} onChange={(e) => this.handleChange(e, 'title')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check label="Valid?" checked={valid} onChange={(e) => this.handleChange(e, 'valid')} />
                    </Form.Group>  
                      
                    <Form.Group>
                        <Form.Label>Order *</Form.Label>
                        <Form.Control type="text" required value={order} onChange={(e) => this.handleChange(e, 'order')} />
                    </Form.Group>
                    
                    <Form.Group>
                        <Form.Label>Route *</Form.Label>
                        <Form.Control type="text" required value={route} onChange={(e) => this.handleChange(e, 'route')} />
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <CMSImageUploader
                                width={74}
                                height={68}                           
                                disabled={this.state.readOnly} 
                                readOnly={this.state.readOnly} 
                                label="Icon *"
                                required = {this.props.mode === 'create' ?? !this.state.item.iconImageUrl} 
                                onOk={(croppedImage) => this.getCroppedImage(croppedImage, 'dynamicIcon')}
                            />
                        </Form.Group>
                        { (croppedImages.dynamicIcon && croppedImages.dynamicIcon.url) || (item && item.iconImageUrl) ?
                            <Form.Group style={{width:'50%', textAlign: 'right'}}>
                                <Image fluid src={croppedImages.dynamicIcon?croppedImages.dynamicIcon.url:item.iconImageUrl} />
                            </Form.Group>
                        : null}
                    </Form.Row>

                    <Form.Group>
                        <Toast onClose={() => this.setState({ showToast: false })} show={this.state.showToast} delay={3000} autohide>
                            {this.props.mode==='create'?
                                <Toast.Body>Dynamic Icon Created.</Toast.Body>
                            : null}
                            {this.props.mode==='update'?
                                <Toast.Body>Dynamic Icon Updated.</Toast.Body>
                            : null}
                        
                        </Toast>
                            {this.props.mode==='create'?
                                <Button type="submit">Create Dynamic Icon</Button>
                            : null}
                            {this.props.mode==='update'?
                                <Button type="submit">Update Dynamic Icon</Button>
                            : null}
                    </Form.Group>

                </Form>  
                }  
           
           </div>
        )
    }

}
export default DynamicIcon
