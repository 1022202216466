import React, { Component } from 'react'
import { Form, Button, Spinner, Col, Image } from "react-bootstrap"
import { API, graphqlOperation, Storage } from 'aws-amplify'

import { publish } from '../widgets/CMSModal'
import CMSTag from '../widgets/CMSTag'
import CMSImageUploader from './CMSImageUploader'

import OutletModel from '../../models/OutletModel'
import DealModel from '../../models/DealModel'
import MerchantModel from '../../models/MerchantModel'
import * as utils from '../../utils'
import ACL from '../../utils/ACL'
class Merchant extends Component {

    constructor(props) {
        super(props)

        const merchant = props.merchant ? { ...props.merchant } : {}
        const defaultImageUrl = `https://assets.${utils.getEnv()}.cardspal.com/DefaultImages/MerchantImages/defaultMerchant.png`
        this.state = {
            item: {
                merchant_id: merchant.merchant_id??null,
                merchant_name: merchant.merchant_name??'',
                merchant_name_lowercase: merchant.merchant_name_lowercase??'',
                display_merchant_name: merchant.display_merchant_name??'',
                mainCat: merchant.mainCat??'',
                google_type: merchant.google_type??'[]',
                valid: merchant.valid??'Y',
                acceptCC: merchant.acceptCC??'0',
                originalTags: merchant.tags??[],
                tags: merchant.tags??[],
                merchantImageUrl: merchant.merchantImageUrl??'',
                merchantImageUrlV2: merchant.merchantImageUrlV2??'',
                defaultImageUrl: merchant.defaultImageUrl??defaultImageUrl,
                pockCalMerchImage: merchant.pockCalMerchImage??''    
            },
            croppedImages: {}
        }
    }

    async componentDidMount() {
        this.loadTags()
        this.setState({
            isAdmin: await ACL.isAdmin(),
            readOnly: this.props.mode === 'view' || ((this.props.mode !== 'create') && !(await ACL.canWriteMerchant(this.props.merchant))) // need to use this.props.merchant because createdByGroups not stored in state
        })       
    }

    async loadTags() {
        try {
            if (this.state.item.merchant_id) {
                const listTags = await new MerchantModel(this.state.item).getTags()
                this.state.item.originalTags = listTags
                this.state.item.tags = listTags
                this.setState({
                    item: this.state.item
                });
            }
        } catch (err) {
            console.error(err);
        }        
    }

    handleChange(e, fieldname) {
        const item = { ...this.state.item }
        const target = e.target
        if (target.type === 'checkbox') {
            if (fieldname === 'valid') {
                item[fieldname] = e.target.checked ? 'Y' : 'N'
            } else if (fieldname === 'acceptCC') {
                item[fieldname] = e.target.checked ? '1' : '0'
            } else {
                item[fieldname] = e.target.checked ? 'TRUE' : 'FALSE'
            }
        } else {
            item[fieldname] = e.target.value
        }

        if (fieldname === 'merchant_name') {
            item['merchant_name_lowercase'] = item[fieldname].replace(/ /g, '').toLowerCase()
        }

        this.setState({ item: item })
    }

    handleTagChange(tags) {
        this.state.item.tags = tags
        this.setState({
            item: this.state.item
        })
    }

    async handleImageUpload(path, fieldname) {
        const image = this.state.croppedImages[fieldname]
        if (image.blob) {
            image.blob.name = path
            const resultUpload = await Storage.put(path, image.blob)
            console.log('result upload to S3', resultUpload)
        }
    }

    async getCroppedImage(croppedImage, fieldname) {
        let croppedImages = this.state.croppedImages??{}
        croppedImages[fieldname] = croppedImage
        console.log('croppedImages', croppedImages)
        this.setState({
            croppedImages: croppedImages,
        })
    }

    async handleSubmit(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            processing: true
        })

        try {
            if (this.props.mode === 'create') {
                await this.handleCreate(this.state.item)
            } else if (this.props.mode === 'update') {
                await this.handleUpdate(this.state.item)
            } else {
                this.props.onClose()
            }
        } catch(error) {
            console.error(error)
            this.alert('Error processing Merchant')
        }
    }

    async handleCreate(merchant) {
        try{
            // create merchant
            const merchantModel = new MerchantModel(merchant)
            const createdMerchant = await merchantModel.create()
            
            // update tags
            const result = await merchantModel.setTags(merchant.originalTags, merchant.tags)
            console.log(result)

            // get path to form url from id and upload to s3
            const id = createdMerchant.merchant_id
            merchant.merchant_id = id
            console.log('croppedImages', this.state.croppedImages)
            for (const key of Object.keys(this.state.croppedImages)) {
                console.log('key', key)
                const image = this.state.croppedImages[key]
                console.log('image', image)
                const path = `MerchantImages/${key}_${id}${image.ext}`
                merchant[key] = utils.getAssetUrl(path)
                this.handleImageUpload(path, key)
            }
            // update merchant once again for newly formed image urls
            const merchantModel2 = new MerchantModel(merchant)
            const updatedMerchant = await merchantModel2.update()

            this.alert('Merchant Created', () => {
                if (this.props.onCreate) {
                    this.props.onCreate(createdMerchant)
                }    
            })
        } catch(e) {
            console.log(e)
            this.alert(`Error in merchant creation. Please report this to admin. ${JSON.stringify(e)}`)
        }
    }

    async handleUpdate(merchant) {

        const invalidation = []

        for (const key of Object.keys(this.state.croppedImages)) {
            console.log('key', key)
            const image = this.state.croppedImages[key]
            console.log('image', image)
            const path = `MerchantImages/${key}_${merchant.merchant_id}${image.ext}`
            merchant[key] = utils.getAssetUrl(path)
            invalidation.push(key)
            this.handleImageUpload(path, key)
        }

        const merchantModel = new MerchantModel(merchant)
        const updatedMerchant = await merchantModel.update({invalidateCloudFrontCache: invalidation})
        const result = await merchantModel.setTags(merchant.originalTags, merchant.tags)
        console.log(result)
        
        // update merchant info into outlets
        const outlets = updatedMerchant.outlets.items
        for (const outlet of outlets) {
            const outletModel = new OutletModel(outlet)
            const updatedOutlet = await outletModel.updateMerchantInfo(merchant)

            // update merchant info into deals
            const deals = updatedOutlet.deals.items
            for (const deal of deals) {
                const dealModel = new DealModel(deal)
                dealModel.updateMerchantInfo(merchant)
            }
        }

        this.alert('Merchant Updated', () => {
            if (this.props.onUpdate) {
                this.props.onUpdate(updatedMerchant)
            }
        })
    }

    alert(msg, callback) {
        this.setState({
            processing: false
        })
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    render() {
        return <div>
            {this.props.show ?
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    {/* <h3><FaStore /> {this.props.title??(this.props.mode==='create'?'Create Merchant':'Update Merchant')}</h3> */}
                    { this.state.isAdmin ?
                        <div>
                            <Form.Group>
                                <Form.Label>Merchant Id</Form.Label>
                                <Form.Control type="text" disabled readOnly value={this.state.item.merchant_id} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Merchant Lowercase Name</Form.Label>
                                <Form.Control type="text" value={this.state.item.merchant_name_lowercase} onChange={(e) => this.handleChange(e, 'merchant_name_lowercase')} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Merchant Google Type</Form.Label>
                                <Form.Control type="text" value={this.state.item.google_type} onChange={(e) => this.handleChange(e, 'google_type')} />
                            </Form.Group>
                            {/* <Form.Group>
                                <Form.Check label="Online Presence Only" checked={this.state.item.online_company.toUpperCase() === 'TRUE'} onChange={(e) => this.handleChange(e, 'online_company')} />
                            </Form.Group> */}
                            <Form.Group>
                                <Form.Check type="checkbox" id="valid" custom label="Visible in CardsPal App" checked={this.state.item.valid.toUpperCase() === 'Y'} onChange={(e) => this.handleChange(e, 'valid')} />
                            </Form.Group>
                        </div>
                    : null}
                    <Form.Group>
                        <Form.Label>Merchant Name *</Form.Label>
                        <Form.Control required type="text" disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.merchant_name} onChange={(e) => this.handleChange(e, 'merchant_name')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Merchant Display Name *</Form.Label>
                        <Form.Control required type="text" disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.display_merchant_name} onChange={(e) => this.handleChange(e, 'display_merchant_name')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Merchant Category *</Form.Label>
                        <Form.Control required as="select" custom disabled={this.state.readOnly} readOnly={this.state.readOnly} value={this.state.item.mainCat} onChange={(e) => this.handleChange(e, 'mainCat')}>
                            <option value="">-- Select your business category --</option>
                            <option value="Food">Food</option>
                            <option value="Shopping">Shopping</option>
                            <option value="Activities">Activities</option>
                            <option value="Travel">Travel</option>
                            <option value="Transport">Transport</option>
                            <option value="Wellness">Wellness</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <CMSImageUploader
                                width={110}
                                height={110}                           
                                disabled={this.state.readOnly} 
                                readOnly={this.state.readOnly} 
                                label="Merchant Image Logo" 
                                onOk={(croppedImage) => this.getCroppedImage(croppedImage, 'pockCalMerchImage')}
                            />
                        </Form.Group>
                        { (this.state.croppedImages.pockCalMerchImage && this.state.croppedImages.pockCalMerchImage.url) || (this.state.item && this.state.item.pockCalMerchImage) ?
                            <Form.Group style={{width:'50%', textAlign: 'right'}}>
                                <Image fluid src={this.state.croppedImages.pockCalMerchImage?this.state.croppedImages.pockCalMerchImage.url:`${this.state.item.pockCalMerchImage}?timestamp=${Date.now()}`} />
                            </Form.Group>
                        : null}
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} >
                            <CMSImageUploader
                                width={375}
                                height={266}                           
                                disabled={this.state.readOnly} 
                                readOnly={this.state.readOnly} 
                                label="Merchant Image Banner"
                                onOk={(croppedImage) => this.getCroppedImage(croppedImage, 'merchantImageUrlV2')}
                            />
                        </Form.Group>
                        { (this.state.croppedImages.merchantImageUrlV2 && this.state.croppedImages.merchantImageUrlV2.url) || (this.state.item && this.state.item.merchantImageUrlV2) ?
                            <Form.Group style={{width:'50%', textAlign: 'right'}}>
                                <Image fluid src={this.state.croppedImages.merchantImageUrlV2?this.state.croppedImages.merchantImageUrlV2.url:`${this.state.item.merchantImageUrlV2}?timestamp=${Date.now()}`} />
                            </Form.Group>
                        : null}
                    </Form.Row>
                    { this.state.isAdmin ?
                        <Form.Row>
                            <Form.Group as={Col}>
                                <CMSImageUploader
                                    width={375}
                                    height={266}                           
                                    disabled={this.state.readOnly} 
                                    readOnly={this.state.readOnly} 
                                    label="Merchant Default Banner" 
                                    onOk={(croppedImage) => this.getCroppedImage(croppedImage, 'defaultImageUrl')}
                                />
                            </Form.Group>
                        { (this.state.croppedImages.defaultImageUrl && this.state.croppedImages.defaultImageUrl.url) || (this.state.item && this.state.item.defaultImageUrl) ?
                            <Form.Group style={{width:'50%', textAlign: 'right'}}>
                                <Image fluid src={this.state.croppedImages.defaultImageUrl?this.state.croppedImages.defaultImageUrl.url:`${this.state.item.defaultImageUrl}?timestamp=${Date.now()}`} />
                            </Form.Group>
                        : null }
                        </Form.Row>
                    : null }
                    <Form.Row>
                        <Form.Group as={Col}>
                            <CMSTag tags={this.state.item.tags} disabled={this.state.readOnly} readOnly={this.state.readOnly} onChange={(tags) => this.handleTagChange(tags)} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Check type="checkbox" id="acceptCC" custom disabled={this.state.readOnly} readOnly={this.state.readOnly} label="Accepts Credit Card" checked={this.state.item.acceptCC === '1'} onChange={(e) => this.handleChange(e, 'acceptCC')} />
                    </Form.Group>
                    <Form.Group>
                        {this.props.mode === 'create' ? 
                            <Button disabled={ this.state.processing || this.state.readOnly } type="submit">
                                {this.props.btnText??'Create Merchant'}
                            </Button>
                        : this.props.mode === 'update' ?
                            <Button disabled={ this.state.processing || this.state.readOnly } type="submit">
                                {this.props.btnText??'Update Merchant'}
                            </Button>
                        : this.props.mode === 'view' ?
                            <Button disabled={ this.state.processing } type="submit">
                                {this.props.btnText??'Ok'}
                            </Button>
                        : null}
                            
                        {this.state.processing ?
                            <Spinner animation="border" size="sm" className="ml-1" />
                        : null}
                    </Form.Group>

                </Form>
                : null}
        </div>
            ;
    }
}

export default Merchant