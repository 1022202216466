import { Hub } from 'aws-amplify'
import React, { Component } from 'react'
import { Navbar, OverlayTrigger, Tooltip, Button } from "react-bootstrap"
import { FaUser, FaRegQuestionCircle, FaChrome, FaFilePdf, FaWallet } from "react-icons/fa"
import 'bootstrap/dist/css/bootstrap.min.css'

import { Auth } from '@aws-amplify/auth'

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  async componentDidMount() {
    const user = await Auth.currentAuthenticatedUser()
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"]
    this.setState({
      user: user,
      groups: groups ?? [],
      isAdmin: groups ? groups.includes('admin') : false,
      isMerchant: groups ? groups.includes('merchant') : false,
      isNetwork: groups ? groups.includes('network') : false,
    })
  }

  showSubPlans() {
    console.log('header sub plan')
    Hub.dispatch(
      'SubPlanChannel', 
      {
        event: 'open'
      }
    )
  }

  async signOut() {
    Auth.signOut({ global: true }).then(async () => {
      window.location.reload()
    })
  }

  render() {

    return (
      <Navbar>
        <Navbar.Brand className="mr-auto">
          <img src="logo-2x-fullcolor.png" alt='' /> <small>beta</small>
        </Navbar.Brand>

        <Navbar>
          <a href="https://cardspal.com/wp-content/uploads/2021/05/CardsPal-for-Business_User-Guide_May-2021.pdf" target="_user_guide">
            <FaFilePdf></FaFilePdf> User Guide
          </a>
        </Navbar>
        <Navbar>
          <a href="https://www.google.com/chrome" target="_download_chrome">
            <FaChrome /> Supports Chrome
          </a>
        </Navbar>
        { this.state.user && !this.state.isAdmin ?
          <Navbar>
            <a href="#" onClick={() => this.showSubPlans()}>
              <FaWallet/> My Subscription
            </a>
          </Navbar>
        : null}

        <Navbar.Text className="mr-sm-2">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={(props) => (
              <Tooltip id="button-tooltip" {...props}>
                For support, please send your email to support@cardspal.com
              </Tooltip>
            )}
          >
            <FaRegQuestionCircle />
          </OverlayTrigger>
          {this.state.isMerchant ?
            <FaUser onClick={() => this.updateMerchant()} />
            : null}&nbsp;
          {this.state.user ? this.state.user.attributes.email : ''}
        </Navbar.Text>
        { this.state.user ?
          <Navbar.Text>
            <Button onClick={() => this.signOut()}>Sign Out</Button>
          </Navbar.Text>
          : null}
      </Navbar>
    )
  }
}

export default Header
