import React, { Component } from 'react'
import { Button, Form, Modal, FormFile } from "react-bootstrap"
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { publish } from './CMSModal'

const MAX_FILE_SIZE_MB=5 // 5 MB
const MAX_FILE_SIZE_BYTES=1024*1024*MAX_FILE_SIZE_MB // 5 MB
class CMSImageUploader extends Component {

    constructor(props) {
        super(props)

        this.state = {

        }
    }
            
    async componentDidMount() {
        this.setState({
            crop: {
                unit: '%',
                x: 0,
                y: 0,
                width: this.props.width,
                aspect: this.props.width / this.props.height
            }
        })
    }

    async setCrop(crop) {
        this.setState({
            crop: crop
        })
    }

    handleFileChange(e) {
        this.setState({
            file: null,
            url: null,
            ext: null
        })

        const file = e.target.files[0]

        if (file.size > MAX_FILE_SIZE_BYTES) {

            this.alert(`Uploaded image is too large. Try to keep it below ${MAX_FILE_SIZE_MB}MB in filesize.`)
            e.target.value = null

        } else {
            const url = URL.createObjectURL(file)
            const ext = file.name.substring(file.name.lastIndexOf('.'))
            this.setState({
                file: file,
                url: url,
                ext: ext
            })
        }
    }
    
    alert(msg, callback) {
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    getCroppedImg(doNotCrop) {
        return new Promise((resolve, reject) => {

            const crop = this.state.crop
            var reader = new FileReader()
            reader.onload = (event) => {
                const image = document.getElementsByClassName('ReactCrop__image')[0]
                const canvas = document.createElement('canvas')
                const scaleX = image.naturalWidth / image.width
                const scaleY = image.naturalHeight / image.height
                canvas.width = doNotCrop ? this.props.width : crop.width
                canvas.height = doNotCrop ? this.props.height : crop.height
                const ctx = canvas.getContext('2d')

                if (doNotCrop) {
                    ctx.drawImage( image, 0, 0)
                } else {
                    ctx.drawImage(
                        image,
                        crop.x * scaleX,
                        crop.y * scaleY,
                        crop.width * scaleX,
                        crop.height * scaleY,
                        0,
                        0,
                        crop.width,
                        crop.height,
                    );
                }

                // As Base64 string
                const base64Image = canvas.toDataURL('image/png')
                // As a blob
                canvas.toBlob(blob => {
                    // blob.name = fileName
                    resolve({
                        url: base64Image, 
                        blob: blob,
                        ext: '.png'
                    })
                }, 'image/png', 1)
            }
            reader.readAsDataURL(this.state.file);
        })
    }
    
    async ok(doNotCrop) {
        if (this.props.onOk) {
            this.props.onOk(await this.getCroppedImg(doNotCrop))
        }

        this.setState({
            url: null
        })
    }

    async cancel() {
        this.setState({
            url: null
        })
    }

    render() {
        return <div style={{width: '400px'}}>
            <Form.File required={this.props.required} disabled={this.props.readOnly} readOnly={this.props.readOnly} label={this.props.label} custom accept="image/*" onChange={(e) => this.handleFileChange(e)}></Form.File>
            <Form.Text>image cropped will be scaled to display {this.props.width} by {this.props.height} pixels (you can upload larger images though, just use the cropping rectangle)</Form.Text>
            <Form.Text>filesize limit {MAX_FILE_SIZE_MB}MB</Form.Text>

            <Modal show={this.state.url}>
                <Modal.Body>
                    <ReactCrop id="reactCrop" src={this.state.url} minWidth={this.props.width} crop={this.state.crop} keepSelection={true} onChange={(crop) => this.setCrop(crop)} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=> this.ok(this.props.doNotCrop || false)}>Done</Button>
                    <Button variant="danger" onClick={()=> this.cancel()}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>
    }
}

export default CMSImageUploader