import React, { Component } from 'react'
import { Form, Button, Toast, Col, InputGroup, Spinner } from "react-bootstrap"
import Select from 'react-select';
import { DISCOUNT_LEVEL, DISCOUNT_TYPE, APPLIES_TO, APPLICABLE_ON } from '../../utils/constants'
import moment from 'moment-timezone'
import Datetime from 'react-datetime'
import { API, graphqlOperation } from 'aws-amplify'
import { publish } from '../widgets/CMSModal'
import { createDiscountCampaign, createDiscountMaster, updateDiscountCampaign } from '../../graphql/mutations'
import { guidGenerator, isValidHttpUrl } from '../../utils'
import "react-datetime/css/react-datetime.css"
import { getDealMaster } from '../../graphql/custom'
import { getMerchant } from '../../graphql/queries'
class DiscountCode extends Component {
    constructor(props) {
        super(props);
        const discountCode = this.props.discountCode ? { ...this.props.discountCode } : {};
        this.state = {
            isDealSpecificLoading: false,
            isDealExclusionLoading: false,
            isMerchantExclusionLoading: false,
            isMerchantSpecificLoading: false,
            dynamic:'',
            list: [],
            showToast: false,
            item: {
                campaignId: discountCode.campaignId,
                code: discountCode.code,
                discountType: discountCode.discountType || DISCOUNT_TYPE[0].value,
                valid: discountCode.valid || "1",
                startTimestamp: discountCode.startTimestamp || moment().unix(),
                endTimestamp: discountCode.endTimestamp || '',
                discountLevel: discountCode.discountLevel || DISCOUNT_LEVEL[0].value,
                provider: discountCode.provider || 'CardsPal',
                amount: discountCode.amount,
                description: discountCode.description || "",
                minimumSpend: discountCode.minimumSpend,
                capped:discountCode.capped,
                usageLimitPerUser: discountCode.usageLimitPerUser,
                usageCapacity: discountCode.usageCapacity || 1,
                terms: discountCode.terms || "",
                createdAt: discountCode.createdAt,
                updatedAt: discountCode.updatedAt,
                multiplier: discountCode.multiplier || '',
                dealSpecific: discountCode.dealSpecific || [],
                dealExclusion: discountCode.dealExclusion || [],
                merchantExclusion: discountCode.merchantExclusion || [],
                merchantSpecific: discountCode.merchantSpecific || [],
                appliesTo: discountCode.appliesTo || APPLIES_TO[0].value,
                applicableOn: discountCode.applicableOn || APPLICABLE_ON[0].value
            },
            hasMinSpend: false, 
            hasEndTimestamp: this.props.mode==='create' ? true : (discountCode.endTimestamp ? true : false),
            isSingleUse: discountCode.usageLimitPerUser === 1, 
            errors: {},
            warnings: {}
        }
    }

    async componentDidMount() {
        
    }

    handleChange = (e, fieldName) => {
        const item = { ...this.state.item };
        let { hasMinSpend, hasEndTimestamp, isSingleUse } = this.state
        const target = e.target;

        if (target.type === 'checkbox') {
            if (fieldName==='hasMinSpend') {
                hasMinSpend = target.checked
            } else if (fieldName==='hasEndTimestamp') {
                hasEndTimestamp = target.checked ? false : true
                item['endTimestamp'] = ''
            } else {
                item[fieldName] = target.checked ? "1" : "0";
            }
        } else if (target.type === 'radio') {
            isSingleUse = target.value === 'single'
            if (isSingleUse) item['usageLimitPerUser'] = "1"
        } else if (fieldName==='usageCapacity') {
            item['usageCapacity'] = target.value
        } else if (fieldName === 'usageLimitPerUser' || fieldName === 'multiplier') {
            // do not allow non integer for this field
            const re = /^[0-9\b]+$|^$/;
            if (re.test(target.value)) {
                item[fieldName] = target.value
            }
        } else if (fieldName==='minimumSpend' || fieldName==='amount' || fieldName==='capped') {
            const re = /^[0-9]*\.?[0-9]*$/
            if (re.test(target.value)) {
                item[fieldName] = target.value;
            }
        } else if (fieldName === 'dealSpecific' || fieldName === 'dealExclusion' || fieldName === 'merchantExclusion' || fieldName === 'merchantSpecific') {
            let value = target.value.replaceAll(' ', '')
            if (value && value.length > 0) {
                item[fieldName] = target.value.replace(/\s/g, '').split(',');
            } else {
                item[fieldName] = []
            }
        }
        else {
            item[fieldName] = target.value;
        }
        this.setState({ hasMinSpend, hasEndTimestamp, isSingleUse, item });
    }

    handleChangeDiscountType(selected, action) {
        const item = { ...this.state.item };
        item.discountType = selected.value
        this.setState({ item })
    }
    
    handleChangeAppliesTo(selected, action) {
        const item = { ...this.state.item };
        item.appliesTo = selected.value
        item.applicableOn = APPLICABLE_ON[0].value
        item.merchantExclusion = []
        item.merchantSpecific = []
        item.dealSpecific = []
        item.dealExclusion = []
        this.state.errors.dealExclusion = ''
        this.state.errors.dealSpecific = ''
        this.state.errors.merchantSpecific = ''
        this.state.errors.merchantExclusion = ''
        this.setState({ item })
    }

    handleChangeApplicableOn(selected, action) {
        const item = { ...this.state.item };
        item.applicableOn = selected.value
        this.setState({ item })
    }


    async validate(e, fieldName) {
        const { errors, warnings, item: discountCode } = this.state
        let isValid = false

        if (['minimumSpend', 'amount'].includes(fieldName) && discountCode.minimumSpend && ((Number(discountCode.minimumSpend) - Number(discountCode.amount)) <= 0.5 ) && (discountCode.discountType !=="percentage")) {
            errors[fieldName] = 'The difference between minimum spend and discount value must be above $0.50'
        } else if (['minimumSpend', 'amount', 'capped'].includes(fieldName) && discountCode[fieldName] && discountCode[fieldName] <= 0) {
            errors[fieldName] = 'Must be greater than 0'
        } else if (fieldName==='fieldName' && Number(discountCode.minimumSpend) <= Number(discountCode.amount)) {
            errors.minimumSpend = 'Must be more than discount value'
        } else if (fieldName==='amount' && (discountCode.discountType ==="percentage") && (Number(discountCode.amount) > 100 )) {
            errors.amount = 'Maximum value is 100'
        } else if (fieldName==='amount' && discountCode.minimumSpend && Number(discountCode.amount) >= Number(discountCode.minimumSpend) && (discountCode.discountType !=="percentage")) {
            errors.amount = 'Must be less than minimum spending'
        } else if (fieldName === 'usageCapacity' && (discountCode.usageCapacity <= 0 || discountCode.usageCapacity > 99999)) {
            errors.usageCapacity = 'Usage capacity should be greater than 0 or less than 99,999'
        } else if (fieldName==='usageLimitPerUser' && discountCode.usageLimitPerUser && Number(discountCode.usageLimitPerUser)<=1) {
            errors.usageLimitPerUser = 'Usage limit per user should be greater than 1'
        } else if (fieldName === 'multiplier' && discountCode.multiplier && Number(discountCode.multiplier) < 1) {
            errors.multiplier = 'Must be greater than 1'
        } else if (fieldName === 'dealSpecific' && discountCode.dealSpecific?.length > 0) {
            this.setState({ isDealSpecificLoading: true })
            let warning = ''
            for (const pk of discountCode.dealSpecific) {
                try {
                    let result = await API.graphql(graphqlOperation(getDealMaster, { pk: pk }))
                    if (!result?.data?.getDealMaster?.pk) {
                        errors.dealSpecific = 'Invalid Deal ID detected'
                        break;
                    } else {
                        if (discountCode.dealExclusion?.includes(pk)) {
                            errors.dealSpecific = 'Similar deal ID detected under “Deal-exclusion” section.'
                            break;
                        } else if (discountCode.merchantExclusion?.includes(result?.data?.getDealMaster?.merchant_id)) {
                            errors.dealSpecific = 'Similar deal ID detected under merchant listed in “Merchant Exclusion” section.'
                            break;
                        } else {
                            errors.dealSpecific = ''
                        }
                        if (result.data.getDealMaster.valid === 'N') {
                            warning = 'Warning: Deal is invalidated. Please double check.'
                        }
                    }
                } catch (error) {
                    errors.dealSpecific = 'Invalid Deal ID detected'
                    console.error('error on getDealMaster ', error)
                    break;
                }
            }
            warnings.dealSpecific = warning
            this.setState({ isDealSpecificLoading: false })
        } else if (fieldName === 'dealExclusion' && discountCode.dealExclusion?.length > 0) {
            this.setState({ isDealExclusionLoading: true })
            let warning = ''
            for (const pk of discountCode.dealExclusion) {
                try {
                    let result = await API.graphql(graphqlOperation(getDealMaster, { pk: pk }))
                    if (!result?.data?.getDealMaster?.pk) {
                        errors.dealExclusion = 'Invalid Deal ID detected'
                        break;
                    } else {
                        if (discountCode.dealSpecific?.includes(pk)) {
                            errors.dealExclusion = 'Similar deal ID detected under “Deal-specific” section.'
                            break;
                        } else if (discountCode.merchantExclusion?.includes(result?.data?.getDealMaster?.merchant_id)) {
                            errors.dealExclusion = 'Similar deal ID detected under merchant listed in “Merchant Exclusion” section.'
                            break;
                        } else {
                            errors.dealExclusion = ''
                        }
                        if (result.data.getDealMaster.valid === 'N') {
                            warning = 'Warning: Deal is invalidated. Please double check.'
                        }
                    }
                } catch (error) {
                    console.error('error on getDealMaster ', error)
                    errors.dealExclusion = 'Invalid Deal ID detected'
                    break;
                }
            }
            warnings.dealExclusion = warning
            this.setState({ isDealExclusionLoading: false })
        } else if (fieldName === 'merchantExclusion' && discountCode.merchantExclusion?.length > 0) {
            this.setState({ isMerchantExclusionLoading: true })
            let warning = ''
            for (const merchant_id of discountCode.merchantExclusion) {
                try {
                    let result = await API.graphql(graphqlOperation(getMerchant, { merchant_id: merchant_id }))
                    if (!result?.data?.getMerchant?.merchant_id) {
                        errors.merchantExclusion = 'Invalid Merchant ID detected'
                        break;
                    }else if (discountCode.merchantSpecific?.includes(merchant_id)) {
                        errors.merchantExclusion = 'Similar deal ID detected under “Merchant-specific” section.'
                        break;
                    }else {
                        errors.merchantExclusion = ''
                        if (!result.data.getMerchant.valid || result.data.getMerchant.valid === 'N') {
                            warning = 'Warning: Merchant is invalidated. Please double check.'
                        }
                    }
                } catch (error) {
                    console.error('error on getDealMaster ', error)
                    errors.merchantExclusion = 'Invalid Merchant ID detected'
                    break;
                }
            }
            warnings.merchantExclusion = warning
            this.setState({ isMerchantExclusionLoading: false })
        } else if (fieldName === 'merchantSpecific' && discountCode.merchantSpecific?.length > 0) {
            this.setState({ isMerchantSpecificLoading: true })
            let warning = ''
            for (const merchant_id of discountCode.merchantSpecific) {
                try {
                    let result = await API.graphql(graphqlOperation(getMerchant, { merchant_id: merchant_id }))
                    if (!result?.data?.getMerchant?.merchant_id) {
                        errors.merchantSpecific = 'Invalid Merchant ID detected'
                        break;
                    }else if (discountCode.merchantExclusion?.includes(merchant_id)) {
                        errors.merchantSpecific = 'Similar merchant ID detected under “Merchant-exclusion” section.'
                        break;
                    } else {
                        errors.merchantSpecific = ''
                        if (!result.data.getMerchant.valid || result.data.getMerchant.valid === 'N') {
                            warning = 'Warning: Merchant is invalidated. Please double check.'
                        }
                    }
                } catch (error) {
                    console.error('error on getDealMaster ', error)
                    errors.merchantSpecific = 'Invalid Merchant ID detected'
                    break;
                }
            }
            warnings.merchantSpecific = warning
            this.setState({ isMerchantSpecificLoading: false })
        } else if (fieldName === 'multiplierLifetimeQty' && discountCode.multiplierLifetimeQty && Number(discountCode.multiplierLifetimeQty) < 1) {
            errors.multiplier = 'Must be greater than 1.'
        }else if ((fieldName === 'multiplier' || fieldName === 'multiplierLifetimeQty') && discountCode.multiplier && discountCode.multiplierLifetimeQty && (Number(discountCode.multiplierLifetimeQty) < Number(discountCode.multiplier))) {
            console.log(fieldName, discountCode.multiplier, discountCode.multiplierLifetimeQty)
            fieldName === 'multiplier' ? errors.multiplier = 'Cannot be greater than multiplier lifetime quantity.' : errors.multiplierLifetimeQty = 'Cannot be smaller than multipler.'
        }else if (fieldName === 'multiplierLifetimeQty' && discountCode.multiplierLifetimeQty && !discountCode.multiplier) {
            errors.multiplier = 'Cannot be blank when multiplier lifetime quantity is set.'
        } else {
            isValid = true
        }

        if (isValid) {
            // clear the error message, if there are previous message
            if(fieldName === 'multiplierLifetimeQty'){
                errors.multiplier = ''
                errors.multiplierLifetimeQty = ''
            }else
                errors[fieldName] = ''
            warnings[fieldName] = ''
        }
        this.setState({ errors, warnings })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        
        const { errors, hasMinSpend, isSingleUse, hasEndTimestamp, item: discountCode } = this.state
        const currentDT = moment()

        let stop = false
        Object.keys(errors).forEach((i) => {
            if (errors[i] !== '') {
                this.alert(errors[i])
                stop = true
                return
            }
        });
        
        if (hasEndTimestamp && discountCode.startTimestamp >= discountCode.endTimestamp ){
            this.alert('Start Date/Time must not equal or after End Date/Time')
            stop = true
            return 
        } else if (hasEndTimestamp && discountCode.endTimestamp >= currentDT) {
            this.alert('End Date/Time should be future dates')
            stop = true
            return
        } else if (discountCode.amount <= 0) {
            this.alert('Must be greater than 0')
            stop = true
            return
        } else if (Number(discountCode.minimumSpend) && Number(discountCode.amount) >= Number(discountCode.minimumSpend) && (discountCode.discountType !=="percentage")) {
            this.alert('Discount code amount must not be greater than minimum spending')
            stop = true
            return
        } else if (Number(discountCode.minimumSpend) && (Number(discountCode.minimumSpend) - Number(discountCode.amount)) <= 0.5  && (discountCode.discountType !=="percentage")) {
            this.alert('The difference between minimum spend and discount value must be above $0.50')
            stop = true
            return
        } else if (Number(discountCode.minimumSpend) && Number(discountCode.minimumSpend) <= 0) {
            this.alert('Minimum spending amount must be greater than 0')
            stop = true
            return
        } else if (Number(discountCode.minimumSpend) && Number(discountCode.minimumSpend) <= Number(discountCode.amount) && (discountCode.discountType !=="percentage")) {
            this.alert('Minimum spending must be more than discount value')
            stop = true
            return
        } else if (discountCode.usageCapacity <= 0 || discountCode.usageCapacity > 99999) {
            this.alert('Usage capacity should be greater than 0 or less than 99,999')
            stop = true
            return
        } else if (!isSingleUse && Number(discountCode.usageLimitPerUser)>10) {
            this.alert('Usage limit per user should not be greater than 10')
            stop = true
            return
        } else if (discountCode.dealSpecific?.length <1 && discountCode.dealExclusion?.length < 1 && discountCode.appliesTo === APPLIES_TO[1].value){
            this.alert('Please fill up either Deal Specific or Deal Exclusion field when applying to Specific deals orders.')
            stop = true
            return
        } else if (discountCode.merchantSpecific?.length <1 && discountCode.merchantExclusion?.length < 1 && discountCode.appliesTo === APPLIES_TO[2].value){
            this.alert('Please fill up either Merchant Specific or Merchant Exclusion field when applying to Specific merchants orders.')
            stop = true
            return
        }

        if (discountCode.appliesTo === APPLIES_TO[0].value){
            discountCode.merchantExclusion = []
            discountCode.merchantSpecific = []
            discountCode.dealSpecific = []
            discountCode.dealExclusion = []      
        }else if(discountCode.appliesTo === APPLIES_TO[1].value){
            discountCode.merchantExclusion = []
            discountCode.merchantSpecific = []
        }else if(discountCode.appliesTo === APPLIES_TO[2].value){
            discountCode.dealExclusion = []
            discountCode.dealSpecific = []
        }

        if (!Number(discountCode.minimumSpend)) delete discountCode.minimumSpend
        if (!Number(discountCode.usageLimitPerUser)) delete discountCode.usageLimitPerUser
        if (!Number(discountCode.capped)) delete discountCode.capped

        if (this.props.mode === 'create' && !stop) {
            discountCode.campaignId = guidGenerator()
            this.handleCreate(discountCode, discountCode.usageCapacity);
        } else if (this.props.mode === 'update' && !stop) {
            this.handleUpdate(discountCode);
        }
    }

    handleDateTime(m, fieldName) {
        const item = { ...this.state.item }
        let hasEndTimestamp = this.state.hasEndTimestamp

        if (typeof m === 'string') {
            item[fieldName] = moment(m, 'YYYY-MM-DD HH:mm:ss').unix()
        } else {
            item[fieldName] = m.unix()
        }

        if (fieldName==='endTimestamp') {
            hasEndTimestamp = true
        }
        
        this.setState({ item, hasEndTimestamp })
    }

    async handleCreate(data, totalDiscountCode) {
        API.graphql(graphqlOperation(createDiscountCampaign, { input: data })).then(async (result) => {
            for(let i=0; i < totalDiscountCode; i++) {
                try {
                    const codes = {
                        id: guidGenerator(),
                        valid: "1",
                        status: "0",
                        campaignId: data.campaignId
                    }
                    await API.graphql(graphqlOperation(createDiscountMaster, { input: codes }))
                } catch(e) {
                    console.error('error on handleCreate ', e)
                }
            }
            this.setState({ showToast: true });
            if (this.props.onCreate) {
                this.props.onCreate(result.data);
            }
        }).catch((error) => {
            console.error('error on handleCreate ', error)
            this.alert(`Error creating record. ${error.errors[0].message}`)
        })
    }

    handleUpdate(data) {
        API.graphql(graphqlOperation(updateDiscountCampaign, { input: data })).then(async (result) => {
            this.setState({ showToast: true });
            if (this.props.onUpdate) {
                this.props.onUpdate(result.data);
            }
        }).catch((error) => {
            console.error('error on handleUpdate ', error)
            this.alert(`Error updating record. ${error.errors[0].message}`)
        })
    }

    alert(msg, callback) {
        publish({
            mode: 'alert',
            msg: msg,
            onClose: callback
        })
    }

    render() {
        const { valid, terms, code, startTimestamp, endTimestamp, description, discountType, amount, usageLimitPerUser, usageCapacity, minimumSpend, multiplier, multiplierLifetimeQty, dealSpecific, dealExclusion, merchantExclusion, merchantSpecific, appliesTo, applicableOn, capped } = this.state.item
        const { errors, warnings, hasEndTimestamp, isSingleUse } = this.state
        const { mode } = this.props
        
        // we filter which one to show in the select option
        const selectedDiscountType = DISCOUNT_TYPE.filter(item => item.value === discountType)
        const selectedAppliesTo = APPLIES_TO.filter(item => item.value === appliesTo)
        const selectedApplicableOn = APPLICABLE_ON.filter(item => item.value === applicableOn)
        const boxStyling = {background:"#FFFFFF", boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.15)", borderRadius: "14px", padding:"10px", marginBottom:"10px"}

        return (
            <div>
               {this.props.show &&
            
                <Form onSubmit={(e) => this.handleSubmit(e)}>  
                    <h3>{this.props.mode==='create'?'Create':'Update'} Discount Code</h3>
                    <div style={boxStyling ?? {}}>
                        <Form.Group>
                            <Form.Check label="Validate" checked={valid==="1"} onChange={(e) => this.handleChange(e, 'valid')} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Discount code*</Form.Label>
                            <Form.Control type="text" disabled={mode==='update'} required value={code} onChange={(e) => this.handleChange(e, 'code')} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Discount code description*</Form.Label>
                            <Form.Control type="text" required value={description} onChange={(e) => this.handleChange(e, 'description')} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Discount code capacity*</Form.Label>
                                <Form.Control disabled={mode === 'update'} value={usageCapacity} type="number" required onBlur={(e) => this.validate(e, 'usageCapacity')} onChange={(e) => this.handleChange(e, 'usageCapacity')} />
                            <Form.Text style={{color:"red", marginTop: "-10px !important"}}>{errors.usageCapacity}</Form.Text>
                        </Form.Group>
                    </div>

                    <div style={boxStyling ?? {}}>
                        <Form.Group>
                                <Form.Label>Discount types*</Form.Label>
                            <Select 
                                    isClearable
                                    closeMenuOnSelect={true}
                                    value={selectedDiscountType}
                                    onChange={(selected, action) => this.handleChangeDiscountType(selected, action)}
                                    options={DISCOUNT_TYPE}
                                    isDisabled={mode === 'update'}
                                    styles={{
                                    singleValue: base => ({ ...base })
                                }}
                            />
                        </Form.Group>
                    </div>
                        {/* <Form.Group>
                            <Form.Group as={Col}>
                                <Form.Check
                                    type='radio'
                                    name='codePerUser'
                                    disabled={mode==='update'}
                                    value='single'
                                    label='One-time use discount code per user'
                                    onChange={(e) => this.handleChange(e, 'single')}
                                    checked={isSingleUse}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Check
                                    type='radio'
                                    name='codePerUser'
                                    disabled={mode==='update'}
                                    value='multiple'
                                    label='Multiple use discount code per user'
                                    onChange={(e) => this.handleChange(e, 'multiple')}
                                    checked={!isSingleUse}
                                />
                                {!isSingleUse?
                                <div style={{marginTop:"10px"}}>
                                    <Form.Label>Number of uses allowed</Form.Label>
                                    <Form.Control disabled={mode==='update'} type="text" required={!isSingleUse} value={usageLimitPerUser} onBlur={(e) => this.validate(e, 'usageLimitPerUser')} onChange={(e) => this.handleChange(e, 'usageLimitPerUser')} />
                                    <Form.Text style={{color:"red", marginTop: "-10px !important"}}>{errors.usageLimitPerUser}</Form.Text>
                                </div>
                                :null}
                            </Form.Group>
                        </Form.Group> */}
                        <div style={boxStyling ?? {}}>
                        <Form.Group>
                            <Form.Label>Discount value*</Form.Label>
                            <InputGroup className="mb-3">
                                {console.log(this.state.item.discountType )}
                                <InputGroup.Text>{this.state.item.discountType === 'percentage' ? '%' : '$'}</InputGroup.Text>
                                <Form.Control disabled={mode==='update'} type="number" required value={amount} onBlur={(e) => this.validate(e, 'amount')} onChange={(e) => this.handleChange(e, 'amount')} />
                            </InputGroup>
                            <Form.Text style={{color:"red" , marginTop: "-10px !important"}}>{errors.amount}</Form.Text>
                        </Form.Group>

                        <Form.Group>
                                <Form.Label>Applies to*</Form.Label>
                            <Select 
                                    isClearable
                                    closeMenuOnSelect={true}
                                    value={selectedAppliesTo}
                                    onChange={(selected, action) => this.handleChangeAppliesTo(selected, action)}
                                    options={APPLIES_TO}
                                    isDisabled={mode === 'update'}
                                    styles={{
                                    singleValue: base => ({ ...base })
                                }}
                            />
                        </Form.Group>
                        
                        {this.state.item.appliesTo === 'specific-deal' && 
                        <Form.Group>
                            <div style={{ marginTop: "10px" }}>
                                <Form.Label>Deal Specific - Input deals ID(s)</Form.Label>
                                <Form.Text style={{ color: "#A7A9BC", marginTop: -8, marginBottom: 8 }}>Input multiple deal IDs by separating with a comma in between</Form.Text>
                                <InputGroup className="mb-3">
                                    <Form.Control as="textarea" rows={2} value={dealSpecific?.join(',')} onBlur={(e) => this.validate(e, 'dealSpecific')} onChange={(e) => this.handleChange(e, 'dealSpecific')} />
                                </InputGroup>
                                <Form.Text style={{ color: "red", marginTop: "-10px !important" }}>{errors.dealSpecific}</Form.Text>
                                <Form.Text style={{ color: "#FF8F39", marginTop: "-10px !important" }}>{warnings.dealSpecific}</Form.Text>
                            </div>
                            {this.state.isDealSpecificLoading ?
                                <Spinner animation="border" />
                                : null}

                            <div style={{ marginTop: "10px" }}>
                                <Form.Label>Deal Exclusion - Input deals ID(s)</Form.Label>
                                <Form.Text style={{ color: "#A7A9BC", marginTop: -8, marginBottom: 8 }}>Input multiple deal IDs by separating with a comma in between</Form.Text>
                                <InputGroup className="mb-3">
                                    <Form.Control as="textarea" rows={2} value={dealExclusion?.join(',')} onBlur={(e) => this.validate(e, 'dealExclusion')} onChange={(e) => this.handleChange(e, 'dealExclusion')} />
                                </InputGroup>
                                <Form.Text style={{ color: "red", marginTop: "-10px !important" }}>{errors.dealExclusion}</Form.Text>
                                <Form.Text style={{ color: "#FF8F39", marginTop: "-10px !important" }}>{warnings.dealExclusion}</Form.Text>
                            </div>
                            {this.state.isDealExclusionLoading ?
                                <Spinner animation="border" />
                                : null}
                            
                            <Form.Group>
                                <Form.Label>Applicable on*</Form.Label>
                            <Select 
                                    isClearable
                                    closeMenuOnSelect={true}
                                    value={selectedApplicableOn}
                                    onChange={(selected, action) => this.handleChangeApplicableOn(selected, action)}
                                    options={APPLICABLE_ON}
                                    isDisabled={mode === 'update'}
                                    styles={{
                                    singleValue: base => ({ ...base })
                                }}
                            />
                            </Form.Group>
                            

                        </Form.Group>
                        }
                        {this.state.item.appliesTo === 'specific-merchant' && 
                        <>
                        <Form.Group>
                            <div style={{ marginTop: "10px" }}>
                                <Form.Label>Merchant Specific - Input Merchant ID(s)</Form.Label>
                                <Form.Text style={{ color: "#A7A9BC", marginTop: -8, marginBottom: 8 }}>Input multiple merchant IDs by separating with a comma in between</Form.Text>
                                <InputGroup className="mb-3">
                                    <Form.Control as="textarea" rows={2} value={merchantSpecific?.join(',')} onBlur={(e) => this.validate(e, 'merchantSpecific')} onChange={(e) => this.handleChange(e, 'merchantSpecific')} />
                                </InputGroup>
                                <Form.Text style={{ color: "red", marginTop: "-10px !important" }}>{errors.merchantSpecific}</Form.Text>
                                <Form.Text style={{ color: "#FF8F39", marginTop: "-10px !important" }}>{warnings.merchantSpecific}</Form.Text>
                            </div>
                            {this.state.isMerchantSpecificLoading ?
                                <Spinner animation="border" />
                                : null}
                        </Form.Group>

                        <Form.Group>
                            <div style={{ marginTop: "10px" }}>
                                <Form.Label>Merchant Exclusion - Input merchant ID(s)</Form.Label>
                                <Form.Text style={{ color: "#A7A9BC", marginTop: -8, marginBottom: 8 }}>Input multiple merchant IDs by separating with a comma in between</Form.Text>
                                <InputGroup className="mb-3">
                                    <Form.Control as="textarea" rows={2} value={merchantExclusion?.join(',')} onBlur={(e) => this.validate(e, 'merchantExclusion')} onChange={(e) => this.handleChange(e, 'merchantExclusion')} />
                                </InputGroup>
                                <Form.Text style={{ color: "red", marginTop: "-10px !important" }}>{errors.merchantExclusion}</Form.Text>
                                <Form.Text style={{ color: "#FF8F39", marginTop: "-10px !important" }}>{warnings.merchantExclusion}</Form.Text>
                            </div>
                            {this.state.isMerchantExclusionLoading ?
                                <Spinner animation="border" />
                                : null}
                        </Form.Group>
                        </>

                        }
                        
                        <Form.Row>
                        {this.state.item.applicableOn === 'transaction' ?
                            <>
                            <Form.Group as={Col}>
                                
                                
                                <div style={{marginTop:"10px"}}>
                                    <Form.Label>Min. spend ($)</Form.Label>
                                    <Form.Control disabled={mode==='update'}  type="number" value={minimumSpend} onBlur={(e) => this.validate(e, 'minimumSpend')} onChange={(e) => this.handleChange(e, 'minimumSpend')} />
                                    <Form.Text style={{color:"red", marginTop: "-10px !important"}}>{errors.minimumSpend}</Form.Text>
                                </div>
                            </Form.Group>
                            {this.state.item.discountType === 'percentage'  ?
                            <Form.Group as={Col}>

                                <div style={{marginTop:"10px"}}>
                                    <Form.Label>Capped at ($)</Form.Label>
                                        <Form.Control disabled={mode==='update'} type="number" value={capped} onBlur={(e) => this.validate(e, 'capped')} onChange={(e) => this.handleChange(e, 'capped')} />
                                    <Form.Text style={{color:"red", marginTop: "-10px !important"}}>{errors.minimumSpend}</Form.Text>
                                </div>
                   
                            </Form.Group>
                            :null}
                            </>
                        :
                            <Form.Group as={Col}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Multiplier up to</Form.Label>
                                        <Form.Text style={{ color: "#A7A9BC", marginTop: -8, marginBottom: 8 }}>Leave blank for unlimited quantity per transaction</Form.Text>
                                        <Form.Control disabled={mode === 'update'} type="number" value={multiplier} onBlur={(e) => this.validate(e, 'multiplier')} onChange={(e) => this.handleChange(e, 'multiplier')} />
                                        <Form.Text style={{ color: "red", marginTop: "-10px !important" }}>{errors.multiplier}</Form.Text>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>Multiplier lifetime quantity (Per user)</Form.Label>
                                        <Form.Text style={{ color: "#A7A9BC", marginTop: -8, marginBottom: 8 }}>Leave blank for unlimited multiplier lifetime quantity per user</Form.Text>
                                        <Form.Control disabled={mode === 'update'} type="number" value={multiplierLifetimeQty} onBlur={(e) => this.validate(e, 'multiplierLifetimeQty')} onChange={(e) => this.handleChange(e, 'multiplierLifetimeQty')} />
                                        <Form.Text style={{ color: "red", marginTop: "-10px !important" }}>{errors.multiplierLifetimeQty}</Form.Text>
                                    </Form.Group>
                                </Form.Row>

                            </Form.Group>
                        }

                        </Form.Row>

                        </div>

                        <div style={boxStyling ?? {}}>
                            <Form.Label>Usage limit (Per user)</Form.Label>
                            <Form.Text style={{ color: "#A7A9BC", marginTop: -8, marginBottom: 8 }}>Leave blank for unlimited usage per user</Form.Text>
                            <Form.Control disabled={mode === 'update'} type="number" value={usageLimitPerUser} onBlur={(e) => this.validate(e, 'usageLimitPerUser')} onChange={(e) => this.handleChange(e, 'usageLimitPerUser')} />
                            <Form.Text style={{ color: "red", marginTop: "-10px !important" }}>{errors.usageLimitPerUser}</Form.Text>
                        </div>
                    

                    <div style={boxStyling ?? {}}>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Start Date/Time*</Form.Label>
                            <Datetime className="startDate dtPosition" closeOnSelect dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" inputProps={{disabled: mode==='update'}} value={startTimestamp ? moment.unix(startTimestamp) : ''} onChange={(m) => this.handleDateTime(m, 'startTimestamp')} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>End Date/Time*</Form.Label>
                            <Datetime className="endDate dtPosition" closeOnSelect dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" inputProps={{disabled: mode==='update' || !hasEndTimestamp}} 
                                value={endTimestamp ? moment.unix(endTimestamp) : ''} onChange={(m) => this.handleDateTime(m, 'endTimestamp')} 
                                renderInput={(props) => {
                                    return <input {...props} value={(endTimestamp) ? props.value : ''} />
                                }}/>
                            <Form.Check disabled={mode==='update'} label="No end date/time" checked={!hasEndTimestamp} onChange={(e) => this.handleChange(e, 'hasEndTimestamp')} />
                        </Form.Group>
                    </Form.Row>
                    </div>

                    <div style={boxStyling ?? {}}>
                    <Form.Group>
                            <Form.Label>T&Cs</Form.Label>
                            <Form.Control as="textarea" value={terms} rows={5} onBlur={(e) => this.validate(e, 'terms')} onChange={(e) => this.handleChange(e, 'terms')} />
                        <Form.Text style={{color:"red", marginTop: "-10px !important"}}>{errors.terms}</Form.Text>
                    </Form.Group>
                    </div>

                    <Form.Group>
                        <Toast onClose={() => this.setState({ showToast: false })} show={this.state.showToast} delay={3000} autohide>
                            {this.props.mode==='create'?
                                <Toast.Body>Discount Code Created.</Toast.Body>
                            : null}
                            {this.props.mode==='update'?
                                <Toast.Body>Discount Code Updated.</Toast.Body>
                            : null}
                        </Toast>

                        {this.props.mode==='create'?
                            <Button type="submit">Create Discount Code</Button>
                        : null}
                        {this.props.mode==='update'?
                            <Button type="submit">Update Discount Code</Button>
                        : null}
                    </Form.Group>

                </Form>  
                }  
           
           </div>
        ) 
    }
}

export default DiscountCode