import React, { Component } from 'react';
import { API } from 'aws-amplify'
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import { backOff } from 'exponential-backoff'

class CacheView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            paths: [],
            results: {}
        }
    }

    async componentDidMount() {
    }

    handlePathsChange(e) {
        const paths = e.target.value.split('\n')

        this.setState({ paths: paths })
    }

    invalidateCache() {
        this.setState({
            results: {}
        })

        const paths = this.state.paths.filter(s => s.trim() !== '')
        console.log('paths', paths)

        paths.forEach(async (path) => {
            this.state.results[path] = 'pending...'
            this.setState({
                results: this.state.results
            })
            const result = await backOff(() => API.post('CloudFront', '/invalidate', {
                body: {
                    path: path
                }
            }))
            console.log(result)
            this.state.results[path] = result
            this.setState({
                results: this.state.results
            })
        })
    }

    render() {
        return <Container fluid>
            <Row>
                <Col>
                    <h3>Cache</h3>
                    <Form.Row>
                        <Form.Group>
                            <Form.Control as="textarea" value={this.state.paths.join('\n')} onChange={(e) => this.handlePathsChange(e)} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group>
                            <Button disabled={this.state.paths.filter(s => s.trim() !== '').length === 0} className="float-right" size="sm" onClick={(e) => this.invalidateCache(e)}>
                                Invalidate
                            </Button>
                        </Form.Group>
                    </Form.Row>

                    {Object.keys(this.state.results).map(key => <div>{key} : {this.state.results[key].statusCode} : {JSON.stringify(this.state.results[key])}</div>)}
                </Col>
            </Row>
        </Container>
    }
}

export default CacheView