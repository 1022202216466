import React, { Component } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { FaEdit, FaCopy, FaEye, FaEyeSlash, FaHourglassHalf, FaUserLock, FaTrashAlt } from "react-icons/fa"
import { Button, Modal, ButtonGroup, ToggleButton, Spinner, ListGroup, Image, Row, Col } from 'react-bootstrap'
import moment from 'moment'

import ACL from '../../utils/ACL'
import { dealByMerchantUniqueIdUpdatedAt } from '../../graphql/queries'

import DealModel from '../../models/DealModel'

import Deal from './Deal'

const DATETIME_FORMAT = 'Do MMM YYYY, h:mm:ss a'
class ListDeals extends Component {

    constructor(props) {
        super(props)

        this.state = {
            deals: [],
            deal: null,
            nextToken: null
        }
    }

    async componentDidMount() {     
        this.setState({
            isAdmin: await ACL.isAdmin()
        })

        this.listDeals()
    }
    
    async listDeals() {
        let result = this.state.deals
        let params = {
            sortDirection: 'DESC',
            limit: 50,
            nextToken: this.state.nextToken
        }
        if (this.props.aggregator) {
            const param = {
                aggregator: { eq: this.props.aggregator }
            }

            params.filter = {...param}
        }
        if (this.props.merchantUniqueId) {
            params.merchant_unique_id = this.props.merchantUniqueId
        }
        if (await ACL.isBank()) {
            const bank = await ACL.getBanksFromGroups()
            const param = {
                bank: { eq: bank[0] }
            }

            params.filter = {...param}
        }

        console.log('props', this.props)
        console.log('params', params)
        
        this.setState({
            isLoading: true
        })

        const deals = await API.graphql(graphqlOperation(dealByMerchantUniqueIdUpdatedAt, params))
        const items = deals.data.dealByMerchantUniqueIdUpdatedAt.items

        const groups = await ACL.getGroups()
        result = result.concat(await Promise.all(items.map(async (item) => {
            item.tags = await new DealModel(item).getTags()
            item.readOnly = ! (await ACL.canWriteDeal(item, groups))
            return item
        })))

        this.setState({
            deals: result,
            isLoading: false,
            nextToken: deals.data.dealByMerchantUniqueIdUpdatedAt.nextToken
        })
    }

    isApproved(deal) {
        return (deal.approval?deal.approval.isApproved:true)
    }

    filterDeals(deals) {
        return deals.filter(o => this.state.isAdmin || o.valid === 'Y' || !this.isApproved(o))
    }

    onSelect(e, item) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            deal: item
        })

        if (this.props.onSelect) {
            this.props.onSelect(e, item)
        }
    }

    onClone(e, item) {
        e.preventDefault()
        e.stopPropagation()

        if (this.props.onClone) {
            this.props.onClone(e, item)
        }
    }

    handleClose() {
        this.setState({
            show: false
        })
    }

    async onToggleValid(e, item, index) {
        e.preventDefault()
        e.stopPropagation()

        const dealModel = new DealModel(item)
        if (item.valid === 'Y') {
            const deal = await dealModel.invalidate()
            // merge to retain non data fields introduced in cms
            this.state.deals[index] = {...item, ...deal}
        } else {
            const deal = await dealModel.validate()
            // merge to retain non data fields introduced in cms
            this.state.deals[index] = {...item, ...deal}
        }
        // trigger a select to force refresh
        this.onSelect(e, this.state.deals[index])

        this.setState({
            deals: this.filterDeals(this.state.deals)
        })
    }

    updateDeal(e, deal) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            show: true,
            mode: 'update',
            deal: deal
        })
    }

    addDeal(d) {
        const deals = this.state.deals
        deals.unshift(d)
        this.setState({
            deals: deals
        })
    }

    refreshDeal(d) {
        const deals = this.state.deals
        for (const [index, deal] of deals.entries()) {
            if (d.pk === deal.pk) {
                deals[index] = d
            }
        }
        this.setState({
            deals: deals
        })
    }

    onUpdateDeal(deal) {
        this.refreshDeal(deal)
        this.setState({
            show: false
        })
    }
    
    render() {
        return <div>
            { this.state.isLoading ? 
            <Spinner animation="border" size="sm" />
            : 
                <ListGroup className="scrollview" variant="flush">
                {
                    this.state.deals.length > 0 ?
                        this.state.deals.map((item, index) =>
                            <ListGroup.Item key={`list-deals-${item.pk}`} action active={this.state.deal?(item.pk==this.state.deal.pk):false} onClick={(e) => this.onSelect(e, item)}>
                                <div>
                                    { this.props.aggregator ? item.merchant_name:'' }
                                </div>
                                <Row>
                                    <Col md={3}>
                                        <Image src={`${item.image_url}?timestamp=${Date.now()}`} fluid rectangle="true" />
                                    </Col>
                                    <Col>
                                        {item.promotion_caption} &nbsp;
                                        <br />
                                        <small>{item.start_timestamp?moment.unix(item.start_timestamp).format(DATETIME_FORMAT):''} - {item.end_timestamp?moment.unix(item.end_timestamp).format(DATETIME_FORMAT):''}</small>
                                        {this.props.onClone ?
                                            <FaCopy title="duplicate" color="#440099" onClick={(e) => this.onClone(e, item)} />
                                        : null}
                                        {item.readOnly?
                                            <FaUserLock title="view only" color="red" />
                                        : null}
                                        {item.valid && (item.valid.toUpperCase() === 'Y') ?
                                            <FaEye title="visible" color="green" />
                                        : <FaEyeSlash title="not visible" color="red" />}
                                        {!this.isApproved(item)?
                                            <FaHourglassHalf title="pending" color="red" />
                                        : null}
                                        
                                        <Button className="float-right" size="sm" onClick={(e) => this.updateDeal(e, item)}>
                                            <FaEdit/> Edit
                                        </Button>

                                        <ButtonGroup toggle size="sm" className="float-right mr-1">
                                            <ToggleButton type="checkbox" checked={item.valid==='Y'} onClick={(e) => this.onToggleValid(e, item, index)}>
                                                <FaTrashAlt title="delete" />
                                            </ToggleButton>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        )
                    : <ListGroup.Item variant="danger">
                        <p>No Deals.</p>
                        <p>Add Deals so that your listings will appear in our app.</p>
                    </ListGroup.Item>
                }
                </ListGroup>
            }

            { this.state.nextToken && <Button onClick={() => this.listDeals() }>
                    Load more
                </Button>
            }

            <Modal show={this.state.show} onHide={() => this.handleClose()} size="xl" backdrop='static'>
                <Modal.Header closeButton>
                    <Modal.Title>{(this.state.mode==='create')?'Create':(this.state.mode==='update'?'Update':'')} Deal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Deal
                        show={this.state.show}
                        mode='update'
                        deal={this.state.deal}
                        key={`update-deal-${this.state.deal ? this.state.deal.pk : ''}-${this.state.timestamp}`}
                        onUpdate={(deal) => this.onUpdateDeal(deal)}
                    />
                </Modal.Body>
            </Modal>
        </div>
    }
}

export default ListDeals