import React, { Component } from 'react';
import { Container, Row, Col, Spinner, ListGroup } from 'react-bootstrap';
import { FaPlus } from "react-icons/fa";
import { API, graphqlOperation } from 'aws-amplify'
import { getPinnedDeals } from '../../graphql/queries';
import PinnedDeals from '../widgets/PinnedDeals'
import DealModel from '../../models/DealModel'

class PinnedDealsView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            isLoading: false
        }
    }

    async componentDidMount() {
        this.setState({
            isLoading: true
        });

        API.graphql(graphqlOperation(getPinnedDeals, {isPinned: "1"})).then((result) => {
            this.setState({ list: result.data.getPinnedDeals.items, isLoading: false });
        })        
    }

    selectPinnedDeals(e, data) {
        e.preventDefault();
        e.stopPropagation();
        
        this.setState({
            pinned: data,
            mode: 'updatePinnedDeals',
        });
    }

    createPinnedDeals(e) {
        e.preventDefault()
        e.stopPropagation()

        this.setState({
            mode: 'createPinnedDeals'
        })
    }

    refreshPinnedDeals(data) {
        this.setState({isLoading: true})
        API.graphql(graphqlOperation(getPinnedDeals, {isPinned: "1"})).then((result) => {
            this.setState({ list: result.data.getPinnedDeals.items, isLoading: false });
        }).catch(e => {
            console.debug('Error on refreshPinnedDeals ',e)
            this.setState({isLoading: false})
        })
    }

    render() {
        const { list, pinned, mode } = this.state;
        
        return <Container fluid>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h3>Pinned Deals</h3>
                        </Col>
                        <Col>
                            <FaPlus className="float-right" onClick={(e) => this.createPinnedDeals(e)} />
                        </Col>
                    </Row>
                    <Row>
                    { this.state.isLoading ? 
                        <Spinner animation="border" />
                    : 
                        <Col className="scrollview">
                            <ListGroup variant="flush">
                            {
                                list.length > 0 ?
                                    list.map((item) =>
                                        <ListGroup.Item key={`list-pinnedDeals-${item.pk}`} action onClick={(e) => this.selectPinnedDeals(e, item)}>
                                            <div>
                                                { item.promotion_caption }
                                            </div>
                                            <small>{item.merchant_name}</small>
                                        </ListGroup.Item>
                                    )
                                : 'No Pinned Deals'
                            }
                            </ListGroup>
                        </Col>
                    }
                    </Row>
                </Col>

                <Col className="scrollview">
                    <PinnedDeals
                        mode='create'
                        key={`create-pinnedDeals`}
                        refresh={(data) => this.refreshPinnedDeals(data)}
                        show={mode === 'createPinnedDeals'}
                    />

                    <PinnedDeals
                        mode='update'
                        key={`update-pinnedDeals-${pinned ? pinned.pk : ''}`}
                        data={pinned}
                        refresh={(data) => this.refreshPinnedDeals(data)}
                        show={mode === 'updatePinnedDeals'}
                    />
                </Col>
            </Row>            
        </Container>
    }
}

export default PinnedDealsView