import React, { Component } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import { Navbar, OverlayTrigger, Tooltip, Button } from "react-bootstrap"
import { FaUser, FaRegQuestionCircle } from "react-icons/fa"

import { updateDealMaster } from '../../graphql/mutations'
import { listDealMasters } from '../../graphql/queries'
import { listMerchants } from '../../graphql/custom'
import { Auth } from '@aws-amplify/auth'
import { forEach } from 'lodash'

const OLD_IMAGE_BASE=(process.env.NODE_ENV==='development')?'https://cardspalcms924f8353bab14967adc677b1502c2131174412-dev.s3-ap-southeast-1.amazonaws.com':'https://cardspalcms924f8353bab14967adc677b1502c2131104122-prod.s3-ap-southeast-1.amazonaws.com'
const NEW_IMAGE_BASE=(process.env.NODE_ENV==='development')?'https://assets.dev.cardspal.com':'https://assets.prod.cardspal.com'
class Extract extends Component {
  constructor() {

    super()
    this.state = {
        merchants: [],
        deals: []
    }
  }

  async componentDidMount() {
    this.extractDeals()
    this.migrateDeals()
  }

  async extractMerchants() {
    let token = null
    let merchants = []
    do {
        let options = {
            filter: {
            }
        }
        if (token) {
            options.nextToken = token
        }

        const result = await API.graphql(graphqlOperation(listMerchants, options))
        console.log('result', result)
        merchants = merchants.concat(result.data.listMerchants.items)
        token = result.data.listMerchants.nextToken
    } while (token)

    this.setState({
        merchants: merchants
    })
  }

  async extractDeals() {
    let token = null
    let deals = []
    do {
        let options = {
            filter: {
                image_url: {
                    beginsWith: OLD_IMAGE_BASE
                }
            }
        }
        if (token) {
            options.nextToken = token
        }

        const result = await API.graphql(graphqlOperation(listDealMasters, options))
        console.log('result', result)
        deals = deals.concat(result.data.listDealMasters.items)
        token = result.data.listDealMasters.nextToken
    } while (token)

    console.log('deals', deals)
    this.setState({
        deals: deals
    })
  }

  async migrateDeals() {
      this.state.deals.forEach(async (deal) => {
        if (deal.image_url.startsWith(OLD_IMAGE_BASE)) {

            const newUrl = deal.image_url.replace(OLD_IMAGE_BASE, NEW_IMAGE_BASE)
            console.log('deal image', deal.image_url, newUrl)

            deal.image_url = newUrl
            const result = await API.graphql(graphqlOperation(updateDealMaster, {input: deal}))
            console.log('update result', result)
        }
      })
  }

  render() {

    const deals = this.state.deals.map(deal =>
        <table>
            <td>{deal.merchant_name}</td>,
            <td>{deal.image_url}</td>,
        </table>
    )

    const merchants = this.state.merchants.map(merchant =>
        <table>
            <td>{merchant.merchant_name}</td>
        </table>
    )

    return (
        <div>
            {deals}
            {merchants}

            <button onClick={()=>this.migrateDeals()}>migrate</button>
        </div>

    )
  }
}

export default Extract
