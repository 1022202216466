/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSubPlan = /* GraphQL */ `
  mutation CreateSubPlan(
    $input: CreateSubPlanInput!
    $condition: ModelSubPlanConditionInput
  ) {
    createSubPlan(input: $input, condition: $condition) {
      email
      plan
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const updateSubPlan = /* GraphQL */ `
  mutation UpdateSubPlan(
    $input: UpdateSubPlanInput!
    $condition: ModelSubPlanConditionInput
  ) {
    updateSubPlan(input: $input, condition: $condition) {
      email
      plan
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubPlan = /* GraphQL */ `
  mutation DeleteSubPlan(
    $input: DeleteSubPlanInput!
    $condition: ModelSubPlanConditionInput
  ) {
    deleteSubPlan(input: $input, condition: $condition) {
      email
      plan
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const createMerchantMccMapping = /* GraphQL */ `
  mutation CreateMerchantMccMapping(
    $input: CreateMerchantMccMappingInput!
    $condition: ModelMerchantMccMappingConditionInput
  ) {
    createMerchantMccMapping(input: $input, condition: $condition) {
      mcc
      merchantId
      createdAt
      updatedAt
    }
  }
`;
export const updateMerchantMccMapping = /* GraphQL */ `
  mutation UpdateMerchantMccMapping(
    $input: UpdateMerchantMccMappingInput!
    $condition: ModelMerchantMccMappingConditionInput
  ) {
    updateMerchantMccMapping(input: $input, condition: $condition) {
      mcc
      merchantId
      createdAt
      updatedAt
    }
  }
`;
export const deleteMerchantMccMapping = /* GraphQL */ `
  mutation DeleteMerchantMccMapping(
    $input: DeleteMerchantMccMappingInput!
    $condition: ModelMerchantMccMappingConditionInput
  ) {
    deleteMerchantMccMapping(input: $input, condition: $condition) {
      mcc
      merchantId
      createdAt
      updatedAt
    }
  }
`;
export const createCardParam = /* GraphQL */ `
  mutation CreateCardParam(
    $input: CreateCardParamInput!
    $condition: ModelCardParamConditionInput
  ) {
    createCardParam(input: $input, condition: $condition) {
      id
      cardId
      discount
      bonus
      minSpend
      maxSpend
      cap
      capFrequency
      mcc
      mccMainCat
      mccSubcat
      merchant
      merchantId
      merchantMainCat
      merchantSubcat
      valid
      createdAt
      updatedAt
      createdByUser
      updatedByUser
      createdByGroups
    }
  }
`;
export const updateCardParam = /* GraphQL */ `
  mutation UpdateCardParam(
    $input: UpdateCardParamInput!
    $condition: ModelCardParamConditionInput
  ) {
    updateCardParam(input: $input, condition: $condition) {
      id
      cardId
      discount
      bonus
      minSpend
      maxSpend
      cap
      capFrequency
      mcc
      mccMainCat
      mccSubcat
      merchant
      merchantId
      merchantMainCat
      merchantSubcat
      valid
      createdAt
      updatedAt
      createdByUser
      updatedByUser
      createdByGroups
    }
  }
`;
export const deleteCardParam = /* GraphQL */ `
  mutation DeleteCardParam(
    $input: DeleteCardParamInput!
    $condition: ModelCardParamConditionInput
  ) {
    deleteCardParam(input: $input, condition: $condition) {
      id
      cardId
      discount
      bonus
      minSpend
      maxSpend
      cap
      capFrequency
      mcc
      mccMainCat
      mccSubcat
      merchant
      merchantId
      merchantMainCat
      merchantSubcat
      valid
      createdAt
      updatedAt
      createdByUser
      updatedByUser
      createdByGroups
    }
  }
`;
export const createCardMaster = /* GraphQL */ `
  mutation CreateCardMaster(
    $input: CreateCardMasterInput!
    $condition: ModelCardMasterConditionInput
  ) {
    createCardMaster(input: $input, condition: $condition) {
      annual_fee
      annual_fee_waiver
      bank
      card_detail_id
      cardParams {
        items {
          id
          cardId
          discount
          bonus
          minSpend
          maxSpend
          cap
          capFrequency
          mcc
          mccMainCat
          mccSubcat
          merchant
          merchantId
          merchantMainCat
          merchantSubcat
          valid
          createdAt
          updatedAt
          createdByUser
          updatedByUser
          createdByGroups
        }
        nextToken
      }
      card_rep_fee
      card_type
      cash_adv_fee
      cash_adv_int
      ccbuddy_card_name
      cont_payment
      country
      cred_limit
      deal_handler
      exceed_fee
      extra_doc
      feature
      feature1
      feature1_type
      feature2
      feature2_type
      feature3
      feature3_type
      feature_cnt
      for_trans_fee
      interest
      interest_free
      image_url
      late_fee
      max_income_sp_num
      min_age
      min_income_for
      min_income_sp
      min_income_sp_num
      min_pay
      min_spend
      mob_payment
      payment_network
      proof_identity
      proof_sal_emp
      proof_self_emp
      rtob_links
      singsaver_name
      six_interest
      six_interest_online
      six_process
      six_process_online
      supp_card_annual_fee
      supp_card_annual_fee_waiver
      three_interest
      three_interest_online
      three_process
      three_process_online
      twelve_interest
      twelve_interest_online
      twelve_process
      twelve_process_online
      valid
      welcome_offers
      addCardsVisible
      applyNowVisible
      cardspal_rating
      compareNowVisible
      ctaButtonText
      htmlContent
      promo_ranking
      createdAt
      updatedAt
    }
  }
`;
export const updateCardMaster = /* GraphQL */ `
  mutation UpdateCardMaster(
    $input: UpdateCardMasterInput!
    $condition: ModelCardMasterConditionInput
  ) {
    updateCardMaster(input: $input, condition: $condition) {
      annual_fee
      annual_fee_waiver
      bank
      card_detail_id
      cardParams {
        items {
          id
          cardId
          discount
          bonus
          minSpend
          maxSpend
          cap
          capFrequency
          mcc
          mccMainCat
          mccSubcat
          merchant
          merchantId
          merchantMainCat
          merchantSubcat
          valid
          createdAt
          updatedAt
          createdByUser
          updatedByUser
          createdByGroups
        }
        nextToken
      }
      card_rep_fee
      card_type
      cash_adv_fee
      cash_adv_int
      ccbuddy_card_name
      cont_payment
      country
      cred_limit
      deal_handler
      exceed_fee
      extra_doc
      feature
      feature1
      feature1_type
      feature2
      feature2_type
      feature3
      feature3_type
      feature_cnt
      for_trans_fee
      interest
      interest_free
      image_url
      late_fee
      max_income_sp_num
      min_age
      min_income_for
      min_income_sp
      min_income_sp_num
      min_pay
      min_spend
      mob_payment
      payment_network
      proof_identity
      proof_sal_emp
      proof_self_emp
      rtob_links
      singsaver_name
      six_interest
      six_interest_online
      six_process
      six_process_online
      supp_card_annual_fee
      supp_card_annual_fee_waiver
      three_interest
      three_interest_online
      three_process
      three_process_online
      twelve_interest
      twelve_interest_online
      twelve_process
      twelve_process_online
      valid
      welcome_offers
      addCardsVisible
      applyNowVisible
      cardspal_rating
      compareNowVisible
      ctaButtonText
      htmlContent
      promo_ranking
      createdAt
      updatedAt
    }
  }
`;
export const deleteCardMaster = /* GraphQL */ `
  mutation DeleteCardMaster(
    $input: DeleteCardMasterInput!
    $condition: ModelCardMasterConditionInput
  ) {
    deleteCardMaster(input: $input, condition: $condition) {
      annual_fee
      annual_fee_waiver
      bank
      card_detail_id
      cardParams {
        items {
          id
          cardId
          discount
          bonus
          minSpend
          maxSpend
          cap
          capFrequency
          mcc
          mccMainCat
          mccSubcat
          merchant
          merchantId
          merchantMainCat
          merchantSubcat
          valid
          createdAt
          updatedAt
          createdByUser
          updatedByUser
          createdByGroups
        }
        nextToken
      }
      card_rep_fee
      card_type
      cash_adv_fee
      cash_adv_int
      ccbuddy_card_name
      cont_payment
      country
      cred_limit
      deal_handler
      exceed_fee
      extra_doc
      feature
      feature1
      feature1_type
      feature2
      feature2_type
      feature3
      feature3_type
      feature_cnt
      for_trans_fee
      interest
      interest_free
      image_url
      late_fee
      max_income_sp_num
      min_age
      min_income_for
      min_income_sp
      min_income_sp_num
      min_pay
      min_spend
      mob_payment
      payment_network
      proof_identity
      proof_sal_emp
      proof_self_emp
      rtob_links
      singsaver_name
      six_interest
      six_interest_online
      six_process
      six_process_online
      supp_card_annual_fee
      supp_card_annual_fee_waiver
      three_interest
      three_interest_online
      three_process
      three_process_online
      twelve_interest
      twelve_interest_online
      twelve_process
      twelve_process_online
      valid
      welcome_offers
      addCardsVisible
      applyNowVisible
      cardspal_rating
      compareNowVisible
      ctaButtonText
      htmlContent
      promo_ranking
      createdAt
      updatedAt
    }
  }
`;
export const createMall = /* GraphQL */ `
  mutation CreateMall(
    $input: CreateMallInput!
    $condition: ModelMallConditionInput
  ) {
    createMall(input: $input, condition: $condition) {
      mallId
      address
      imageUrl
      name
      order
      searchField
      valid
      createdAt
      updatedAt
    }
  }
`;
export const updateMall = /* GraphQL */ `
  mutation UpdateMall(
    $input: UpdateMallInput!
    $condition: ModelMallConditionInput
  ) {
    updateMall(input: $input, condition: $condition) {
      mallId
      address
      imageUrl
      name
      order
      searchField
      valid
      createdAt
      updatedAt
    }
  }
`;
export const deleteMall = /* GraphQL */ `
  mutation DeleteMall(
    $input: DeleteMallInput!
    $condition: ModelMallConditionInput
  ) {
    deleteMall(input: $input, condition: $condition) {
      mallId
      address
      imageUrl
      name
      order
      searchField
      valid
      createdAt
      updatedAt
    }
  }
`;
export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      campaignId
      campaignName
      dealId
      valid
      countAssigned
      title
      redeemButtonText
      eventTrackerName
      startTimestamp
      endTimestamp
      eligibility
      killSwitch
      cssCode
      helpCenterUrl
      howToRedeemUrl
      createdAt
      updatedAt
      popUpImageUrl
      enableNotification
      notificationSchedule
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign(
    $input: UpdateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    updateCampaign(input: $input, condition: $condition) {
      campaignId
      campaignName
      dealId
      valid
      countAssigned
      title
      redeemButtonText
      eventTrackerName
      startTimestamp
      endTimestamp
      eligibility
      killSwitch
      cssCode
      helpCenterUrl
      howToRedeemUrl
      createdAt
      updatedAt
      popUpImageUrl
      enableNotification
      notificationSchedule
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign(
    $input: DeleteCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    deleteCampaign(input: $input, condition: $condition) {
      campaignId
      campaignName
      dealId
      valid
      countAssigned
      title
      redeemButtonText
      eventTrackerName
      startTimestamp
      endTimestamp
      eligibility
      killSwitch
      cssCode
      helpCenterUrl
      howToRedeemUrl
      createdAt
      updatedAt
      popUpImageUrl
      enableNotification
      notificationSchedule
    }
  }
`;
export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement(
    $input: CreateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    createAnnouncement(input: $input, condition: $condition) {
      announcementId
      active
      message
      createdAt
      updatedAt
    }
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement(
    $input: UpdateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    updateAnnouncement(input: $input, condition: $condition) {
      announcementId
      active
      message
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement(
    $input: DeleteAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    deleteAnnouncement(input: $input, condition: $condition) {
      announcementId
      active
      message
      createdAt
      updatedAt
    }
  }
`;
export const createSearchKeyword = /* GraphQL */ `
  mutation CreateSearchKeyword(
    $input: CreateSearchKeywordInput!
    $condition: ModelSearchKeywordConditionInput
  ) {
    createSearchKeyword(input: $input, condition: $condition) {
      searchKeywordId
      keyword
      keywordLowerCase
      valid
      hot
      new
      createdAt
      updatedAt
    }
  }
`;
export const updateSearchKeyword = /* GraphQL */ `
  mutation UpdateSearchKeyword(
    $input: UpdateSearchKeywordInput!
    $condition: ModelSearchKeywordConditionInput
  ) {
    updateSearchKeyword(input: $input, condition: $condition) {
      searchKeywordId
      keyword
      keywordLowerCase
      valid
      hot
      new
      createdAt
      updatedAt
    }
  }
`;
export const deleteSearchKeyword = /* GraphQL */ `
  mutation DeleteSearchKeyword(
    $input: DeleteSearchKeywordInput!
    $condition: ModelSearchKeywordConditionInput
  ) {
    deleteSearchKeyword(input: $input, condition: $condition) {
      searchKeywordId
      keyword
      keywordLowerCase
      valid
      hot
      new
      createdAt
      updatedAt
    }
  }
`;
export const createBanner = /* GraphQL */ `
  mutation CreateBanner(
    $input: CreateBannerInput!
    $condition: ModelBannerConditionInput
  ) {
    createBanner(input: $input, condition: $condition) {
      bannerId
      buttonText
      valid
      homeBannerImageUrl
      bannerPageImageUrl
      type
      order
      title
      content
      externalLinkActive
      externalLink
      campaignId
      createdAt
      updatedAt
    }
  }
`;
export const updateBanner = /* GraphQL */ `
  mutation UpdateBanner(
    $input: UpdateBannerInput!
    $condition: ModelBannerConditionInput
  ) {
    updateBanner(input: $input, condition: $condition) {
      bannerId
      buttonText
      valid
      homeBannerImageUrl
      bannerPageImageUrl
      type
      order
      title
      content
      externalLinkActive
      externalLink
      campaignId
      createdAt
      updatedAt
    }
  }
`;
export const deleteBanner = /* GraphQL */ `
  mutation DeleteBanner(
    $input: DeleteBannerInput!
    $condition: ModelBannerConditionInput
  ) {
    deleteBanner(input: $input, condition: $condition) {
      bannerId
      buttonText
      valid
      homeBannerImageUrl
      bannerPageImageUrl
      type
      order
      title
      content
      externalLinkActive
      externalLink
      campaignId
      createdAt
      updatedAt
    }
  }
`;
export const createTags = /* GraphQL */ `
  mutation CreateTags(
    $input: CreateTagsInput!
    $condition: ModelTagsConditionInput
  ) {
    createTags(input: $input, condition: $condition) {
      id
      merchantId
      tagName
      tagType
      highlights
      createdAt
      updatedAt
    }
  }
`;
export const updateTags = /* GraphQL */ `
  mutation UpdateTags(
    $input: UpdateTagsInput!
    $condition: ModelTagsConditionInput
  ) {
    updateTags(input: $input, condition: $condition) {
      id
      merchantId
      tagName
      tagType
      highlights
      createdAt
      updatedAt
    }
  }
`;
export const deleteTags = /* GraphQL */ `
  mutation DeleteTags(
    $input: DeleteTagsInput!
    $condition: ModelTagsConditionInput
  ) {
    deleteTags(input: $input, condition: $condition) {
      id
      merchantId
      tagName
      tagType
      highlights
      createdAt
      updatedAt
    }
  }
`;
export const createDealApproval = /* GraphQL */ `
  mutation CreateDealApproval(
    $input: CreateDealApprovalInput!
    $condition: ModelDealApprovalConditionInput
  ) {
    createDealApproval(input: $input, condition: $condition) {
      pk
      isApproved
      deal {
        address
        aggregator
        approval {
          pk
          isApproved
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          createdYearMonth
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        purchasableTexts
        showInOutletIds
        additionalPromotionInfo
        ticketType
        minQtyTx
      }
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      createdYearMonth
    }
  }
`;
export const updateDealApproval = /* GraphQL */ `
  mutation UpdateDealApproval(
    $input: UpdateDealApprovalInput!
    $condition: ModelDealApprovalConditionInput
  ) {
    updateDealApproval(input: $input, condition: $condition) {
      pk
      isApproved
      deal {
        address
        aggregator
        approval {
          pk
          isApproved
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          createdYearMonth
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        purchasableTexts
        showInOutletIds
        additionalPromotionInfo
        ticketType
        minQtyTx
      }
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      createdYearMonth
    }
  }
`;
export const deleteDealApproval = /* GraphQL */ `
  mutation DeleteDealApproval(
    $input: DeleteDealApprovalInput!
    $condition: ModelDealApprovalConditionInput
  ) {
    deleteDealApproval(input: $input, condition: $condition) {
      pk
      isApproved
      deal {
        address
        aggregator
        approval {
          pk
          isApproved
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          createdYearMonth
        }
        area
        bank
        brand
        mainCat
        cc_buddy_category
        ccbuddy_card_name
        country
        deal_handler
        display_merchant_name
        district
        district_name
        end
        end_timestamp
        festive
        general_location
        geohashlong
        geohashshort
        google_id
        google_placeid
        google_type
        image_path
        image_url
        is_online
        isSponsored
        sponsoredRank
        latitude
        longitude
        merchant_id
        merchant_name
        merchant_name_lowercase
        merchant_unique_id
        online_company
        phone
        pk
        postal_code
        promo_code
        promotion
        promotion_analytic
        promotion_caption
        rank
        rank_updated
        score
        sector
        start
        start_timestamp
        store_id
        subcategory
        TR_tags
        tags {
          nextToken
        }
        terms
        usable_promocode
        valid
        website
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        isPinned
        pinPageLocation
        pinOrder
        purchasable
        actualPrice
        discount
        discountPrice
        purchasePrice
        purchaseLimitPerCustomer
        purchasableTexts
        showInOutletIds
        additionalPromotionInfo
        ticketType
        minQtyTx
      }
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      createdYearMonth
    }
  }
`;
export const createDealMaster = /* GraphQL */ `
  mutation CreateDealMaster(
    $input: CreateDealMasterInput!
    $condition: ModelDealMasterConditionInput
  ) {
    createDealMaster(input: $input, condition: $condition) {
      address
      aggregator
      approval {
        pk
        isApproved
        deal {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          purchasableTexts
          showInOutletIds
          additionalPromotionInfo
          ticketType
          minQtyTx
        }
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        createdYearMonth
      }
      area
      bank
      brand
      mainCat
      cc_buddy_category
      ccbuddy_card_name
      country
      deal_handler
      display_merchant_name
      district
      district_name
      end
      end_timestamp
      festive
      general_location
      geohashlong
      geohashshort
      google_id
      google_placeid
      google_type
      image_path
      image_url
      is_online
      isSponsored
      sponsoredRank
      latitude
      longitude
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_unique_id
      online_company
      phone
      pk
      postal_code
      promo_code
      promotion
      promotion_analytic
      promotion_caption
      rank
      rank_updated
      score
      sector
      start
      start_timestamp
      store_id
      subcategory
      TR_tags
      tags {
        items {
          id
          merchantId
          tagName
          tagType
          highlights
          createdAt
          updatedAt
        }
        nextToken
      }
      terms
      usable_promocode
      valid
      website
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      isPinned
      pinPageLocation
      pinOrder
      purchasable
      actualPrice
      discount
      discountPrice
      purchasePrice
      purchaseLimitPerCustomer
      purchasableTexts
      showInOutletIds
      additionalPromotionInfo
      ticketType
      minQtyTx
    }
  }
`;
export const updateDealMaster = /* GraphQL */ `
  mutation UpdateDealMaster(
    $input: UpdateDealMasterInput!
    $condition: ModelDealMasterConditionInput
  ) {
    updateDealMaster(input: $input, condition: $condition) {
      address
      aggregator
      approval {
        pk
        isApproved
        deal {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          purchasableTexts
          showInOutletIds
          additionalPromotionInfo
          ticketType
          minQtyTx
        }
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        createdYearMonth
      }
      area
      bank
      brand
      mainCat
      cc_buddy_category
      ccbuddy_card_name
      country
      deal_handler
      display_merchant_name
      district
      district_name
      end
      end_timestamp
      festive
      general_location
      geohashlong
      geohashshort
      google_id
      google_placeid
      google_type
      image_path
      image_url
      is_online
      isSponsored
      sponsoredRank
      latitude
      longitude
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_unique_id
      online_company
      phone
      pk
      postal_code
      promo_code
      promotion
      promotion_analytic
      promotion_caption
      rank
      rank_updated
      score
      sector
      start
      start_timestamp
      store_id
      subcategory
      TR_tags
      tags {
        items {
          id
          merchantId
          tagName
          tagType
          highlights
          createdAt
          updatedAt
        }
        nextToken
      }
      terms
      usable_promocode
      valid
      website
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      isPinned
      pinPageLocation
      pinOrder
      purchasable
      actualPrice
      discount
      discountPrice
      purchasePrice
      purchaseLimitPerCustomer
      purchasableTexts
      showInOutletIds
      additionalPromotionInfo
      ticketType
      minQtyTx
    }
  }
`;
export const deleteDealMaster = /* GraphQL */ `
  mutation DeleteDealMaster(
    $input: DeleteDealMasterInput!
    $condition: ModelDealMasterConditionInput
  ) {
    deleteDealMaster(input: $input, condition: $condition) {
      address
      aggregator
      approval {
        pk
        isApproved
        deal {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          purchasableTexts
          showInOutletIds
          additionalPromotionInfo
          ticketType
          minQtyTx
        }
        createdAt
        updatedAt
        createdByUser
        createdByGroups
        createdYearMonth
      }
      area
      bank
      brand
      mainCat
      cc_buddy_category
      ccbuddy_card_name
      country
      deal_handler
      display_merchant_name
      district
      district_name
      end
      end_timestamp
      festive
      general_location
      geohashlong
      geohashshort
      google_id
      google_placeid
      google_type
      image_path
      image_url
      is_online
      isSponsored
      sponsoredRank
      latitude
      longitude
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_unique_id
      online_company
      phone
      pk
      postal_code
      promo_code
      promotion
      promotion_analytic
      promotion_caption
      rank
      rank_updated
      score
      sector
      start
      start_timestamp
      store_id
      subcategory
      TR_tags
      tags {
        items {
          id
          merchantId
          tagName
          tagType
          highlights
          createdAt
          updatedAt
        }
        nextToken
      }
      terms
      usable_promocode
      valid
      website
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      isPinned
      pinPageLocation
      pinOrder
      purchasable
      actualPrice
      discount
      discountPrice
      purchasePrice
      purchaseLimitPerCustomer
      purchasableTexts
      showInOutletIds
      additionalPromotionInfo
      ticketType
      minQtyTx
    }
  }
`;
export const createMerchantOutlet = /* GraphQL */ `
  mutation CreateMerchantOutlet(
    $input: CreateMerchantOutletInput!
    $condition: ModelMerchantOutletConditionInput
  ) {
    createMerchantOutlet(input: $input, condition: $condition) {
      address
      deals {
        items {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          purchasableTexts
          showInOutletIds
          additionalPromotionInfo
          ticketType
          minQtyTx
        }
        nextToken
      }
      mainCat
      display_merchant_name
      distance
      general_location
      geohashlong
      geohashshort
      geohashsix
      google_id
      google_placeid
      google_type
      image_path
      image_src
      latitude
      longitude
      mallId
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_namelist
      merchant_unique_id
      online_company
      outlet_name
      phone
      postal_code
      postal_district
      postal_district_name
      postal_sector
      protectedAttributes
      store_id
      subc_online
      subcategory
      valid
      merchantImageUrl
      merchantImageUrlV2
      defaultImageUrl
      businessSummary
      outletWebsite
      mcc
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      blkAptNum
      roadName
      buildingName
      unitNum
      country
      type
      hideOperatingHours
    }
  }
`;
export const updateMerchantOutlet = /* GraphQL */ `
  mutation UpdateMerchantOutlet(
    $input: UpdateMerchantOutletInput!
    $condition: ModelMerchantOutletConditionInput
  ) {
    updateMerchantOutlet(input: $input, condition: $condition) {
      address
      deals {
        items {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          purchasableTexts
          showInOutletIds
          additionalPromotionInfo
          ticketType
          minQtyTx
        }
        nextToken
      }
      mainCat
      display_merchant_name
      distance
      general_location
      geohashlong
      geohashshort
      geohashsix
      google_id
      google_placeid
      google_type
      image_path
      image_src
      latitude
      longitude
      mallId
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_namelist
      merchant_unique_id
      online_company
      outlet_name
      phone
      postal_code
      postal_district
      postal_district_name
      postal_sector
      protectedAttributes
      store_id
      subc_online
      subcategory
      valid
      merchantImageUrl
      merchantImageUrlV2
      defaultImageUrl
      businessSummary
      outletWebsite
      mcc
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      blkAptNum
      roadName
      buildingName
      unitNum
      country
      type
      hideOperatingHours
    }
  }
`;
export const deleteMerchantOutlet = /* GraphQL */ `
  mutation DeleteMerchantOutlet(
    $input: DeleteMerchantOutletInput!
    $condition: ModelMerchantOutletConditionInput
  ) {
    deleteMerchantOutlet(input: $input, condition: $condition) {
      address
      deals {
        items {
          address
          aggregator
          area
          bank
          brand
          mainCat
          cc_buddy_category
          ccbuddy_card_name
          country
          deal_handler
          display_merchant_name
          district
          district_name
          end
          end_timestamp
          festive
          general_location
          geohashlong
          geohashshort
          google_id
          google_placeid
          google_type
          image_path
          image_url
          is_online
          isSponsored
          sponsoredRank
          latitude
          longitude
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_unique_id
          online_company
          phone
          pk
          postal_code
          promo_code
          promotion
          promotion_analytic
          promotion_caption
          rank
          rank_updated
          score
          sector
          start
          start_timestamp
          store_id
          subcategory
          TR_tags
          terms
          usable_promocode
          valid
          website
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          isPinned
          pinPageLocation
          pinOrder
          purchasable
          actualPrice
          discount
          discountPrice
          purchasePrice
          purchaseLimitPerCustomer
          purchasableTexts
          showInOutletIds
          additionalPromotionInfo
          ticketType
          minQtyTx
        }
        nextToken
      }
      mainCat
      display_merchant_name
      distance
      general_location
      geohashlong
      geohashshort
      geohashsix
      google_id
      google_placeid
      google_type
      image_path
      image_src
      latitude
      longitude
      mallId
      merchant_id
      merchant_name
      merchant_name_lowercase
      merchant_namelist
      merchant_unique_id
      online_company
      outlet_name
      phone
      postal_code
      postal_district
      postal_district_name
      postal_sector
      protectedAttributes
      store_id
      subc_online
      subcategory
      valid
      merchantImageUrl
      merchantImageUrlV2
      defaultImageUrl
      businessSummary
      outletWebsite
      mcc
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      blkAptNum
      roadName
      buildingName
      unitNum
      country
      type
      hideOperatingHours
    }
  }
`;
export const createMerchant = /* GraphQL */ `
  mutation CreateMerchant(
    $input: CreateMerchantInput!
    $condition: ModelMerchantConditionInput
  ) {
    createMerchant(input: $input, condition: $condition) {
      mainCat
      outlets {
        items {
          address
          mainCat
          display_merchant_name
          distance
          general_location
          geohashlong
          geohashshort
          geohashsix
          google_id
          google_placeid
          google_type
          image_path
          image_src
          latitude
          longitude
          mallId
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_namelist
          merchant_unique_id
          online_company
          outlet_name
          phone
          postal_code
          postal_district
          postal_district_name
          postal_sector
          protectedAttributes
          store_id
          subc_online
          subcategory
          valid
          merchantImageUrl
          merchantImageUrlV2
          defaultImageUrl
          businessSummary
          outletWebsite
          mcc
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          blkAptNum
          roadName
          buildingName
          unitNum
          country
          type
          hideOperatingHours
        }
        nextToken
      }
      display_merchant_name
      google_type
      merchant_id
      merchant_name
      merchant_name_lowercase
      online_company
      valid
      acceptCC
      merchantImageUrl
      merchantImageUrlV2
      defaultImageUrl
      pockCalMerchImage
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      website
      summary
      cmsPlan
      stripeExpressConnectedAccountId
    }
  }
`;
export const updateMerchant = /* GraphQL */ `
  mutation UpdateMerchant(
    $input: UpdateMerchantInput!
    $condition: ModelMerchantConditionInput
  ) {
    updateMerchant(input: $input, condition: $condition) {
      mainCat
      outlets {
        items {
          address
          mainCat
          display_merchant_name
          distance
          general_location
          geohashlong
          geohashshort
          geohashsix
          google_id
          google_placeid
          google_type
          image_path
          image_src
          latitude
          longitude
          mallId
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_namelist
          merchant_unique_id
          online_company
          outlet_name
          phone
          postal_code
          postal_district
          postal_district_name
          postal_sector
          protectedAttributes
          store_id
          subc_online
          subcategory
          valid
          merchantImageUrl
          merchantImageUrlV2
          defaultImageUrl
          businessSummary
          outletWebsite
          mcc
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          blkAptNum
          roadName
          buildingName
          unitNum
          country
          type
          hideOperatingHours
        }
        nextToken
      }
      display_merchant_name
      google_type
      merchant_id
      merchant_name
      merchant_name_lowercase
      online_company
      valid
      acceptCC
      merchantImageUrl
      merchantImageUrlV2
      defaultImageUrl
      pockCalMerchImage
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      website
      summary
      cmsPlan
      stripeExpressConnectedAccountId
    }
  }
`;
export const deleteMerchant = /* GraphQL */ `
  mutation DeleteMerchant(
    $input: DeleteMerchantInput!
    $condition: ModelMerchantConditionInput
  ) {
    deleteMerchant(input: $input, condition: $condition) {
      mainCat
      outlets {
        items {
          address
          mainCat
          display_merchant_name
          distance
          general_location
          geohashlong
          geohashshort
          geohashsix
          google_id
          google_placeid
          google_type
          image_path
          image_src
          latitude
          longitude
          mallId
          merchant_id
          merchant_name
          merchant_name_lowercase
          merchant_namelist
          merchant_unique_id
          online_company
          outlet_name
          phone
          postal_code
          postal_district
          postal_district_name
          postal_sector
          protectedAttributes
          store_id
          subc_online
          subcategory
          valid
          merchantImageUrl
          merchantImageUrlV2
          defaultImageUrl
          businessSummary
          outletWebsite
          mcc
          createdAt
          updatedAt
          createdByUser
          createdByGroups
          blkAptNum
          roadName
          buildingName
          unitNum
          country
          type
          hideOperatingHours
        }
        nextToken
      }
      display_merchant_name
      google_type
      merchant_id
      merchant_name
      merchant_name_lowercase
      online_company
      valid
      acceptCC
      merchantImageUrl
      merchantImageUrlV2
      defaultImageUrl
      pockCalMerchImage
      createdAt
      updatedAt
      createdByUser
      createdByGroups
      website
      summary
      cmsPlan
      stripeExpressConnectedAccountId
    }
  }
`;
export const createTopicNotifications = /* GraphQL */ `
  mutation CreateTopicNotifications(
    $input: CreateTopicNotificationsInput!
    $condition: ModelTopicNotificationsConditionInput
  ) {
    createTopicNotifications(input: $input, condition: $condition) {
      notificationId
      topicName
      title
      message
      customUrl
      publishTimestamp
      bigText
      createdAt
      updatedAt
      createdBy
      isActivated
      csvFileName
    }
  }
`;
export const updateTopicNotifications = /* GraphQL */ `
  mutation UpdateTopicNotifications(
    $input: UpdateTopicNotificationsInput!
    $condition: ModelTopicNotificationsConditionInput
  ) {
    updateTopicNotifications(input: $input, condition: $condition) {
      notificationId
      topicName
      title
      message
      customUrl
      publishTimestamp
      bigText
      createdAt
      updatedAt
      createdBy
      isActivated
      csvFileName
    }
  }
`;
export const deleteTopicNotifications = /* GraphQL */ `
  mutation DeleteTopicNotifications(
    $input: DeleteTopicNotificationsInput!
    $condition: ModelTopicNotificationsConditionInput
  ) {
    deleteTopicNotifications(input: $input, condition: $condition) {
      notificationId
      topicName
      title
      message
      customUrl
      publishTimestamp
      bigText
      createdAt
      updatedAt
      createdBy
      isActivated
      csvFileName
    }
  }
`;
export const createCoupons = /* GraphQL */ `
  mutation CreateCoupons(
    $input: CreateCouponsInput!
    $condition: ModelCouponsConditionInput
  ) {
    createCoupons(input: $input, condition: $condition) {
      pk
      type
      merchantId
      merchantName
      merchantImage
      title
      promoCode
      terms
      valid
      createdAt
      updatedAt
    }
  }
`;
export const updateCoupons = /* GraphQL */ `
  mutation UpdateCoupons(
    $input: UpdateCouponsInput!
    $condition: ModelCouponsConditionInput
  ) {
    updateCoupons(input: $input, condition: $condition) {
      pk
      type
      merchantId
      merchantName
      merchantImage
      title
      promoCode
      terms
      valid
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoupons = /* GraphQL */ `
  mutation DeleteCoupons(
    $input: DeleteCouponsInput!
    $condition: ModelCouponsConditionInput
  ) {
    deleteCoupons(input: $input, condition: $condition) {
      pk
      type
      merchantId
      merchantName
      merchantImage
      title
      promoCode
      terms
      valid
      createdAt
      updatedAt
    }
  }
`;
export const createCategoryBestDeals = /* GraphQL */ `
  mutation CreateCategoryBestDeals(
    $input: CreateCategoryBestDealsInput!
    $condition: ModelCategoryBestDealsConditionInput
  ) {
    createCategoryBestDeals(input: $input, condition: $condition) {
      categoryBestDealsId
      content
      seeMoreShown
      mainCat
      valid
      title
      createdAt
      updatedAt
    }
  }
`;
export const updateCategoryBestDeals = /* GraphQL */ `
  mutation UpdateCategoryBestDeals(
    $input: UpdateCategoryBestDealsInput!
    $condition: ModelCategoryBestDealsConditionInput
  ) {
    updateCategoryBestDeals(input: $input, condition: $condition) {
      categoryBestDealsId
      content
      seeMoreShown
      mainCat
      valid
      title
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategoryBestDeals = /* GraphQL */ `
  mutation DeleteCategoryBestDeals(
    $input: DeleteCategoryBestDealsInput!
    $condition: ModelCategoryBestDealsConditionInput
  ) {
    deleteCategoryBestDeals(input: $input, condition: $condition) {
      categoryBestDealsId
      content
      seeMoreShown
      mainCat
      valid
      title
      createdAt
      updatedAt
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      activityId
      activityDesc
      coinValue
      bonusValue
      order
      repeat
      route
      subTitle
      title
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      activityId
      activityDesc
      coinValue
      bonusValue
      order
      repeat
      route
      subTitle
      title
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      activityId
      activityDesc
      coinValue
      bonusValue
      order
      repeat
      route
      subTitle
      title
      type
      createdAt
      updatedAt
    }
  }
`;
export const createDailyTips = /* GraphQL */ `
  mutation CreateDailyTips(
    $input: CreateDailyTipsInput!
    $condition: ModelDailyTipsConditionInput
  ) {
    createDailyTips(input: $input, condition: $condition) {
      id
      cardId
      dealId
      route
      category
      day
      title
      description
      displayType
      displayDate
      icon
      theme
      createdAt
      updatedAt
    }
  }
`;
export const updateDailyTips = /* GraphQL */ `
  mutation UpdateDailyTips(
    $input: UpdateDailyTipsInput!
    $condition: ModelDailyTipsConditionInput
  ) {
    updateDailyTips(input: $input, condition: $condition) {
      id
      cardId
      dealId
      route
      category
      day
      title
      description
      displayType
      displayDate
      icon
      theme
      createdAt
      updatedAt
    }
  }
`;
export const deleteDailyTips = /* GraphQL */ `
  mutation DeleteDailyTips(
    $input: DeleteDailyTipsInput!
    $condition: ModelDailyTipsConditionInput
  ) {
    deleteDailyTips(input: $input, condition: $condition) {
      id
      cardId
      dealId
      route
      category
      day
      title
      description
      displayType
      displayDate
      icon
      theme
      createdAt
      updatedAt
    }
  }
`;
export const createCMSUser = /* GraphQL */ `
  mutation CreateCMSUser(
    $input: CreateCMSUserInput!
    $condition: ModelCMSUserConditionInput
  ) {
    createCMSUser(input: $input, condition: $condition) {
      email
      firstName
      lastName
      mobile
      roles
      marketingConsent
      agreeToTerms
      subPlan
      subPlanStartDate
      subPlanEndDate
      createdAt
      updatedAt
      profileImage
      stripe {
        items {
          accountId
          email
          merchantId
          type
          journeyStep
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      corpName
      uen
      verified
    }
  }
`;
export const updateCMSUser = /* GraphQL */ `
  mutation UpdateCMSUser(
    $input: UpdateCMSUserInput!
    $condition: ModelCMSUserConditionInput
  ) {
    updateCMSUser(input: $input, condition: $condition) {
      email
      firstName
      lastName
      mobile
      roles
      marketingConsent
      agreeToTerms
      subPlan
      subPlanStartDate
      subPlanEndDate
      createdAt
      updatedAt
      profileImage
      stripe {
        items {
          accountId
          email
          merchantId
          type
          journeyStep
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      corpName
      uen
      verified
    }
  }
`;
export const deleteCMSUser = /* GraphQL */ `
  mutation DeleteCMSUser(
    $input: DeleteCMSUserInput!
    $condition: ModelCMSUserConditionInput
  ) {
    deleteCMSUser(input: $input, condition: $condition) {
      email
      firstName
      lastName
      mobile
      roles
      marketingConsent
      agreeToTerms
      subPlan
      subPlanStartDate
      subPlanEndDate
      createdAt
      updatedAt
      profileImage
      stripe {
        items {
          accountId
          email
          merchantId
          type
          journeyStep
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      corpName
      uen
      verified
    }
  }
`;
export const createCMSUserStripeAccount = /* GraphQL */ `
  mutation CreateCMSUserStripeAccount(
    $input: CreateCMSUserStripeAccountInput!
    $condition: ModelCMSUserStripeAccountConditionInput
  ) {
    createCMSUserStripeAccount(input: $input, condition: $condition) {
      accountId
      email
      merchantId
      type
      journeyStep
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateCMSUserStripeAccount = /* GraphQL */ `
  mutation UpdateCMSUserStripeAccount(
    $input: UpdateCMSUserStripeAccountInput!
    $condition: ModelCMSUserStripeAccountConditionInput
  ) {
    updateCMSUserStripeAccount(input: $input, condition: $condition) {
      accountId
      email
      merchantId
      type
      journeyStep
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteCMSUserStripeAccount = /* GraphQL */ `
  mutation DeleteCMSUserStripeAccount(
    $input: DeleteCMSUserStripeAccountInput!
    $condition: ModelCMSUserStripeAccountConditionInput
  ) {
    deleteCMSUserStripeAccount(input: $input, condition: $condition) {
      accountId
      email
      merchantId
      type
      journeyStep
      status
      createdAt
      updatedAt
    }
  }
`;
export const createVoucherMaster = /* GraphQL */ `
  mutation CreateVoucherMaster(
    $input: CreateVoucherMasterInput!
    $condition: ModelVoucherMasterConditionInput
  ) {
    createVoucherMaster(input: $input, condition: $condition) {
      id
      dealId
      code
      codeType
      startTimestamp
      endTimestamp
      userId
      status
      valid
      transactionId
      retrievalTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateVoucherMaster = /* GraphQL */ `
  mutation UpdateVoucherMaster(
    $input: UpdateVoucherMasterInput!
    $condition: ModelVoucherMasterConditionInput
  ) {
    updateVoucherMaster(input: $input, condition: $condition) {
      id
      dealId
      code
      codeType
      startTimestamp
      endTimestamp
      userId
      status
      valid
      transactionId
      retrievalTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteVoucherMaster = /* GraphQL */ `
  mutation DeleteVoucherMaster(
    $input: DeleteVoucherMasterInput!
    $condition: ModelVoucherMasterConditionInput
  ) {
    deleteVoucherMaster(input: $input, condition: $condition) {
      id
      dealId
      code
      codeType
      startTimestamp
      endTimestamp
      userId
      status
      valid
      transactionId
      retrievalTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const createVoucherUser = /* GraphQL */ `
  mutation CreateVoucherUser(
    $input: CreateVoucherUserInput!
    $condition: ModelVoucherUserConditionInput
  ) {
    createVoucherUser(input: $input, condition: $condition) {
      masterId
      dealId
      userId
      code
      startTimestamp
      endTimestamp
      status
      transactionId
      redeemTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateVoucherUser = /* GraphQL */ `
  mutation UpdateVoucherUser(
    $input: UpdateVoucherUserInput!
    $condition: ModelVoucherUserConditionInput
  ) {
    updateVoucherUser(input: $input, condition: $condition) {
      masterId
      dealId
      userId
      code
      startTimestamp
      endTimestamp
      status
      transactionId
      redeemTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteVoucherUser = /* GraphQL */ `
  mutation DeleteVoucherUser(
    $input: DeleteVoucherUserInput!
    $condition: ModelVoucherUserConditionInput
  ) {
    deleteVoucherUser(input: $input, condition: $condition) {
      masterId
      dealId
      userId
      code
      startTimestamp
      endTimestamp
      status
      transactionId
      redeemTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const createDynamicContent = /* GraphQL */ `
  mutation CreateDynamicContent(
    $input: CreateDynamicContentInput!
    $condition: ModelDynamicContentConditionInput
  ) {
    createDynamicContent(input: $input, condition: $condition) {
      contentId
      valid
      type
      order
      title
      tag
      pinPageLocation
      seeMoreEnabled
      startTimestamp
      endTimestamp
      bannerLinked
      bannerContent
      bannerPageImageUrl
      iconImageUrl
      route
      createdAt
      updatedAt
    }
  }
`;
export const updateDynamicContent = /* GraphQL */ `
  mutation UpdateDynamicContent(
    $input: UpdateDynamicContentInput!
    $condition: ModelDynamicContentConditionInput
  ) {
    updateDynamicContent(input: $input, condition: $condition) {
      contentId
      valid
      type
      order
      title
      tag
      pinPageLocation
      seeMoreEnabled
      startTimestamp
      endTimestamp
      bannerLinked
      bannerContent
      bannerPageImageUrl
      iconImageUrl
      route
      createdAt
      updatedAt
    }
  }
`;
export const deleteDynamicContent = /* GraphQL */ `
  mutation DeleteDynamicContent(
    $input: DeleteDynamicContentInput!
    $condition: ModelDynamicContentConditionInput
  ) {
    deleteDynamicContent(input: $input, condition: $condition) {
      contentId
      valid
      type
      order
      title
      tag
      pinPageLocation
      seeMoreEnabled
      startTimestamp
      endTimestamp
      bannerLinked
      bannerContent
      bannerPageImageUrl
      iconImageUrl
      route
      createdAt
      updatedAt
    }
  }
`;
export const createDiscountMaster = /* GraphQL */ `
  mutation CreateDiscountMaster(
    $input: CreateDiscountMasterInput!
    $condition: ModelDiscountMasterConditionInput
  ) {
    createDiscountMaster(input: $input, condition: $condition) {
      id
      valid
      status
      campaignId
      createdAt
      updatedAt
    }
  }
`;
export const updateDiscountMaster = /* GraphQL */ `
  mutation UpdateDiscountMaster(
    $input: UpdateDiscountMasterInput!
    $condition: ModelDiscountMasterConditionInput
  ) {
    updateDiscountMaster(input: $input, condition: $condition) {
      id
      valid
      status
      campaignId
      createdAt
      updatedAt
    }
  }
`;
export const deleteDiscountMaster = /* GraphQL */ `
  mutation DeleteDiscountMaster(
    $input: DeleteDiscountMasterInput!
    $condition: ModelDiscountMasterConditionInput
  ) {
    deleteDiscountMaster(input: $input, condition: $condition) {
      id
      valid
      status
      campaignId
      createdAt
      updatedAt
    }
  }
`;
export const createDiscountCampaign = /* GraphQL */ `
  mutation CreateDiscountCampaign(
    $input: CreateDiscountCampaignInput!
    $condition: ModelDiscountCampaignConditionInput
  ) {
    createDiscountCampaign(input: $input, condition: $condition) {
      campaignId
      code
      discountType
      terms
      usageLimitPerUser
      usageCapacity
      description
      discountLevel
      provider
      amount
      minimumSpend
      valid
      startTimestamp
      endTimestamp
      createdAt
      updatedAt
      multiplier
      dealSpecific
      dealExclusion
      merchantExclusion
      merchantSpecific
      capped
      appliesTo
      applicableOn
      multiplierLifetimeQty
    }
  }
`;
export const updateDiscountCampaign = /* GraphQL */ `
  mutation UpdateDiscountCampaign(
    $input: UpdateDiscountCampaignInput!
    $condition: ModelDiscountCampaignConditionInput
  ) {
    updateDiscountCampaign(input: $input, condition: $condition) {
      campaignId
      code
      discountType
      terms
      usageLimitPerUser
      usageCapacity
      description
      discountLevel
      provider
      amount
      minimumSpend
      valid
      startTimestamp
      endTimestamp
      createdAt
      updatedAt
      multiplier
      dealSpecific
      dealExclusion
      merchantExclusion
      merchantSpecific
      capped
      appliesTo
      applicableOn
      multiplierLifetimeQty
    }
  }
`;
export const deleteDiscountCampaign = /* GraphQL */ `
  mutation DeleteDiscountCampaign(
    $input: DeleteDiscountCampaignInput!
    $condition: ModelDiscountCampaignConditionInput
  ) {
    deleteDiscountCampaign(input: $input, condition: $condition) {
      campaignId
      code
      discountType
      terms
      usageLimitPerUser
      usageCapacity
      description
      discountLevel
      provider
      amount
      minimumSpend
      valid
      startTimestamp
      endTimestamp
      createdAt
      updatedAt
      multiplier
      dealSpecific
      dealExclusion
      merchantExclusion
      merchantSpecific
      capped
      appliesTo
      applicableOn
      multiplierLifetimeQty
    }
  }
`;
export const createPromoCodeMaster = /* GraphQL */ `
  mutation CreatePromoCodeMaster(
    $input: CreatePromoCodeMasterInput!
    $condition: ModelPromoCodeMasterConditionInput
  ) {
    createPromoCodeMaster(input: $input, condition: $condition) {
      promoCodeId
      campaignId
      codeType
      countAssigned
      countRedeemed
      endTimestamp
      retrievalTimestamp
      startTimestamp
      usageStatus
      userId
      valid
      validityType
      createdAt
      updatedAt
    }
  }
`;
export const updatePromoCodeMaster = /* GraphQL */ `
  mutation UpdatePromoCodeMaster(
    $input: UpdatePromoCodeMasterInput!
    $condition: ModelPromoCodeMasterConditionInput
  ) {
    updatePromoCodeMaster(input: $input, condition: $condition) {
      promoCodeId
      campaignId
      codeType
      countAssigned
      countRedeemed
      endTimestamp
      retrievalTimestamp
      startTimestamp
      usageStatus
      userId
      valid
      validityType
      createdAt
      updatedAt
    }
  }
`;
export const deletePromoCodeMaster = /* GraphQL */ `
  mutation DeletePromoCodeMaster(
    $input: DeletePromoCodeMasterInput!
    $condition: ModelPromoCodeMasterConditionInput
  ) {
    deletePromoCodeMaster(input: $input, condition: $condition) {
      promoCodeId
      campaignId
      codeType
      countAssigned
      countRedeemed
      endTimestamp
      retrievalTimestamp
      startTimestamp
      usageStatus
      userId
      valid
      validityType
      createdAt
      updatedAt
    }
  }
`;
export const createAdvertisement = /* GraphQL */ `
  mutation CreateAdvertisement(
    $input: CreateAdvertisementInput!
    $condition: ModelAdvertisementConditionInput
  ) {
    createAdvertisement(input: $input, condition: $condition) {
      advertisementId
      assetUrl
      buttonText
      category
      frequency
      name
      order
      route
      guest
      loggedIn
      fromAd
      skipTime
      type
      valid
      videoDuration
      createdAt
      updatedAt
    }
  }
`;
export const updateAdvertisement = /* GraphQL */ `
  mutation UpdateAdvertisement(
    $input: UpdateAdvertisementInput!
    $condition: ModelAdvertisementConditionInput
  ) {
    updateAdvertisement(input: $input, condition: $condition) {
      advertisementId
      assetUrl
      buttonText
      category
      frequency
      name
      order
      route
      guest
      loggedIn
      fromAd
      skipTime
      type
      valid
      videoDuration
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdvertisement = /* GraphQL */ `
  mutation DeleteAdvertisement(
    $input: DeleteAdvertisementInput!
    $condition: ModelAdvertisementConditionInput
  ) {
    deleteAdvertisement(input: $input, condition: $condition) {
      advertisementId
      assetUrl
      buttonText
      category
      frequency
      name
      order
      route
      guest
      loggedIn
      fromAd
      skipTime
      type
      valid
      videoDuration
      createdAt
      updatedAt
    }
  }
`;
