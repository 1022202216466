import { Hub, API, Auth, graphqlOperation } from 'aws-amplify'
import moment from 'moment'
import React, { Component } from 'react'
import { getSubPlan } from '../../graphql/queries'
import { Modal, Card, ListGroup, ListGroupItem, CardDeck } from "react-bootstrap"
import ACL from '../../utils/ACL'

// ordering matters. from least to most top tier plans. this will determine what cta.
export const SubPlans = [
    {
        version: 1,
        heading: 'Bronze Plan',
        frequency: 'Yearly',
        price: 99,
        description: 'Best for growing businesses with multiple deals<br /><br />',
        features: [
            'Listing of <strong>unlimited</strong> deals on CardsPal app',
            'Be featured in <strong>"Nearby Deals"</strong>',
            'Be featured in relevant <strong>categories</strong> and <strong>search</strong> results',
            'Additional shoutout for <strong>new merchants</strong>',
            '<strong>Manage deals</strong> across multiple outlets',
            'Be informed of the <strong>latest campaigns</strong> to engage our users'
        ],
        url: 'https://cardspal.com/business/cardspal-for-business-bronze-plan/',
        code: 'bronze',
        ddlText: 'bronze'
    },
    {
        version: 1,
        heading: 'Silver Plan',
        frequency: 'Yearly',
        price: 249,
        description: 'Increase your brand and deals exposure with our marketing support',
        features: [
            'Listing of <strong>unlimited deals</strong> on CardsPal app',
            '<strong>1</strong> x eDM feature (non-dedicated)',
            '<strong>5</strong> x Telegram posts (dedicated)',
            '<strong>1</strong> x Facebook post (non-dedicated)',
            '<strong>1</strong> x Instagram post (non-dedicated)',
            'Includes all entitlements in the <strong>Bronze Plan</strong>',
        ],
        url: 'https://cardspal.com/business/cardspal-for-business-silver-plan',
        code: 'silver',
        ddlText: 'silver'
    },
    {
        version: 1,
        heading: 'Gold Plan',
        frequency: 'Yearly',
        price: 499,
        description: 'Great for multiple year-round user engagement<br /><br />',
        features: [
            'Listing of <strong>unlimited deals</strong> on CardsPal app',
            '<strong>3</strong> x eDM feature (non-dedicated)',
            '<strong>8</strong> x Telegram posts (dedicated)',
            '<strong>3</strong> x Facebook post (non-dedicated)',
            '<strong>3</strong> x Instagram post (non-dedicated)',
            'Includes all entitlements in the <strong>Bronze Plan</strong>',
        ],
        url: 'https://cardspal.com/business/cardspal-for-business-gold-plan',
        code: 'gold',
        ddlText: 'gold'
    },
    {
        version: 2,
        heading: 'Waiver - Professional Plan',
        frequency: 'Yearly',
        price: 0,
        originalPrice: 468,
        description: '',
        features: [
            'Listing of <strong>unlimited deals</strong> on CardsPal app (unlimited brands)',
            'Be featured in <strong>"Nearby Deals"</strong>',
            'Be featured in relevant <strong>categories</strong> and <strong>search</strong> results',
            'Additional shoutout for <strong>new merchants</strong>',
            '<strong>Manage deals</strong> across multiple outlets',
            'Be informed of the <strong>latest campaigns</strong> + <strong>priority</strong> in campaign feature for each brand'
        ],
        url: 'https://cardspal.com/business/cardspal-for-business-professional-plan',
        code: 'waiver',
        ddlText: 'waiver'
    },
    {
        version: 2,
        heading: 'Free Trial',
        frequency: 'Yearly',
        price: 0,
        description: 'FREE for a limited time only!<br /><br /><br />',
        features: [
            'Listing of <strong>1</strong> deal on CardsPal app (limited to 1 brand)',
            'Be featured in <strong>"Nearby Deals"</strong>',
            'Be featured in relevant <strong>categories</strong> and <strong>search</strong> results',
            'Additional shoutout for <strong>new merchants</strong>',
            'Manage your deal across <strong>multiple outlets</strong>',
            'Be informed of the <strong>latest campaigns</strong> to engage our users'
        ],
        code: 'free',
        ddlText: 'free'
    },
    {
        version: 2,
        heading: 'Starter Plan',
        frequency: 'Yearly',
        price: 128,
        subHeading: 'Total entitlements worth $499<br />Includes welcome gift - 1x feature in EDM (non-dedicated)<br /><br />',
        description: 'Suitable for businesses with 1 to 3 brands',
        features: [
            'Listing of <strong>unlimited deals</strong> on CardsPal app (limited to 1 brand) - Additional brand at $100',
            'Be featured in <strong>"Nearby Deals"</strong>',
            'Be featured in relevant <strong>categories</strong> and <strong>search</strong> results',
            'Additional shoutout for <strong>new merchants</strong>',
            '<strong>Manage deals</strong> across multiple outlets',
            'Be informed of the <strong>latest campaigns</strong> to engage our users'
        ],
        url: 'https://cardspal.com/business/cardspal-for-business-starter-plan-options/',
        code: ['starter-1', 'starter-2', 'starter-3'],
        ddlText: ['starter-1', 'starter-2', 'starter-3']
    },
    {
        version: 2,
        heading: 'Professional Plan',
        frequency: 'Yearly',
        price: 468,
        subHeading: 'Total entitlements worth $2,599<br />Includes welcome gift - 1x feature in EDM (non-dedicated) & 1x feature in "Deal of the day" for each brand<br /><br />',
        description: 'Best for businesses with 4 brands or more',
        features: [
            'Listing of <strong>unlimited deals</strong> on CardsPal app (unlimited brands)',
            'Be featured in <strong>"Nearby Deals"</strong>',
            'Be featured in relevant <strong>categories</strong> and <strong>search</strong> results',
            'Additional shoutout for <strong>new merchants</strong>',
            '<strong>Manage deals</strong> across multiple outlets',
            'Be informed of the <strong>latest campaigns</strong> + <strong>priority</strong> in campaign feature for each brand'
        ],
        url: 'https://cardspal.com/business/cardspal-for-business-professional-plan',
        code: 'professional',
        ddlText: 'professional'
    }
]
export class CMSSubPlan extends Component {

    constructor(props) {
        super(props)

        this.state = {
            subplan: '',
            show: false
        }

        Hub.listen('SubPlanChannel', (data) => {
            console.log(data)

            if (data.payload.event === 'open') {
                this.setState({
                    show: true
                })
            }
        })
    }

    async componentDidMount() {
        const attributes = await ACL.getAttributes()
        const plan = (await API.graphql(graphqlOperation(getSubPlan, { email: attributes.email }))).data.getSubPlan??{}
        const subplan = plan.plan??'free'
        const expiry = plan.endDate?parseInt(plan.endDate):null // dates are Strings of unix timestamp, need to convert to numbers for parsing in moments
        // filter plans to display, ie new plans and those subscribed
        const plans = SubPlans.filter(plan => (plan.version === 2 && plan.code !== 'waiver') || plan.code === subplan || plan.code.includes(subplan))
        let isOldPlan = false, subPlanIndex = -1
        for (let i=0; i<plans.length; i++) {
            const p = plans[i]
            if (p.code === subplan || p.code.includes(subplan)) {
                isOldPlan = p.version < 2
                subPlanIndex = i
            }
        }
        
        this.setState({
            email: attributes.email,
            plans,
            subplan,
            expiry,
            subPlanIndex,
            isOldPlan
        })
    }

    componentWillUnmount() {
    }

    onClose() {
        this.setState({
            show: false
        })

        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    onConfirm() {
        this.setState({
            show: false
        })

        if (this.props.onConfirm) {
            this.props.onConfirm()
        }
    }

    onCancel() {
        this.setState({
            show: false
        })

        if (this.props.onCancel) {
            this.props.onCancel()
        }
    }

    render() {
        return <Modal show={this.state.show} enforceFocus={false} onHide={() => this.onClose()} centered size="xl" className="text-center">

            <Modal.Header closeButton>
                <Modal.Title className="text-center w-100">
                    <p>Ready for more business?</p>
                    <p>Pick a plan and experience how easy it is to supercharge your business!</p>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <CardDeck>
                    {this.state.plans && this.state.plans.map((plan, index) => {
                        return <Card key={plan.code}>
                            <Card.Header style={{backgroundColor: (this.state.subplan === plan.code || (plan.code.includes(this.state.subplan)))?'#f2e7ff':null}}>
                                {plan.heading}
                                {plan.version===1?<small><br />(Phased Out)</small>:null}
                            </Card.Header>
                            <Card.Body>
                            <Card.Subtitle as="small" className="text-muted">{plan.frequency}</Card.Subtitle>
                            <Card.Title as="h2"><small>S$</small> {plan.price}</Card.Title>
                                {plan.originalPrice&&<Card.Subtitle><strike>S${plan.originalPrice}</strike></Card.Subtitle>}
                                <Card.Subtitle as="small" style={{color: '#D3ADF7'}} dangerouslySetInnerHTML={{__html:[plan.subHeading]}}></Card.Subtitle>
                                <Card.Subtitle as="small" className="text-muted" dangerouslySetInnerHTML={{__html:[plan.description]}}></Card.Subtitle>
                                <ListGroup variant="flush">
                                    {plan.features.map((feature, index) =>
                                        <ListGroupItem key={`${plan.code}-${index}`}><small dangerouslySetInnerHTML={{__html:feature}}></small></ListGroupItem>
                                    )}
                                </ListGroup>
                            </Card.Body>

                            <Card.Footer style={{backgroundColor: (this.state.subplan === plan.code || (plan.code.includes(this.state.subplan)))?'#f2e7ff':null}}>
                                {(this.state.subplan === plan.code || plan.code.includes(this.state.subplan)) ?
                                    <div className="text-muted">
                                        Your Current Plan
                                        {this.state.expiry?
                                            <span> is expiring on {moment.unix(this.state.expiry).format('Do MMM YYYY')}</span>
                                        :null}
                                    </div>
                                : (plan.code === 'free') ?
                                    <span>&nbsp;</span>
                                : (plan.version >=2 && plan.code !== 'waiver' && plan.url && this.state.subPlanIndex<=index) ?
                                    <a href={`${plan.url}?email=${this.state.email}`} target="_blank" rel="noreferrer">Get Started</a>
                                : 
                                    null
                                }
                            </Card.Footer>
                        </Card>
                    })}
                </CardDeck>

                <p>
                    Can't make up your mind or prefer a customised plan? <a href="https://cardspal.com/business/cardspal-for-business-contact-form">Contact us now!</a>
                </p>

                <p className="mt-5">
                    <a href="https://cardspal.com/business/#price" target="__cardspal_plans" className="float-left">
                        Learn more about CardsPal for Business Plans&gt;
                        </a>

                    <a href="https://cardspal.com/business/#faq" target="__cardspal_faq" className="float-right">
                        Frequently Asked Questions&gt;
                        </a>
                </p>
            </Modal.Body>
        </Modal>
    }
}

